<template>
  <span
    v-resize="checkMobile"
  >
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
        <v-expansion-panels
          v-if="agent && (agent.first_name || agent.last_name)"
          v-model="dataPanel"
        >
          <v-expansion-panel v-if="agent && (agent.first_name || agent.last_name)"
          v-model="dataPanel">
            <v-expansion-panel-header>
              <h2 class="mb-0 d-flex">
                {{ agent.first_name }} {{ agent.last_name }}
              </h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container v-if="agent" grid-list-md>
                <v-layout row wrap>
                  <v-card-text>
                    <v-text-field
                      :label="$t('custom.email')"
                      v-model.trim="agent.email"
                      :disabled="true"
                    ></v-text-field>
                    <v-text-field
                      v-model.trim="agent.name"
                      :placeholder="$t('employers.nickname')"
                      :label="$t('employers.nickname')"
                    ></v-text-field>
                    <v-text-field
                      v-model.trim="agent.first_name"
                      :placeholder="$t('employers.first_name')"
                      :label="$t('employers.name')"
                    ></v-text-field>
                    <v-text-field
                      v-model.trim="agent.middle_name"
                      :placeholder="$t('employers.middle_name')"
                      :label="$t('employers.middle_name')"
                    ></v-text-field>
                    <v-text-field
                      v-model.trim="agent.last_name"
                      :placeholder="$t('employers.last_name')"
                      :label="$t('employers.last_name')"
                    ></v-text-field>
                    <v-select
                      v-model="agent_setting.connect_type"
                      clearable
                      multiple
                      :items="connect_types"
                      :label="$t('agents.connect_type')"
                      class="mt-5"

                    ></v-select>
                    <v-text-field
                      v-model="agent_setting.commission_percent"
                      :label="$t('agents.commission_percent')"
                      type="number"
                      :disabled="agent_setting.commission_fix > 0"
                    ></v-text-field>
                    <v-text-field
                      v-model="agent_setting.commission_fix"
                      :label="$t('agents.commission_fix') + ', ' + currency"
                      type="number"
                      :disabled="agent_setting.commission_percent > 0"
                    ></v-text-field>
                    <!-- <v-text-field
                      v-model="agent_setting.prepayment_percent"
                      :label="$t('agents.prepayment_percent')"
                      type="number"
                    ></v-text-field> -->
                    <v-textarea
                      v-model.trim="agent_setting.payment_details"
                      :label="$t('agents.payment_details')"
                      rows="1"
                      auto-grow
                    ></v-textarea>
                    <v-btn class="mb-3" color="success" small @click="addWebsite()">
                      {{ $t("agents.add_site") }}
                      <v-icon class="ml-1"> mdi-plus </v-icon>
                    </v-btn>
                    <v-flex
                      :class="isMobile ? 'text-center' : 'd-flex mx-auto'"
                      md12
                      v-for="(website, index) in websites"
                      :key="index"
                    >
                      <v-text-field
                        class="mr-2 my-2"
                        v-model="website.value"
                        :label="$t('agents.website')"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                      <v-btn
                        v-if="websites && websites.length > 1"
                        :class="isMobile ? 'm-2' : 'ml-2 my-2'"
                        color="error"
                        @click="removeWebsite(index)"
                      >
                        -
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <v-radio-group
                        @change="update_agent_settings"
                        v-model="agent_setting.paid_commission_from_total"
                      >
                        <v-radio
                          :label="$t('agents.paid_commission_from_total')"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          :label="$t('agents.paid_commission_from_not_total')"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-flex>
                    <v-switch
                      v-if="checkSuperAdmin()"
                      @change="update_agent_settings"
                      v-model="agent_setting.can_edit_booking"
                      :label="$t('agents.can_edit_booking')"
                      class="mr-2 my-auto"
                      hide-details
                      dense
                    ></v-switch>
                    <v-switch
                      v-if="checkSuperAdmin()"
                      @change="saveEditAgent()"
                      v-model="agent.can_change_branch"
                      :label="$t('users.can_change_branch')"
                      class="mr-2 my-auto"
                      hide-details
                      dense
                    ></v-switch>
                    <v-switch
                      v-if="checkSuperAdmin()"
                      @change="update_agent_settings"
                      v-model="agent_setting.access_to_account"
                      :label="$t('agents.access_to_account')"
                      class="mr-2 my-auto"
                      hide-details
                      dense
                    ></v-switch>
                    <v-switch
                      v-if="checkSuperAdmin()"
                      @change="update_agent_settings"
                      v-model="agent_setting.can_take_money"
                      :label="$t('agents.can_take_money')"
                      class="mr-2 my-auto"
                      hide-details
                      dense
                    ></v-switch>
                    <!-- <v-switch
                      v-if="checkSuperAdmin()"
                      @change="update_agent_settings"
                      v-model="agent_setting.can_take_prepayment"
                      :label="$t('agents.can_take_prepayment')"
                      class="mr-2 my-auto"
                      hide-details
                      dense
                    ></v-switch> -->
                  </v-card-text>
                </v-layout>
                <v-flex class="d-flex align-middle justify-center">
                  <v-btn color="success"
                    @click="saveEditAgent()"
                  >
                    {{ $t("custom.save") }}<v-icon class="mx-auto">
                      mdi-content-save-check-outline
                    </v-icon>
                  </v-btn>
                </v-flex>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-toolbar
          v-if="agent && agent.agent_setting"
          text
          :color="$vuetify.theme.dark ? '' : 'white'"
          :class="isMobile ? 'mt-1' : 'mt-4'"
          :height="isMobile ? 100 : null"
        >
          <v-flex :class="isMobile ? '' : 'd-flex'">
            <v-flex v-if="agent.agent_setting && agent.agent_setting.commission_percent">
              {{ $t('agents.agents_commission') }}:
              {{ agent.agent_setting.commission_percent }}%
            </v-flex>
            <v-flex v-else-if="agent.agent_setting && agent.agent_setting.commission_fix">
              {{ $t('agents.agents_commission') }}:
              {{ agent.agent_setting.commission_fix }}{{ currency }}
            </v-flex>
            <v-flex>
              {{ $t("agents.to_payout") }}:
              {{ calculateAgentCommission() }}{{ currency }}
            </v-flex>
            <v-flex v-if="agent.account">
              {{ $t("custom.counts") }}:
              {{ agent.account.cash }}{{ currency }}
            </v-flex>
          </v-flex>
        </v-toolbar>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title>
        <span class="headline">{{ $t("bookings.counts") }}</span>
      </v-card-title>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 class="text-right mt-3">
            <v-icon
              v-if="selectedCounts && selectedCounts.length > 0"
              @click="processedSelectedCounts()"
              class="mx-2"
              style="cursor: pointer"
              color="warning"
              v-tooltip="{
                content: $t('counts.processed_selected_payment'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 1000,
                  hide: 500,
                },
              }"
            >
              mdi-check-underline-circle-outline
            </v-icon>
            <v-icon
              v-if="(user_role == 'superadmin' || user_role == 'admin')"
              @click="toXlsx()"
              class="mx-2"
              style="cursor: pointer"
              color="success"
            >
              mdi-file-excel-outline
            </v-icon>
          </v-flex>
          <v-flex xs12 sm12 md12>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="counts"
              :search="search"
              :loading="isLoading"
              :loading-text="$t('custom.loading_table')"
              :items-per-page="20"
              :dense="isMobile"
              mobile-breakpoint="100"
              :sort-by="['created_at']"
              :sort-desc="[true]"
              :class="!isMobile ? '' : 'is-mobile'"
              :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [20, 50, 100, -1],
              }"
            >
              <template slot="item" slot-scope="props">
                <tr
                  :class="
                    props.item.operation && props.item.sum < 0
                      ? 'red--text'
                      : ''
                  "
                >
                  <td>
                    <v-checkbox
                      v-model="props.item.selected"
                      color="primary"
                    ></v-checkbox>
                  </td>
                  <td class="text-center">
                    <router-link v-if="user_role == 'superadmin' || user_role == 'admin'" :to="{ path: `/company_counts/${props.item.id}` }">{{
                      props.item.id
                    }}</router-link>
                    <span v-else>{{
                      props.item.id
                    }}</span>
                  </td>
                  <td class="text-center" nowrap>
                    {{ formatCompletedAt(props.item.created_at) }}
                  </td>
                  <td class="text-center">
                    {{ translateCategory(props.item.group) }}
                  </td>
                  <td class="text-center">{{ props.item.description }}</td>
                  <td class="text-center">
                    {{ props.item.sum }}{{ currency }}
                  </td>
                  <td class="text-center" v-if="props.item.operation">
                    <v-icon class="text-center" style="color: green">
                      mdi-plus-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="!props.item.operation">
                    <v-icon class="text-center" style="color: tomato">
                      mdi-minus-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cash">
                    <v-icon
                      class="text-center"
                      color="success"
                      v-tooltip="{
                        content: $t('tooltips.cash_count'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-cash
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cashless">
                    <v-icon
                      class="text-center"
                      color="warning"
                      v-tooltip="{
                        content: $t('tooltips.terminal_count'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-contactless-payment-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.entity">
                    <v-icon
                      class="text-center"
                      color="secondery"
                      v-tooltip="{
                        content: $t('tooltips.entity_count'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-bank
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cash_card">
                    <v-icon
                      class="text-center"
                      color="info"
                      v-tooltip="{
                        content: $t('money.card_to_card'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-credit-card-check-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.client_balance">
                    <v-icon
                      class="text-center"
                      color="error"
                      v-tooltip="{
                        content: $t('money.client_balance'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-account-arrow-right
                    </v-icon>
                  </td>
                  <td
                    class="text-left"
                    nowrap
                    v-if="
                      props.item.booking_id &&
                      props.item.car_id &&
                      props.item.car_code
                    "
                  >
                    <p>
                      {{ $t("bookings.booking") }}:
                      <router-link
                        :to="{ path: `/bookings/${props.item.booking_id}` }"
                      >
                        {{ props.item.booking_id }}
                      </router-link>
                    </p>
                    <p>
                      {{ $t("custom.car") }}:
                      <router-link
                        :to="{ path: `/cars/${props.item.car_id}` }"
                      >
                        {{ props.item.car_code }}
                      </router-link>
                    </p>
                  </td>
                  <td
                    class="text-center"
                    v-else-if="props.item.car_id && props.item.car_code"
                  >
                    <router-link
                      :to="{ path: `/cars/${props.item.car_id}` }"
                      >{{ props.item.car_code }}</router-link
                    >
                  </td>
                  <td class="text-center" v-else-if="props.item.source">
                    {{ props.item.source }}
                  </td>
                  <td class="text-center" v-else-if="props.item.debt_id">
                    {{ $t("debts.debt") }} {{ $t("custom.number")
                    }}{{ props.item.debt_id }}
                  </td>
                  <td class="text-center" v-else-if="props.item.agent_id">
                    {{ $t("agents.agent") }} {{ $t("custom.number") }}
                    <router-link
                      :to="{ path: `/agent_card/${props.item.agent_id}` }"
                    >
                      {{ props.item.agent_id }}
                    </router-link>
                  </td>
                  <td
                    class="text-center"
                    v-else-if="props.item.investor_id"
                  >
                    <router-link
                      :to="{ path: `/investors/${props.item.investor_id}` }"
                      >{{ $t("employers.partner") }} {{ $t("custom.number")
                      }}{{ props.item.investor_id }}</router-link
                    >
                  </td>
                  <td class="text-center" v-else>-</td>
                  <td class="text-center">
                    <v-icon
                      v-if="props.item.completed"
                      color="success"
                      v-tooltip="{
                        content: $t('counts.completed'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      @click="completedTooltipDialog(props.item)"
                    >
                      mdi-check-circle-outline
                    </v-icon>
                    <v-icon
                      v-else
                      color="error"
                      v-tooltip="{
                        content: $t('counts.not_completed'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      @click="processedCount(props.item)"
                    >
                      mdi-alert-circle-outline
                    </v-icon>
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                {{ $t("custom.no_data_in_table") }}
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="mdi-alert"
              >
                {{ $t("tables.no_search_result", { msg: search }) }}
              </v-alert>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-title>
        <span class="headline">{{ $t("custom.bookings") }}</span>
      </v-card-title>
      <hr class="mb-2 mt-3" />
      <v-card-text>
        <v-toolbar
          text
          :color="$vuetify.theme.dark ? '' : 'white'"
          class="start-tour-car-1"
        >
          <v-spacer></v-spacer>
          <v-row align="center" class="ml-4">
            <v-text-field
              :dense="isMobile"
              v-model="search_bookings"
              prepend-icon="mdi-magnify"
              :label="$t('custom.search')"
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-row>
        </v-toolbar>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <vc-date-picker
              :value="new Date()"
              :attributes="calendar_attributes"
              title-position="left"
              :columns="$screens({ default: 1, md: 2, lg: 4 })"
              :is-expanded="true"
              :locale="$i18n.locale"
              :is-dark="$vuetify.theme.dark"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-3"></v-divider>

        <v-data-table
          :headers="bookings_headers"
          :items="bookings"
          :search="search_bookings"
          :loading="isLoading"
          :loading-text="$t('custom.loading_table')"
          sort-by="sort"
          :sort-desc="true"
          item-key="id"
          :items-per-page="10"
          mobile-breakpoint="100"
          :class="!isMobile ? '' : 'is-mobile'"
          dense
          :footer-props="{
            pageText: `{0} ${$t('custom.of')} {1}`,
            itemsPerPageText: $t('custom.elements_table'),
            showFirstLastPage: true,
            itemsPerPageOptions: [20, 50, 100, -1],
          }"
        >
          <template slot="item" slot-scope="props">
            <tr
              @dblclick="replaceToBooking(props.item)"
              style="cursor: pointer"
              :class="
                !props.item.technical ? '' : 'blue-grey lighten-4'
              "
            >
              <td class="text-center" :key="props.item.id">
                <router-link
                  :to="{
                    name: 'BookingCard',
                    params: { id: props.item.id },
                  }"
                  >{{ props.item.id }}</router-link
                >
              </td>
              <td nowrap class="text-center">
                {{ returnDate(props.item.created_at) }}
              </td>
              <td class="text-center">
                <v-flex class="d-flex text-center justify-center">
                  <v-icon
                    class="mr-2"
                    v-if="props.item.in_rent"
                    color="primary"
                    v-tooltip="{
                      content: $t('bookings.in_rent'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                  >
                    mdi-car
                  </v-icon>
                  <v-icon
                    v-if="props.item.active && !props.item.in_rent"
                    class="mr-2"
                    style="z-index: 1"
                    color="secondary"
                    v-tooltip="{
                      content: $t('bookings.booked'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                  >
                    mdi-clock-outline
                  </v-icon>
                  <v-icon
                    v-if="props.item.failure"
                    v-tooltip="{
                      content: $t('bookings.decline'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                  >
                    mdi-close-circle-outline
                  </v-icon>
                </v-flex>
              </td>
              <td class="text-center">
                <v-flex class="d-flex text-center justify-center">
                  <v-menu
                    offset-y
                    v-if="props.item.state == 'Новая'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon
                          color="primary"
                          v-tooltip="{
                            content: $t('bookings.new'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }"
                        >
                          mdi-new-box
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(state, index) in bookings_states"
                        :key="index"
                        @click="
                          saveBookingState(
                            state.value,
                            props.item.id
                          )
                        "
                      >
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu
                    offset-y
                    v-if="props.item.state == 'Подтверждена'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon
                          color="success"
                          v-tooltip="{
                            content: $t('bookings.approved'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }"
                        >
                          mdi-check-all
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(state, index) in bookings_states"
                        :key="index"
                        @click="
                          saveBookingState(
                            state.value,
                            props.item.id
                          )
                        "
                      >
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu
                    offset-y
                    v-if="props.item.state == 'Не подтверждена'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon
                          color="warning"
                          v-tooltip="{
                            content: $t('bookings.not_approved'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }"
                        >
                          mdi-cellphone-basic
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(state, index) in bookings_states"
                        :key="index"
                        @click="
                          saveBookingState(
                            state.value,
                            props.item.id
                          )
                        "
                      >
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu
                    offset-y
                    v-if="props.item.state == 'В обработке'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon
                          color="primary"
                          v-tooltip="{
                            content: $t('bookings.in_work'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }"
                        >
                          mdi-autorenew
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(state, index) in bookings_states"
                        :key="index"
                        @click="
                          saveBookingState(
                            state.value,
                            props.item.id
                          )
                        "
                      >
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu
                    offset-y
                    v-if="props.item.state == 'Отказ клиента'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon
                          color="danger"
                          v-tooltip="{
                            content: $t('bookings.declined'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }"
                        >
                          mdi-account-remove-outline
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(state, index) in bookings_states"
                        :key="index"
                        @click="
                          saveBookingState(
                            state.value,
                            props.item.id
                          )
                        "
                      >
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu
                    offset-y
                    v-if="props.item.state == 'Нет машин'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon
                          color="secondary"
                          v-tooltip="{
                            content: $t(
                              'bookings.declined_free_cars'
                            ),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }"
                        >
                          mdi-car-off
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(state, index) in bookings_states"
                        :key="index"
                        @click="
                          saveBookingState(
                            state.value,
                            props.item.id
                          )
                        "
                      >
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu
                    offset-y
                    v-if="props.item.state == 'Недозвон'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="warning--text"
                      >
                        <v-icon
                          color="warning"
                          v-tooltip="{
                            content: $t('bookings.not_called'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }"
                        >
                          mdi-phone-off-outline
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(state, index) in bookings_states"
                        :key="index"
                        @click="
                          saveBookingState(
                            state.value,
                            props.item.id
                          )
                        "
                      >
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu
                    offset-y
                    v-if="
                      props.item.state == 'Ожидает ответа клиента'
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon
                          color="pink"
                          v-tooltip="{
                            content: $t(
                              'bookings.waiting_for_answer'
                            ),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }"
                        >
                          mdi-account-question-outline
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(state, index) in bookings_states"
                        :key="index"
                        @click="
                          saveBookingState(
                            state.value,
                            props.item.id
                          )
                        "
                      >
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu
                    offset-y
                    v-if="props.item.state == 'Ожидает оплаты'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon
                          color="lime"
                          v-tooltip="{
                            content: $t('bookings.waiting_for_pay'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }"
                        >
                          mdi-currency-usd-off
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(state, index) in bookings_states"
                        :key="index"
                        @click="
                          saveBookingState(
                            state.value,
                            props.item.id
                          )
                        "
                      >
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu
                    offset-y
                    v-if="props.item.state == 'Активная'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon
                          color="light-blue"
                          v-tooltip="{
                            content: $t('bookings.active_state'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }"
                        >
                          mdi-alpha-a-circle-outline
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(state, index) in bookings_states"
                        :key="index"
                        @click="
                          saveBookingState(
                            state.value,
                            props.item.id
                          )
                        "
                      >
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu
                    offset-y
                    v-if="props.item.state == 'Отмена'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon
                          color="brown"
                          v-tooltip="{
                            content: $t('bookings.canceled_state'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }"
                        >
                          mdi-cancel
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(state, index) in bookings_states"
                        :key="index"
                        @click="
                          saveBookingState(
                            state.value,
                            props.item.id
                          )
                        "
                      >
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu
                    offset-y
                    v-if="props.item.state == 'Отъездила'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon
                          color="light-green"
                          v-tooltip="{
                            content: $t('bookings.ride_true'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }"
                        >
                          mdi-garage-variant
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(state, index) in bookings_states"
                        :key="index"
                        @click="
                          saveBookingState(
                            state.value,
                            props.item.id
                          )
                        "
                      >
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu
                    offset-y
                    v-if="
                      props.item.state == 'Другое' ||
                      props.item.state == null
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon
                          color="secondary"
                          v-tooltip="{
                            content: $t('bookings.other_state'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }"
                        >
                          mdi-head-question-outline
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(state, index) in bookings_states"
                        :key="index"
                        @click="
                          saveBookingState(
                            state.value,
                            props.item.id
                          )
                        "
                      >
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-flex>
              </td>
              <td
                class="text-center"
                nowrap
                v-if="!props.item.entity"
                :style="
                  user_role == 'guest' ? 'display: none;' : ''
                "
              >
                <router-link
                  :to="{
                    name: 'ClientCard',
                    params: { id: props.item.client_id },
                  }"
                >
                  {{
                    props.item.last_name ? props.item.last_name : ""
                  }}
                  {{
                    props.item.first_name
                      ? props.item.first_name[0]
                      : ""
                  }}.
                  {{
                    props.item.middle_name
                      ? props.item.middle_name[0]
                      : ""
                  }}.
                </router-link>
              </td>
              <td
                class="text-center"
                v-else
                :style="
                  user_role == 'guest' ? 'display: none;' : ''
                "
              >
                <router-link
                  :to="{
                    name: 'ClientCard',
                    params: { id: props.item.client_id },
                  }"
                  >{{ props.item.entity_name }}</router-link
                >
              </td>
              <td v-if="!isMobile" nowrap class="text-center">
                {{ formatDateBookingDates(props.item.start_date) }}
                <br />
                {{ formatTimeBookingDates(props.item.start_date) }}
              </td>
              <td v-else nowrap class="text-center">
                {{ shortFormatBookingDates(props.item.start_date) }}
              </td>
              <td
                class="text-center"
                nowrap
                v-if="!isMobile && !props.item.active"
              >
                {{ formatDateBookingDates(props.item.end_date) }}
                <br />
                {{ formatTimeBookingDates(props.item.end_date) }}
              </td>
              <td
                class="text-center"
                nowrap
                v-if="isMobile && !props.item.active"
              >
                {{ shortFormatBookingDates(props.item.end_date) }}
              </td>
              <td
                class="text-center"
                nowrap
                v-if="!isMobile && props.item.active"
                :style="checkLateDate(props.item.end_date)"
              >
                {{ formatDateBookingDates(props.item.end_date) }}
                <br />
                {{ formatTimeBookingDates(props.item.end_date) }}
              </td>
              <td
                class="text-center"
                nowrap
                v-if="isMobile && props.item.active"
                :style="checkLateDate(props.item.end_date)"
              >
                {{ shortFormatBookingDates(props.item.end_date) }}
              </td>
              <td class="text-center">{{ props.item.days }}</td>
              <td class="text-center">
                {{ props.item.location_start }}
              </td>
              <td class="text-center">
                {{ props.item.location_end }}
              </td>
              <td
                class="text-truncate"
                style="max-width: 200px"
                @click="
                  (showDescriptionEditId = props.item.id),
                    (descriptionEdit = props.item.description),
                    (showDescriptionEditDialog = true)
                "
                v-tooltip="{
                  content: `${props.item.description}`,
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              >
                {{ props.item.description }}
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ $t("custom.no_data_in_table") }}
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
          >
            {{
              $t("tables.no_search_result", {
                msg: search_bookings,
              })
            }}
          </v-alert>
        </v-data-table>
        <!-- </v-layout> -->
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showProcessedInfoDialog"
      :retain-focus="false"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title v-if="workers && workers.length > 0">
          {{`${$t('counts.completed')}: ${formatCompletedAt(
            editedCount.completed_at
          )}, ${findWorkersName([editedCount.completed_by])}`}}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showProcessedInfoDialog = false, editedCount = {}">{{
            $t("custom.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
/* eslint-disable */
let uuid = require("uuid");
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
import { isMobile } from "mobile-device-detect";
import xlsx from "json-as-xlsx";
import PullToRefresh from "pulltorefreshjs";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { saveAs } from "file-saver";

export default {
  data() {
    return {
      agent: {
        role: null,
        email: null,
        name: null,
        active: true,
        can_change_branch: false,
      },
      editedCount: {},
      counts: [],
      bookings: [],
      selected: [],
      search: null,
      headers: [
        { text: '', sortable: false, align: "center", value: "selected" },
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.group"), value: "group", align: "center" },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        {
          text: this.$t("custom.operation"),
          value: "operation",
          align: "center",
        },
        { text: this.$t("custom.type"), value: "cashless", align: "center" },
        { text: this.$t("custom.source"), sortable: false, align: "center" },
        { text: this.$t("counts.completed"), value: "completed", align: "center" },
      ],
      agent_setting: {
        access_to_account: true,
        can_edit_booking: true,
        can_take_money: true,
        can_take_prepayment: true,
        commission_fix: 0,
        commission_percent: 0,
        connect_type: ["manual"],
        payment_details: "",
        prepayment_percent: 0,
        website: [],
        paid_commission_from_total: true,
      },
      bookings_headers: [
        { text: this.$t("custom.number"), value: "id", align: "center" },
        {
          text: this.$t("custom.created"),
          value: "created_at",
          align: "center",
        },
        {
          text: this.$t("custom.state_short"),
          value: "in_rent",
          align: "center",
        },
        {
          text: this.$t("custom.state_short_2"),
          value: "state",
          align: "center",
        },
        { text: this.$t("custom.client"), value: "last_name", align: "center" },
        { text: this.$t("custom.start"), value: "start_date", align: "center" },
        { text: this.$t("custom.end"), value: "end_date", align: "center" },
        {
          text: this.$t("custom.days"),
          sortable: false,
          value: "days",
          align: "center",
        },
        {
          text: this.$t("custom.checkin"),
          sortable: false,
          value: "location_start",
          align: "center",
        },
        {
          text: this.$t("custom.checkout"),
          sortable: false,
          value: "location_end",
          align: "center",
        },
        {
          text: this.$t("custom.description_2"),
          sortable: false,
          align: "center",
        },
      ],
      bookings_states: [
        { text: this.$t("bookings_states.new"), value: "Новая" },
        { text: this.$t("bookings_states.in_work"), value: "В обработке" },
        { text: this.$t("bookings_states.abandoned"), value: "Недозвон" },
        { text: this.$t("bookings_states.decline"), value: "Отказ клиента" },
        { text: this.$t("bookings_states.no_cars"), value: "Нет машин" },
        {
          text: this.$t("bookings_states.wait_answer"),
          value: "Ожидает ответа клиента",
        },
        { text: this.$t("bookings_states.wait_pay"), value: "Ожидает оплаты" },
        { text: this.$t("bookings_states.active"), value: "Активная" },
        { text: this.$t("bookings_states.cancel"), value: "Отмена" },
        { text: this.$t("bookings_states.rided"), value: "Отъездила" },
        {
          text: this.$t("bookings_states.return_deposit"),
          value: "Вернуть залог",
        },
        { text: this.$t("bookings_states.accepted"), value: "Подтверждена" },
        {
          text: this.$t("bookings_states.not_accepted"),
          value: "Не подтверждена",
        },
        { text: this.$t("bookings_states.other"), value: "Другое" },
      ],
      websites: [],
      calendar_attributes: [],
      connect_types: [
        { text: this.$t("agents.connect_type_1"), value: "manual" },
      ],
      roles: [
        { text: this.$t("employers.employee"), value: "user" },
        { text: this.$t("employers.manager"), value: "manager" },
        { text: this.$t("employers.admin"), value: "admin" },
        { text: this.$t("employers.partner"), value: "partner" },
        { text: this.$t("employers.guest"), value: "guest" },
      ],
      super_roles: [
        { text: this.$t("employers.employee"), value: "user" },
        { text: this.$t("employers.manager"), value: "manager" },
        { text: this.$t("employers.admin"), value: "admin" },
        { text: this.$t("employers.superadmin"), value: "superadmin" },
        { text: this.$t("employers.partner"), value: "partner" },
        { text: this.$t("employers.guest"), value: "guest" },
      ],
      workers: [],
      search_bookings: "",
      dataPanel: true,
      showProcessedInfoDialog: false,
      isMobile: false,
      isLoading: false,
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/agents_data/${this.$route.params.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.agent = response.data.agent;
          this.agent_setting = response.data.agent_setting;
          this.agent.agent_setting = response.data.agent_setting;
          this.websites = response.data.agent_setting.website.map((website) => {
            return { value: website };
          });
          this.agent.account = response.data.account;
          this.counts = [];
          response.data.counts.data.forEach((count) => {
            this.counts.push(count.attributes);
          });
          this.bookings = [];
          response.data.bookings.data.forEach((booking) => {
            this.bookings.push(booking.attributes);
          });
          function compare(a, b) {
            if (
              moment(a.end_date, "DD-MM-YYYY H:mm").isBefore(
                moment(b.end_date, "DD-MM-YYYY H:mm")
              )
            ) {
              return 1;
            }
            if (
              moment(a.end_date, "DD-MM-YYYY H:mm").isSameOrAfter(
                moment(b.end_date, "DD-MM-YYYY H:mm")
              )
            ) {
              return -1;
            }
            return 0;
          }
          this.bookings = this.bookings.sort(compare);
          function selectHighlight(booking) {
            if (booking.active) {
              return booking.in_rent ? "green" : "blue";
            } else {
              return "gray";
            }
          }

          this.calendar_attributes = [];
          this.bookings.forEach((booking) => {
            if (booking.active || booking.in_rent || booking.ride) {
              self.calendar_attributes.push({
                highlight: {
                  start: {
                    fillMode: "outline",
                    color: selectHighlight(booking),
                  },
                  base: { fillMode: "light", color: selectHighlight(booking) },
                  end: { fillMode: "outline", color: selectHighlight(booking) },
                },
                content: "black",
                dates: {
                  start: moment(booking.start_date, "DD-MM-YYYY H:mm").toDate(),
                  end: moment(booking.end_date, "DD-MM-YYYY H:mm").toDate(),
                },
                popover: {
                  label: `${self.$t("custom.number")}${booking.id} ${
                    booking.start_date
                  } ${booking.location_start} - ${booking.end_date} ${
                    booking.location_end
                  } | ${booking.last_name} ${booking.first_name} ${
                    booking.middle_name
                  }`,
                  visibility: "hover",
                  hideIndicator: true,
                },
              });
            }
          });

          this.agent.bookings_ids = response.data.bookings.data.map(
            (booking) => {
              return parseInt(booking.id);
            }
          );
          // this.workers = response.data.workers;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  computed: {
    selectedCounts() {
      let selected_counts = [];
      let counts = this.counts.filter((count) => {
        return count.selected;
      });
      counts.forEach((count) => {
        selected_counts.push(count);
      });
      return counts;
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    company() {
      if (this.$store.getters.getCompany) {
        return this.$store.getters.getCompany.company;
      } else {
        return null;
      }
    },
    current_user_id() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.id;
      } else {
        return "";
      }
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    checkWriteFile() {
      return Capacitor.isNativePlatform() && Filesystem.checkPermissions();
    },
    requestPermissionsWriteFile() {
      return Filesystem.requestPermissions();
    },
  },
  methods: {
    saveEditAgent() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        this.axios
          .patch(
            `/admin/users/${this.$route.params.id}`,
            {
              user: {
                role: this.agent.role,
                name: this.agent.name,
                last_name: this.agent.last_name,
                middle_name: this.agent.middle_name,
                first_name: this.agent.first_name,
                partner_id: this.agent.partner_id,
                can_change_branch: this.agent.can_change_branch,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.update_agent_settings();
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("users.user_update_success_title"),
              text: this.$t("users.user_update_success_text"),
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status == 406) {
              this.setError(error, this.$t("errors.must_be_one_superadmin"));
            } else {
              this.setError(error, this.$t("errors.error"));
            }
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.only_admins"),
        });
      }
    },
    update_agent_settings() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        this.agent_setting.website = this.websites.map((website) => {
          return website.value;
        });
        this.axios
          .patch(
            `api/v1/agent_settings/${this.agent_setting.id}`,
            {
              agent_settings: this.agent_setting,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("agents.settings_saved"),
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status == 406) {
              this.setError(error, this.$t("errors.must_be_one_superadmin"));
            } else {
              this.setError(error, this.$t("errors.error"));
            }
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.only_admins"),
        });
      }
    },
    removeWebsite(index) {
      this.websites.splice(index, 1);
    },
    addWebsite() {
      this.websites.push({ value: "" });
    },
    saveUserActive(state) {
      if (this.checkAdmin()) {
        this.isLoading = true;
        this.axios
          .patch(
            `/admin/users/${this.$route.params.id}`,
            {
              user: {
                active: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.user.active = state;
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("users.user_update_success_title"),
              text: this.$t("users.user_update_success_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.only_admins"),
        });
      }
    },
    processedCount(count) {
      if (this.user_role == "superadmin" || this.user_role == "admin") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("counts.processed_payment_not_declined"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.yes"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .post(
                `/api/v1/processed_count`,
                {
                  count_id: count.id,
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                let foundIndex = this.counts.findIndex(
                  (element) => element.id === count.id
                )
                this.counts.splice(foundIndex, 1, response.data);

                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "success",
                  title: this.$t("counts.update_count_title"),
                  text: this.$t("counts.update_count_text"),
                });
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.update_count"));
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "error",
                  title: this.$t("errors.update_count"),
                  text: this.error,
                });
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    calculateAgentCommission() {
      let self = this;
      let commission = 0;
      let paid_commission = 0;
      const agent = this.agent
      let counts = this.counts.filter(
        (count) => agent.bookings_ids.includes(count.booking_id) || count.agent_id == agent.id
      );
      let total_counts_sum = 0;
      counts.forEach((count) => {
        if(agent.agent_setting.paid_commission_from_total) {
          if(count.group != "Залог" && count.group != "Выплаты агентам") {
            total_counts_sum += count.sum;
          }
        } else {
          if(count.group == "Оплата аренды") {
            total_counts_sum += count.sum;
          }
        }
        // минусуем уже выплаченные агенту комиссии
        if(count.group == 'Выплаты агентам') {
          paid_commission += count.sum;
        }
      });
      if(agent.agent_setting && agent.agent_setting.commission_percent) {
        commission = total_counts_sum * parseFloat(agent.agent_setting.commission_percent) / 100.0;
      }
      if(agent.agent_setting && agent.agent_setting.commission_fix) {
        commission += parseFloat(agent.agent_setting.commission_fix);
      }
      return commission - paid_commission;
    },
    processedSelectedCounts() {
      if (this.user_role == "superadmin" || this.user_role == "admin") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("counts.processed_payment_not_declined"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.yes"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          let counts_ids = this.selectedCounts.map((count) => count.id)
          if (result.value && counts_ids && counts_ids.length > 0) {
            this.isLoading = true;
            this.axios
              .post(
                `/api/v1/processed_selected_counts`,
                {
                  counts_ids: this.selectedCounts.map((count) => count.id),
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                this.selectedCounts.forEach((count) => {
                  let foundIndex = this.counts.findIndex(
                    (element) => element.id === count.id
                  )
                  count.completed = true;
                  this.counts.splice(foundIndex, 1, count);
                })

                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "success",
                  title: this.$t("counts.update_count_title"),
                  text: this.$t("counts.update_count_text"),
                });
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.update_count"));
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "error",
                  title: this.$t("errors.update_count"),
                  text: this.error,
                });
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    toXlsx() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        let data = [
          {
            sheets: "Payments",
            columns: [
              { label: "ID", value: "id" },
              { label: this.$t("clients.created_at"), value: "created_at" },
              { label: this.$t("custom.amount"), value: "sum" },
              { label: this.$t("custom.operation"), value: "operation" },
              { label: this.$t("counts.about"), value: "description" },
              { label: this.$t("money.terminal"), value: "cashless" },
              { label: this.$t("money.card_to_card"), value: "cash_card" },
              { label: this.$t("money.cash"), value: "cash" },
              { label: this.$t("money.from_entity"), value: "entity" },
              { label: this.$t("custom.group"), value: (row) => {
                return this.translateCategory(row.group);
              }},
              { label: this.$t("custom.car"), value: "car_code" },
              { label: this.$t("bookings.booking"), value: "booking_id" },
              { label: this.$t("debts.debt"), value: "debt_id" },
              { label: this.$t("employers.partner"), value: "investor_id" },
              { label: this.$t("counts.completed"), value: "completed" },
            ],
            content: this.selectedCounts && this.selectedCounts.length > 0 ? this.selectedCounts : this.counts,
          },
        ];
        let settings = {
          fileName: "agents_payments", // Name of the resulting spreadsheet
          extraLength: 3, // A bigger number means that columns will be wider
          writeMode: "write", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
          writeOptions: {
            bookType: "xlsx",
            type: "array",
          }, // Style options from https://docs.sheetjs.com/docs/api/write-options
          RTL: false, // Display the columns from right-to-left (the default value is false)
        };
        let wbout = xlsx(data, settings);
        var blob_data = new Blob([new Uint8Array(wbout)], {type:"application/octet-stream"});
        if (!this.isNative) {
          saveAs(blob_data, 'agents_payments.xlsx');
        } else {
          if (Filesystem.checkPermissions()) {
            const getBase64FromBlob = async (blob) => {
              return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                  const base64data = reader.result;
                  resolve(base64data);
                }
              });
            }
            getBase64FromBlob(blob_data).then(
              (base64) => {
                // save file
                async function writeFile() {
                  return await Filesystem.writeFile({
                    path: 'agents_payments.xlsx',
                    data: base64,
                    directory: Directory.Documents,
                  });
                };
                let file_uri = null
                let writedFile = writeFile().then((getUriResult) => {
                  // share file opened file
                  let shareFile = async () => {
                    await Share.share({
                      title: 'Download document',
                      files: [getUriResult.uri],
                    });
                  };
                  let sharedFile = shareFile();
                });
              }
            );
          } else {
            Filesystem.requestPermissions();
          }
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    findWorkersName(ids) {
      let names = [];
      ids.forEach((id) => {
        this.workers.forEach((worker) => {
          if (worker.id == id) {
            names.push(worker.name);
          }
        });
      });
      return names ? names.join(", ") : "";
    },
    completedTooltipDialog(item) {
      if(item && item.completed_at && item.completed_by) {
        this.showProcessedInfoDialog = true;
        this.editedCount = item;
      } else {
        this.showProcessedInfoDialog = false;
      }
    },
    translateCategory(category) {
      let group_text = "";
      switch (category) {
        case "Оплата аренды":
          group_text = this.$t("groups_counts.rent");
          break;
        case "Доплата за доставку":
          group_text = this.$t("groups_counts.delivery");
          break;
        case "Доплата за приём":
          group_text = this.$t("groups_counts.checkout");
          break;
        case "Доплата за топливо":
          group_text = this.$t("groups_counts.fuel");
          break;
        case "Доплата за мойку":
          group_text = this.$t("groups_counts.clean");
          break;
        case "Доплата за оборудование":
          group_text = this.$t("groups_counts.equipment");
          break;
        case "Доплата за дополнительное время":
          group_text = this.$t("groups_counts.add_time");
          break;
        case "Доплата за повреждения":
          group_text = this.$t("groups_counts.damages");
          break;
        case "Доплата за штрафы":
          group_text = this.$t("groups_counts.fines");
          break;
        case "Доплата за другое":
          group_text = this.$t("groups_counts.other");
          break;
        case "Доплата за превышение пробега":
          group_text = this.$t("groups_counts.mileage");
          break;
        case "Доплата за страховку":
          group_text = this.$t("groups_counts.insurance");
          break;
        case "Доплата за доп водителей":
          group_text = this.$t("groups_counts.add_drivers");
          break;
        case "Ремонт":
          group_text = this.$t("groups_counts.repair");
          break;
        case "Обслуживание":
          group_text = this.$t("groups_counts.service");
          break;
        case "Запчасти":
          group_text = this.$t("groups_counts.parts");
          break;
        case "Страховка":
          group_text = this.$t("groups_counts.insurance_main");
          break;
        case "Накладные расходы":
          group_text = this.$t("groups_counts.common_expences");
          break;
        case "Транспортные расходы":
          group_text = this.$t("groups_counts.transport_expences");
          break;
        case "Налоги":
          group_text = this.$t("groups_counts.tax");
          break;
        case "Штрафы":
          group_text = this.$t("groups_counts.fines_main");
          break;
        case "Аренда имущества":
          group_text = this.$t("groups_counts.rent_property");
          break;
        case "Мойка автомобилей":
          group_text = this.$t("groups_counts.clean_main");
          break;
        case "Топливо":
          group_text = this.$t("groups_counts.fuel_main");
          break;
        case "Реклама":
          group_text = this.$t("groups_counts.ads");
          break;
        case "Зарплата":
          group_text = this.$t("groups_counts.salary");
          break;
        case "Залог":
          group_text = this.$t("groups_counts.deposit");
          break;
        case "Внутренние переводы":
          group_text = this.$t("groups_counts.internal");
          break;
        case "Продажа имущества":
          group_text = this.$t("groups_counts.sell_property");
          break;
        case "Покупка имущества":
          group_text = this.$t("groups_counts.buy_property");
          break;
        case "Прочие расходы":
          group_text = this.$t("groups_counts.other_expences");
          break;
        case "Прочие доходы":
          group_text = this.$t("groups_counts.other_incomes");
          break;
        case "Выплаты партнёрам":
          group_text = this.$t("groups_counts.partners_payments");
          break;
        case "Сервисные сборы":
          group_text = this.$t("groups_counts.service_fees");
          break;
        case "Пополнение баланса клиента":
          group_text = this.$t("groups_counts.top_up_client_balance");
          break;
        case "Выплаты агентам":
          group_text = this.$t("groups_counts.agent_payments");
          break;
      }
      return group_text;
    },
    checkLateDate(date) {
      if (!moment().isSameOrBefore(moment(date, "DD-MM-YYYY H:mm"), "day")) {
        return "color: tomato;";
      }
    },
    formatTimeBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("H:mm");
    },
    formatDateBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("DD MMM");
    },
    returnDate(item) {
      if (item) {
        return moment(item, "YYYY-MM-DD H:mm").format("DD MMM H:mm");
      } else {
        return "-";
      }
    },
    setCar(id) {
      if (id != null) {
        return this.cars.find((car) => car.id == id).code;
      } else {
        return "-";
      }
    },
    shortFormatBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("DD-MM H:mm");
    },
    replaceToBooking(booking) {
      this.$router.push({ path: `/bookings/${booking.id}` });
    },
    formatCompletedAt(date) {
      if (date == null) {
        return "-";
      }
      return moment.parseZone(date).format("lll");
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkAdminManager() {
      return (
        this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isManager
      );
    },
    checkSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
  },
};
</script>
