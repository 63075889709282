<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <!-- <h2>{{ $t("custom.cars") }}</h2> -->
    <v-card>
      <v-toolbar
        text
        :color="$vuetify.theme.dark ? '' : 'white'"
        v-if="!isMobile"
        class="mb-4 start-tour-cars-1"
      >
        <vc-date-picker
          v-model="sort_date_range_not_formatted"
          mode="dateTime"
          :attributes="attrs_calendar"
          is24hr
          is-range
          :locale="$i18n.locale"
          :is-dark="$vuetify.theme.dark"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              :value="sort_date_range"
              v-on="inputEvents.start || inputEvents.end"
              class="px-2"
              :label="$t('cars.free_on_period')"
              readonly
              prepend-icon="mdi-calendar-clock"
              style="width: 350px;"
              hide-details
              clearable
              @click:clear="
                (sort_date_range_not_formatted = {
                  start: new Date(),
                  end: null,
                }),
                  (sort_date_range = null)
              "
            />
          </template>
        </vc-date-picker>
        <!-- <v-icon
          class="mr-2 mt-2 text-left"
          v-if="sort_date_range_not_formatted.end"
          @click="
            (sort_date_range_not_formatted = {
              start: new Date(),
              end: null,
            }),
              (sort_date_range = null)
          "
        >
          mdi-close
        </v-icon> -->
        <v-checkbox
          class="mt-5"
          :label="$t('cars.econom')"
          v-model="showEconom"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-5"
          :label="$t('cars.middle')"
          v-model="showMiddle"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-5"
          :label="$t('cars.business')"
          v-model="showBusiness"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-5"
          :label="$t('cars.commercial')"
          v-model="showCommercial"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('custom.search')"
          single-line
          clearable
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn class="mr-2" small color="success" to="/cars/new">{{
          $t("custom.add_car")
        }}</v-btn>
        <v-btn small color="secondary" to="/archive_cars">{{
          $t("custom.archive")
        }}</v-btn>
        <download-excel
          :data="cars"
          v-if="
            cars &&
            cars.length > 0 &&
            (user_role == 'superadmin' || user_role == 'admin')
          "
        >
          <v-icon class="ml-2" style="cursor: pointer" color="success">
            mdi-file-excel-outline
          </v-icon>
        </download-excel>
        <v-btn
          v-if="!isIos"
          icon
          :href="`https://rentprog.${
            $root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/${$root.$i18n.locale}/categories/7`"
          target="_blank"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar
        v-else
        height="230px"
        text
        :color="$vuetify.theme.dark ? '' : 'white'"
        class="mb-2 start-tour-cars-1"
      >
        <v-flex md12 class="text-center" justify-center>
          <v-flex md12 class="mt-2 d-flex" style="max-width: 250px">
            <vc-date-picker
              v-model="sort_date_range_not_formatted"
              mode="dateTime"
              :attributes="attrs_calendar"
              is24hr
              is-range
              :locale="$i18n.locale"
              :is-dark="$vuetify.theme.dark"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <v-text-field
                  :value="sort_date_range"
                  v-on="inputEvents.start || inputEvents.end"
                  class="pb-2"
                  :label="$t('cars.free_on_period')"
                  readonly
                  prepend-icon="mdi-calendar-clock"
                  style="width: 310px;"
                  hide-details
                  clearable
                  @click:clear="
                    (sort_date_range_not_formatted = {
                      start: new Date(),
                      end: null,
                    }),
                      (sort_date_range = null)
                  "
                />
              </template>
            </vc-date-picker>
          </v-flex>
          <v-flex md12 class="mt-2">
            <v-flex md6 class="d-flex justify-space-between">
              <v-checkbox
                class=""
                :label="$t('cars.econom')"
                v-model="showEconom"
              ></v-checkbox>
              <v-checkbox
                class=""
                :label="$t('cars.middle')"
                v-model="showMiddle"
              ></v-checkbox>
            </v-flex>
            <v-flex md6 class="d-flex justify-space-between">
              <v-checkbox
                class=""
                :label="$t('cars.business')"
                v-model="showBusiness"
              ></v-checkbox>
              <v-checkbox
                class=""
                :label="$t('cars.commercial')"
                v-model="showCommercial"
              ></v-checkbox>
            </v-flex>
          </v-flex>
          <v-flex md12 class="mt-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('custom.search')"
              class="mb-1"
              single-line
              clearable
              hide-details
            ></v-text-field>
            <v-flex md12 class="d-flex justify-center align-center">
              <v-btn small class="mt-2" color="success" to="/cars/new">{{
                $t("custom.add_car")
              }}</v-btn>
              <v-btn
                small
                class="mt-2 ml-2"
                color="secondary"
                to="/archive_cars"
                >{{ $t("custom.archive") }}</v-btn
              >
              <download-excel
                :data="cars"
                v-if="
                  cars &&
                  cars.length > 0 &&
                  (user_role == 'superadmin' || user_role == 'admin')
                "
              >
                <v-icon
                  class="ml-2 mt-3"
                  style="cursor: pointer"
                  color="success"
                >
                  mdi-file-excel-outline
                </v-icon>
              </download-excel>
            </v-flex>
          </v-flex>
        </v-flex>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :loading="isLoading"
        :loading-text="$t('custom.loading_table')"
        :items="cars"
        :search="search"
        sort-by="sort"
        :sort-desc="false"
        item-key="id"
        mobile-breakpoint="100"
        :class="!isMobile ? '' : 'is-mobile'"
        dense
        :items-per-page="50"
        :footer-props="{
          pageText: `{0} ${$t('custom.of')} {1}`,
          itemsPerPageText: $t('custom.elements_table'),
          showFirstLastPage: true,
          itemsPerPageOptions: [20, 50, 100, -1],
        }"
        id="registration-step-37"
      >
        <template slot="header" slot-scope="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="[
                'column sortable',
                pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy ? 'active' : '',
              ]"
              @click="changeSort(header.value)"
            >
              <v-icon small>arrow_upward</v-icon>
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template slot="item" slot-scope="props">
          <tr
            @dblclick="replaceToCar(props.item)"
            :class="props.item.active ? '' : 'grey--text text--lighten-1'"
          >
            <td class="text-center" :key="props.item.id">
              <router-link
                id="car_id"
                :to="{ path: `/cars/${props.item.id}` }"
                >{{ props.item.id }}</router-link
              >
            </td>
            <td class="text-center">
              <router-link
                id="car_id"
                :to="{ path: `/cars/${props.item.id}` }"
                >{{ props.item.car_name }}</router-link
              >
            </td>
            <td class="text-center">
              <router-link :to="{ path: `/cars/${props.item.id}` }">{{
                props.item.code
              }}</router-link>
            </td>
            <!-- Состояние -->
            <td class="text-center align-center">
              <v-menu offset-y v-if="props.item.state == 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-tooltip="{
                      content: $t('states.normal'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="success"
                  >
                    mdi-thumb-up
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(state, index) in states"
                    :key="index"
                    @click="updateCarState(props.item.id, state.id)"
                  >
                    <v-list-item-title>
                      {{ state.state }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 2">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-tooltip="{
                      content: $t('states.in_service'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="secondary"
                  >
                    mdi-tools
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(state, index) in states"
                    :key="index"
                    @click="updateCarState(props.item.id, state.id)"
                  >
                    <v-list-item-title>
                      {{ state.state }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 3">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-tooltip="{
                      content: $t('states.critical'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                  >
                    mdi-alert-box
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(state, index) in states"
                    :key="index"
                    @click="updateCarState(props.item.id, state.id)"
                  >
                    <v-list-item-title>
                      {{ state.state }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 4">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-tooltip="{
                      content: $t('states.long_rent'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="pink"
                  >
                    mdi-all-inclusive
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(state, index) in states"
                    :key="index"
                    @click="updateCarState(props.item.id, state.id)"
                  >
                    <v-list-item-title>
                      {{ state.state }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 5">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-tooltip="{
                      content: $t('states.no_rent'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="info"
                  >
                    mdi-airplane-off
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(state, index) in states"
                    :key="index"
                    @click="updateCarState(props.item.id, state.id)"
                  >
                    <v-list-item-title>
                      {{ state.state }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 6">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-tooltip="{
                      content: $t('states.no_rent'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="orange"
                  >
                    mdi-alarm-light
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(state, index) in states"
                    :key="index"
                    @click="updateCarState(props.item.id, state.id)"
                  >
                    <v-list-item-title>
                      {{ state.state }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td
              class="text-center"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              {{ props.item.number }}
            </td>
            <td class="text-center">{{ props.item.color }}</td>
            <td class="text-center">{{ props.item.year }}</td>
            <td
              class="text-center"
              v-for="(period, index) in periods"
              :key="period.index"
            >
              {{ returnPrice(props.item.id, index) }}
            </td>
            <td class="text-center">
              {{ returnPrice(props.item.id, periods.length) }}
            </td>
            <td class="text-center">{{ props.item.price_hour }}</td>
            <td class="text-center">{{ props.item.deposit }}</td>
            <td class="text-center" nowrap>
              {{ returnDate(props.item.last_inspection) }}
            </td>
            <!-- tires types -->
            <td class="text-center">
              <v-menu offset-y v-if="props.item.tire_type == 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-tooltip="{
                      content: $t('wheels.summer'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="yellow"
                  >
                    mdi-weather-sunny
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(tire_type, index) in tire_types"
                    :key="index"
                    @click="updateTireType(props.item.id, tire_type.id)"
                  >
                    <v-list-item-title>
                      {{ tire_type.tire_type }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.tire_type == 2">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-tooltip="{
                      content: $t('wheels.winter'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="info"
                  >
                    mdi-snowflake
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(tire_type, index) in tire_types"
                    :key="index"
                    @click="updateTireType(props.item.id, tire_type.id)"
                  >
                    <v-list-item-title>
                      {{ tire_type.tire_type }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.tire_type == 3">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-tooltip="{
                      content: $t('wheels.all_seasons'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="info"
                  >
                    mdi-theme-light-dark
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(tire_type, index) in tire_types"
                    :key="index"
                    @click="updateTireType(props.item.id, tire_type.id)"
                  >
                    <v-list-item-title>
                      {{ tire_type.tire_type }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.tire_type == 4">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-tooltip="{
                      content: $t('wheels.both'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="info"
                  >
                    mdi-circle-half-full
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(tire_type, index) in tire_types"
                    :key="index"
                    @click="updateTireType(props.item.id, tire_type.id)"
                  >
                    <v-list-item-title>
                      {{ tire_type.tire_type }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.tire_type == 5">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-tooltip="{
                      content: $t('wheels.spike'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="light-green"
                  >
                    mdi-pine-tree
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(tire_type, index) in tire_types"
                    :key="index"
                    @click="updateTireType(props.item.id, tire_type.id)"
                  >
                    <v-list-item-title>
                      {{ tire_type.tire_type }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <template slot="no-data">
          {{ $t("custom.no_data_in_table") }}
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
          {{ $t("tables.no_search_result", { msg: search }) }}
        </v-alert>
      </v-data-table>
    </v-card>
  </span>
</template>
<script>
/* eslint-disable */
import { isMobile } from "mobile-device-detect";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { Capacitor } from "@capacitor/core";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      cars: [],
      all_cars: [],
      prices: [],
      editedCar: "",
      bookings: [],
      filtred_bookings_car_ids: [],
      states: [
        { id: 1, state: this.$t("states.normal") },
        { id: 2, state: this.$t("states.in_service") },
        { id: 3, state: this.$t("states.critical") },
        { id: 4, state: this.$t("states.long_rent") },
        { id: 5, state: this.$t("states.no_rent") },
        { id: 6, state: this.$t("states.service_needed") },
      ],
      tire_types: [
        { id: 1, tire_type: this.$i18n.t("wheels.summer") },
        { id: 2, tire_type: this.$i18n.t("wheels.winter") },
        { id: 3, tire_type: this.$i18n.t("wheels.all_seasons") },
        { id: 4, tire_type: this.$i18n.t("wheels.both") },
        { id: 5, tire_type: this.$i18n.t("wheels.spike") },
      ],
      error: "",
      snack_text: "",
      snack_mode: "",
      snack_color: "",
      search: "",
      sort_date_range_not_formatted: {
        start: new Date(),
        end: null,
      },
      sort_date_range: null,
      showRangeMenu: false,
      attrs_calendar: [
        {
          key: "today",
          highlight: {
            color: "blue",
            fillMode: "outline",
            contentClass: "italic",
          },
          dates: new Date(),
        },
      ],
      showEconom: false,
      showMiddle: false,
      showBusiness: false,
      showCommercial: false,
      dialog: false,
      car_class: ["Эконом", "Средний", "Бизнес", "Коммерческий"],
      options: {
        sortBy: "car_name",
      },
      selected: [],
      seasons: [],
      selected_season: null,
      periods: [],
      headers: [
        { text: this.$t("custom.id"), value: "id", align: "center" },
        { text: this.$t("custom.car"), value: "car_name", align: "center" },
        { text: this.$t("custom.code"), value: "code", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
        {
          text: this.$t("custom.number"),
          sortable: false,
          value: "number",
          align: "center",
        },
        { text: this.$t("custom.color"), sortable: false, align: "center" },
        { text: this.$t("custom.year"), sortable: false, align: "center" },
        // { text: "+31", sortable: false, align: "center" },
        {
          text: this.$t("custom.price_for_hour"),
          sortable: false,
          align: "center",
        },
        { text: this.$t("bookings.deposit"), sortable: false, align: "center" },
        {
          text: this.$t("to.to_short"),
          value: "last_inspection",
          align: "center",
        },
        { text: this.$t("wheels.tire"), sortable: false, align: "center" },
      ],
      isMobile: false,
      isLoading: false,
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      if (
        this.company &&
        this.company.periods
      ) {
        this.isLoading = true;
        // Делаю заголовки из периодов
        this.company.periods
          .sort((a, b) => {
            return parseInt(b) - parseInt(a);
          })
          .forEach((period) => {
            this.periods.push(period);
            this.headers.splice(7, 0, {
              text: `${period}`,
              sortable: false,
              align: "center",
            });
          });
        let last_value = this.periods
          .sort((a, b) => {
            return parseInt(a) - parseInt(b);
          })
          [self.periods.length - 1].split(" - ")[1];
        if (last_value) {
          this.headers.splice(7 + self.periods.length, 0, {
            text: `${last_value}+`,
            sortable: false,
            align: "center",
          });
        } else {
          this.periods.push({
            text: `+`,
            sortable: false,
            align: "center",
          });
        }
        // this.periods.push(`${this.periods[this.periods.length][1]}+`);
        this.axios
          .get("/api/v1/all_cars_with_bookings", {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.seasons = response.data.seasons;
            this.seasons.forEach((season) => {
              let start = moment(season.start_date, "DD.MM");
              let end = moment(season.end_date, "DD.MM");
              if (moment().isBetween(start, end)) {
                this.selected_season = season;
              }
            });
            response.data.cars.data.forEach((car) => {
              this.cars.push(car.attributes);
              this.all_cars.push(car.attributes);
            });
            response.data.cars.included.forEach((price) => {
              this.prices.push(price.attributes);
            });
            this.bookings = [];
            // берем из сериалазера
            response.data.bookings.data.forEach((booking) => {
              if (booking.attributes.active) {
                this.bookings.push(booking.attributes);
              }
            });
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.seasons_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
        // this.axios
        //   .get("/api/v1/seasons.json", {
        //     headers: {
        //       Authorization: this.$store.getters.getAuthToken,
        //     },
        //   })
        //   .then((response) => {
        //     this.seasons = response.data;
        //     self.seasons.forEach((season) => {
        //       let start = moment(season.start_date, "DD.MM");
        //       let end = moment(season.end_date, "DD.MM");
        //       if (moment().isBetween(start, end)) {
        //         self.selected_season = season;
        //       }
        //     });
        //   })
        //   .catch((error) => {
        //     this.isLoading = false;
        //     this.setError(error, this.$t("errors.seasons_load"));
        //     console.log(error);
        //   });
        // this.axios
        //   .get("/api/v1/bookings", {
        //     headers: {
        //       Authorization: this.$store.getters.getAuthToken,
        //     },
        //   })
        //   .then((response) => {
        //     this.bookings = [];
        //     // берем из сериалазера
        //     response.data.data.forEach((booking) => {
        //       if (booking.attributes.active) {
        //         this.bookings.push(booking.attributes);
        //       }
        //     });
        //   })
        //   .catch((error) => {
        //     this.setError(error, this.$t("errors.bookings_load"));
        //     console.log(error);
        //   });

        // this.axios
        //   .get("/api/v1/cars", {
        //     headers: {
        //       Authorization: this.$store.getters.getAuthToken,
        //     },
        //   })
        //   .then((response) => {
        //     response.data.data.forEach((element) => {
        //       this.cars.push(element.attributes);
        //       this.all_cars.push(element.attributes);
        //     });
        //     response.data.included.forEach((element) => {
        //       this.prices.push(element.attributes);
        //     });
        //   })
        //   .catch((error) => {
        //     this.setError(error, this.$t("errors.cars_load"));
        //     console.log(error);
        //   })
          // .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          icon: "warning",
          title: this.$t("errors.input_periods"),
          text: this.$t("errors.input_periods_text"),
          confirmButtonText: this.$t("custom.ok"),
        });
      }
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  mounted: function () {
    if (
      this.$tours["registrationTour"] &&
      this.$store.getters.getTourNextStep
    ) {
      this.$tours["registrationTour"].nextStep();
    }
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  watch: {
    sort_date_range_not_formatted() {
      if (
        this.sort_date_range_not_formatted.start &&
        this.sort_date_range_not_formatted.end
      ) {
        this.sort_date_range = `${moment(
          this.sort_date_range_not_formatted.start
        ).format("DD-MM-YYYY H:mm")} - ${moment(
          this.sort_date_range_not_formatted.end
        ).format("DD-MM-YYYY H:mm")}`;
      }
    },
    sort_date_range: function () {
      var self = this;
      this.filtred_bookings_car_ids = [];
      this.cars = [];
      this.all_cars.forEach((car) => this.cars.push(car));

      // Зазаор между бронями
      let zazor =
        self.$store.getters.getCompany.interval_bookings > 0
          ? self.$store.getters.getCompany.interval_bookings
          : 1;

      if (this.sort_date_range && this.sort_date_range.includes(" - ")) {
        let check_dates = this.sort_date_range.split(" - ");
        // выбераем сезон цен
        self.selected_season = null;
        self.seasons.forEach((season) => {
          let start = moment(season.start_date, "DD.MM");
          let end = moment(season.end_date, "DD.MM");
          if (moment(check_dates, "DD-MM-YYYY H:mm").isBetween(start, end)) {
            self.selected_season = season;
          }
        });

        function checkDates(booking_dates) {
          let start_booking = moment(
            booking_dates[0],
            "DD-MM-YYYY H:mm"
          ).subtract(zazor, "hours");
          let end_booking = moment(booking_dates[1], "DD-MM-YYYY H:mm").add(
            zazor,
            "hours"
          );
          let start_check_period = moment(check_dates[0], "DD-MM-YYYY H:mm");
          let end_check_period = moment(check_dates[1], "DD-MM-YYYY H:mm");

          if (
            moment(start_booking).isBetween(
              start_check_period,
              end_check_period,
              "hour"
            ) ||
            moment(end_booking).isBetween(
              start_check_period,
              end_check_period,
              "hour"
            ) ||
            moment(start_check_period).isBetween(
              start_booking,
              end_booking,
              "hour"
            ) ||
            moment(end_check_period).isBetween(
              start_booking,
              end_booking,
              "hour"
            )
          ) {
            return true;
          } else {
            return false;
          }
        }
        this.bookings.forEach((booking) => {
          let booking_dates = [];
          booking_dates.push(booking.start_date);
          booking_dates.push(booking.end_date);

          if (checkDates(booking_dates)) {
            this.filtred_bookings_car_ids.push(booking.car_id);
          }

          let non_active_cars = this.cars.forEach((car) => {
            if (!car.active) {
              this.filtred_bookings_car_ids.push(car.id);
            }
          });
        });
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function (arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach((id) =>
          removeByAttr(this.cars, "id", id)
        );
      } else if (
        this.sort_date_range &&
        !this.sort_date_range.includes(" - ") &&
        this.sort_date_range.length > 0
      ) {
        let check_date = this.sort_date_range;
        // выбераем сезон цен
        self.selected_season = null;
        self.seasons.forEach((season) => {
          let start = moment(season.start_date, "DD.MM");
          let end = moment(season.end_date, "DD.MM");
          if (moment(check_date, "DD-MM-YYYY H:mm").isBetween(start, end)) {
            self.selected_season = season;
          }
        });

        function checkDates(booking_dates) {
          let start_booking = moment(
            booking_dates[0],
            "DD-MM-YYYY H:mm"
          ).subtract(zazor, "hours");
          let end_booking = moment(booking_dates[1], "DD-MM-YYYY H:mm").add(
            zazor,
            "hours"
          );
          let start_check_date = moment(check_date, "DD-MM-YYYY H:mm");

          if (
            moment(start_check_date).isBetween(
              start_booking,
              end_booking,
              "hour"
            )
          ) {
            return true;
          } else {
            return false;
          }
        }
        this.bookings.forEach((booking) => {
          let booking_dates = [];
          booking_dates.push(booking.start_date);
          booking_dates.push(booking.end_date);

          if (checkDates(booking_dates)) {
            this.filtred_bookings_car_ids.push(booking.car_id);
          }

          let non_active_cars = this.cars.forEach((car) => {
            if (!car.active) {
              this.filtred_bookings_car_ids.push(car.id);
            }
          });
        });
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function (arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach((id) =>
          removeByAttr(this.cars, "id", id)
        );
      } else {
        this.isLoading = true;
        // Костыль, запоминает ссылку на response.data из created и не переопределяет all_cars, нихера не помогает
        this.axios
          .get("/api/v1/cars", {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            let cars = [];
            response.data.data.forEach((element) => {
              cars.push(element.attributes);
            });
            this.cars = cars;
            // выбераем сезон цен
            self.selected_season = null;
            self.seasons.forEach((season) => {
              let start = moment(season.start_date, "DD.MM");
              let end = moment(season.end_date, "DD.MM");
              if (moment().isBetween(start, end)) {
                self.selected_season = season;
              }
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.cars_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    showEconom() {
      // Усложнить и при выборе совместно другого класса показывать оба
      if (this.showEconom) {
        this.cars = this.cars.filter((car) => car.car_class == "Эконом");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function (arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach((id) =>
          removeByAttr(this.cars, "id", id)
        );
      }
    },
    showMiddle() {
      if (this.showMiddle) {
        this.cars = this.cars.filter((car) => car.car_class == "Средний");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function (arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach((id) =>
          removeByAttr(this.cars, "id", id)
        );
      }
    },
    showBusiness() {
      if (this.showBusiness) {
        this.cars = this.cars.filter((car) => car.car_class == "Бизнес");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function (arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach((id) =>
          removeByAttr(this.cars, "id", id)
        );
      }
    },
    showCommercial() {
      if (this.showCommercial) {
        this.cars = this.cars.filter((car) => car.car_class == "Коммерческий");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function (arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach((id) =>
          removeByAttr(this.cars, "id", id)
        );
      }
    },
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
    company () {
      return this.$store.getters.getCompany.company;
    },
  },
  methods: {
    returnPrice(car_id, index) {
      let self = this;
      let prices = null;
      let default_prices = null;
      if ((self.prices, car_id, index >= 0)) {
        prices = this.prices.filter((price) => {
          return price.car_id == car_id;
        });
        if (prices && prices.length > 0 && index >= 0) {
          default_prices = this.prices.filter((price) => {
            return price.season_id == null;
          });

          if (
            prices &&
            this.selected_season &&
            prices.filter((price) => price.season_id == this.selected_season.id)
              .length > 0
          ) {
            return prices.filter(
              (price) => price.season_id == this.selected_season.id
            )[0].values[index];
          } else {
            if (prices.filter((price) => price.season_id == null)[0]) {
              return prices.filter((price) => price.season_id == null)[0]
                .values[index];
            } else {
              return "?";
            }
          }
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
    replaceToCar(car) {
      this.$router.push({ path: `/cars/${car.id}` });
    },
    updateCarState(id, state) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let car = this.cars.find((car) => car.id == id);
        if (car) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/cars/${id}`,
              {
                cars: {
                  state: state,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.cars.find((car) => car.id == id).state = state;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("other.state_updated"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.change_car_state"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateTireType(id, tire_type) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let car = this.cars.find((car) => car.id == id);
        if (car) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/cars/${id}`,
              {
                cars: {
                  tire_type: tire_type,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then(() => {
              this.cars.find((car) => car.id == id).tire_type = tire_type;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("other.tire_updated"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.change_car_tire_type"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    returnDate(item) {
      if (item) {
        return moment(item, "YYYY-MM-DD").format("ll");
      } else {
        return "-";
      }
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
  },
  components: {},
};
</script>
