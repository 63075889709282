<template>
  <span>
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-card>
      <v-card-title>
        <h4>
          {{ $t("locations.locations") }}
          <v-btn icon @click="addLocation()" class="mx-1">
            <v-icon color="success">mdi-plus-circle-outline</v-icon>
          </v-btn>
        </h4>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
        <v-data-table :items="locations" :headers="headers" :loading="isLoading" :items-per-page="20"
          :loading-text="$t('custom.loading_table')" :search="search" item-key="id"
          :sort-desc="[true]" mobile-breakpoint="100" :footer-props="{
            pageText: `{0} ${$t('custom.of')} {1}`,
            itemsPerPageText: $t('custom.elements_table'),
            showFirstLastPage: true,
            itemsPerPageOptions: [20, 50, 100, -1],
          }">
          <template slot="item" slot-scope="props">
            <tr :class="!props.item.active || props.item.is_valid ? '' : 'error'">
              <td class="">
                <v-chip v-if="props.item.is_airport" color="primary" class="mr-2">
                  {{ $t('locations.is_airport') }}
                </v-chip>
                <v-chip v-if="props.item.is_city" color="primary" class="mr-2">
                  {{ $t('locations.is_city') }}
                </v-chip>
                <v-chip v-if="props.item.is_office" color="primary" class="mr-2">
                  {{ $t('locations.is_office') }}
                </v-chip>
                <v-chip v-if="props.item.is_station" color="primary" class="mr-2">
                  {{ $t('locations.is_station') }}
                </v-chip>
              </td>
              <td class="text-center" :title="props.item.id">
                <v-text-field v-if="showEditName && location && location.id == props.item.id" @keyup.enter="updateLocation()"
                @blur="updateLocation()" v-model="props.item.name" hide-details dense></v-text-field>
                <span v-else @click="location = props.item, showEditName = true">{{ props.item.name }}</span>
              </td>
              <td class="text-center nowrap">
                <v-icon
                  class="mx-1"
                  @click="editLocation(props.item)"
                >
                  mdi-pencil-outline
                </v-icon>
                <v-icon
                  class="mx-1"
                  :loading="isLoadingDeleteLocation"
                  color="error"
                  @click="deleteLocation(props.item.id)"
                  v-if="user_role == 'superadmin' || user_role == 'admin'"
                >
                  mdi-delete-forever
                </v-icon>
              </td>
              <td class="text-center nowrap">
                <v-text-field v-if="showEditPrice && location && location.id == props.item.id" @keyup.enter="updateLocation()"
                @blur="updateLocation()" v-model="props.item.price" hide-details dense></v-text-field>
                <span v-else @click="location = props.item, showEditPrice = true">{{ props.item.price }}</span>
              </td>
              <td class="text-center nowrap">
                <v-text-field v-if="showEditNightPrice && location && location.id == props.item.id" @keyup.enter="updateLocation()"
                @blur="updateLocation()" v-model="props.item.night_price" hide-details dense clearable></v-text-field>
                <span v-else-if="props.item.night_price" @click="location = props.item, showEditNightPrice = true">{{ props.item.night_price }}</span>
                <span v-else @click="location = props.item, showEditNightPrice = true">{{ $t("locations.not_availble")}}</span>
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ $t("custom.no_data_in_table") }}
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
            {{ $t("tables.no_search_result", { msg: search }) }}
          </v-alert>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="newDialog" max-width="800">
      <v-card :loading="isLoadingSaveLocation">
        <v-card-title>
          <span class="headline">{{ $t("locations.create_title") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model.trim="location.name" :label="$t('locations.name')" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model.trim="location.address" :label="$t('locations.address')" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model.trim="location.phone_number" :label="$t('locations.phone_number')" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model.trim="location.email" :label="$t('locations.email')" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox v-model="location.is_airport" :label="$t('locations.is_airport')" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox v-model="location.is_city" :label="$t('locations.is_city')" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox v-model="location.is_office" :label="$t('locations.is_office')" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox v-model="location.is_station" :label="$t('locations.is_station')" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="location.is_airport">
                <v-text-field v-model.trim="location.iata" :label="$t('locations.iata')" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="location.is_airport">
                <v-text-field v-model.trim="location.icao" :label="$t('locations.icao')" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model.number="location.price" :label="$t('locations.price')"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model.number="location.night_price" :label="$t('locations.night_price')" hide-details></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="newDialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="saveLocation()" :loading="isLoadingSaveLocation">{{$t("custom.save")}}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="800">
      <v-card :loading="isLoadingUpdateLocation">
        <v-card-title>
          <span class="headline">{{ $t("locations.edit_title") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model.trim="location.name" :label="$t('locations.name')" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model.trim="location.address" :label="$t('locations.address')" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model.trim="location.phone_number" :label="$t('locations.phone_number')" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model.trim="location.email" :label="$t('locations.email')" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox v-model="location.is_airport" :label="$t('locations.is_airport')" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox v-model="location.is_city" :label="$t('locations.is_city')" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox v-model="location.is_office" :label="$t('locations.is_office')" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox v-model="location.is_station" :label="$t('locations.is_station')" hide-details></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="location.is_airport">
                <v-text-field v-model.trim="location.iata" :label="$t('locations.iata')" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="location.is_airport">
                <v-text-field v-model.trim="location.icao" :label="$t('locations.icao')" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="location.lat" :label="$t('locations.lat')"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="location.lon" :label="$t('locations.lon')"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model.number="location.price" :label="$t('locations.price')"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model.number="location.night_price" :label="$t('locations.night_price')" hide-details></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="editDialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="updateLocation()" :loading="isLoadingUpdateLocation">{{$t("custom.update")}}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      locations: [],
      location: {
        name: "",
        city: "",
        country: "",
        address: "",
        phone_number: "",
        email: "",
        iata: "",
        icao: "",
        lat: "",
        lon: "",
        is_airport: false,
        is_city: false,
        is_office: false,
        is_station: false,
        price: 0,
        night_price: null,
      },
      headers: [
        { text: this.$t("locations.type"), align: "start", sortable: false },
        { text: this.$t("locations.name"), value: "name", align: "center" },
        { text: this.$t("custom.control"), align: "center", sortable: false },
        { text: this.$t("locations.price"), value: "price", align: "center" },
        { text: this.$t("locations.night_price"), value: "night_price", align: "center" },
      ],
      search: null,
      newDialog: false,
      editDialog: false,
      showEditPrice: false,
      showEditName: false,
      showEditNightPrice: false,
      isMobile: false,
      isLoading: false,
      isLoadingSaveLocation: false,
      isLoadingUpdateLocation: false,
      isLoadingDeleteLocation: false,
      error: "",
    };
  },
  mounted() {
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
        this.axios
          .get("/api/v1/locations", {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.locations = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    search() {
      if (this.search == "" || this.search == " ") {
        this.search = null;
      } else {
        let self = this;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.loadOperations();
          }, 200); // delay
        }
        debounce();
      }
    },
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    company() {
      return this.$store.getters.getCompany.company;
    },
  },
  methods: {
    addLocation() {
      this.newDialog = true;
      this.location.email = this.$store.getters.getCompany.company.email_for_bookings;
      this.location.phone_number = this.$store.getters.getCompany.company.phone;
    },
    editLocation(location) {
      this.location = location;
      this.editDialog = true;
    },
    saveLocation() {
      this.isLoading = true;
      this.axios
        .post("/api/v1/locations",
        {
          locations: {
            name: this.location.name,
            city: this.location.city,
            country: this.location.country,
            address: this.location.address,
            phone_number: this.location.phone_number,
            email: this.location.email,
            iata: this.location.iata,
            icao: this.location.icao,
            lat: this.location.lat,
            lon: this.location.lon,
            is_airport: this.location.is_airport,
            is_city: this.location.is_city,
            is_office: this.location.is_office,
            is_station: this.location.is_station,
            price: this.location.price,
            night_price: this.location.night_price,
          },
        },
        {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.locations.push(response.data);
          this.newDialog = false;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    updateLocation() {
      if(!this.location || !this.location.id || this.location.price == null || this.location.price == undefined || this.location.price < 0) {
        console.log("Location is not valid");
        return;
      }

      this.isLoadingUpdateLocation = true;
      this.axios
        .patch(`/api/v1/locations/${this.location.id}`,
        {
          locations: {
            name: this.location.name,
            city: this.location.city,
            country: this.location.country,
            address: this.location.address,
            phone_number: this.location.phone_number,
            email: this.location.email,
            iata: this.location.iata,
            icao: this.location.icao,
            lat: this.location.lat,
            lon: this.location.lon,
            is_airport: this.location.is_airport,
            is_city: this.location.is_city,
            is_office: this.location.is_office,
            is_station: this.location.is_station,
            price: this.location.price,
            night_price: this.location.night_price,
          },
        },
        {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.locations.splice(
            this.locations.findIndex((x) => x.id == response.data.id),
            1,
            response.data
          );
          this.editDialog = false;
          this.showEditPrice = false;
          this.showEditName = false;
          this.showEditNightPrice = false;
          this.location = {
            name: "",
            city: "",
            country: "",
            address: "",
            phone_number: "",
            email: "",
            iata: "",
            icao: "",
            lat: "",
            lon: "",
            is_airport: false,
            is_city: false,
            is_office: false,
            is_station: false,
            price: 0,
            night_price: null
          };
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => (this.isLoadingUpdateLocation = false));
    },
    deleteLocation(location_id) {
      if (this.user_role != "guest" || this.user_role != "partner") {
        let self = this;
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoadingDeleteLocation = true;
            this.axios
              .delete(`/api/v1/locations/${location_id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then(() => {
                this.locations.splice(
                  this.locations.findIndex((x) => x.id == location_id),
                  1
                );
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.error"))
              })
              .finally(() => (this.isLoadingDeleteLocation = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        var fpath = this.PageData.backCrumb.url;
        this.$router
          .push({
            path: fpath,
          })
          .catch((err) => { });
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
<style></style>
