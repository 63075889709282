<template>
  <span>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <h3 class="text-center">
          {{ $t("cars.add_car") }}
          <v-btn small icon :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
            }/${$root.$i18n.locale}/categories/7/guides/21`" target="_blank">
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
          <br v-if="$store.getters.getMainCompany.plan && $store.getters.getMainCompany.plan.cars_limit > 0"><span v-if="$store.getters.getMainCompany.plan && $store.getters.getMainCompany.plan.cars_limit > 0" class="text-subtitle-1"><span :class="$store.getters.getMainCompany.active_cars_count >= $store.getters.getMainCompany.plan.cars_limit ? 'error--text' : ''">{{ $store.getters.getMainCompany.active_cars_count }}</span>/{{ $store.getters.getMainCompany.plan.cars_limit }}</span>
        </h3>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
            <v-col cols="12" sm="6" md="3" id="registration-step-29">
              <v-text-field
                v-model="car.car_name"
                :label="$t('cars.model')"
                :placeholder="$t('cars.model_plc')"
                dense
                hide-details
                outlined
                required
                v-tooltip="{
                  content: $t('tooltips.car_model'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" id="registration-step-30">
              <v-text-field
                v-model="car.code"
                :label="$t('cars.code')"
                dense
                hide-details
                outlined
                required
                v-tooltip="{
                  content: $t('tooltips.car_code'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" id="registration-step-31">
              <v-text-field
                v-model.number="car.sort"
                :label="$t('cars.sort_number')"
                dense
                hide-details
                outlined
                v-tooltip="{
                  content: this.$t('tooltips.car_sort'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" id="registration-step-32">
              <v-text-field
                v-model="car.number"
                :label="$t('cars.gov_number')"
                :placeholder="$t('cars.gov_number_pcl')"
                dense
                hide-details
                outlined
                required
                v-tooltip="{
                  content: $t('tooltips.car_number'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="car.vin"
                :label="$t('cars.vin')"
                dense
                hide-details
                outlined
                v-tooltip="{
                  content: $t('tooltips.vin'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="car.body_number"
                :label="$t('cars.body_number')"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="car.pts"
                :label="$t('cars.reg_number')"
                dense
                hide-details
                outlined
                v-tooltip="{
                  content: $t('tooltips.car_passport'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="car.registration_certificate"
                :label="$t('cars.sts_number')"
                dense
                hide-details
                outlined
                v-tooltip="{
                  content: $t('tooltips.car_sts'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-select
                v-model="car.car_type"
                :items="car_types"
                dense
                hide-details
                item-text="text"
                item-value="value"
                :label="$t('cars.body_type')"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3" id="registration-step-33">
              <v-select
                v-model="car.car_class"
                :items="car_classes"
                item-text="text"
                item-value="value"
                dense
                hide-details
                :label="$t('cars.class')"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="car.color"
                :label="$t('cars.color')"
                hide-details
                dense
                outlined
                v-tooltip="{
                  content: $t('tooltips.car_color'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model.number="car.year"
                :label="$t('cars.year_created')"
                type="number"
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-select
                v-model="car.transmission"
                :items="gears"
                item-text="text"
                item-value="value"
                dense
                hide-details
                :label="$t('cars.gear_big')"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-select
                v-model="car.drive_unit"
                :items="drive_units"
                item-text="text"
                item-value="value"
                dense
                hide-details
                :label="$t('cars.drive_unit')"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-select
                v-model="car.fuel"
                :items="fuels"
                dense
                hide-details
                item-text="text"
                item-value="value"
                :label="$t('cars.fuel')"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model.number="car.tank_value"
                :label="$t('cars.tank_value')"
                dense
                hide-details
                outlined
                v-tooltip="{
                  content: $t('tooltips.tank_value'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model.number="car.gas_mileage"
                :label="$t('cars.gas_mileage')"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-select
                v-model="car.steering_side"
                :items="steering_side"
                dense
                hide-details
                :label="$t('cars.steering_side')"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model.number="car.number_doors"
                :label="$t('cars.number_doors')"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model.number="car.number_seats"
                :label="$t('cars.number_seats')"
                dense
                hide-details
                outlined
                v-tooltip="{
                  content: $t('tooltips.number_seats'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model.number="car.engine_capacity"
                :label="$t('cars.engine_capacity')"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model.number="car.engine_power"
                :label="$t('cars.engine_power')"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="car.tire_size"
                :label="$t('cars.tire_size')"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" id="registration-step-34">
              <v-text-field
                v-model="car.purchase_date"
                type="date"
                :label="$t('cars.purchase_date')"
                dense
                hide-details
                outlined
                v-tooltip="{
                  content: $t('tooltips.purchase_date'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" id="registration-step-35">
              <v-text-field
                v-model.number="car.purchase_price"
                :label="$t('cars.purchase_price')"
                dense
                hide-details
                outlined
                v-tooltip="{
                  content: $t('tooltips.purchase_price'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="car.franchise"
                :label="$t('cars.franchise')"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="car.max_fine"
                :label="$t('cars.max_fine')"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="car.repair_cost"
                :label="$t('cars.repair_cost')"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="car.beacon_emai"
                :label="$t('cars.beacon_emai')"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="car.beacon_phone"
                :label="$t('cars.beacon_phone')"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="car.beacon_pin"
                :label="$t('cars.beacon_pin')"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.number="car.start_mileage"
                :label="$t('cars.start_mileage')"
                dense
                hide-details
                outlined
                v-tooltip="{
                  content: $t('tooltips.start_mileage'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.number="car.mileage"
                :label="$t('cars.mileage')"
                dense
                hide-details
                outlined
                v-tooltip="{
                  content: $t('tooltips.current_mileage'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <v-checkbox
                v-model="car.is_electropackage"
                :label="$t('cars.is_electropackage')"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <v-checkbox
                :label="$t('cars.is_air')"
                v-model="car.is_air"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <v-checkbox
                :label="$t('cars.climate_control')"
                v-model="car.climate_control"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="car.description"
                :label="$t('cars.description')"
                rows="2"
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-expansion-panels v-model="showDopPanel">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h2 class="mb-0">
                      {{ $t("cars.extra_characteristics") }}
                    </h2>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12" sm="6" md="3">
                        <v-select
                          v-model="car.interior"
                          :items="interiors"
                          item-text="text"
                          item-value="value"
                          dense
                          hide-details
                          :label="$t('cars.interior')"
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-select
                          v-model="car.roof"
                          :items="roofs"
                          item-text="text"
                          item-value="value"
                          dense
                          hide-details
                          :label="$t('cars.roof')"
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model.number="car.trunk_volume"
                          :label="$t('cars.trunk_volume')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model.number="car.airbags"
                          :label="$t('cars.airbags')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model.number="car.window_lifters"
                          :label="$t('cars.window_lifters')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model.number="car.custom_field_1"
                          :label="$t('cars.custom_field_1')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model.number="car.custom_field_2"
                          :label="$t('cars.custom_field_2')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model.number="car.custom_field_3"
                          :label="$t('cars.custom_field_3')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.rain_sensor"
                          :label="$t('cars.rain_sensor')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.heated_windshield"
                          :label="$t('cars.heated_windshield')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.folding_seats"
                          :label="$t('cars.folding_seats')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.video_system"
                          :label="$t('cars.video_system')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.tv_system"
                          :label="$t('cars.tv_system')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.cd_system"
                          :label="$t('cars.cd_system')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.usb_system"
                          :label="$t('cars.usb_system')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.audio_system"
                          :label="$t('cars.audio_system')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.wheel_adjustment"
                          :label="$t('cars.wheel_adjustment')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.wheel_adjustment_full"
                          :label="$t('cars.wheel_adjustment_full')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.parktronic_camera"
                          :label="$t('cars.parktronic_camera')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.parktronic_back"
                          :label="$t('cars.parktronic_back')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.parktronic"
                          :label="$t('cars.parktronic')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.heated_seats_front"
                          :label="$t('cars.heated_seats_front')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.heated_seats"
                          :label="$t('cars.heated_seats')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          v-model="car.abs"
                          :label="$t('cars.abs')"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          :label="$t('cars.ebd')"
                          v-model="car.ebd"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <v-checkbox
                          :label="$t('cars.esp')"
                          v-model="car.esp"
                          dense
                          hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
      </v-card-text>
      <v-card-actions id="registration-step-36">
        <v-spacer></v-spacer>
        <v-btn color="success" @click="submit()">{{
          $t("custom.save")
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </span>
</template>
<script>
/* eslint-disable */
export default {
  data() {
    return {
      car: {
        car_name: null,
        code: null,
        sort: 0,
        number: null,
        vin: null,
        body_number: null,
        pts: null,
        registration_certificate: null,
        year: null,
        transmission: null,
        fuel: null,
        tank_value: 50,
        number_doors: 5,
        engine_capacity: null,
        is_electropackage: false,
        car_type: null,
        car_class: null,
        is_air: false,
        price_hour: null,
        deposit: null,
        insurance: null,
        oil_engine: null,
        oil_transmission: null,
        oil_engine_date: null,
        oil_engine_km: null,
        oil_transmission_km: null,
        oil_transmission_date: null,
        grm: null,
        grm_km: null,
        grm_date: null,
        antifreeze: null,
        antifreeze_km: null,
        antifreeze_date: null,
        brake_fluid: null,
        brake_fluid_km: null,
        brake_fluid_date: null,
        candle: null,
        candle_km: null,
        candle_date: null,
        power_steering: null,
        power_steering_km: null,
        power_steering_date: null,
        diagnostic_card: null,
        mileage: null,
        salon_filter_renew: null,
        salon_filter_renew_km: null,
        salon_filter_renew_date: null,
        akb_renew: null,
        akb_renew_km: null,
        akb_renew_date: null,
        fuel_filter_renew: null,
        fuel_filter_renew_km: null,
        fuel_filter_renew_date: null,
        purchase_price: null,
        purchase_date: null,
        sale_price: null,
        sale_date: null,
        tire_size: null,
        number_seats: 5,
        drive_unit: "Передний",
        engine_power: null,
        airbags: null,
        roof: "Обычная",
        gas_mileage: null,
        steering_side: "Левая",
        interior: "Ткань",
        abs: false,
        ebd: false,
        esp: false,
        window_lifters: 4,
        trunk_volume: 0,
        description: null,
      },
      car_marks: [],
      car_mark: null,
      isLoadingCarMarks: false,
      car_mark_search: null,
      car_models: [],
      car_model: null,
      isLoadingCarModels: false,
      car_model_search: null,
      car_generations: [],
      car_generation: null,
      isLoadingCarGenerations: false,
      car_generation_search: null,
      car_configurations: [],
      car_configuration: null,
      isLoadingCarConfigurations: false,
      car_configuration_search: null,
      car_modifications: [],
      car_modification: null,
      isLoadingCarModifications: false,
      car_modification_search: null,
      tabs: 0,
      periods: [],
      seasons: [],
      drive_units: [
        { text: this.$t("drive_units.front"), value: "Передний" },
        { text: this.$t("drive_units.back"), value: "Задний" },
        { text: this.$t("drive_units.full"), value: "Полный" },
      ],
      car_classes: [
        { text: this.$t("car_classes.economy"), value: "Эконом" },
        { text: this.$t("car_classes.middle"), value: "Средний" },
        { text: this.$t("car_classes.business"), value: "Бизнес" },
        { text: this.$t("car_classes.commercial"), value: "Коммерческий" },
      ],
      car_types: [
        { text: this.$t("car_types.micro"), value: "Микро" },
        { text: this.$t("car_types.hatchback"), value: "Хэтчбек" },
        { text: this.$t("car_types.sedan"), value: "Седан" },
        { text: this.$t("car_types.universal"), value: "Универсал" },
        { text: this.$t("car_types.minivan"), value: "Минивен" },
        { text: this.$t("car_types.coupe"), value: "Купе" },
        { text: this.$t("car_types.crossover"), value: "Кроссовер" },
        { text: this.$t("car_types.suv"), value: "Внедорожник" },
        { text: this.$t("car_types.pickup"), value: "Пикап" },
        { text: this.$t("car_types.limousine"), value: "Лимузин" },
        { text: this.$t("car_types.van"), value: "Фургон" },
        { text: this.$t("car_types.cabriolet"), value: "Кабриолет" },
        { text: this.$t("car_types.bus"), value: "Микроавтобус" },
        { text: this.$t("car_types.campervan"), value: "Автодом" },
        { text: this.$t("car_types.light_van"), value: "Легковой фургон" },
        { text: this.$t("car_types.rodster"), value: "Родстер" },
        { text: this.$t("car_types.sportcar"), value: "Спорткар" },
      ],
      fuels: [
        { text: this.$t("fuels.gas"), value: "Бензин" },
        { text: this.$t("fuels.gas_92"), value: "Бензин 92" },
        { text: this.$t("fuels.gas_95"), value: "Бензин 95" },
        { text: this.$t("fuels.gas_98"), value: "Бензин 98" },
        { text: this.$t("fuels.gas_100"), value: "Бензин 100" },
        { text: this.$t("fuels.disel"), value: "Дизель" },
        { text: this.$t("fuels.gas_natural"), value: "Газ" },
        { text: this.$t("fuels.gas_gasoline"), value: "Бензин/Газ" },
        { text: this.$t("fuels.electricity"), value: "Электричество" },
        { text: this.$t("fuels.hybrid"), value: "Гибрид" },
        { text: this.$t("fuels.hydrogen"), value: "Водород" }
      ],
      gears: [
        { text: this.$t("transmissions.automatic"), value: "Автомат" },
        { text: this.$t("transmissions.manual"), value: "Механика" },
        { text: this.$t("transmissions.variator"), value: "Вариатор" },
        { text: this.$t("transmissions.robot"), value: "Робот" },
      ],
      roofs: [
        { text: this.$t("roofs.ordinary"), value: "Обычная" },
        { text: this.$t("roofs.glass"), value: "Стеклянная" },
        { text: this.$t("roofs.folding"), value: "Складывающаяся" },
      ],
      steering_side: [
        { text: this.$t("steering_side.left"), value: "Левая" },
        { text: this.$t("steering_side.right"), value: "Правая" },
      ],
      interiors: [
        { text: this.$t("interiors.textile"), value: "Ткань" },
        { text: this.$t("interiors.leather"), value: "Кожа" },
      ],
      showDopPanel: false,
      isLoading: false,
      error: "",
    };
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get(`/api/v1/car_marks`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.car_marks = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  mounted: function () {
    if (this.$tours["registrationTour"]) {
      this.$tours["registrationTour"].nextStep();
    }
  },
  watch: {
    car_mark() {
      if (this.car_mark) {
        this.car.car_mark_id = this.car_mark.id;
        this.isLoading = true;
        this.isLoadingCarModels = true;
        this.axios
          .get(`/api/v1/car_models?car_mark_id=${this.car_mark.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.car_models = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => {
            this.isLoadingCarModels = false;
            this.isLoading = false;
          });
      } else {
        this.car_models = [];
        this.car_generations = [];
        this.car_configurations = [];
        this.car_modifications = [];
      }
    },
    car_model() {
      if (this.car_model) {
        this.car.car_model_id = this.car_model.id;
        this.isLoading = true;
        this.isLoadingCarGenerations = true;
        this.car.car_name = `${this.car_mark.name} ${this.car_model.name}`;
        this.car.code = `${this.car_mark.name[0]}${this.car_model.name[0]}1`;
        this.axios
          .get(`/api/v1/car_generations?car_model_id=${this.car_model.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.car_generations = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => {
            this.isLoadingCarGenerations = false;
            this.isLoading = false;
          });
      } else {
        this.car_generations = [];
        this.car_configurations = [];
        this.car_modifications = [];
      }
    },
    car_generation() {
      if (this.car_generation) {
        this.car.car_generation_id = this.car_generation.id;
        this.isLoadingCarConfigurations = true;
        this.isLoading = true;
        this.axios
          .get(
            `/api/v1/car_configurations?car_generation_id=${this.car_generation.id}`,
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.car_configurations = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => {
            this.isLoadingCarConfigurations = false;
            this.isLoading = false;
          });
      } else {
        this.car_configurations = [];
        this.car_modifications = [];
      }
    },
    car_configuration() {
      if (this.car_configuration) {
        this.car.car_configuration_id = this.car_configuration.id;
        this.car_modifications = this.car_configuration.modifications
        if (this.car_configuration["body_type"]) {
          switch (this.car_configuration["body_type"]) {
            case 'купе': case 'купе-хардтоп':
              this.car.car_type = "Купе"
              break;
            case 'родстер': case 'тарга': case 'спидстер':
              this.car.car_type = "Родстер"
              break;
            case 'седан': case 'седан 2 дв.': case 'лифтбек': case 'седан-хардтоп':
              this.car.car_type = "Седан"
              break;
            case 'хэтчбек 3 дв.': case 'хэтчбек 5 дв.': case 'хэтчбек 4 дв.':
              this.car.car_type = "Хэтчбек"
              break;
            case 'внедорожник 5 дв.': case 'внедорожник 3 дв.': case 'внедорожник открытый':
              this.car.car_type = "Внедорожник"
              break;
            case 'универсал': case 'универсал 5 дв.': case 'универсал 3 дв.':
              this.car.car_type = "Универсал"
              break;
            case 'кабриолет':
              this.car.car_type = "Кабриолет"
              break;
            case 'минивэн': case 'микровэн': case 'компактвэн':
              this.car.car_type = "Минивен"
              break;
            case 'фургон':
              this.car.car_type = "Фургон"
              break;
            case 'пикап': case 'пикап одинарная кабина': case 'пикап полуторная кабина': case 'пикап двойная кабина':
              this.car.car_type = "Пикап"
              break;
            case 'лимузин':
              this.car.car_type = "Лимузин"
              break;
            default:
              console.log(this.car_configuration["body_type"])
              break;
          }
        }
      } else {
        this.car_modifications = [];
      }
    },
    car_modification() {
      if (this.car_modification) {
        console.log(this.car_modification.specifications)
        this.car.car_complectation_id = this.car_modification["complectation-id"];
        if (this.car_modification.specifications.transmission) {
          let self = this
          switch (this.car_modification.specifications.transmission) {
            case 'механическая':
              self.car.transmission = "Механика"
              break;
            case 'автоматическая':
              self.car.transmission = "Автомат"
              break;
            case 'робот':
              self.car.transmission = "Робот"
              break;
            case 'вариатор':
              self.car.transmission = "Вариатор"
              break;
            default:
              break;
          }
        }
        if (this.car_modification.specifications["doors-count"]) {
          this.car.doors_count = this.car_modification.specifications["doors-count"]
        }
        if (this.car_modification.specifications["fuel-tank-capacity"]) {
          this.car.tank_value = this.car_modification.specifications["fuel-tank-capacity"]
        }
        if (this.car_modification.specifications["horse-power"]) {
          this.car.engine_power = this.car_modification.specifications["horse-power"]
        }
        if (this.car_modification.specifications["consumption-mixed"]) {
          this.car.gas_mileage = this.car_modification.specifications["consumption-mixed"]
        }
        if (this.car_modification.specifications["volume-litres"]) {
          this.car.engine_capacity = this.car_modification.specifications["volume-litres"]
        }
        if (this.car_modification.specifications["wheel-size"]) {
          this.car.tire_size = this.car_modification.specifications["wheel-size"][0]
        }
        if (this.car_modification.specifications["drive"]) {
          switch (this.car_modification.specifications["drive"]) {
            case 'задний':
              this.car.drive_unit = "Задний"
              break;
            case 'передний':
              this.car.drive_unit = "Передний"
              break;
            case 'полный':
              this.car.drive_unit = "Полный"
              break;
            default:
              break;
          }
        }
        if (this.car_modification.specifications["fuel"]) {
          switch (this.car_modification.specifications["fuel"]) {
            case 'АИ-95':
              this.car.drive_unit = "Бензин 95"
              break;
            case 'АИ-92':
              this.car.drive_unit = "Бензин 92"
              break;
            case 'АИ-98':
              this.car.drive_unit = "Бензин 98"
              break;
            case 'газ':
              this.car.drive_unit = "Газ"
              break;
            case 'ДТ':
              this.car.drive_unit = "Дизель"
              break;
            case 'водород':
              this.car.drive_unit = "Водород"
              break;
            default:
              break;
          }
        }
      }
    }
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    main_company() {
      return this.$store.getters.getMainCompany;
    },
  },
  methods: {
    submit() {
      if(this.$store.getters.getMainCompany && this.$store.getters.getMainCompany.plan && this.$store.getters.getMainCompany.plan.cars_limit > 0 && this.$store.getters.getMainCompany.active_cars_count && this.$store.getters.getMainCompany.active_cars_count >= this.$store.getters.getMainCompany.plan.cars_limit) {
        this.$swal({
          title: this.$t('plans.cars_limit_reached_title'),
          text: this.$t('plans.cars_limit_reached_text'),
          icon: "warning",
          showCancelButton: false
        });
      } else {
        if (this.user_role != "guest" && this.user_role != "partner") {
          let self = this;
          if (this.car.car_name && this.car.code && this.car.number) {
            this.isLoading = true;
            let car = this.car;
            this.axios
              .post(
                "/api/v1/cars",
                {
                  cars: {
                    car_name: car.car_name,
                    code: car.code,
                    sort: car.sort,
                    number: car.number,
                    vin: car.vin,
                    body_number: car.body_number,
                    pts: car.pts,
                    registration_certificate: car.registration_certificate,
                    year: car.year,
                    transmission: car.transmission,
                    fuel: car.fuel,
                    tank_value: car.tank_value,
                    number_doors: car.number_doors,
                    engine_capacity: car.engine_capacity,
                    is_electropackage: car.is_electropackage,
                    car_type: car.car_type,
                    car_class: car.car_class,
                    color: car.color,
                    is_air: car.is_air,
                    price_hour: car.price_hour,
                    deposit: car.deposit,
                    insurance: car.insurance,
                    oil_engine: car.oil_engine,
                    oil_engine_date: car.oil_engine_date,
                    oil_transmission: car.oil_transmission,
                    oil_engine_km: car.oil_engine_km,
                    oil_transmission_km: car.oil_transmission_km,
                    oil_transmission_date: car.oil_transmission_date,
                    mileage: car.mileage,
                    start_mileage: car.start_mileage,
                    grm: car.grm,
                    grm_km: car.grm_km,
                    grm_date: car.grm_date,
                    antifreeze: car.antifreeze,
                    antifreeze_km: car.antifreeze_km,
                    antifreeze_date: car.antifreeze_date,
                    brake_fluid: car.brake_fluid,
                    brake_fluid_km: car.brake_fluid_km,
                    brake_fluid_date: car.brake_fluid_date,
                    candle: car.candle,
                    candle_km: car.candle_km,
                    candle_date: car.candle_date,
                    power_steering: car.power_steering,
                    power_steering_km: car.power_steering_km,
                    power_steering_date: car.power_steering_date,
                    diagnostic_card: car.diagnostic_card,
                    salon_filter_renew: car.salon_filter_renew,
                    salon_filter_renew_km: car.salon_filter_renew_km,
                    salon_filter_renew_date: car.salon_filter_renew_date,
                    akb_renew: car.akb_renew,
                    akb_renew_km: car.akb_renew_km,
                    akb_renew_date: car.akb_renew_date,
                    fuel_filter_renew: car.fuel_filter_renew,
                    fuel_filter_renew_km: car.fuel_filter_renew_km,
                    fuel_filter_renew_date: car.fuel_filter_renew_date,
                    purchase_price: car.purchase_price,
                    purchase_date: car.purchase_date,
                    sale_price: car.sale_price,
                    sale_date: car.sale_date,
                    tire_size: car.tire_size,
                    number_seats: car.number_seats,
                    drive_unit: car.drive_unit,
                    engine_power: car.engine_power,
                    airbags: car.airbags,
                    roof: car.roof,
                    gas_mileage: car.gas_mileage,
                    steering_side: car.steering_side,
                    interior: car.interior,
                    abs: car.abs,
                    ebd: car.ebd,
                    esp: car.esp,
                    window_lifters: car.window_lifters,
                    trunk_volume: car.trunk_volume,
                    description: car.description,
                    franchise: car.franchise,
                    max_fine: car.max_fine,
                    repair_cost: car.repair_cost,
                    heated_seats: car.heated_seats,
                    heated_seats_front: car.heated_seats_front,
                    parktronic: car.parktronic,
                    parktronic_back: car.parktronic_back,
                    parktronic_camera: car.parktronic_camera,
                    wheel_adjustment: car.wheel_adjustment,
                    wheel_adjustment_full: car.wheel_adjustment_full,
                    audio_system: car.audio_system,
                    video_system: car.video_system,
                    tv_system: car.tv_system,
                    cd_system: car.cd_system,
                    usb_system: car.usb_system,
                    climate_control: car.climate_control,
                    folding_seats: car.folding_seats,
                    heated_windshield: car.heated_windshield,
                    rain_sensor: car.rain_sensor,
                    beacon_emai: car.beacon_emai,
                    beacon_phone: car.beacon_phone,
                    beacon_pin: car.beacon_pin,
                    custom_field_1: car.custom_field_1,
                    custom_field_2: car.custom_field_2,
                    custom_field_3: car.custom_field_3,
                    car_mark_id: car.car_mark_id,
                    car_model_id: car.car_model_id,
                    car_generation_id: car.car_generation_id,
                    car_configuration_id: car.car_configuration_id,
                    car_modification_id: car.car_modification_id,
                    car_complectation_id: car.car_complectation_id,
                  },
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                this.$router.replace(`/cars/${response.data.id}`);

                this.$swal({
                  showConfirmButton: true,
                  icon: "success",
                  title: this.$t("cars.create_car"),
                  text: this.$t("cars.create_car_text"),
                });
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.create_car"));
              })
              .finally(() => (this.isLoading = false));
          } else {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "warning",
              title: this.$t("errors.required_fields"),
              text: this.$t("errors.required_fields_car"),
            });
          }
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("errors.no_access"),
          });
        }
      }
    },
    translateBodyType(body_type) {
      let transtation_body_type = ""
      // Для перевода
      switch (body_type) {
        case 'купе':
          transtation_body_type = this.$t('car_base_ru.COUPE')
          break;
        case 'купе-хардтоп':
          transtation_body_type = this.$t('car_base_ru.COUPE_HARDTOP')
          break;
        case 'родстер':
          transtation_body_type = this.$t('car_base_ru.ROADSTER')
          break;
        case 'тарга':
          transtation_body_type = this.$t('car_base_ru.TARGA')
          break;
        case 'спидстер':
          transtation_body_type = this.$t('car_base_ru.SPEEDSTER')
          break;
        case 'седан':
          transtation_body_type = this.$t('car_base_ru.SEDAN')
          break;
        case 'седан 2 дв.':
          transtation_body_type = this.$t('car_base_ru.SEDAN_2_DOORS')
          break;
        case 'лифтбек':
          transtation_body_type = this.$t('car_base_ru.LIFTBACK')
          break;
        case 'седан-хардтоп':
          transtation_body_type = this.$t('car_base_ru.SEDAN_HARDTOP')
          break;
        case 'хэтчбек 3 дв.':
          transtation_body_type = this.$t('car_base_ru.HATCHBACK_3_DOORS')
          break;
        case 'хэтчбек 5 дв.':
          transtation_body_type = this.$t('car_base_ru.HATCHBACK_5_DOORS')
          break;
        case 'хэтчбек 4 дв.':
          transtation_body_type = this.$t('car_base_ru.HATCHBACK_4_DOORS')
          break;
        case 'внедорожник 5 дв.':
          transtation_body_type = this.$t('car_base_ru.ALLROAD_5_DOORS')
          break;
        case 'внедорожник 3 дв.':
          transtation_body_type = this.$t('car_base_ru.ALLROAD_3_DOORS')
          break;
        case 'внедорожник открытый':
          transtation_body_type = this.$t('car_base_ru.ALLROAD_OPEN')
          break;
        case 'универсал':
          transtation_body_type = this.$t('car_base_ru.WAGON')
          break;
        case 'универсал 5 дв.':
          transtation_body_type = this.$t('car_base_ru.WAGON_5_DOORS')
          break;
        case 'универсал 3 дв.':
          transtation_body_type = this.$t('car_base_ru.WAGON_3_DOORS')
          break;
        case 'кабриолет':
          transtation_body_type = this.$t('car_base_ru.CABRIO')
          break;
        case 'минивэн':
          transtation_body_type = this.$t('car_base_ru.MINIVAN')
          break;
        case 'микровэн':
          transtation_body_type = this.$t('car_base_ru.MICROVAN')
          break;
        case 'компактвэн':
          transtation_body_type = this.$t('car_base_ru.COMPACTVAN')
          break;
        case 'фургон':
          transtation_body_type = this.$t('car_base_ru.VAN')
          break;
        case 'пикап':
          transtation_body_type = this.$t('car_base_ru.PICKUP')
          break;
        case 'пикап одинарная кабина':
          transtation_body_type = this.$t('car_base_ru.PICKUP_ONE')
          break;
        case 'пикап полуторная кабина':
          transtation_body_type = this.$t('car_base_ru.PICKUP_ONE_HALF')
          break;
        case 'пикап двойная кабина':
          transtation_body_type = this.$t('car_base_ru.PICKUP_TWO')
          break;
        case 'лимузин':
          transtation_body_type = this.$t('car_base_ru.LIMOUSINE')
          break;
        case 'фаэтон':
          transtation_body_type = this.$t('car_base_ru.PHAETON')
          break;
        default:
          break;
      }
      return transtation_body_type;
    },
    translateTransmission(transmission) {
      let transtation_transmission = ""
      // Для перевода
      switch (transmission) {
        case 'механическая':
          transtation_transmission = this.$t('car_base_ru.MECHANICAL')
          break;
        case 'автоматическая':
          transtation_transmission = this.$t('car_base_ru.AUTOMATIC')
          break;
        case 'робот':
          transtation_transmission = this.$t('car_base_ru.ROBOT')
          break;
        case 'вариатор':
          transtation_transmission = this.$t('car_base_ru.VARIATOR')
          break;
        default:
          break;
      }
      return transtation_transmission;
    },
    translateFuel(fuel) {
      let transtation_fuel = ""
      // Для перевода
      switch (fuel) {
        case 'бензин':
          transtation_fuel = this.$t('car_base_ru.GASOLINE')
          break;
        case 'электро':
          transtation_fuel = this.$t('car_base_ru.ELECTRO')
          break;
        case 'дизель':
          transtation_fuel = this.$t('car_base_ru.DIESEL')
          break;
        case 'гибрид':
          transtation_fuel = this.$t('car_base_ru.HYBRID')
          break;
        case 'СУГ':
          transtation_fuel = this.$t('car_base_ru.LPG')
          break;
        default:
          break;
      }
      return transtation_fuel;
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
