<template>
  <form>
    <div class="image-container mb-3" v-if="previewPath">
      <img :src="previewPath" alt="Uploaded Image Preview" />
    </div>
    <div class="form-group">
      <div ref="dashboardContainer"></div>
    </div>
    <!-- <v-btn :disabled="disabled" @click.prevent="confirmUpload" class="btn btn-primary btn-block mb-2">Confirm upload</v-btn> -->
  </form>
</template>

<script>
import Uppy from "@uppy/core";

import Dashboard from "@uppy/dashboard";
import Russian from "@uppy/locales/lib/ru_RU";
import English from "@uppy/locales/lib/en_US";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

export default {
  props: {
    maxFileSizeInBytes: {
      type: Number,
      required: true,
    },
    editedClient: {
      type: Object,
    },
    width: {
      required: true,
    },
    height: {
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      payload: null,
      previewPath: null,
      disabled: true,
    };
  },
  mounted() {
    this.instantiateUppy();
  },
  beforeDestroy() {
    this.uppy.close();
  },
  methods: {
    instantiateUppy() {
      this.uppy = Uppy({
        debug: true,
        locale: this.$i18n.locale === "ru" ? Russian : English,
        autoProceed: true,
        allowMultipleUploads: true,
        restrictions: {
          maxFileSize: this.maxFileSizeInBytes,
          minNumberOfFiles: 1,
          maxNumberOfFiles: 5,
          allowedFileTypes: [
            "image/*",
            ".heic",
            ".heif",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/pdf",
          ],
        },
      })
        .use(Dashboard, {
          hideUploadButton: true,
          theme: this.theme,
          inline: true,
          width: this.width,
          height: this.height,
          target: this.$refs.dashboardContainer,
          replaceTargetContent: true,
          showProgressDetails: true,
          browserBackButtonClose: true,
          locale: {
            strings: {
              poweredBy2: "",
              // browseFiles: "выберите файлы"
            },
          },
        })
        .use(AwsS3Multipart, {
          limit: 5,
          // companionUrl: "http://localhost:3000/cars/"
          companionUrl: "https://rentprog.net/cars/",
        });

      this.uppy.on("complete", (event) => {
        event.successful.forEach((element) => {
          if (element !== undefined) {
            this.axios
              .post(
                `/api/v1/cars_files/${this.$route.params.id}`,
                {
                  cars_files: {
                    name: `${element.s3Multipart.key}`,
                    url: `https://rentprog.storage.yandexcloud.net/${element.s3Multipart.key}`,
                    original_name: element.name,
                    document_type: element.type,
                  },
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                console.log("updateCarsFiles")
                this.$emit("updateCarsFiles", {
                  id: response.data.id,
                  name: element.name,
                  url: element.response.uploadURL,
                  original_name: element.name,
                  document_type: element.type,
                });
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.error"));
              });
          }
        });
      });
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
    },
  },
};
</script>

<style scoped>
.image-container {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}

.image-container>img {
  width: inherit;
  height: inherit;
}
</style>
