<template>
  <v-app>
    <!-- <drawer v-if="showMenu()"></drawer> -->
    <app-header v-if="showMenu()"></app-header>
    <v-main v-resize="checkMobile">
      <!-- <v-banner v-if="company && appVersion && appVersion != 0 && app_version && appVersion != app_version" color="primary" dark class="text-center">
      {{ $t('app.new_version_message') }}
      <v-btn color="info" @click="reloadPage()">{{ $t('app.new_version_btn') }}</v-btn>
    </v-banner> -->
      <v-container fluid>
        <v-banner v-if="company && company.time_zone == 'UTC'" color="primary" dark class="text-center">
          {{ $t("app.time_zone_message") }}
          <v-btn color="success" @click="
            $router.push({ path: `/company_profile` }).catch((err) => { })
            ">{{ $t("custom.settings") }}</v-btn>
        </v-banner>
        <!-- <banner v-if="showMenu()"></banner> -->
        <!-- <v-banner v-if="showMenu() && !$store.getters.getCurrentUser.registration_tour" color="info" dark class="text-left"> -->
        <!-- <v-banner v-if="false" color="info" dark class="text-left">
          {{ $t("app.first_lesson") }}

          <template v-slot:actions>
            <v-btn
              color="success"
              @click="
                $router.push({ path: `/dashboard` }).catch((err) => {}),
                  $tours['registrationTour'].start()
              "
              >{{ $t("custom.yes") }}</v-btn
            >
            <v-btn color="grey" @click="registrationTourFalse()">{{
              $t("custom.no")
            }}</v-btn>
          </template>
</v-banner> -->
        <!-- Пополните счет -->
        <v-banner v-if="main_company && payer_state == 2" color="warning" dark class="text-left">
          {{ $t("app.system_balance") }}:
          {{ main_company ? Math.round(balance) : 0
          }}<span>{{
            $store.getters.getMainCompany &&
              $store.getters.getMainCompany.currency == "RUB"
              ? "₽"
              : "€"
          }}</span>. {{ $t("app.save_enter") }}
          <template v-slot:actions>
            <v-btn color="info" @click="$router.push({ path: `transactions` }).catch((err) => { })">{{
              $t("custom.add_transfer") }}</v-btn>
          </template>
        </v-banner>
        <!-- Срочно Пополните счет -->
        <!-- <v-banner
          v-if="main_company && payer_state == 3"
          color="error"
          dark
          class="text-left"
        >
          {{ $t("app.system_balance") }}:
          {{ main_company ? Math.round(balance) : 0
          }}<span>{{
            $store.getters.getMainCompany &&
            $store.getters.getMainCompany.currency == "RUB"
              ? "₽"
              : "€"
          }}</span
          >. {{ $t("app.save_enter_last") }}
          <template v-slot:actions>
            <v-btn
              color="info"
              @click="$router.push({ path: `transactions` }).catch((err) => {})"
              >{{ $t("custom.add_transfer") }}</v-btn
            >
          </template>
        </v-banner> -->
        <v-banner single-line v-if="company && showRemoveDemo">
          <v-icon slot="icon" color="warning" size="36">
            mdi-information-outline
          </v-icon>
          {{ $t("demo_content.remove_demo_content_text_2") }}
          <template v-slot:actions>
            <v-btn color="warning" @click="removeDemoContent()">
              {{ $t("demo_content.remove") }}
            </v-btn>
          </template>
        </v-banner>
        <v-banner single-line color="primary" dark class="text-left mb-2" @click:icon="replaceToTelegram()" v-if="
          company &&
          showBanner &&
          showTelegram &&
          !isMobile &&
          !showRemoveDemo
        ">
          <v-icon slot="icon" color="warning" size="36">
            mdi-information-outline
          </v-icon>
          {{ $t("telegram.banner_text") }}. {{ $t("telegram.banner_text_mobile") }}
          <v-btn color="warning" @click="closeBanner" :href="$i18n.locale == 'ru'
            ? 'https://t.me/rentprogcrm'
            : 'https://t.me/rentprog_world'
            " target="_blank" text>
            {{ $t("custom.join") }}
          </v-btn>
          <template v-slot:actions>
            <v-btn @click="closeBanner" icon>
              <v-icon color="secondery"> mdi-window-close </v-icon>
            </v-btn>
          </template>
        </v-banner>
        <v-banner single-line v-if="
          company && showBanner && showTelegram && isMobile && !showRemoveDemo
        " @click:icon="replaceToTelegram()">
          <v-icon slot="icon" color="warning" size="36">
            mdi-information-outline
          </v-icon>
          <v-btn color="primary" @click="closeBanner" :href="$i18n.locale == 'ru'
            ? 'https://t.me/rentprogcrm'
            : 'https://t.me/rentprog_world'
            " target="_blank" text>
            <!-- {{ $t('custom.join') }} -->
            {{ $t("telegram.banner_text_mobile") }}
          </v-btn>
          <template v-slot:actions>
            <v-btn @click="closeBanner" icon>
              <v-icon color="secondery"> mdi-window-close </v-icon>
            </v-btn>
          </template>
        </v-banner>
        <router-view></router-view>
        <tour></tour>
      </v-container>
      <!-- Удалить демо контент -->
      <v-overlay :value="showOverlayRemoveDemo">
        <v-card>
          <v-card-text>
            {{ $t("demo_content.removing_demo_content") }}
            <v-progress-linear class="mt-2" color="error accent-4" indeterminate rounded height="6"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-overlay>
    </v-main>
    <app-footer v-if="!isNative && ($route.name != 'NewCalendar' || !isMobile)"></app-footer>
    <v-dialog v-model="showTransactionDialog" :retain-focus="false" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-center">
          {{ $t("other.add_balance") }}
        </v-card-title>
        <v-card-text>
          <v-row v-if="
            $store.getters.getMainCompany &&
            $store.getters.getMainCompany.currency == 'RUB'
          " class="mx-auto">
            <v-col cols="12">
              <v-text-field v-model.trim="sum" class="mt-5 mx-auto" @click.once="sum = null"
                :label="$t('other.sum')"></v-text-field>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn color="success" class="mt-2" @click="transactionCreate()">{{ $t("other.pay") }}</v-btn>
            </v-col>
            <v-col cols="12" v-if="main_company && main_company.plan && main_company.plan.min_topup_sum">
              <p>{{ $t("other.sum_warn_2", { min_topup_sum: main_company.plan.min_topup_sum, currency:  main_company.plan.currency }) }}</p>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col class="text-center" cols="12">
              <v-text-field v-model.trim="stripe_sum" :readonly="showStripeForm" @click.once="stripe_sum = null"
                :label="$t('other.sum')" hide-details :loading="isLoading"></v-text-field>
              <v-spacer></v-spacer>
            </v-col>
            <v-col v-if="!isAndroid" cols="12">
              <stripe-element-payment v-if="showStripeForm" ref="paymentRef" :locale="$root.$i18n.locale" :pk="pk"
                :testMode="true" :elements-options="elementsOptions" :confirm-params="confirmParams" />
            </v-col>
            <v-col class="text-center" cols="12">
              <v-btn v-if="!showStripeForm" color="success" class="mt-2" @click="generatePaymentIntent"
                :loading="isLoading">
                {{ $t("other.to_pay") }}
              </v-btn>
              <v-btn v-else color="success" class="mt-2" @click="pay" :loading="isLoading">
                {{ $t("other.pay") }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <p>{{ $t("other.sum_warn_euro") }}</p>
              <p>{{ $t("other.sum_warn_2", { min_topup_sum: main_company && main_company.plan && main_company.plan.min_topup_sum ? main_company.plan.min_topup_sum : 0, currency:  main_company && main_company.plan && main_company.plan.currency ? main_company.plan.currency : '' }) }}</p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="(showTransactionDialog = false), (sum = 0)">{{ $t("custom.close")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Должникам -->
    <v-overlay :value="showOverlayTopUp">
      <v-row>
        <v-col cols="12" class="text-center">
          <v-alert prominent type="error" border="top" class="mx-2">
            <v-row align="center">
              <v-col cols="12" class="mt-3">
                {{ $t("other.message_no_pay") }}
              </v-col>
              <v-col cols="12" class="">
                <v-btn v-if="!isNative" small class="mr-2" color="success" @click="transactionStart()">
                  {{ $t("other.add_balance") }}
                </v-btn>
                <v-btn v-if="isIos" small class="mr-2" color="success" @click="startInAppPurchaseIos()">
                  {{ $t("other.add_balance") }}
                </v-btn>
                <v-btn v-if="isAndroid" small class="mr-2" color="success" @click="startInAppPurchaseAndroid()">
                  {{ $t("other.add_balance") }}
                </v-btn>
                <v-btn small class="mr-2" color="secondary" @click="signOut()" outlined>
                  {{ $t("header.sign_out") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-overlay>
  </v-app>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
// import Banner from "./components/Banner.vue";
import Drawer from "./components/Drawer.vue";
import AppFooter from "./components/Footer.vue";
import Tour from "./components/Tour.vue";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import "cordova-plugin-purchase"
import { Capacitor } from "@capacitor/core";
import PullToRefresh from "pulltorefreshjs";
import { StatusBar, Style } from "@capacitor/status-bar";
import { isMobile } from "mobile-device-detect";

export default {
  name: "App",
  metaInfo() {
    return {
      title: this.$t("custom.title_meta_1"),
      meta: [
        { name: "description", content: this.$t("custom.description_meta_1") },
        { property: "og:title", content: this.$t("custom.title_meta_1") },
        { property: "og:site_name", content: "CRM RentProg" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  beforeMount() {
    if (this.isNative) {
      const setStatusBarStyleLight = async () => {
        await StatusBar.setStyle({ style: Style.Light });
      };
      setStatusBarStyleLight();
      if ($crisp && $crisp.push && typeof $crisp.push === 'function' && $crisp.on && typeof $crisp.on === 'function') {
        console.log('crisp $crisp.push(["safe", true])')
        $crisp.push(["safe", true]);
        const hideStatusBar = async () => {
          await StatusBar.hide();
        };
        const showStatusBar = async () => {
          await StatusBar.show();
        };
        $crisp.on("chat:opened", () => {
          console.log("chat:opened");
          hideStatusBar();
        });
        $crisp.on("chat:closed", () => {
          console.log("chat:closed");
          showStatusBar();
        });
      }
    }
  },
  mounted() {
    // const ptr = PullToRefresh.init({
    //   mainElement: "body",
    //   distIgnore: 100,
    //   instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
    //   instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
    //   instructionsRefreshing: this.$t("custom.refreshing"),
    //   onRefresh() {
    //     // window.location.reload();
    //     if (self.$route.name != "NewCalendar") {
    //       self.$router.go(0);
    //     }
    //   },
    // });
    if (
      this.$store.getters.getCurrentUser &&
      this.$store.getters.getCurrentUser != {} &&
      this.$store.getters.getSignedIn &&
      this.$route.name == "Home" &&
      !this.$store.getters.getCurrentUser.registration_tour &&
      this.$tours["registrationTour"]
    ) {
      this.$tours["registrationTour"].start();
    }
    if (this.$store.getters.getMainCompany && this.$store.getters.getMainCompany.payer_state == 3) {
      this.showOverlayTopUp = true;
    }
  },
  components: {
    AppHeader,
    Drawer,
    AppFooter,
    // Banner,
    Tour,
    StripeElementPayment,
  },
  created() {
    // create script tag for newrelic
    // if (process.env.NODE_ENV == "production" && !this.isNative) {
    //   const script = document.createElement("script");
    //   script.src = `/newrelic.js`;
    //   script.async = true;
    //   document.head.appendChild(script);
    // }
  },
  updated() {
    if (
      this.$store.getters.getCurrentUser &&
      this.$store.getters.getCurrentUser != {} &&
      this.$store.getters.getSignedIn &&
      this.$route.name == "Home" &&
      !this.$store.getters.getCurrentUser.registration_tour &&
      this.$tours["registrationTour"]
    ) {
      this.$tours["registrationTour"].start();
    }
    if (this.$store.getters.getMainCompany && this.$store.getters.getMainCompany.payer_state == 3) {
      this.showOverlayTopUp = true;
    }
  },
  destroyed() {
    PullToRefresh.destroyAll();
    // ptr.destroy();
  },
  data() {
    return {
      deferredPrompt: null,
      isMobile: false,
      app_version: process.env.VUE_APP_VERSION,
      showOverlayRemoveDemo: false,
      showOverlayTopUp: false,
      dontShowBalanceOverlay: false,
      showTransactionDialog: false,
      showRebillDialog: false,
      sum: 0,
      stripe_sum: 0,
      promo_code: null,
      in_app_purchase_product: null,
      in_app_purchase_eur: 0,
      in_app_purchase_currency: null,
      in_app_purchase_price: null,
      in_app_purchase_start: false,
      pk: "pk_live_51KtQkKFNgvJ2eyx925rNt6rX9DwYTt6TrcsGIjfIeE7gZWPNSgg4pEC3NKI4tJKiPqgMytdYPPHQ8WhYXtb9Ka9o00htarW7FH",
      elementsOptions: {
        clientSecret: "",
        appearance: {}, // appearance options
      },
      confirmParams: {
        return_url: "https://web.rentprog.com/result_success", // success url
      },
      tab: 0,
      showStripeForm: false,
      isLoading: false,
    };
  },
  watch: {
    currency() {
      if (this.$store.getters.getMainCompany) {
        return this.$store.getters.getMainCompany.currency;
      } else {
        return "EUR";
      }
    },
  },
  computed: {
    company() {
      if (this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.id) {
        return this.$store.getters.getCompany.company;
      } else {
        return {};
      }
    },
    main_company() {
      return this.$store.getters.getMainCompany;
    },
    payer_state() {
      return this.$store.getters.getMainCompany.payer_state;
    },
    balance() {
      return this.$store.getters.getMainCompany.balance;
    },
    appVersion() {
      return this.$store.getters.appVersion;
    },
    showBanner() {
      return this.$store.getters.getShowBanner;
    },
    showTelegram() {
      return this.$store.getters.getCurrentUser.show_telegram;
    },
    showRemoveDemo() {
      return (
        this.company.demo_content &&
        this.$store.getters.getBannerRemoveDemoContent
      );
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
    isAndroid() {
      return Capacitor.getPlatform().toLowerCase() == "android" ? true : false;
    },
  },
  methods: {
    transactionStart() {
      if (
        this.$store.getters.getCurrentUser.email ||
        this.$store.getters.getCompany.company.email_for_bookings
      ) {
        this.showTransactionDialog = !this.showTransactionDialog;
      } else {
        this.$swal({
          icon: "warning",
          title: this.$t("errors.no_email"),
          text: this.$t("errors.no_email_text"),
        });
      }
    },
    generatePaymentIntent() {
      if (this.stripe_sum && this.stripe_sum >= 15) {
        this.isLoading = true;
        let self = this;
        this.axios
          .post(
            "/api/v1/stripe_payment_intent",
            {
              transfers: {
                sum: parseFloat(this.stripe_sum.replace(/,/, ".")).toFixed(2),
                promo_code: this.promo_code,
                // sum: 1000
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.elementsOptions.clientSecret = response.data.clientSecret;
            this.isLoading = false;
            this.showStripeForm = true;
            // this.createPaymentSheet(
            //   response.data.client_secret,
            //   response.data.customer_id,
            //   response.data.ephemeral_key_secret
            // ).then(() => {
            //   this.isLoading = false;
            // });

          })
          .catch((error) => {
            this.isLoading = false;
            if (
              error.response &&
              error.response.status &&
              error.response.status == 406
            ) {
              self.$swal({
                title: self.$t("errors.promocode_not_found_title"),
                text: self.$t("errors.promocode_not_found_text"),
                icon: "error",
              });
              this.promo_code = null;
            } else {
              this.setError(error, this.$t("errors.company_data"));
              console.log(error);
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$swal({
          icon: "warning",
          title: this.$t("errors.bad_sum"),
          text: this.$t("errors.bad_sum_text"),
        });
      }
    },
    async createPaymentSheet(client_secret, customer_id, ephemeral_key_secret) {
      let self = this;
      await Stripe.createPaymentSheet({
        paymentIntentClientSecret: client_secret,
        customerId: customer_id,
        customerEphemeralKeySecret: ephemeral_key_secret,
        enableApplePay: false,
        applePayMerchantId: 'merchant.ru.rentprog',
        enableGooglePay: true,
        merchantCountryCode: 'PT',
        merchantDisplayName: 'RentProg',
      }).then(() => {
        self.presentPaymentSheet();
      });
    },
    async presentPaymentSheet() {
      const { error } = await Stripe.presentPaymentSheet({
        confirmPayment: true,
      });

      if (error) {
        console.log(error);
      } else {
        // this.$swal({
        //   icon: "success",
        //   title: this.$t("success"),
        //   text: this.$t("success_text"),
        // });
        // this.$router.push({ name: "home" });
      }
    },
    startInAppPurchaseIos() {
      let self = this;
      var myProduct;
      const { store, ProductType, Platform, LogLevel, TransactionState } = CdvPurchase;
      // set timeout to wait for platform ready
      this.isLoading = true;
      store.off();
      store.verbosity = LogLevel.INFO;
      store.register([
        {
          type: CdvPurchase.CONSUMABLE,
          id: 'ru.rentprog',
          platform: Platform.APPLE_APPSTORE,
        }
      ]);
      store.error(function (error) {
        console.error('CdvPurchase ERROR: ' + error.message);
      });
      store.initialize([Platform.APPLE_APPSTORE]);
      setTimeout(() => {
        self.isLoading = false;
        store.ready(() => {
          console.log('Platform is ready');
          // const monitor = store.monitor(myTransaction, state => {
          //   console.log('new state: ' + state);
          //   if (state === TransactionState.FINISHED)
          //     monitor.stop();
          // });
          store.update();

          const myProduct = store.get('ru.rentprog', Platform.APPLE_APPSTORE)
          if (myProduct) {
            console.log('myProduct found', myProduct);
            self.in_app_purchase_product = myProduct;
            self.in_app_purchase_currency = self.in_app_purchase_product['offers'][0]['pricingPhases'][0]['currency']
            self.in_app_purchase_price = self.in_app_purchase_product['offers'][0]['pricingPhases'][0]['priceMicros'] / 1000000
            // get currecy price in euro
            self.isLoading = true;
            let url = `https://api.currencyapi.com/v3/latest?apikey=fca_live_sBpm46aqmsrZYxpWWytCPi2Uoy6OfCfxqrSshcpr&currencies=${self.currency}&base_currency=${self.in_app_purchase_currency}`

            self.axios({
              url: url,
              method: "get",
            })
              .then((response) => {
                let value = response.data.data[self.currency].value;
                self.in_app_purchase_eur = (parseFloat(value * self.in_app_purchase_price)).toFixed(2);
              })
              .then((response) => {
                self.isLoading = false;
                self.$swal({
                  title: self.$t("custom.you_sure_title"),
                  text: self.$t("transfers.in_app_purchase_question", { payment_sum: self.in_app_purchase_price, payment_currency: self.in_app_purchase_currency, top_up_sum: (self.in_app_purchase_eur - (parseFloat(self.in_app_purchase_eur * 0.3))).toFixed(2), top_up_currency: self.currency }),
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: self.$t("custom.yes"),
                  cancelButtonText: self.$t("custom.no"),
                  showCloseButton: true,
                }).then((result) => {
                  if (result.value) {
                    console.log('want to buy')
                    const myTransaction = store.findInLocalReceipts(myProduct);

                    myProduct.getOffer().order().then((transaction) => {
                      console.log(transaction);
                    });
                    store.when('product').approved((product) => {
                      this.topUpBalanceFromInAppPurchase();
                      product.finish();
                      store.update();
                      // store.off();
                    });
                  }
                });
              })
              .catch((error) => {
                self.isLoading = false;
                self.setError(error, self.$t("errors.error"));
                console.log(error);
              })
              .finally(() => {
                self.isLoading = false;
              });
          } else {
            self.$swal({
              icon: "warning",
              title: self.$t("errors.error"),
              text: self.$t("errors.payment_creating"),
            });
            console.log('myProduct not found', myProduct);
          }
        });
      }, 2000);


    },
    async startInAppPurchaseAndroid() {
      let self = this;
      // We register a dummy product.
      CdvPurchase.store.register({
        id: "ru.rentprog.20",
        type: CdvPurchase.ProductType.CONSUMABLE,
        platform: CdvPurchase.Platform.GOOGLE_PLAY,
        group: 'default',
      });
      CdvPurchase.store.verbosity = CdvPurchase.LogLevel.DEBUG;
      CdvPurchase.store.error(function (error) {
        console.error('CdvPurchase ERROR: ' + error.message);
      });
      CdvPurchase.store
        .when()
        .approved(p => {
          console.log('approved', p);
        })
        .verified(p => {
          console.log('verified', p);
        })
        .unverified(p => {
          console.log('unverified', p);
        })
        .finished(p => {
          console.log('finished', p);
        });


      await CdvPurchase.store.initialize([CdvPurchase.Platform.GOOGLE_PLAY]);
      await CdvPurchase.store.update();


      CdvPurchase.store.ready(() => {
        console.log('store ready');
        const myProduct = CdvPurchase.store.get('ru.rentprog.20', CdvPurchase.Platform.GOOGLE_PLAY)
        console.log('myProduct', myProduct);
        if (myProduct) {
          console.log('myProduct found', myProduct);
          self.in_app_purchase_product = myProduct;
          self.in_app_purchase_currency = self.in_app_purchase_product['offers'][0]['pricingPhases'][0]['currency']
          self.in_app_purchase_price = self.in_app_purchase_product['offers'][0]['pricingPhases'][0]['priceMicros'] / 1000000
          // get currecy price in euro
          self.isLoading = true;
          let url = `https://api.currencyapi.com/v3/latest?apikey=fca_live_sBpm46aqmsrZYxpWWytCPi2Uoy6OfCfxqrSshcpr&currencies=${self.currency}&base_currency=${self.in_app_purchase_currency}`

          self.axios({
            url: url,
            method: "get",
          })
            .then((response) => {
              let value = response.data.data[self.currency].value;
              self.in_app_purchase_eur = (parseFloat(value * self.in_app_purchase_price)).toFixed(2);
            })
            .then((response) => {
              self.isLoading = false;
              self.$swal({
                title: self.$t("custom.you_sure_title"),
                text: self.$t("transfers.in_app_purchase_question", { payment_sum: self.in_app_purchase_price, payment_currency: self.in_app_purchase_currency, top_up_sum: (self.in_app_purchase_eur - (parseFloat(self.in_app_purchase_eur * 0.3))).toFixed(2), top_up_currency: self.currency }),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: self.$t("custom.yes"),
                cancelButtonText: self.$t("custom.no"),
                showCloseButton: true,
              }).then((result) => {
                if (result.value) {
                  console.log('want to buy')
                  const myTransaction = CdvPurchase.store.findInLocalReceipts(myProduct);

                  myProduct.getOffer().order().then((transaction) => {
                    console.log(transaction);
                  });
                  CdvPurchase.store.when('product').approved((product) => {
                    this.topUpBalanceFromInAppPurchase();
                    product.finish();
                    CdvPurchase.store.update();
                    // store.off();
                  });
                }
              });
            })
            .catch((error) => {
              self.isLoading = false;
              self.setError(error, self.$t("errors.error"));
              console.log(error);
            })
            .finally(() => {
              self.isLoading = false;
            });
        } else {
          self.$swal({
            icon: "warning",
            title: self.$t("errors.error"),
            text: self.$t("errors.payment_creating"),
          });
          console.log('myProduct not found', myProduct);
        }
      });
    },
    topUpBalanceFromInAppPurchase() {
      console.log('topUpBalanceFromInAppPurchase')
      if (!this.in_app_purchase_start) {
        this.in_app_purchase_start = true;
        this.isLoading = true;
        this.axios
          .post(
            "/api/v1/create_from_in_app_purchase",
            {
              transfers: {
                sum: parseFloat(this.in_app_purchase_eur - (this.in_app_purchase_eur * 0.3)).toFixed(2),
                recurrent: false,
                turn_off: false,
                state: 1,
                status: 'Paid In App Purchase',
                paid: true
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.transactions.unshift(response.data.transfer);
            this.$store.commit("setMainCompany", response.data.main_company);
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    pay() {
      this.$refs.paymentRef.submit();
    },
    transactionCreate() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (
          this.sum &&
          parseFloat(this.sum.replace(/,/, ".")) &&
          parseFloat(this.sum.replace(/,/, ".")) > 0
        ) {
          if (parseInt(this.sum) >= (this.main_company.plan.min_topup_sum || 0)) {
            this.isLoading = true;
            let self = this;
            // Создаём транзакцию у нас
            this.axios
              .post(
                "/api/v1/create_transfer",
                {
                  transfers: {
                    sum: parseFloat(this.sum.replace(/,/, ".")).toFixed(2),
                    // save_card: this.save_card,
                    recurrent: this.recurrent,
                    turn_off: false,
                    promo_code: this.promo_code,
                  },
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                if (response.data.Success == true && response.data.PaymentURL) {
                  window.location.replace(response.data.PaymentURL);
                  this.showTransactionDialog = false;
                  this.sum = 0;
                } else {
                  this.$swal({
                    icon: "error",
                    title: response.data.Message,
                    text: response.data.Details,
                  });
                }
              })
              .catch((error) => {
                this.isLoading = false;
                if (
                  error.response &&
                  error.response.status &&
                  error.response.status == 406
                ) {
                  self.$swal({
                    title: self.$t("errors.promocode_not_found_title"),
                    text: self.$t("errors.promocode_not_found_text"),
                    icon: "error",
                  });
                  this.promo_code = null;
                } else {
                  this.setError(error, this.$t("errors.payment_creating"));
                  this.$swal({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "error",
                    title: this.$t("errors.payment_creating"),
                    text: error,
                  });
                }
              })
              .finally(() => (this.isLoading = false));
          } else {
            this.$swal({
              icon: "warning",
              title: this.$t("other.payment_min", { min_topup_sum: this.main_company && this.main_company.plan && this.main_company.plan.min_topup_sum ? this.main_company.plan.min_topup_sum : 0, currency:  this.main_company && this.main_company.plan && this.main_company.plan.currency ? this.main_company.plan.currency : '' }),
            });
          }
        } else {
          this.$swal({
            icon: "warning",
            title: this.$t("errors.payment_sum"),
            text: this.$t("errors.payment_sum_text"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    showMenu() {
      if (
        this.$route.path == "/signin" ||
        this.$route.path == "/signup" ||
        this.$route.path == "/result_success" ||
        this.$route.path == "/result_bad" ||
        this.$route.path == "/forgot_password" ||
        this.$route.name == "ResetPassword" ||
        this.$route.name == "ClientPage" ||
        this.$route.path == "/404"
      ) {
        return false;
      } else {
        return true;
      }
    },
    closeBanner() {
      let self = this;
      this.$store.commit("setShowBanner", false);
      this.axios
        .patch(
          `/api/v1/users/${self.$store.getters.getCurrentUser.id}`,
          {
            users: {
              show_telegram: false,
            },
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .catch((error) => {
          self.setError(error, self.$t("errors.profile_update"));
          self.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: self.$t("errors.profile_update"),
            text: self.error,
          });
        })
        .finally(() => (self.isLoading = false));
    },
    removeDemoContent() {
      let self = this;
      this.$swal({
        title: this.$t("custom.you_sure_title"),
        text: this.$t("demo_content.remove_demo_content_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.you_sure_yes"),
        cancelButtonText: this.$t("custom.you_sure_no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value) {
          this.showOverlayRemoveDemo = true;
          this.axios
            .post(`/api/v1/remove_demo_content`,
              {
                company_id: self.$store.getters.getCompany.company.id,
              },
              {
                headers: {
                  Authorization: self.$store.getters.getAuthToken,
                },
              })
            .then((response) => {
              self.$store.commit("setBannerRemoveDemoContent");
              setTimeout(() => {
                self.showOverlayRemoveDemo = false;
                self.$swal(
                  self.$t("custom.deleted"),
                  self.$t("demo_content.remove_demo_content_success"),
                  "success"
                );
              }, 2000);
            })
            .catch((error) => {
              self.showOverlayRemoveDemo = false;
              self.setError(error, self.$t("errors.error"));
              console.log(error);
            });
        }
      });
    },
    reloadPage() {
      window.location.reload(true);
    },
    replaceToTelegram() {
      if (this.$i18n.locale == 'ru') {
        window.open("https://t.me/rentprogcrm", "_blank");
      } else {
        window.open("https://t.me/rentprog_world", "_blank");
      }
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    signOut() {
      let self = this;
      this.axios({
        url: "users/sign_out",
        baseURL:
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            // ? "http://10.0.2.2:3000"
            : "https://rentprog.net",
        method: "delete",
        headers: { Authorization: self.$store.getters.getAuthToken },
      })
        .then(() => {
          this.$store.commit("unsetCurrentUser");
        })
        .catch((error) => {
          self.isLoading = false;
          self.$swal({
            title: self.$t("errors.error"),
            text: error,
            icon: "error",
          });
          console.log(error);
        });
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>

<style lang="less">
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }

  &.info {
    .tooltip-inner {
      background: rgba(#004499, 0.9);
      color: white;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .tooltip-arrow {
      border-color: rgba(#004499, 0.9);
    }
  }

  &.popover {
    .popover-inner {
      background: #f9f9f9;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: #f9f9f9;
    }
  }
}
</style>
