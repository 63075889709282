<template>
  <v-container>
    <v-overlay v-if="!isLoading" :value="includedInPlan('agents')" class="px-5">
      <v-alert
        color="info"
        border="top"
        class="mx-2"
      >
        <v-row>
          <v-col cols="12" class="text-right mt-0 mb-0 pb-0">
            <v-icon @click="goBack">mdi-close</v-icon>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-icon size="50">mdi-information</v-icon>
          </v-col>
          <v-col cols="12" class="text-center font-bold">
            {{ $t('custom.free_tariff_warning') }}
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn @click="subscribeToPaidTariff()" color="success" class="mx-1 my-1" :loading="isLoadingSubscribeToPaidTariff">
              {{ $t('custom.to_paid_plan') }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn small text @click="toTariffOnSite()" class="mx-1 my-1">
              {{ $t('custom.tariff_more_info') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-overlay>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-alert v-if="error" :value="true" color="error">
      {{ error }}
    </v-alert>
    <v-card>
      <v-card-title class="text-center mb-0 pb-0">
        <h2 class="mx-auto">{{ $t('agents.adding_agent') }}
          <br v-if="$store.getters.getMainCompany.plan && $store.getters.getMainCompany.plan.users_limit > 0"><span v-if="$store.getters.getMainCompany.plan && $store.getters.getMainCompany.plan.users_limit > 0" class="text-subtitle-1"><span :class="$store.getters.getMainCompany.active_users_count >= $store.getters.getMainCompany.plan.users_limit ? 'error--text' : ''">{{ $store.getters.getMainCompany.active_users_count }}</span>/{{ $store.getters.getMainCompany.plan.users_limit }}</span>
        </h2>
      </v-card-title>

      <v-card-text>
        <v-container grid-list-md>
          <v-row justify="center" align="center" wrap>
            <v-flex xs12 sm12 md4> </v-flex>
          </v-row>
          <v-row justify="center" align="center" wrap>
            <v-flex xs12 sm12 md4 class="justify-center">
              <v-text-field
                v-model="user_name"
                :label="$t('employers.nickname') + ' *'"
                required
              ></v-text-field>
              <v-text-field
                v-model="first_name"
                :label="$t('employers.name') + ' *'"
                required
              ></v-text-field>
              <v-text-field
                v-model="middle_name"
                :label="$t('employers.middle_name')"
                required
              ></v-text-field>
              <v-text-field
                v-model="last_name"
                :label="$t('employers.last_name') + ' *'"
                required
              ></v-text-field>
              <v-text-field
                v-model="phone"
                :label="$t('employers.phone') + ' *'"
                required
              ></v-text-field>
              <v-text-field
                v-model="email"
                :label="$t('employers.email') + ' *'"
                required
              ></v-text-field>
              <v-flex class="text-center">
                <v-btn
                  v-if="checkAdminManager()"
                  @click="signup()"
                  color="success"
                  >{{ $t('agents.create_agent') }}</v-btn
                >
              </v-flex>
              <v-flex class="text-center mt-3">
                {{ $t('agents.after_save_email') }}
              </v-flex>
            </v-flex>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
/* eslint-disable */
import moment from "moment";
export default {
  data: () => ({
    user: [],
    user_name: null,
    first_name: null,
    middle_name: null,
    last_name: null,
    phone: null,
    email: null,
    show1: false,
    isLoading: false,
    isLoadingSubscribeToPaidTariff: false,
    error: ""
  }),
  created() {
    if (this.$store.getters.isLoggedIn) {
      if (this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin || this.$store.getters.isManager) {
      } else {
        this.goBack();
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          title: this.$t('errors.not_permitted_title'),
          text: this.$t('errors.only_admins_2')
        });
      }
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  computed: {
    userMustChangeTariffPlan() {
      if (this.$store.getters.getMainCompany) {
        if (this.$store.getters.getMainCompany.plan && this.$store.getters.getMainCompany.plan.id) {
          if(this.$store.getters.getMainCompany.plan.name == "Старт" || this.$store.getters.getMainCompany.plan.name == "Start") {
            if(this.$store.getters.getMainCompany.plan.trial_end && moment(this.$store.getters.getMainCompany.plan.trial_end).isAfter(moment())) {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return false
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    async signup() {
      if(this.$store.getters.getMainCompany && this.$store.getters.getMainCompany.plan && this.$store.getters.getMainCompany.plan.users_limit > 0 && this.$store.getters.getMainCompany.active_users_count && this.$store.getters.getMainCompany.active_users_count >= this.$store.getters.getMainCompany.plan.users_limit) {
        this.$swal({
          title: this.$t('plans.user_limit_reached_title'),
          text: this.$t('plans.user_limit_reached_text'),
          icon: "warning",
          showCancelButton: false
        });
      } else {
        let self = this;
        if (
          this.email &&
          this.user_name &&
          this.first_name &&
          this.last_name &&
          this.phone
        ) {
          this.isLoading = true;
          try {
            this.axios
              .post(
                "/create_agent",
                {
                  users: {
                    name: self.user_name,
                    first_name: self.first_name,
                    middle_name: self.middle_name,
                    last_name: self.last_name,
                    phone: self.phone,
                    email: self.email,
                    role: "agent",
                  },
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken
                  }
                }
              )
              .then((response) => {
                this.$router.replace(`/agents/${response.data.id}`);
                this.$swal({
                  icon: "success",
                  title: this.$t('agents.added_agent')
                });
              })
              .catch(error => {
                this.setError(error, this.$t('errors.employer_add'));
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "error",
                  title: this.$t('errors.employer_add'),
                  text: this.error
                });
              })
              .finally(() => {
                this.isLoading = false;
              })
          } catch (error) {
            // Handle Errors here.
            this.isLoading = false;

              this.$swal({
                title: this.$t('errors.error'),
                text: error,
                icon: "error",
                showCancelButton: false
              });
            console.log(error);
          }
        } else {
          this.$swal({
            title: this.$t('errors.all_fields_title'),
            text: this.$t('errors.all_fields_text'),
            icon: "error",
            showCancelButton: false
          });
        }
      }
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    checkAdminManager() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin || this.$store.getters.isManager;
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        var fpath = this.PageData.backCrumb.url;
        this.$router
          .push({
            path: fpath
          })
          .catch(err => {});
      }
    },
    subscribeToPaidTariff() {
      this.$router.push({ name: "Transactions" });
    },
    toTariffOnSite() {
      window.open(`https://rentprog.${this.$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/${this.$root.$i18n.locale}/tariffs`, '_blank');
    },
    includedInPlan(service) {
      if (this.$store.getters.getMainCompany) {
        if (this.$store.getters.getMainCompany.plan) {
          if (this.$store.getters.getMainCompany.plan.included_services) {
            // check trial period
            if (this.$store.getters.getMainCompany.plan.trial_end && moment(this.$store.getters.getMainCompany.plan.trial_end).isAfter(moment())) {
              return false;
            }
            return !this.$store.getters.getMainCompany.plan.included_services.includes(service);
          }
        }
      }
      return false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.id : null,
            username: this.$store.getters.getCurrentUser.name ? this.$store.getters.getCurrentUser.name : null,
            email: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.email : null,
          },
          custom: {
            company: this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.name ? this.$store.getters.getCompany.company.name : null,
          }
        }
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t('errors.error'),
        text: `${error}. ${text}`
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    }
  }
};
</script>
