import { render, staticRenderFns } from "./DocTemplate.vue?vue&type=template&id=ca7ce1f8"
import script from "./DocTemplate.vue?vue&type=script&lang=js"
export * from "./DocTemplate.vue?vue&type=script&lang=js"
import style0 from "./DocTemplate.vue?vue&type=style&index=0&id=ca7ce1f8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports