<template>
  <span v-resize="checkMobile">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <!-- Должникам -->
    <v-overlay absolute :value="$store.getters.getMainCompany &&
      $store.getters.getMainCompany.payer_state == 3
      ">
      <v-flex md12>
        {{ $t("other.message_no_pay") }}
        <v-btn color="success" :to="{ path: `/transactions` }">
          {{ $t("other.add_balance") }} ->
        </v-btn>
      </v-flex>
    </v-overlay>
    <!-- Брони -->
    <!-- <h2 v-if="!isMobile">{{ $t("archive.new_bookings") }}</h2> -->
    <v-toolbar v-if="!isMobile" text :color="$vuetify.theme.dark ? '' : 'white'">
      <v-btn small color="primary" @click="
        (show_active_booking = !show_active_booking)
        " v-if="showActive">{{ $t("custom.unactive_bookings") }}</v-btn>
      <v-btn small color="primary" @click="
        (show_active_booking = !show_active_booking)
        " v-if="!showActive">{{ $t("custom.active_bookings") }}</v-btn>
      <v-btn class="ml-1" small color="secondary" :to="{ name: 'ArchiveBookings' }">{{ $t("custom.archive") }}</v-btn>
      <!-- Date select -->
      <v-row align="center" class="ml-2 d-flex align-start">
        <v-menu ref="menu_sort_date" v-model="menu_sort_date" :close-on-content-click="false" :close-on-click="false"
          :return-value.sync="sort_date" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="sort_date_for_input" :label="$t('bookings.date_sort')" prepend-icon="mdi-calendar"
              readonly v-bind="attrs" v-on="on" hide-details></v-text-field>
          </template>
          <v-date-picker :locale="$i18n.locale" v-model="sort_date" first-day-of-week="1" no-title scrollable>
            <!-- <v-spacer></v-spacer> -->
            <!-- <v-btn text color="primary" @click="menu_sort_date = false">
              Закрыть
            </v-btn> -->
            <v-btn text color="primary" @click="
              $store.commit('setsortDateInBookings', null),
              (sort_date = null),
              (sort_date_for_input = null),
              (menu_sort_date = false),
              $refs.menu_sort_date.save(null)
              ">
              {{ $t("custom.remove") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu_sort_date.save(sort_date)">
              {{ $t("custom.ok") }}
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-icon class="mr-2 mt-2 text-left" v-if="sort_date" @click="
          $store.commit('setsortDateInBookings', null),
          (sort_date = null),
          (sort_date_for_input = null),
          (menu_sort_date = false),
          $refs.menu_sort_date.save(null)
          ">
          mdi-close
        </v-icon>
      </v-row>
      <v-spacer></v-spacer>
      <v-select v-model="selected_booking_state" clearable multiple :items="bookings_states"
        :label="$t('bookings.select_state')" class="ml-2 mt-5"></v-select>
      <v-spacer></v-spacer>
      <v-row align="center" class="ml-2">
        <v-text-field :dense="isMobile" v-model="search" prepend-icon="mdi-magnify" :label="$t('custom.search')"
          single-line clearable hide-details></v-text-field>
        <v-spacer></v-spacer>
        <v-btn small color="success" :to="{ name: 'NewBooking' }">{{
          $t("bookings.new_booking")
          }}</v-btn>
        <v-spacer></v-spacer>
        <v-icon v-if="selectedBookings && selectedBookings.length > 0" @click="archiveBookings()" color="red"
          class="mr-1" size="30" v-tooltip="{
            content: $t('bookings.archivate_booking'),
            placement: 'bottom-center',
            classes: ['info'],
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }">
          mdi-archive-arrow-down
        </v-icon>
        <!-- <v-icon @click="manualUpdateApi"> mdi-reload </v-icon> -->
        <v-icon v-if="(user_role == 'superadmin' || user_role == 'admin')" @click="toXlsx" class="mx-2"
          style="cursor: pointer" color="success">
          mdi-file-excel-outline
        </v-icon>
        <div :class="'pulse mt-3 mx-2 ' + (connected ? 'pulse_success' : 'pulse_error')
          " v-tooltip="{
            content: connected
              ? $t('custom.connected')
              : $t('custom.disconnected'),
            placement: 'bottom-center',
            classes: ['info'],
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }"></div>
        <v-btn v-if="!isIos" icon :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/${$root.$i18n.locale}/categories/2`" target="_blank">
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-row>
    </v-toolbar>
    <v-toolbar height="160px" v-if="isMobile" text :color="$vuetify.theme.dark ? '' : 'white'" class="mb-5">
      <v-flex md12 class="text-center" justify-center>
        <v-flex md12 class="mt-2">
          <v-btn small color="primary" @click="
            (show_active_booking = !show_active_booking)
            " v-if="showActive">{{ $t("bookings.unactive_bookings") }}</v-btn>
          <v-btn small color="primary" @click="
            (show_active_booking = !show_active_booking)
            " v-if="!showActive">{{ $t("bookings.active_bookings") }}</v-btn>
          <v-btn class="ml-1" small color="secondary" :to="{ name: 'ArchiveBookings' }">{{ $t("custom.archive")
            }}</v-btn>
          <v-icon v-if="(user_role == 'superadmin' || user_role == 'admin')" @click="toXlsx" class="mx-2"
            style="cursor: pointer" color="success">
            mdi-file-excel-outline
          </v-icon>
        </v-flex>
        <v-flex md12 class="mt-2 d-flex">
          <v-menu ref="menu_sort_date" v-model="menu_sort_date" :close-on-content-click="false" :close-on-click="false"
            :return-value.sync="sort_date" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="sort_date_for_input" dense :placeholder="$t('bookings.date_sort')"
                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details></v-text-field>
            </template>
            <v-date-picker v-model="sort_date" no-title scrollable first-day-of-week="1">
              <!-- <v-spacer></v-spacer> -->
              <!-- <v-btn text color="primary"
              @click="
                  $store.commit('setsortDateInBookings', null),
                    (sort_date = null),
                    (sort_date_for_input = null),
                    (menu_sort_date = false),
                    $refs.menu_sort_date.save(null)
                "
              >
                Закрыть
              </v-btn> -->
              <v-btn text color="primary" @click="
                $store.commit('setsortDateInBookings', null),
                (sort_date = null),
                (sort_date_for_input = null),
                (menu_sort_date = false),
                $refs.menu_sort_date.save(null)
                ">
                {{ $t("custom.remove") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$refs.menu_sort_date.save(sort_date)">
                {{ $t("custom.ok") }}
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-icon class="mr-2 mt-2 text-left" v-if="sort_date" @click="
            $store.commit('setsortDateInBookings', null),
            (sort_date = null),
            (sort_date_for_input = null),
            (menu_sort_date = false),
            $refs.menu_sort_date.save(null)
            ">
            mdi-close
          </v-icon>
        </v-flex>
        <v-flex md12 class="mt-2">
          <v-text-field :dense="isMobile" v-model="search" prepend-icon="mdi-magnify" :label="$t('custom.search')"
            single-line clearable hide-details></v-text-field>
        </v-flex>
        <v-flex md12 class="mt-2 d-flex align-center justify-center">
          <v-btn small class="mb-2" color="success" :to="{ name: 'NewBooking' }" block>{{ $t("custom.add") }}</v-btn>
          <v-spacer></v-spacer>
          <v-icon v-if="selectedBookings && selectedBookings.length > 0" @click="archiveBookings()" color="red"
            class="ml-4 mb-2" size="30" v-tooltip="{
              content: $t('bookings.archivate_booking'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }">
            mdi-archive-arrow-down
          </v-icon>
          <!-- <v-icon
            @click="manualUpdateApi"
            v-tooltip="{
              content: $t('custom.update_data'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }"
          >
            mdi-reload
          </v-icon> -->
        </v-flex>
      </v-flex>
    </v-toolbar>
    <v-data-table v-if="inactive_bookings.length > 0 || showActive" v-model="selected" :headers="selectedHeaders"
      v-resize="checkMobile" :items="showBooking()" :loading="isLoading" :loading-text="$t('custom.loading_table')"
      :search="search" :server-items-length.sync="total" :options.sync="tableOptions" :page.sync="page" item-key="id"
      :class="!isMobile ? 'mt-2 start-tour-bookings-1' : 'mt-2 start-tour-bookings-1 is-mobile'" mobile-breakpoint="100"
      :items-per-page="50" dense :footer-props="{
        pageText: `{0} ${$t('custom.of')} ${total}`,
        itemsPerPageText: $t('custom.elements_table'),
        showFirstLastPage: true,
        itemsPerPageOptions: [50, 150, 300, -1],
      }">
      <template slot="header" slot-scope="props">
        <tr id="registration-step-64">
          <th v-for="header in props.headers" :key="header.text" nowrap>
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template slot="item" slot-scope="props">
        <tr @dblclick="replaceToBooking(props.item)" style="cursor: pointer" :class="selectColorBooking(props.item)">
          <td v-if="!showActive">
            <v-checkbox v-model="props.item.selected" color="primary"></v-checkbox>
          </td>
          <td class="text-center" :key="props.item.id">
            <v-chip v-if="props.item.vseprokaty_id" class="ma-2" :color="props.item.active ? 'warning' : 'secondary'"
              text-color="white">
              <v-avatar left color="secondary darken-2" v-tooltip="{
                content: $t('agregator.from_agregator'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
                <v-icon>mdi-alpha-a-circle-outline</v-icon>
              </v-avatar>
              <v-avatar left color="error darken-2" v-if="props.item.fast_booking" v-tooltip="{
                content: $t('agregator.fast_booking'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
                <v-icon>mdi-lightning-bolt-circle</v-icon>
              </v-avatar>
              <router-link class="white--text text-decoration-none" v-tooltip="{
                content: `${$t('custom.number') + ' ' + props.item.number}`,
                placement: 'bottom-center',
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }" :to="{ name: 'BookingCard', params: { id: props.item.id } }">{{ props.item.id }}</router-link>
            </v-chip>
            <v-chip v-else-if="props.item.new" class="ma-2" color="info" text-color="white">
              <v-avatar left>
                <v-icon>mdi-new-box</v-icon>
              </v-avatar>
              <router-link class="white--text text-decoration-none"
                :to="{ name: 'BookingCard', params: { id: props.item.id } }" v-tooltip="{
                  content: `${$t('custom.number') + ' ' + props.item.number}`,
                  placement: 'bottom-center',
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }">{{ props.item.id }}</router-link>
            </v-chip>
            <v-flex v-else class="d-inline-flex">
              <router-link id="booking_item" v-tooltip="{
                content: `${$t('custom.number') + ' ' + props.item.number}`,
                placement: 'bottom-center',
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }" :to="{ name: 'BookingCard', params: { id: props.item.id } }">{{ props.item.id }}</router-link>
            </v-flex>
          </td>
          <td class="text-center">
            {{ formatCreatedAt(props.item.created_at) }}
          </td>
          <td class="text-center" v-if="showActive">
            <v-flex class="d-flex text-center justify-center">
              <v-icon class="mr-2" v-if="props.item.in_rent" color="primary" v-tooltip="{
                content: $t('bookings.in_rent'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
                mdi-car
              </v-icon>
              <v-icon v-if="props.item.active && !props.item.in_rent" class="mr-2" style="z-index: 1" color="secondary"
                v-tooltip="{
                  content: $t('bookings.booked'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }">
                mdi-clock-outline
              </v-icon>
              <v-icon v-if="props.item.failure" v-tooltip="{
                content: $t('bookings.decline'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
                mdi-close-circle-outline
              </v-icon>
            </v-flex>
          </td>
          <td class="text-center">
            <v-flex class="d-flex text-center justify-center">
              <v-menu offset-y v-if="props.item.state == 'Новая'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="primary" v-tooltip="{
                      content: $t('bookings.new'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-new-box
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Подтверждена'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="success" v-tooltip="{
                      content: $t('bookings.approved'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-check-all
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Не подтверждена'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="warning" v-tooltip="{
                      content: $t('bookings.not_approved'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-cellphone-basic
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'В обработке'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="primary" v-tooltip="{
                      content: $t('bookings.in_work'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-autorenew
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Отказ клиента'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="danger" v-tooltip="{
                      content: $t('bookings.declined'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-account-remove-outline
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Нет машин'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="secondary" v-tooltip="{
                      content: $t('bookings.declined_free_cars'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-car-off
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Недозвон'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="warning--text">
                    <v-icon color="warning" v-tooltip="{
                      content: $t('bookings.not_called'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-phone-off-outline
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Ожидает ответа клиента'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="pink" v-tooltip="{
                      content: $t('bookings.waiting_for_answer'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-account-question-outline
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Ожидает оплаты'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="lime" v-tooltip="{
                      content: $t('bookings.waiting_for_pay'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-currency-usd-off
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Активная'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="light-blue" v-tooltip="{
                      content: $t('bookings.active_state'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-alpha-a-circle-outline
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Отмена'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="brown" v-tooltip="{
                      content: $t('bookings.canceled_state'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-cancel
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Отъездила'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="light-green" v-tooltip="{
                      content: $t('bookings.ride_true'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-garage-variant
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Вернуть залог'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="blue" v-tooltip="{
                      content: $t('bookings.waiting_for_deposit'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-keyboard-return
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Другое' || props.item.state == null">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="secondary" v-tooltip="{
                      content: $t('bookings.other_state'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-head-question-outline
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-flex>
          </td>
          <!-- Оплата -->
          <td class="text-center">
            <!-- <v-btn x-small outlined color="primary" v-if="!props.item.in_rent && props.item.counts.length === 0" @click="paymentStart(props.item)" :disabled="!props.item.active || props.item.in_rent" >Оплатить</v-btn>
                    <v-btn x-small :color="calculatePayments(props.item) != 'Оплачено' ? 'warning' : 'success'" v-if="props.item.counts.length > 0 || props.item.in_rent" @click="paymentStart(props.item)" :disabled="!props.item.active && (calculatePayments(props.item) != 'Оплачено' ? false : true)">{{calculatePayments(props.item)}}</v-btn> -->
            <v-btn x-small outlined :to="{ name: 'BookingCard', params: { id: props.item.id } }" color="primary" v-if="
              !props.item.in_rent &&
              props.item.counts.length === 0 &&
              !props.item.technical
            " style="cursor: default" :disabled="!props.item.active || props.item.in_rent" v-tooltip="{
              content: $t('bookings.not_paid'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }">{{ $t("bookings.not_paid_2") }}</v-btn>
            <v-btn x-small :color="selectColorPaid(props.item)" v-if="
              props.item.counts.length > 0 ||
              (props.item.in_rent && !props.item.technical)
            " style="cursor: default" :disabled="!props.item.active &&
              (calculatePayments(props.item) != $t('bookings.paid')
                ? false
                : true)
              " v-tooltip="{
                content: `${calculatePayments(props.item) != $t('bookings.paid')
                  ? $t('bookings.partly_paid')
                  : $t('bookings.fully_paid')
                  }`,
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">{{ calculatePayments(props.item) }}</v-btn>
          </td>
          <td v-if="!isMobile" class="text-center">
            <router-link :to="{ path: `/cars/${props.item.car_id}` }" :class="setCarStateClass(props.item.car_state)"
              v-tooltip="{
                content: `${setCarStateTooltip(props.item.car_state)}`,
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">{{ props.item.car_name }}({{ props.item.car_code }})</router-link>
          </td>
          <td v-else nowrap class="text-center">
            <router-link :to="{ path: `/cars/${props.item.car_id}` }" :class="setCarStateClass(props.item.car_state)">{{
              props.item.car_name }}({{ props.item.car_code }})</router-link>
          </td>
          <td class="text-center" nowrap v-if="!props.item.entity">
            <router-link v-if="user_role != 'guest' && user_role != 'partner'" :class="checkClientCategory(props.item)"
              :to="{ name: 'ClientCard', params: { id: props.item.client_id } }" v-tooltip="{
                content: `${props.item.client_category}`,
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
              {{ props.item.last_name ? props.item.last_name : "" }}
              {{ props.item.first_name ? props.item.first_name[0] : "" }}.
              {{ props.item.middle_name ? props.item.middle_name[0] : "" }}.
            </router-link>
          </td>
          <td class="text-center" v-else>
            <router-link v-if="user_role != 'guest' && user_role != 'partner'" :class="checkClientCategory(props.item)"
              :to="{ name: 'ClientCard', params: { id: props.item.client_id } }">{{ props.item.entity_name
              }}</router-link>
          </td>
          <td v-if="!isMobile" nowrap class="text-center">
            {{ formatDateBookingDates(props.item.start_date) }}
            <br />
            {{ formatTimeBookingDates(props.item.start_date) }}
          </td>
          <td v-else nowrap class="text-center">
            {{ shortFormatBookingDates(props.item.start_date) }}
          </td>
          <td class="text-center" nowrap v-if="!isMobile && !props.item.active">
            {{ formatDateBookingDates(props.item.end_date) }}
            <br />
            {{ formatTimeBookingDates(props.item.end_date) }}
          </td>
          <td class="text-center" nowrap v-if="isMobile && !props.item.active">
            {{ shortFormatBookingDates(props.item.end_date) }}
          </td>
          <td class="text-center" nowrap v-if="!isMobile && props.item.active"
            :style="checkLateDate(props.item.end_date)">
            {{ formatDateBookingDates(props.item.end_date) }}
            <br />
            {{ formatTimeBookingDates(props.item.end_date) }}
          </td>
          <td class="text-center" nowrap v-if="isMobile && props.item.active"
            :style="checkLateDate(props.item.end_date)">
            {{ shortFormatBookingDates(props.item.end_date) }}
          </td>
          <td class="text-center">{{ props.item.days }}</td>
          <td class="text-center">{{ props.item.location_start }}</td>
          <td class="text-center">{{ props.item.location_end }}</td>
          <td class="text-truncate" style="max-width: 200px" @click="
            (showDescriptionEditId = props.item.id),
            (descriptionEdit = props.item.description),
            (showDescriptionEditDialog = true)
            " v-tooltip="{
              content: `${props.item.description}`,
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }">
            {{ props.item.description }}
          </td>
          <!-- <td
            class="text-center description-trunc"
            @click="
              (showDescriptionEditId = props.item.id),
                (descriptionEdit = props.item.description),
                (showDescriptionEditDialog = true)
            "
          >
            <read-more
              v-if="props.item.description && props.item.description.length > 0"
              :more-str="$t('custom.read')"
              :text="props.item.description"
              link="#"
              :less-str="$t('custom.less')"
              :max-chars="30"
            ></read-more>
          </td> -->
          <td class="text-center">
            <v-flex class="px-3">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon v-if="!props.item.responsible" @click="
                      getWorkersNames(),
                      (updatingBookingId = props.item.id)
                      " color="secondary" class="mx-1" v-tooltip="{
                        content: $t('bookings.responsible'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }">
                      mdi-account-question
                    </v-icon>
                    <div v-else class="mx-1">
                      <v-icon color="success" class="mr-1" @click="
                        getWorkersNames(),
                        (updatingBookingId = props.item.id)
                        " v-tooltip="{
                          content: $t('bookings.responsible'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                        mdi-account-check
                      </v-icon>
                      <v-flex @click="
                        getWorkersNames(),
                        (updatingBookingId = props.item.id)
                        " v-tooltip="{
                          content: $t('bookings.responsible'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                        {{ props.item.responsible }}
                      </v-flex>
                    </div>
                  </span>
                </template>
                <v-list class="mr-3">
                  <v-list-item v-for="(responsible, index) in responsibles" :key="index"
                    @click="updateResponsible(props.item.id, responsible.name, responsible.id)">
                    <v-list-item-title>
                      {{ responsible.name ? responsible.name : $t("bookings.no_responsible") }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-flex>
          </td>
          <!-- <td
            class="text-center"
            v-if="props.item.responsible"
            @click="
              getWorkersNames(),
                (updatingBookingId = props.item.id),
                (selected_worker = props.item.responsible),
                (showSetResponsible = true)
            "
          >
            {{ props.item.responsible }}
          </td>
          <td class="text-center justify-center" v-else>
            <v-icon
              class="mr-2"
              color="grey"
              @click="
                getWorkersNames(),
                  (updatingBookingId = props.item.id),
                  (showSetResponsible = true)
              "
              v-tooltip="{
                content: $t('bookings.responsible'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }"
            >
              mdi-account-question
            </v-icon>
          </td> -->
        </tr>
      </template>
      <template slot="no-data">
        {{ $t("custom.no_data_in_table") }}
      </template>
      <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
        {{ $t("tables.no_search_result", { msg: search }) }}
      </v-alert>
    </v-data-table>
    <!-- Отъезженные брони -->
    <v-divider v-if="!showActive && inactive_bookings.length > 0"></v-divider>
    <h2 v-if="!showActive && rided_bookings.length > 0">
      {{ $t("archive.ride_bookings") }}
    </h2>
    <v-toolbar v-if="
      !showActive &&
      rided_bookings.length > 0 &&
      inactive_bookings.length > 0
    " text :color="$vuetify.theme.dark ? '' : 'white'">
      <v-spacer></v-spacer>
      <v-row align="center" class="ml-2">
        <v-text-field :dense="isMobile" v-model="search_rided_bookings" class="mx-2" prepend-icon="mdi-magnify"
          :label="$t('custom.search')" single-line clearable hide-details></v-text-field>
        <v-icon v-if="selectedBookings && selectedBookings.length > 0" @click="archiveBookings()" color="red"
          class="mx-2" size="30" v-tooltip="{
            content: $t('bookings.archivate_booking'),
            placement: 'bottom-center',
            classes: ['info'],
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }">
          mdi-archive-arrow-down
        </v-icon>
      </v-row>
    </v-toolbar>
    <v-data-table v-if="!showActive && rided_bookings.length > 0" :headers="selectedHeaders" v-resize="checkMobile"
      :items="rided_bookings" :loading="isLoading" :loading-text="$t('custom.loading_table')"
      :search="search_rided_bookings" :disable-sort="sort_date && !(sort_date == '' || sort_date == null) ? true : false
        " :sort-by="showActive ? ['in_rent'] : ['id']" :sort-desc="[true]" item-key="id"
      :class="!isMobile ? 'mt-2' : 'mt-2 is-mobile'" mobile-breakpoint="100" :items-per-page="100" dense :footer-props="{
        pageText: `{0} ${$t('custom.of')} {1}`,
        itemsPerPageText: $t('custom.elements_table'),
        showFirstLastPage: true,
        itemsPerPageOptions: [50, 150, 300, -1],
      }">
      <template slot="header" slot-scope="props">
        <tr id="registration-step-64">
          <th v-for="header in props.headers" :key="header.text" nowrap>
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template slot="item" slot-scope="props">
        <tr @dblclick="replaceToBooking(props.item)" style="cursor: pointer"
          :class="!props.item.technical ? '' : 'blue-grey lighten-4'">
          <td v-if="!showActive">
            <v-checkbox v-model="props.item.selected" color="primary"></v-checkbox>
          </td>
          <td class="text-center" :key="props.item.id">
            <v-chip v-if="props.item.vseprokaty_id" class="ma-2" :color="props.item.active ? 'warning' : 'secondary'"
              text-color="white">
              <v-avatar left color="secondary darken-2" v-tooltip="{
                content: $t('agregator.from_agregator'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
                <v-icon>mdi-alpha-a-circle-outline</v-icon>
              </v-avatar>
              <v-avatar left v-if="props.item.fast_booking" color="error darken-2" v-tooltip="{
                content: $t('agregator.fast_booking'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
                <v-icon>mdi-lightning-bolt-circle</v-icon>
              </v-avatar>
              <router-link class="white--text text-decoration-none"
                :to="{ name: 'BookingCard', params: { id: props.item.id } }">{{ props.item.id }}</router-link>
            </v-chip>
            <v-chip v-else-if="props.item.new" class="ma-2" color="info" text-color="white">
              <v-avatar left>
                <v-icon>mdi-new-box</v-icon>
              </v-avatar>
              <router-link :to="{ name: 'BookingCard', params: { id: props.item.id } }">{{ props.item.id
                }}</router-link>
            </v-chip>
            <v-flex v-else class="d-inline-flex">
              <router-link :to="{ name: 'BookingCard', params: { id: props.item.id } }">{{ props.item.id
                }}</router-link>
            </v-flex>
          </td>
          <td class="text-center">
            {{ formatCreatedAt(props.item.created_at) }}
          </td>
          <td class="text-center" v-if="showActive">
            <v-flex class="d-flex text-center justify-center">
              <v-icon class="mr-2" v-if="props.item.in_rent" color="primary" v-tooltip="{
                content: $t('bookings.in_rent'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
                mdi-car
              </v-icon>
              <v-icon v-if="props.item.active && !props.item.in_rent" class="mr-2" style="z-index: 1" color="secondary"
                v-tooltip="{
                  content: $t('bookings.booked'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }">
                mdi-clock-outline
              </v-icon>
              <v-icon v-if="props.item.failure" v-tooltip="{
                content: $t('bookings.decline'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
                mdi-close-circle-outline
              </v-icon>
            </v-flex>
          </td>
          <td class="text-center">
            <v-flex class="d-flex text-center justify-center">
              <v-menu offset-y v-if="props.item.state == 'Новая'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="primary" v-tooltip="{
                      content: $t('bookings.new'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-new-box
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Подтверждена'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="success" v-tooltip="{
                      content: $t('bookings.approved'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-check-all
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Не подтверждена'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="warning" v-tooltip="{
                      content: $t('bookings.not_approved'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-cellphone-basic
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'В обработке'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="primary" v-tooltip="{
                      content: $t('bookings.in_work'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-autorenew
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Отказ клиента'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="danger" v-tooltip="{
                      content: $t('bookings.declined'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-account-remove-outline
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Нет машин'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="secondary" v-tooltip="{
                      content: $t('bookings.declined_free_cars'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-car-off
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Недозвон'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="warning--text">
                    <v-icon color="warning" v-tooltip="{
                      content: $t('bookings.not_called'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-phone-off-outline
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Ожидает ответа клиента'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="pink" v-tooltip="{
                      content: $t('bookings.waiting_for_answer'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-account-question-outline
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Ожидает оплаты'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="lime" v-tooltip="{
                      content: $t('bookings.waiting_for_pay'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-currency-usd-off
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Активная'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="light-blue" v-tooltip="{
                      content: $t('bookings.active_state'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-alpha-a-circle-outline
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Отмена'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="brown" v-tooltip="{
                      content: $t('bookings.canceled_state'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-cancel
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Отъездила'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="light-green" v-tooltip="{
                      content: $t('bookings.ride_true'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-garage-variant
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Вернуть залог'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="blue" v-tooltip="{
                      content: $t('bookings.waiting_for_deposit'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-keyboard-return
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="props.item.state == 'Другое' || props.item.state == null">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="secondary" v-tooltip="{
                      content: $t('bookings.other_state'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-head-question-outline
                    </v-icon>
                  </span>
                </template>
                <v-list>
                  <v-list-item v-for="(state, index) in bookings_states" :key="index"
                    @click="saveBookingState(state.value, props.item.id)">
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-flex>
          </td>
          <!-- Оплата -->
          <td class="text-center">
            <!-- <v-btn x-small outlined color="primary" v-if="!props.item.in_rent && props.item.counts.length === 0" @click="paymentStart(props.item)" :disabled="!props.item.active || props.item.in_rent" >Оплатить</v-btn>
                    <v-btn x-small :color="calculatePayments(props.item) != 'Оплачено' ? 'warning' : 'success'" v-if="props.item.counts.length > 0 || props.item.in_rent" @click="paymentStart(props.item)" :disabled="!props.item.active && (calculatePayments(props.item) != 'Оплачено' ? false : true)">{{calculatePayments(props.item)}}</v-btn> -->
            <v-btn x-small outlined :to="{ name: 'BookingCard', params: { id: props.item.id } }" color="primary" v-if="
              !props.item.in_rent &&
              props.item.counts.length === 0 &&
              !props.item.technical
            " style="cursor: default" :disabled="!props.item.active || props.item.in_rent" v-tooltip="{
              content: $t('bookings.not_paid'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }">{{ $t("bookings.not_paid_2") }}</v-btn>
            <v-btn x-small :color="selectColorPaid(props.item)" v-if="
              props.item.counts.length > 0 ||
              (props.item.in_rent && !props.item.technical)
            " style="cursor: default" :disabled="!props.item.active &&
              (calculatePayments(props.item) != $t('bookings.paid')
                ? false
                : true)
              " v-tooltip="{
                content: `${calculatePayments(props.item) != $t('bookings.paid')
                  ? $t('bookings.partly_paid')
                  : $t('bookings.fully_paid')
                  }`,
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">{{ calculatePayments(props.item) }}</v-btn>
          </td>
          <td v-if="!isMobile" class="text-center">
            <router-link :to="{ path: `/cars/${props.item.car_id}` }" :class="setCarStateClass(props.item.car_state)"
              v-tooltip="{
                content: `${setCarStateTooltip(props.item.car_state)}`,
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">{{ props.item.car_name }}({{ props.item.car_code }})</router-link>
          </td>
          <td v-else nowrap class="text-center">
            <router-link :to="{ path: `/cars/${props.item.car_id}` }" :class="setCarStateClass(props.item.car_state)">{{
              props.item.car_code }}</router-link>
          </td>
          <td class="text-center" nowrap v-if="!props.item.entity">
            <router-link v-if="user_role != 'guest' && user_role != 'partner'" :class="checkClientCategory(props.item)"
              :to="{ name: 'ClientCard', params: { id: props.item.client_id } }" v-tooltip="{
                content: `${props.item.client_category}`,
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
              {{ props.item.last_name ? props.item.last_name : "" }}
              {{ props.item.first_name ? props.item.first_name[0] : "" }}.
              {{ props.item.middle_name ? props.item.middle_name[0] : "" }}.
            </router-link>
          </td>
          <td class="text-center" v-else>
            <router-link v-if="user_role != 'guest' && user_role != 'partner'" :class="checkClientCategory(props.item)"
              :to="{ name: 'ClientCard', params: { id: props.item.client_id } }">{{ props.item.entity_name
              }}</router-link>
          </td>
          <td v-if="!isMobile" nowrap class="text-center">
            {{ formatDateBookingDates(props.item.start_date) }}
            <br />
            {{ formatTimeBookingDates(props.item.start_date) }}
          </td>
          <td v-else nowrap class="text-center">
            {{ shortFormatBookingDates(props.item.start_date) }}
          </td>
          <td class="text-center" nowrap v-if="!isMobile && !props.item.active">
            {{ formatDateBookingDates(props.item.end_date) }}
            <br />
            {{ formatTimeBookingDates(props.item.end_date) }}
          </td>
          <td class="text-center" nowrap v-if="isMobile && !props.item.active">
            {{ shortFormatBookingDates(props.item.end_date) }}
          </td>
          <td class="text-center" nowrap v-if="!isMobile && props.item.active"
            :style="checkLateDate(props.item.end_date)">
            {{ formatDateBookingDates(props.item.end_date) }}
            <br />
            {{ formatTimeBookingDates(props.item.end_date) }}
          </td>
          <td class="text-center" nowrap v-if="isMobile && props.item.active"
            :style="checkLateDate(props.item.end_date)">
            {{ shortFormatBookingDates(props.item.end_date) }}
          </td>
          <td class="text-center">{{ props.item.days }}</td>
          <td class="text-center">{{ props.item.location_start }}</td>
          <td class="text-center">{{ props.item.location_end }}</td>
          <td class="text-truncate" style="max-width: 200px" @click="
            (showDescriptionEditId = props.item.id),
            (descriptionEdit = props.item.description),
            (showDescriptionEditDialog = true)
            " v-tooltip="{
              content: `${props.item.description}`,
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }">
            {{ props.item.description }}
          </td>
          <!-- <td
            class="text-center description-trunc"
            @click="
              (showDescriptionEditId = props.item.id),
                (descriptionEdit = props.item.description),
                (showDescriptionEditDialog = true)
            "
          >
            <read-more
              v-if="props.item.description && props.item.description.length > 0"
              :more-str="$t('custom.read')"
              :text="props.item.description"
              link="#"
              :less-str="$t('custom.less')"
              :max-chars="30"
            ></read-more>
          </td> -->
          <td class="text-center" v-if="props.item.responsible" @click="
            getWorkersNames(),
            (updatingBookingId = props.item.id),
            (selected_worker = props.item.responsible),
            (showSetResponsible = true)
            ">
            {{ props.item.responsible }}
          </td>
          <td class="text-center justify-center" v-else>
            <v-icon class="mr-2" color="grey" @click="
              getWorkersNames(),
              (updatingBookingId = props.item.id),
              (showSetResponsible = true)
              " v-tooltip="{
                content: $t('bookings.responsible'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
              mdi-account-question
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!-- Частичные возвраты залога -->
    <v-divider v-if="
      !showActive &&
      part_return_deposit_bookings &&
      part_return_deposit_bookings.length > 0
    "></v-divider>
    <h2 v-if="
      !showActive &&
      part_return_deposit_bookings &&
      part_return_deposit_bookings.length > 0
    ">
      {{ $t("bookings.return_deposits") }}
    </h2>
    <v-toolbar v-if="
      !showActive &&
      part_return_deposit_bookings &&
      part_return_deposit_bookings.length > 0
    " text :color="$vuetify.theme.dark ? '' : 'white'">
      <v-spacer></v-spacer>
      <v-row align="center" class="ml-2">
        <v-text-field :dense="isMobile" v-model="search_return_deposit" prepend-icon="mdi-magnify"
          :label="$t('custom.search')" single-line clearable hide-details></v-text-field>
        <v-spacer></v-spacer>
        <download-excel :data="part_return_deposit_bookings" v-if="
          part_return_deposit_bookings &&
          part_return_deposit_bookings.length > 0 &&
          user_role != 'guest' &&
          user_role != 'partner'
        ">
          <v-icon class="ml-2" style="cursor: pointer" color="success">
            mdi-file-excel-outline
          </v-icon>
        </download-excel>
      </v-row>
    </v-toolbar>
    <v-data-table v-if="
      !showActive &&
      part_return_deposit_bookings &&
      part_return_deposit_bookings.length > 0
    " v-model="selected" :headers="headers_return_deposit" v-resize="checkMobile" :items="part_return_deposit_bookings"
      :loading="isLoading" :loading-text="$t('custom.loading_table')" :search="search" :disable-sort="sort_date && !(sort_date == '' || sort_date == null) ? true : false
        " :sort-by="['end_date_js']" :sort-desc="[true]" item-key="id" :class="!isMobile ? 'mt-2' : 'mt-2 is-mobile'"
      mobile-breakpoint="100" :items-per-page="50" dense :footer-props="{
        pageText: `{0} ${$t('custom.of')} {1}`,
        itemsPerPageText: $t('custom.elements_table'),
        showFirstLastPage: true,
        itemsPerPageOptions: [20, 50, 100, -1],
      }">
      <template slot="header" slot-scope="props">
        <tr>
          <th v-for="header in props.headers" :key="header.text" nowrap>
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template slot="item" slot-scope="props">
        <tr @dblclick="replaceToBooking(props.item)" style="cursor: pointer"
          :class="selectColorRidedBooking(props.item)">
          <td class="text-center" :key="props.item.id">
            <v-chip v-if="props.item.new" class="ma-2" color="info" text-color="white">
              <v-avatar left>
                <v-icon>mdi-new-box</v-icon>
              </v-avatar>
              <router-link :to="{ name: 'BookingCard', params: { id: props.item.id } }">{{ props.item.id
                }}</router-link>
            </v-chip>
            <v-flex v-else class="d-inline-flex">
              <router-link :to="{ name: 'BookingCard', params: { id: props.item.id } }">{{ props.item.id
                }}</router-link>
            </v-flex>
          </td>
          <td v-if="!isMobile" class="text-center">
            {{ formatCreatedAt(props.item.created_at) }}
          </td>
          <!-- Оплата -->
          <td class="text-center">
            <!-- <v-btn x-small outlined color="primary" v-if="!props.item.in_rent && props.item.counts.length === 0" @click="paymentStart(props.item)" :disabled="!props.item.active || props.item.in_rent" >Оплатить</v-btn>
                    <v-btn x-small :color="calculatePayments(props.item) != 'Оплачено' ? 'warning' : 'success'" v-if="props.item.counts.length > 0 || props.item.in_rent" @click="paymentStart(props.item)" :disabled="!props.item.active && (calculatePayments(props.item) != 'Оплачено' ? false : true)">{{calculatePayments(props.item)}}</v-btn> -->
            <v-btn x-small outlined :to="{ name: 'BookingCard', params: { id: props.item.id } }" color="primary" v-if="
              !props.item.in_rent &&
              props.item.counts.length === 0 &&
              !props.item.technical
            " style="cursor: default" :disabled="!props.item.active || props.item.in_rent" v-tooltip="{
              content: $t('bookings.not_paid'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }">{{ $t("bookings.not_paid") }}</v-btn>
            <v-btn x-small :color="calculatePayments(props.item) != $t('bookings.paid')
              ? 'warning'
              : 'success'
              " v-if="
                props.item.counts.length > 0 ||
                (props.item.in_rent && !props.item.technical)
              " style="cursor: default" :disabled="!props.item.active &&
                (calculatePayments(props.item) != $t('bookings.paid')
                  ? false
                  : true)
                " v-tooltip="{
                  content: `${calculatePayments(props.item) != $t('bookings.paid')
                    ? $t('bookings.partly_paid')
                    : $t('bookings.fully_paid')
                    }`,
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }">{{ calculatePayments(props.item) }}</v-btn>
          </td>
          <td class="text-center">
            {{ returnDepositSum(props.item) }}
          </td>
          <td v-if="!isMobile" class="text-center">
            <router-link :to="{ path: `/cars/${props.item.car_id}` }" :class="setCarStateClass(props.item.car_state)"
              v-tooltip="{
                content: `${setCarStateTooltip(props.item.car_state)}`,
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">{{ props.item.car_name }}({{ props.item.car_code }})</router-link>
          </td>
          <td v-else nowrap class="text-center">
            <router-link :to="{ path: `/cars/${props.item.car_id}` }" :class="setCarStateClass(props.item.car_state)">{{
              props.item.car_code }}</router-link>
          </td>
          <td class="text-center" nowrap v-if="!props.item.entity">
            <router-link v-if="user_role != 'guest' && user_role != 'partner'" :class="checkClientCategory(props.item)"
              :to="{ name: 'ClientCard', params: { id: props.item.client_id } }" v-tooltip="{
                content: `${props.item.client_category}`,
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
              {{ props.item.last_name ? props.item.last_name : "" }}
              {{ props.item.first_name ? props.item.first_name[0] : "" }}.
              {{ props.item.middle_name ? props.item.middle_name[0] : "" }}.
            </router-link>
          </td>
          <td class="text-center" v-else>
            <router-link v-if="user_role != 'guest' && user_role != 'partner'" :class="checkClientCategory(props.item)"
              :to="{ name: 'ClientCard', params: { id: props.item.client_id } }">{{ props.item.entity_name
              }}</router-link>
          </td>
          <td v-if="!isMobile" nowrap class="text-center">
            {{ formatDateBookingDates(props.item.start_date) }}
            <br />
            {{ formatTimeBookingDates(props.item.start_date) }}
          </td>
          <td v-else nowrap class="text-center">
            {{ shortFormatBookingDates(props.item.start_date) }}
          </td>
          <td class="text-center" nowrap v-if="!isMobile && !props.item.active">
            {{ formatDateBookingDates(props.item.end_date) }}
            <br />
            {{ formatTimeBookingDates(props.item.end_date) }}
          </td>
          <td class="text-center" nowrap v-if="isMobile && !props.item.active">
            {{ shortFormatBookingDates(props.item.end_date) }}
          </td>
          <td class="text-center" nowrap v-if="!isMobile && props.item.active"
            :style="checkLateDate(props.item.end_date)">
            {{ formatDateBookingDates(props.item.end_date) }}
            <br />
            {{ formatTimeBookingDates(props.item.end_date) }}
          </td>
          <td class="text-center" nowrap v-if="isMobile && props.item.active"
            :style="checkLateDate(props.item.end_date)">
            {{ shortFormatBookingDates(props.item.end_date) }}
          </td>
          <td class="text-center">{{ props.item.days }}</td>
          <td class="text-center">{{ props.item.location_start }}</td>
          <td class="text-center">{{ props.item.location_end }}</td>
          <td class="text-truncate" style="max-width: 200px" @click="
            (showDescriptionEditId = props.item.id),
            (descriptionEdit = props.item.description),
            (showDescriptionEditDialog = true)
            " v-tooltip="{
              content: `${props.item.description}`,
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }">
            {{ props.item.description }}
          </td>
          <!-- <td
            class="text-center description-trunc"
            @click="
              (showDescriptionEditId = props.item.id),
                (descriptionEdit = props.item.description),
                (showDescriptionEditDialog = true)
            "
          >
            <read-more
              v-if="props.item.description && props.item.description.length > 0"
              :more-str="$t('custom.read')"
              :text="props.item.description"
              link="#"
              :less-str="$t('custom.less')"
              :max-chars="30"
            ></read-more>
          </td> -->
          <td class="text-center" v-if="props.item.responsible" @click="
            getWorkersNames(),
            (updatingBookingId = props.item.id),
            (selected_worker = props.item.responsible),
            (showSetResponsible = true)
            ">
            {{ props.item.responsible }}
          </td>
          <td class="text-center" v-else>
            <v-icon class="mr-2" color="grey" @click="
              getWorkersNames(),
              (updatingBookingId = props.item.id),
              (showSetResponsible = true)
              " v-tooltip="{
                content: $t('bookings.responsible'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }">
              mdi-account-question
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="showSetResponsible" :retain-focus="false" @click:outside="
      (selected_worker = null),
      (updatingBookingId = null),
      (showSetResponsible = false)
      " persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("bookings.selecting_responsible")
            }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select v-model="selected_worker" :items="workers_names" :label="$t('bookings.responsible_employee')"
                  item-text="name" item-value="name"></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="
            (selected_worker = null),
            (updatingBookingId = null),
            (showSetResponsible = false)
            ">
            {{ $t("custom.close") }}
          </v-btn>
          <v-btn color="success" text @click="saveResponsibale()">
            {{ $t("custom.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDescriptionEditDialog" :retain-focus="false" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("bookings.booking_description") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-textarea v-model="descriptionEdit" auto-grow rows="2"
                  :label="$t('bookings.add_info_booking')"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="
            (descriptionEdit = null),
            (showDescriptionEditId = null),
            (showDescriptionEditDialog = false)
            ">
            {{ $t("custom.close") }}
          </v-btn>
          <v-btn color="success" text @click="saveDescriptionEdit()">
            {{ $t("custom.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
import { Badge } from "@capawesome/capacitor-badge";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { saveAs } from "file-saver";
import xlsx from "json-as-xlsx";
import PullToRefresh from "pulltorefreshjs";

export default {
  data() {
    return {
      bookings: [],
      active_bookings: [],
      inactive_bookings: [],
      rided_bookings: [],
      filtered_inactive_bookings: [],
      filtered_rided_bookings: [],
      part_return_deposit_bookings: [],
      workers_names: [],
      selected_worker: null,
      responsibles: [],
      show_active_booking: true,
      updateIntervalFunc: null,
      payment: {
        cash: 0,
        cashless: 0,
        cash_card: 0,
        entity: 0,
        booking_id: null,
      },
      gas_start: 0,
      gas_end: 0,
      showArrivalPayments: false,
      showCashCardPayment: false,
      showCashPayment: false,
      showAddPayment: false,
      giveFullDeposit: true,
      showAddArrivalPayment: false,
      giveNullDeposit: false,
      showCleanPaid: false,
      fuel_price: 50,
      clean_price: 0,
      limitCost: 0,
      limitExept: false,
      departure_payments: {
        rent: { sum: 0, type: null },
        deposit: { sum: 0, type: null },
        delivery: { sum: 0, type: null },
        clean: { sum: 0, type: null },
        hours_cost: { sum: 0, type: null },
        insurance: { sum: 0, type: null },
        add_drivers_cost: { sum: 0, type: null },
        equipment: { sum: 0, type: null },
        other: { sum: 0, type: null },
      },
      arrival_payments: {
        rent: { sum: 0, type: null },
        clean: { sum: 0, type: null },
        delivery: { sum: 0, type: null },
        additional_hours: { sum: 0, type: null },
        mileage: { sum: 0, type: null },
        gas: { sum: 0, type: null },
        insurance: { sum: 0, type: null },
        add_drivers_cost: { sum: 0, type: null },
        equipment: { sum: 0, type: null },
        fine: { sum: 0, type: null },
        damage: { sum: 0, type: null },
        other: { sum: 0, type: null },
        deposit: { sum: 0, type: null },
      },
      prolongData: {
        days: null,
        price: null,
        additional_hours: null,
        sum: null,
        type: null,
        add_time_sum: null,
        add_time_type: null,
      },
      add_services: [
        { text: this.$t("add_services.clean"), value: "Мойка" },
        {
          text: this.$t("add_services.add_hours"),
          value: "Дополнительное время",
        },
        {
          text: this.$t("add_services.extra_mileage"),
          value: "Превышение пробега",
        },
        { text: this.$t("add_services.fuel"), value: "Топливо" },
        { text: this.$t("add_services.delivery"), value: "Доставка" },
        { text: this.$t("add_services.checkout"), value: "Приём" },
        { text: this.$t("add_services.rent"), value: "Аренда" },
        { text: this.$t("add_services.insurance"), value: "Страховка" },
        { text: this.$t("add_services.add_drivers"), value: "Доп водители" },
        { text: this.$t("add_services.equipment"), value: "Оборудование" },
        { text: this.$t("add_services.fines"), value: "Штрафы" },
        { text: this.$t("add_services.damages"), value: "Повреждения" },
        { text: this.$t("add_services.others"), value: "Другое" },
      ],
      selected_add_services: [],
      fines: [],
      fines_state: [
        { text: this.$t("fines_state.created"), value: "Создан" },
        { text: this.$t("fines_state.notify"), value: "Оповещён" },
        { text: this.$t("fines_state.paid"), value: "Оплачен" },
        { text: this.$t("fines_state.refund"), value: "Возмещён" },
      ],
      fines_headers_small: [
        { text: this.$t("fines.number"), align: "center", value: "number" },
        { text: this.$t("custom.date"), value: "date", align: "center" },
        { text: this.$t("custom.client"), value: "client_id", align: "center" },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.state"), value: "state", align: "center" },
      ],
      part_payment: false,
      showProblems: false,
      manualEditing: false,
      showDescription: false,
      showAddDrivers: false,
      selected: [],
      search: "",
      search_rided_bookings: null,
      search_add_driver: "",
      bookings_states: [
        { text: this.$t("bookings_states.new"), value: "Новая" },
        { text: this.$t("bookings_states.in_work"), value: "В обработке" },
        { text: this.$t("bookings_states.abandoned"), value: "Недозвон" },
        { text: this.$t("bookings_states.decline"), value: "Отказ клиента" },
        { text: this.$t("bookings_states.no_cars"), value: "Нет машин" },
        {
          text: this.$t("bookings_states.wait_answer"),
          value: "Ожидает ответа клиента",
        },
        { text: this.$t("bookings_states.wait_pay"), value: "Ожидает оплаты" },
        { text: this.$t("bookings_states.active"), value: "Активная" },
        { text: this.$t("bookings_states.cancel"), value: "Отмена" },
        { text: this.$t("bookings_states.rided"), value: "Отъездила" },
        {
          text: this.$t("bookings_states.return_deposit"),
          value: "Вернуть залог",
        },
        { text: this.$t("bookings_states.accepted"), value: "Подтверждена" },
        {
          text: this.$t("bookings_states.not_accepted"),
          value: "Не подтверждена",
        },
        { text: this.$t("bookings_states.other"), value: "Другое" },
      ],
      selected_booking_state: null,
      showDescriptionEditId: null,
      showDescriptionEditDialog: false,
      descriptionEdit: null,
      showSetBookingState: false,
      searchClient: "",
      rowsPerPageItems: [10, 25, 50, 100, -1],
      headers: [
        { text: "", sortable: false, align: "center" },
        { text: this.$t("custom.number"), value: "id", align: "center" },
        {
          text: this.$t("custom.created"),
          value: "created_at",
          align: "center",
        },
        {
          text: this.$t("custom.state_short"),
          value: "in_rent",
          align: "center",
        },
        {
          text: this.$t("custom.state_short_2"),
          value: "state",
          align: "center",
        },
        {
          text: this.$t("bookings.payment"),
          align: "center",
          value: "counts",
          sort: (a, b) => {
            console.log("counts", a, b)
            return a.counts - b.counts;
          },
        },
        { text: this.$t("custom.car"), value: "car_code", align: "center" },
        { text: this.$t("custom.client"), value: "full_name", align: "center" },
        {
          text: this.$t("custom.start"),
          value: "start_date_formatted",
          align: "center",
          // sort: (a, b) => {
          //   return moment(a, "DD-MM-YYYY H:mm").diff(
          //     moment(b, "DD-MM-YYYY H:mm")
          //   );
          // },
        },
        {
          text: this.$t("custom.end"),
          value: "end_date_formatted",
          align: "center",
          // sort: (a, b) => {
          //   return moment(a, "DD-MM-YYYY H:mm").diff(
          //     moment(b, "DD-MM-YYYY H:mm")
          //   );
          // },
        },
        {
          text: this.$t("custom.days"),
          sortable: false,
          value: "days",
          align: "center",
        },
        {
          text: this.$t("custom.checkin"),
          sortable: false,
          value: "location_start",
          align: "center",
        },
        {
          text: this.$t("custom.checkout"),
          sortable: false,
          value: "location_end",
          align: "center",
        },
        {
          text: this.$t("custom.description_2"),
          sortable: false,
          align: "center",
        },
        { text: this.$t("custom.resp"), sortable: false, align: "center" },
      ],
      mobile_headers: [
        { text: "", sortable: false, align: "center" },
        { text: this.$t("custom.number"), value: "id", align: "center" },
        // { text: this.$t('custom.created'), value: "created_at", align: "center" },
        {
          text: this.$t("custom.state_short"),
          value: "in_rent",
          align: "center",
        },
        {
          text: this.$t("custom.state_short_2"),
          value: "state",
          align: "center",
        },
        { text: this.$t("bookings.payment"), align: "center" },
        { text: this.$t("custom.car"), value: "car_code", align: "center" },
        { text: this.$t("custom.client"), value: "full_name", align: "center" },
        {
          text: this.$t("custom.start"),
          value: "start_date",
          align: "center",
          sort: (a, b) => {
            return moment(a, "DD-MM-YYYY H:mm").diff(
              moment(b, "DD-MM-YYYY H:mm")
            );
          },
        },
        {
          text: this.$t("custom.end"),
          value: "end_date",
          align: "center",
          sort: (a, b) => {
            return moment(a, "DD-MM-YYYY H:mm").diff(
              moment(b, "DD-MM-YYYY H:mm")
            );
          },
        },
        {
          text: this.$t("custom.days"),
          sortable: false,
          value: "days",
          align: "center",
        },
        {
          text: this.$t("custom.checkin"),
          sortable: false,
          value: "location_start",
          align: "center",
        },
        {
          text: this.$t("custom.checkout"),
          sortable: false,
          value: "location_end",
          align: "center",
        },
        {
          text: this.$t("custom.description_2"),
          sortable: false,
          align: "center",
        },
        { text: this.$t("custom.resp"), sortable: false, align: "center" },
      ],
      headers_return_deposit: [
        { text: this.$t("custom.number"), value: "id", align: "center" },
        {
          text: this.$t("custom.created"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("bookings.payment"), align: "center" },
        { text: this.$t("bookings.payment_end"), align: "center" },
        { text: this.$t("custom.car"), value: "car_code", align: "center" },
        { text: this.$t("custom.client"), value: "full_name", align: "center" },
        {
          text: this.$t("custom.start"),
          value: "start_date",
          align: "center",
          sort: (a, b) => {
            return moment(a, "DD-MM-YYYY H:mm").diff(
              moment(b, "DD-MM-YYYY H:mm")
            );
          },
        },
        {
          text: this.$t("custom.end"),
          value: "end_date",
          align: "center",
          sort: (a, b) => {
            return moment(a, "DD-MM-YYYY H:mm").diff(
              moment(b, "DD-MM-YYYY H:mm")
            );
          },
        },
        {
          text: this.$t("custom.days"),
          sortable: false,
          value: "days",
          align: "center",
        },
        {
          text: this.$t("custom.checkout"),
          sortable: false,
          value: "location_start",
          align: "center",
        },
        {
          text: this.$t("custom.checkin"),
          sortable: false,
          value: "location_end",
          align: "center",
        },
        {
          text: this.$t("custom.description_2"),
          sortable: false,
          align: "center",
        },
        { text: this.$t("custom.resp"), sortable: false, align: "center" },
      ],
      sortingBookings: { sortBy: "created_at", descending: true },
      options: {
        sortBy: "car_code",
      },
      dialog: false,
      payment_dialog: false,
      showCashlessPayment: false,
      showEntityPayment: false,
      showPartRejection: false,
      prolong: false,
      departure: false,
      departure_yes: false,
      arrival: false,
      arrival_yes: false,
      editedBooking: { payments: [], counts: [] },
      selected_car: null,
      cars: [],
      updatingBookingId: null,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      showClients: true,
      isLoadingClients: false,
      isLoadingClientsAddDriver: false,
      showEditClient: false,
      editPlace: false,
      editSale: false,
      connected: false,
      isLoading: false,
      configSort: {
        altFormat: "d-m-Y",
        altInput: true,
        dateFormat: "d-m-Y",
        disableMobile: "true",
      },
      sort_date: null,
      sort_date_for_input: null,
      menu_sort_date: false,
      attrs_calendar: [
        {
          key: "today",
          highlight: {
            color: "blue",
            fillMode: "outline",
            contentClass: "italic",
          },
          dates: new Date(),
        },
      ],
      start_date: null,
      start_date_with_time: null,
      hours: 0,
      days: 0,
      editDate: false,
      start_time: null,
      end_date: null,
      end_date_with_time: null,
      end_time: null,
      datesWithTime: null,
      dates: null,
      set_car: null,
      extra: false,
      sale: false,
      editExtra: false,
      editCar: false,
      clients: [],
      client: {},
      customer_category: [
        { text: this.$t("customer_category.new"), value: "Новый" },
        { text: this.$t("customer_category.loyal"), value: "Лояльный" },
        { text: this.$t("customer_category.statement"), value: "Постоянный" },
        { text: this.$t("customer_category.problem"), value: "Серый" },
        { text: this.$t("customer_category.bad"), value: "Чёрный" },
      ],
      clients_arr: [],
      search_return_deposit: null,
      dont_send_review_reminder: false,
      warningAddOneDay: false,
      warningLessThenDay: false,
      doc_template_url: "",
      entity_doc_template_url: "",
      showSetResponsible: false,
      page: 1,
      per_page: 50,
      sort_by: "state",
      direction: "asc",
      tableOptions: {},
      total: 0,
      timeout: null,
      isMobile: false,
      isLoading: false,
      error: "",
    };
  },
  channels: {
    BookingsChannel: {
      connected() {
        this.connected = "success";
      },
      disconnected() {
        this.connected = "error";
      },
      received(data) {
        let self = this;
        if (data.action == "update") {
          // Вставляю платеж в массив платежей брони
          data.booking.counts = data.counts;
          this.bookings.forEach((booking) => {
            if (booking.id == data.booking.id) {
              this.bookings.splice(this.bookings.indexOf(booking), 1);
              this.bookings.unshift(data.booking);
            }
          });
          self.active_bookings = [];
          self.inactive_bookings = [];
          self.filtered_inactive_bookings = [];
          self.rided_bookings = [];
          self.filtered_rided_bookings = [];
          self.part_return_deposit_bookings = [];

          this.bookings.forEach(function (booking) {
            if (booking.active == true) {
              booking["new"] = false;
              self.active_bookings.push(booking);
            }
          });
          this.bookings.forEach(function (booking) {
            if (booking.active == false) {
              booking["new"] = false;
              if (booking.part_return_deposit) {
                self.part_return_deposit_bookings.push(booking);
              } else if (!booking.part_return_deposit && !booking.ride) {
                self.inactive_bookings.push(booking);
                self.filtered_inactive_bookings.push(booking);
              } else {
                self.rided_bookings.push(booking);
                self.filtered_rided_bookings.push(booking);
              }
            }
          });
          this.showBooking();
        } else if (data.action == "new") {
          let new_check = this.bookings.findIndex(
            (booking) => booking.id == data.booking.id
          );
          if (new_check == -1) {
            // Вставляю платеж в массив платежей брони
            data.booking.counts = data.counts;
            data.booking.new = true;
            this.bookings.push(data.booking);
            this.active_bookings = [];
            this.inactive_bookings = [];
            this.filtered_inactive_bookings = [];
            this.rided_bookings = [];
            this.filtered_rided_bookings = [];
            this.part_return_deposit_bookings = [];

            this.bookings.forEach(function (booking) {
              if (booking.active == true) {
                self.active_bookings.push(booking);
              }
            });
            this.bookings.forEach(function (booking) {
              if (booking.active == false) {
                booking["new"] = false;
                if (booking.part_return_deposit) {
                  self.part_return_deposit_bookings.push(booking);
                } else if (!booking.part_return_deposit && !booking.ride) {
                  self.inactive_bookings.push(booking);
                  self.filtered_inactive_bookings.push(booking);
                } else {
                  self.rided_bookings.push(booking);
                  self.filtered_rided_bookings.push(booking);
                }
              }
            });
            this.showBooking();
          }
        } else if (data.action == "destroy") {
          this.bookings.forEach((booking) => {
            if (booking.id == data.booking) {
              this.bookings.splice(this.bookings.indexOf(booking), 1);
            }
          });
          this.active_bookings.forEach((booking) => {
            if (booking.id == data.booking) {
              this.active_bookings.splice(
                this.active_bookings.indexOf(booking),
                1
              );
            }
          });
          this.inactive_bookings.forEach((booking) => {
            if (booking.id == data.booking) {
              this.inactive_bookings.splice(
                this.inactive_bookings.indexOf(booking),
                1
              );
            }
          });
          this.filtered_inactive_bookings.forEach((booking) => {
            if (booking.id == data.booking) {
              this.filtered_inactive_bookings.splice(
                this.filtered_inactive_bookings.indexOf(booking),
                1
              );
            }
          });
          this.rided_bookings.forEach((booking) => {
            if (booking.id == data.booking) {
              this.rided_bookings.splice(
                this.rided_bookings.indexOf(booking),
                1
              );
            }
          });
          this.filtered_rided_bookings.forEach((booking) => {
            if (booking.id == data.booking) {
              this.filtered_rided_bookings.splice(
                this.filtered_rided_bookings.indexOf(booking),
                1
              );
            }
          });
          this.part_return_deposit_bookings.forEach((booking) => {
            if (booking.id == data.booking) {
              this.part_return_deposit_bookings.splice(
                this.part_return_deposit_bookings.indexOf(booking),
                1
              );
            }
          });
        }
        // Сортирую на дату если включен фильтр
        if (
          this.$store.getters.getSortDateInBookings != null ||
          this.sort_date != null
        ) {
          this.sortForDate();
        }
      },
    },
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
    this.clearBadge();
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      if (
        this.$tours["registrationTour"] &&
        this.$store.getters.getTourNextStep
      ) {
        this.$tours["registrationTour"].nextStep();
      }
      this.$store.commit("setNewBooking", false);
      this.getDataFromApi();
      this.$cable.subscribe({
        channel: "BookingsChannel",
        room: this.$store.getters.getCompany.company.id,
      });
      if (self.$store.getters.getSortDateInBookings) {
        setTimeout(
          () =>
            (self.sort_date = self.$store.getters.getSortDateInBookings),
          700
        );
        self.sort_date = self.$store.getters.getSortDateInBookings;
      }

      if (self.$store.getters.getSortStateInBookings) {
        self.selected_booking_state =
          self.$store.getters.getSortStateInBookings;
      }
      // Удаляю из селекта перепробег
      if (
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.mileage_limit_cost == 0
      ) {
        this.add_services.splice(
          this.add_services.findIndex(
            (service) => service == "Превышение пробега"
          ),
          1
        );
      }
      // check every 30 seconds if action cable wss server is online
      function check_server() {
        self.check_server = setInterval(function () {
          if (self.$root.$cable._cable.connection.disconnected) {
            self.connected = false;
            console.log("please reconnect");
            // self.$root.$cable.connection.connect();
          } else {
            self.connected = true;
            // console.log("connected");
          }
        }.bind(self), 30000);
      }
      check_server();
    } else {
      this.$store.commit("unsetCurrentUser");
      this.$router.push({ path: `/signin` }).catch((err) => { });
    }
  },
  beforeDestroy() {
    this.$cable.unsubscribe({
      channel: "BookingsChannel",
      room: this.$store.getters.getCompany.company.id,
    });
  },
  // mounted: function() {
  //   if (this.$tours["registrationTour"]) {
  //     this.$tours["registrationTour"].nextStep();
  //   }
  // },
  destroyed() {
    clearInterval(this.check_server);
  },
  watch: {
    client() {
      if (this.client && this.client != "") {
        this.editedBooking.client_id = this.client.id;
        this.editedBooking.first_name = this.client.name;
        this.editedBooking.middle_name = this.client.middlename;
        this.editedBooking.last_name = this.client.lastname;
        this.editedBooking.fio = this.client.fio;
        // Скидка не грузилась в редактировании брони
        this.editedBooking.sale = this.editedBooking.sale
          ? this.editedBooking.sale
          : this.client.sale;
        this.editedBooking.sale_cash = this.editedBooking.sale_cash
          ? this.editedBooking.sale_cash
          : this.client.sale_cash;

        if (!this.arrival) {
          // Set_car включается и при приеме и идет ошибка рассчета цены
          this.calculateCost();
        }
      }
    },
    sort_date() {
      let self = this;
      if (this.sort_date) {
        this.sort_date_for_input = moment(this.sort_date).format("DD-MM-YYYY");
        this.$store.commit("setsortDateInBookings", this.sort_date);
      }
      if (
        this.$store.getters.getSortDateInBookings != null ||
        this.sort_date != null
      ) {
        this.$store.commit("setsortDateInBookings", this.sort_date);
        // this.sortForDate();
        this.getDataFromApi();
      } else {
        this.getDataFromApi();
        // this.active_bookings = [];
        // this.inactive_bookings = [];
        // this.bookings.map(function (booking) {
        //   if (booking.active == true) {
        //     self.active_bookings.push(booking);
        //   }
        // });
        // this.bookings.map(function (booking) {
        //   if (booking.active == false) {
        //     self.inactive_bookings.push(booking);
        //   }
        // });

        this.$store.commit("setsortDateInBookings", null);
      }
    },
    limitExept() {
      if (
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.mileage_limit_cost
      ) {
        if (this.limitExept) {
          this.limitCost = Math.round(
            this.returnMileageExt() *
            this.$store.getters.getCompany.company.mileage_limit_cost
          );
        } else {
          this.limitCost = 0;
        }
      }
    },
    tableOptions(pagination) {
      this.page = pagination.page;
      this.per_page = pagination.itemsPerPage;
      this.sort_by = pagination.sortBy[0] ? pagination.sortBy[0] : "state";
      this.direction = pagination.sortDesc[0] ? "desc" : "asc";
      this.getDataFromApi();
    },
    search() {
      this.$store.commit("setSearchInBookings", this.search);
      if (this.search == "") {
        this.search = null;
      } else {
        let self = this;
        this.page = 1;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.getDataFromApi();
          }, 300); // delay
        }
        debounce();
      }
    },
    show_active_booking() {
      this.$store.commit("show_active_bookings");
      this.getDataFromApi();
    },
    selected_booking_state() {
      if (
        this.selected_booking_state &&
        this.selected_booking_state.length > 0
      ) {
        this.$store.commit(
          "setSortStateInBookings",
          this.selected_booking_state
        );
        this.getDataFromApi();
        this.inactive_bookings = this.inactive_bookings.filter((booking) => {
          return this.selected_booking_state.includes(booking.state);
        });
        this.rided_bookings = this.rided_bookings.filter((booking) => {
          return this.selected_booking_state.includes(booking.state);
        });
      } else {
        let self = this;
        this.getDataFromApi();
        this.inactive_bookings = [];
        this.rided_bookings = [];
        this.bookings.map(function (booking) {
          if (
            booking.active == false &&
            booking.ride == false &&
            booking.part_return_deposit == false
          ) {
            self.inactive_bookings.push(booking);
          } else if (
            booking.active == false &&
            booking.ride == true &&
            booking.part_return_deposit == false
          ) {
            self.rided_bookings.push(booking);
          }
        });
        this.$store.commit("setSortStateInBookings", null);
      }
    },
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    selectedBookings() {
      let bookings = [];
      let inactive_bookings = this.inactive_bookings.filter((booking) => {
        return booking.selected;
      });
      inactive_bookings.forEach((booking) => {
        bookings.push(booking);
      });
      let rided_bookings = this.rided_bookings.filter((booking) => {
        return booking.selected;
      });
      rided_bookings.forEach((booking) => {
        bookings.push(booking);
      });
      return bookings;
    },
    selectedHeaders() {
      // Убираю выделение если активные брони
      let headers = [];
      if (isMobile) {
        this.headers.map((header) => {
          headers.push(header);
        });
        if (this.showActive) {
          headers.splice(0, 1);
        } else {
          headers.splice(3, 1);
        }
        // this.mobile_headers.map((header) => {
        //   headers.push(header);
        // });
        // if (!this.showActive) {
        //   headers.splice(0, 1);
        // } else {
        //   headers.splice(2, 1);
        // }
      } else {
        this.headers.map((header) => {
          headers.push(header);
        });
        if (this.showActive) {
          headers.splice(0, 1);
        } else {
          headers.splice(3, 1);
        }
      }
      return headers;
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    checkWriteFile() {
      return Capacitor.isNativePlatform() && Filesystem.checkPermissions();
    },
    requestPermissionsWriteFile() {
      return Filesystem.requestPermissions();
    },
    showActive() {
      return this.$store.getters.getShowActiveBookings;
    },
  },
  methods: {
    getDataFromApi() {
      this.isLoading = true;
      let self = this;
      this.axios
        .post(
          `/api/v1/index_with_search`,
          {
            search: this.search,
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            direction: this.direction,
            active: this.showActive,
            state: this.selected_booking_state,
            sort_for_date: this.$store.getters.getSortDateInBookings,
            meilisearch: true,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.bookings = [];
          this.active_bookings = [];
          this.inactive_bookings = [];
          this.rided_bookings = [];
          this.filtered_rided_bookings = [];
          this.part_return_deposit_bookings = [];
          this.filtered_inactive_bookings = [];

          // берем из сериалазера
          response.data.bookings.data.forEach((booking) => {
            let attributes = booking.attributes;
            attributes.counts = [];
            booking.relationships.counts.data.forEach((item) => {
              let included_counts = response.data.bookings.included.filter(
                (count) => count.id == item.id
              );
              included_counts.forEach((count) => {
                attributes.counts.push(count.attributes);
              });
            });
            this.bookings.push(attributes);
          });

          this.bookings.forEach(function (booking) {
            if (booking.active == true) {
              booking["new"] = false;
              self.active_bookings.push(booking);
            }
          });
          this.bookings.forEach(function (booking) {
            // ставим имя клиента либо физ либо юр для поиска иначе ищет только по физ
            if (!booking.entity) {
              booking.full_name = `${booking.last_name} ${booking.first_name}`;
            } else {
              booking.full_name = `${booking.entity_name}`;
            }
            if (booking.active == false) {
              booking["new"] = false;
              if (booking.part_return_deposit) {
                self.part_return_deposit_bookings.push(booking);
              } else if (!booking.part_return_deposit && !booking.ride) {
                self.inactive_bookings.push(booking);
                self.filtered_inactive_bookings.push(booking);
              } else {
                self.rided_bookings.push(booking);
                self.filtered_rided_bookings.push(booking);
              }
            }
          });
          this.total = response.data.total;
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.bookings_load"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    calculatePayments(booking) {
      if (booking != undefined) {
        let self = this;
        let paid = 0;
        let booking_sum =
          booking.rental_cost +
          booking.delivery +
          booking.delivery_end +
          booking.equipment +
          booking.insurance +
          booking.clean_payment +
          booking.add_drivers_cost +
          booking.hours_cost +
          booking.mileage_cost +
          booking.damage +
          booking.gas +
          booking.fine +
          booking.other +
          booking.other_end +
          booking.hours_cost_end;
        booking.counts.forEach((count) => {
          // если оплата по терминалу и в платеже есть tinkoff_payment_id то учитываем только если count.tinkoff_payment.paid = true
          if (count.completed) {
            if (count.operation) {
              paid += count.sum;
            } else {
              paid -= count.sum;
            }
          }
        });
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += booking.deposit;
        }
        if (paid == booking_sum) {
          return self.$t("bookings.paid");
        } else {
          return paid - booking_sum >= 0
            ? `+ ${Math.round(paid - booking_sum)}`
            : ` ${Math.round(paid - booking_sum)}`;
        }
      }
    },
    selectColorPaid(booking) {
      if (booking != undefined) {
        let paid = 0;
        let booking_sum =
          booking.rental_cost +
          booking.delivery +
          booking.delivery_end +
          booking.equipment +
          booking.insurance +
          booking.clean_payment +
          booking.add_drivers_cost +
          booking.hours_cost +
          booking.mileage_cost +
          booking.damage +
          booking.gas +
          booking.fine +
          booking.other +
          booking.other_end +
          booking.hours_cost_end;
        booking.counts.forEach((count) => {
          // если оплата по терминалу и в платеже есть tinkoff_payment_id то учитываем только если count.tinkoff_payment.paid = true
          if (count.completed) {
            if (count.operation) {
              paid += count.sum;
            } else {
              paid -= count.sum;
            }
          }
        });
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += booking.deposit;
        }
        if (paid == booking_sum) {
          return "success";
        } else if (paid - booking_sum > 0) {
          return "warning";
        } else if (paid - booking_sum < 0) {
          return "error";
        }
      }
    },
    checkMileageLimit(mileage) {
      if (
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.mileage_limit
      ) {
        let limit = this.$store.getters.getCompany.company.mileage_limit;
        if (mileage > limit) {
          this.limitExept = true;
          return true;
        } else {
          this.limitExept = false;
          return false;
        }
      }
    },
    returnMileageExt() {
      if (this.$store.getters.getCompany.company && this.editedBooking) {
        return Math.round(
          ((this.editedBooking.end_mileage - this.editedBooking.start_mileage) /
            this.editedBooking.days -
            this.$store.getters.getCompany.company.mileage_limit) *
          this.editedBooking.days
        );
      }
    },
    onStartChange(selectedDates, dateStr, instance) {
      this.$set(this.configEnd, "minDate", dateStr);
    },
    checkPartReturnDepositDay(booking) {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.part_return_deposit_days &&
        this.$store.getters.getCompany.company.part_return_deposit_days > 0 &&
        moment().isSameOrAfter(
          moment(booking.end_date, "DD-MM-YYYY H:mm").add(
            this.$store.getters.getCompany.company.part_return_deposit_days,
            "days"
          ),
          "day"
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    saveDescriptionEdit() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        if (this.descriptionEdit && this.showDescriptionEditId) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/bookings/${self.showDescriptionEditId}`,
              {
                bookings: {
                  description: self.descriptionEdit,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let foundIndex = this.bookings.findIndex(
                (element) => element.id === self.showDescriptionEditId
              );
              this.bookings[foundIndex].description = this.descriptionEdit;
              self.descriptionEdit = null;
              self.showDescriptionEditId = null;
              self.showDescriptionEditDialog = false;
            })
            .catch((error) => {
              this.setError(error, this.$t("bookings.booking_desc"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("bookings.booking_desc"),
                text: error,
              });
            })
            .finally(() => (this.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    showPayments(booking) {
      if (booking.counts) {
        if (booking.counts.length > 0) {
          return true;
        }
      }
    },
    returnDepositSum(booking) {
      if (booking != undefined) {
        let paid = 0;
        booking.counts.forEach((count) => {
          // если оплата по терминалу и в платеже есть tinkoff_payment_id то учитываем только если count.tinkoff_payment.paid = true
          if (count.completed) {
            if (count.operation) {
              paid += count.sum;
            } else {
              paid -= count.sum;
            }
          }
        });
        let booking_sum =
          booking.rental_cost +
          booking.delivery +
          booking.delivery_end +
          booking.equipment +
          booking.insurance +
          booking.clean_payment +
          booking.add_drivers_cost +
          booking.hours_cost +
          booking.mileage_cost +
          booking.damage +
          booking.gas +
          booking.fine +
          booking.other +
          booking.other_end +
          booking.hours_cost_end;
        return paid - booking_sum;
      }
    },
    checkLateDate(date) {
      if (!moment().isSameOrBefore(moment(date, "DD-MM-YYYY H:mm"), "day")) {
        return "color: tomato;";
      }
    },
    checkAddPayment(type) {
      if (this.selected_add_services.find((item) => item == type)) {
        return true;
      } else {
        return false;
      }
    },
    findWorker(id) {
      let name;
      if (this.workers_names.find((worker) => worker.id == id)) {
        name = this.workers_names.find((worker) => worker.id == id).name
          ? this.workers_names.find((worker) => worker.id == id).name
          : this.workers_names.find((worker) => worker.id == id).email;
      }
      if (name) {
        return name;
      } else {
        return id;
      }
    },
    toXlsx() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        this.axios
          .get("/api/v1/get_workers_names_with_ids", {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.workers_names = response.data;
            this.axios
              .get(`/api/v1/bookings_export_excel`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                let bookings = [];
                let counts = [];
                let clients = [];
                let workers = [];
                response.data.data.forEach((booking) => {
                  let attributes = booking.attributes;
                  bookings.push(attributes);
                  booking.relationships.counts.data.forEach((item) => {
                    let included_counts = response.data.included.filter(
                      (count) => count.id == item.id
                    );
                    included_counts.forEach((count) => {
                      counts.push(count.attributes);
                    });
                  });
                });
                let data = [
                  {
                    sheets: "Bookings",
                    columns: [
                      { label: "ID", value: "id" },
                      { label: this.$t("custom.created_at"), value: "created_at" },
                      { label: this.$t("bookings.name"), value: "first_name" },
                      {
                        label: this.$t("bookings.middlename"),
                        value: "middle_name",
                      },
                      { label: this.$t("bookings.lastname"), value: "last_name" },
                      { label: this.$t("bookings.car"), value: "car_name" },
                      { label: this.$t("doc_variables.car_code"), value: "car_code" },

                      { label: this.$t("bookings.start_date"), value: "start_date" },
                      { label: this.$t("bookings.end_date"), value: "end_date" },
                      { label: this.$t("bookings.days"), value: "days" },
                      { label: this.$t("bookings.add_hours_short"), value: "additional_hours" },
                      { label: this.$t("bookings.price"), value: "price" },
                      {
                        label: this.$t("bookings.rental_cost"),
                        value: "rental_cost",
                      },
                      { label: this.$t("bookings.delivery"), value: "delivery" },
                      {
                        label: this.$t("bookings.checkout"),
                        value: "delivery_end",
                      },
                      { label: this.$t("bookings.equipment"), value: "equipment" },
                      { label: this.$t("bookings.insurance"), value: "insurance" },
                      {
                        label: this.$t("bookings.clean"),
                        value: "clean_payment",
                      },
                      {
                        label: this.$t("bookings.add_drivers_2"),
                        value: "add_drivers_cost",
                      },
                      { label: this.$t("bookings.hours_cost"), value: "hours_cost" },
                      {
                        label: this.$t("bookings.mileage_cost"),
                        value: "mileage_cost",
                      },
                      { label: this.$t("bookings.damages"), value: "damage" },
                      { label: this.$t("bookings.gas"), value: "gas" },
                      { label: this.$t("bookings.for_fines"), value: "fine" },
                      { label: this.$t("bookings.other"), value: "other" },
                      {
                        label: this.$t("bookings.for_other_end"),
                        value: "other_end",
                      },
                      {
                        label: this.$t("bookings.hours_cost_end"),
                        value: "hours_cost_end",
                      },
                      {
                        label: this.$t("clients.client_source"),
                        value: "client_source",
                      },
                      {
                        label: this.$t("clients.source"),
                        value: "source",
                      },
                      {
                        label: this.$t("bookings.checkiner"),
                        value: (row) => {
                          return this.findWorker(row.start_worker_id);
                        },
                      },
                      {
                        label: this.$t("bookings.checkouter"),
                        value: (row) => {
                          return this.findWorker(row.end_worker_id);
                        },
                      },
                    ],
                    content: bookings,
                  },
                  {
                    sheets: "Payments",
                    columns: [
                      { label: "ID", value: "id" },
                      { label: this.$t("custom.created_at"), value: "created_at" },
                      { label: this.$t("custom.amount"), value: "sum" },
                      { label: this.$t("custom.operation"), value: "operation" },
                      { label: this.$t("counts.about"), value: "description" },
                      { label: this.$t("money.terminal"), value: "cashless" },
                      { label: this.$t("money.card_to_card"), value: "cash_card" },
                      { label: this.$t("money.cash"), value: "cash" },
                      { label: this.$t("money.from_entity"), value: "entity" },
                      {
                        label: this.$t("custom.group"), value: (row) => {
                          return this.translateCategory(row.group);
                        }
                      },
                      { label: this.$t("custom.car"), value: "car_code" },
                      { label: this.$t("bookings.booking"), value: "booking_id" },
                      { label: this.$t("debts.debt"), value: "debt_id" },
                      { label: this.$t("employers.partner"), value: "investor_id" },
                      { label: this.$t("counts.completed"), value: "completed" },
                    ],
                    content: counts,
                  },
                ];
                let settings = {
                  fileName: "bookings", // Name of the resulting spreadsheet
                  extraLength: 3, // A bigger number means that columns will be wider
                  writeMode: "write", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
                  writeOptions: {
                    bookType: "xlsx",
                    type: "array",
                  }, // Style options from https://docs.sheetjs.com/docs/api/write-options
                  RTL: false, // Display the columns from right-to-left (the default value is false)
                };
                let wbout = xlsx(data, settings);
                var blob_data = new Blob([new Uint8Array(wbout)], {type:"application/octet-stream"});
                if (!this.isNative) {
                  saveAs(blob_data, 'bookings.xlsx');
                } else {
                  if (Filesystem.checkPermissions()) {
                    const getBase64FromBlob = async (blob) => {
                      return new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                          const base64data = reader.result;
                          resolve(base64data);
                        }
                      });
                    }
                    getBase64FromBlob(blob_data).then(
                      (base64) => {
                        // save file
                        async function writeFile() {
                          return await Filesystem.writeFile({
                            path: 'bookings.xlsx',
                            data: base64,
                            directory: Directory.Documents,
                          });
                        };
                        let file_uri = null
                        let writedFile = writeFile().then((getUriResult) => {
                          // share file opened file
                          let shareFile = async () => {
                            await Share.share({
                              title: 'Download document',
                              files: [getUriResult.uri],
                            });
                          };
                          let sharedFile = shareFile();
                        });
                      }
                    );
                  } else {
                    Filesystem.requestPermissions();
                  }
                }
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.clients_load"));
                console.log(error);
              })
          })
          .catch((error) => {
            this.setError(error, "Something went wrong in get_workers_names");
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    translateCategory(category) {
      let group_text = "";
      switch (category) {
        case "Оплата аренды":
          group_text = this.$t("groups_counts.rent");
          break;
        case "Доплата за доставку":
          group_text = this.$t("groups_counts.delivery");
          break;
        case "Доплата за приём":
          group_text = this.$t("groups_counts.checkout");
          break;
        case "Доплата за топливо":
          group_text = this.$t("groups_counts.fuel");
          break;
        case "Доплата за мойку":
          group_text = this.$t("groups_counts.clean");
          break;
        case "Доплата за оборудование":
          group_text = this.$t("groups_counts.equipment");
          break;
        case "Доплата за дополнительное время":
          group_text = this.$t("groups_counts.add_time");
          break;
        case "Доплата за повреждения":
          group_text = this.$t("groups_counts.damages");
          break;
        case "Доплата за штрафы":
          group_text = this.$t("groups_counts.fines");
          break;
        case "Доплата за другое":
          group_text = this.$t("groups_counts.other");
          break;
        case "Доплата за превышение пробега":
          group_text = this.$t("groups_counts.mileage");
          break;
        case "Доплата за страховку":
          group_text = this.$t("groups_counts.insurance");
          break;
        case "Доплата за доп водителей":
          group_text = this.$t("groups_counts.add_drivers");
          break;
        case "Ремонт":
          group_text = this.$t("groups_counts.repair");
          break;
        case "Обслуживание":
          group_text = this.$t("groups_counts.service");
          break;
        case "Запчасти":
          group_text = this.$t("groups_counts.parts");
          break;
        case "Страховка":
          group_text = this.$t("groups_counts.insurance_main");
          break;
        case "Накладные расходы":
          group_text = this.$t("groups_counts.common_expences");
          break;
        case "Транспортные расходы":
          group_text = this.$t("groups_counts.transport_expences");
          break;
        case "Налоги":
          group_text = this.$t("groups_counts.tax");
          break;
        case "Штрафы":
          group_text = this.$t("groups_counts.fines_main");
          break;
        case "Аренда имущества":
          group_text = this.$t("groups_counts.rent_property");
          break;
        case "Мойка автомобилей":
          group_text = this.$t("groups_counts.clean_main");
          break;
        case "Топливо":
          group_text = this.$t("groups_counts.fuel_main");
          break;
        case "Реклама":
          group_text = this.$t("groups_counts.ads");
          break;
        case "Зарплата":
          group_text = this.$t("groups_counts.salary");
          break;
        case "Залог":
          group_text = this.$t("groups_counts.deposit");
          break;
        case "Внутренние переводы":
          group_text = this.$t("groups_counts.internal");
          break;
        case "Продажа имущества":
          group_text = this.$t("groups_counts.sell_property");
          break;
        case "Покупка имущества":
          group_text = this.$t("groups_counts.buy_property");
          break;
        case "Прочие расходы":
          group_text = this.$t("groups_counts.other_expences");
          break;
        case "Прочие доходы":
          group_text = this.$t("groups_counts.other_incomes");
          break;
        case "Выплаты партнёрам":
          group_text = this.$t("groups_counts.partners_payments");
          break;
        case "Сервисные сборы":
          group_text = this.$t("groups_counts.service_fees");
          break;
        case "Пополнение баланса клиента":
          group_text = this.$t("groups_counts.top_up_client_balance");
          break;
        case "Выплаты агентам":
          group_text = this.$t("groups_counts.agent_payments");
          break;
      }
      return group_text;
    },
    sortForDate() {
      let self = this;
      this.active_bookings = [];
      this.inactive_bookings = [];
      this.rided_bookings = [];
      this.bookings.map(function (booking) {
        if (booking.active == true) {
          self.active_bookings.push(booking);
        }
      });
      this.bookings.map(function (booking) {
        if (booking.active == false) {
          self.inactive_bookings.push(booking);
        }
      });
      this.active_bookings = this.active_bookings.filter(
        (x) =>
          moment(self.sort_date, "YYYY-MM-DD").isSame(
            moment(x.start_date, "DD-MM-YYYY H:mm"),
            "day"
          ) ||
          moment(self.sort_date, "YYYY-MM-DD").isSame(
            moment(x.end_date, "DD-MM-YYYY H:mm"),
            "day"
          )
      );
      this.inactive_bookings = this.inactive_bookings.filter(
        (x) =>
          moment(self.sort_date, "YYYY-MM-DD").isSame(
            moment(x.start_date, "DD-MM-YYYY H:mm"),
            "day"
          ) ||
          moment(self.sort_date, "YYYY-MM-DD").isSame(
            moment(x.end_date, "DD-MM-YYYY H:mm"),
            "day"
          )
      );
      this.rided_bookings = this.rided_bookings.filter(
        (x) =>
          moment(self.sort_date, "YYYY-MM-DD").isSame(
            moment(x.start_date, "DD-MM-YYYY H:mm"),
            "day"
          ) ||
          moment(self.sort_date, "YYYY-MM-DD").isSame(
            moment(x.end_date, "DD-MM-YYYY H:mm"),
            "day"
          )
      );

      this.active_bookings.forEach((booking) => {
        if (booking.in_rent) {
          booking.time = moment(booking.end_date, "DD-MM-YYYY H:mm").format(
            "DD-MM-YYYY H:mm"
          );
        } else {
          booking.time = moment(booking.start_date, "DD-MM-YYYY H:mm").format(
            "DD-MM-YYYY H:mm"
          );
        }
      });
      this.active_bookings = this.active_bookings.sort(function (x1, x2) {
        if (
          moment(x1.time, "DD-MM-YYYY H:mm").isBefore(
            moment(x2.time, "DD-MM-YYYY H:mm")
          )
        )
          return -1;
        if (
          moment(x1.time, "DD-MM-YYYY H:mm").isAfter(
            moment(x2.time, "DD-MM-YYYY H:mm")
          )
        )
          return 1;
        return 0;
      });

      this.inactive_bookings.forEach((booking) => {
        if (booking.in_rent) {
          booking.time = moment(booking.end_date, "DD-MM-YYYY H:mm").format(
            "DD-MM-YYYY H:mm"
          );
        } else {
          booking.time = moment(booking.start_date, "DD-MM-YYYY H:mm").format(
            "DD-MM-YYYY H:mm"
          );
        }
      });
      this.inactive_bookings = this.inactive_bookings.sort(function (x1, x2) {
        if (
          moment(x1.time, "DD-MM-YYYY H:mm").isBefore(
            moment(x2.time, "DD-MM-YYYY H:mm")
          )
        )
          return -1;
        if (
          moment(x1.time, "DD-MM-YYYY H:mm").isAfter(
            moment(x2.time, "DD-MM-YYYY H:mm")
          )
        )
          return 1;
        return 0;
      });
      this.rided_bookings.forEach((booking) => {
        if (booking.in_rent) {
          booking.time = moment(booking.end_date, "DD-MM-YYYY H:mm").format(
            "DD-MM-YYYY H:mm"
          );
        } else {
          booking.time = moment(booking.start_date, "DD-MM-YYYY H:mm").format(
            "DD-MM-YYYY H:mm"
          );
        }
      });
      this.rided_bookings = this.rided_bookings.sort(function (x1, x2) {
        if (
          moment(x1.time, "DD-MM-YYYY H:mm").isBefore(
            moment(x2.time, "DD-MM-YYYY H:mm")
          )
        )
          return -1;
        if (
          moment(x1.time, "DD-MM-YYYY H:mm").isAfter(
            moment(x2.time, "DD-MM-YYYY H:mm")
          )
        )
          return 1;
        return 0;
      });
    },
    showBooking() {
      if (!this.showActive) {
        return this.inactive_bookings;
      } else {
        return this.active_bookings;
      }
    },
    archiveBookings() {
      this.$swal({
        title: this.$t("bookings.archivate_bookings"),
        text: this.$t("bookings.archivate_bookings_desc"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.yes"),
        cancelButtonText: this.$t("custom.no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.axios
            .post(
              `/api/v1/archivate_bookings`,
              {
                bookings: this.selectedBookings.map((item) => item.id),
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.selectedBookings.forEach((booking) => {
                console.log(
                  this.bookings.indexOf(booking),
                  this.inactive_bookings.indexOf(booking),
                  this.rided_bookings.indexOf(booking)
                );
                let booking_index = this.bookings.indexOf(booking);
                let inactive_booking_index =
                  this.inactive_bookings.indexOf(booking);
                let rided_booking_index = this.rided_bookings.indexOf(booking);
                if (booking_index > -1) {
                  this.bookings.splice(booking_index, 1);
                }
                if (inactive_booking_index > -1) {
                  this.inactive_bookings.splice(inactive_booking_index, 1);
                }
                if (rided_booking_index > -1) {
                  this.rided_bookings.splice(rided_booking_index, 1);
                }
              });
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("bookings.archivate"),
              });
            })
            .catch((error) => {
              this.isLoading = false;
              this.setError(error, this.$t("errors.bad_archivate"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.bad_archivate"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        }
      });
    },
    toggleShowBookings() {
      this.getDataFromApi();
      return this.$store.commit("show_active_bookings");
    },
    updateState(id, state) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let self = this;
        this.axios
          .patch(
            `/api/v1/fines/${id}`,
            {
              fines: {
                state: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.fines.findIndex(
              (element) => element.id === id
            );
            this.fines.splice(foundIndex, 1, response.data);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("bookings.fine_update_title"),
              text: this.$t("bookings.fine_update_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.edit_fines"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.edit_fines"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    stateColor(state) {
      if (state == "Оповещён") {
        return "warning";
      }
      if (state == "Оплачен") {
        return "red";
      }
      if (state == "Возмещён") {
        return "success";
      }
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        // console.log(moment(a.start_date, 'DD-MM-YYYY H:mm').isBefore(b.start_date, 'DD-MM-YYYY H:mm'))
        // if (index[0] === "start_date") {
        return moment(a.start_date, "DD-MM-YYYY H:mm").isBefore(
          moment(b.start_date, "DD-MM-YYYY H:mm")
        );
        // }
      });
      return items;
    },
    clearBadge() {
      if (this.isNative) {
        const clear = async () => {
          await Badge.clear();
        };
        clear();
      }
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("DD MMM H:mm");
    },
    formatBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("DD MMM H:mm");
    },
    formatDateBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("DD MMM");
    },
    formatTimeBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("H:mm");
    },
    shortFormatBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("DD-MM H:mm");
    },
    replaceToBooking(booking) {
      this.$router.push({ path: `/bookings/${booking.id}` });
    },
    setCarStateClass(state) {
      // let car = this.cars.find(car => car.code === code)
      if (state) {
        // В ремонте
        if (state == 1) {
          return "green--text";
        } else if (state == 2) {
          if (this.$vuetify.theme.dark) {
            return "grey--text text--darken-2";
          } else {
            return "black--text";
          }
        } else if (state == 3) {
          return "red--text";
        } else if (state == 4) {
          return "pink--text";
        } else if (state == 5) {
          return "blue--text";
        } else if (state == 6) {
          return "orange--text";
        }
      }
    },
    setCarStateTooltip(state) {
      // let car = this.cars.find(car => car.code === code)
      if (state) {
        // В ремонте
        if (state == 1) {
          return this.$t("states.normal");
        } else if (state == 2) {
          return this.$t("states.in_service");
        } else if (state == 3) {
          return this.$t("states.critical_2");
        } else if (state == 4) {
          return this.$t("states.long_rent");
        } else if (state == 5) {
          return this.$t("states.no_rent");
        } else if (state == 6) {
          return this.$t("states.service_needed");
        }
      }
    },
    setClient(id) {
      let client = this.clients.filter((x) => x.id === id);
      // Warning fio
      this.clients_arr.push(client);
    },
    getWorkersNames() {
      this.isLoading = true;
      this.axios
        .get("/api/v1/get_workers_names_with_ids", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.workers_names = response.data;
          this.responsibles = response.data;
          // добавляем в массив отсутствие ответственного
          this.responsibles.push({
            id: null,
            name: null,
          });
        })
        .catch((error) => {
          this.setError(error, "Something went wrong in get_workers_names");
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    updateResponsible(id, responsible_name, responsible_id) {
      if (this.user_role != "guest" && this.user_role != "partner" && this.user_role != "agent") {
        this.isLoading = true;
        let self = this;
        this.axios
          .patch(
            `/api/v1/update_responsible`,
            {
              bookings: {
                id: id,
                responsible: responsible_name,
                responsible_id: responsible_id
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.bookings.findIndex(
              (element) => element.id === self.updatingBookingId
            );
            this.bookings[foundIndex].responsible = self.selected_worker;
            let foundIndex1 = this.active_bookings.findIndex(
              (element) => element.id === self.updatingBookingId
            );
            if (foundIndex1 >= 0) {
              this.active_bookings[foundIndex1].responsible =
                responsible_name;
            }
            let foundIndex2 = this.inactive_bookings.findIndex(
              (element) => element.id === self.updatingBookingId
            );
            if (foundIndex2 >= 0) {
              this.inactive_bookings[foundIndex2].responsible =
                responsible_name;
            }

            this.selected_worker = "";
            this.updatingBookingId = null;

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("bookings.responsible_changed_title"),
              text: this.$t("bookings.responsible_changed_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.change_responsible"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.change_responsible"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    saveResponsibale() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        if (this.selected_worker != null && this.selected_worker != "" && this.updatingBookingId) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/bookings/${self.updatingBookingId}`,
              {
                bookings: {
                  responsible: self.selected_worker,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let foundIndex = this.bookings.findIndex(
                (element) => element.id === self.updatingBookingId
              );
              this.bookings[foundIndex].responsible = self.selected_worker;
              let foundIndex1 = this.active_bookings.findIndex(
                (element) => element.id === self.updatingBookingId
              );
              if (foundIndex1 >= 0) {
                this.active_bookings[foundIndex1].responsible =
                  self.selected_worker;
              }
              let foundIndex2 = this.inactive_bookings.findIndex(
                (element) => element.id === self.updatingBookingId
              );
              if (foundIndex2 >= 0) {
                this.inactive_bookings[foundIndex2].responsible =
                  self.selected_worker;
              }

              this.selected_worker = "";
              this.updatingBookingId = null;
              this.showSetResponsible = false;
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.select_resp_title"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.select_resp_text"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("errors.select_resp"),
            text: this.error,
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    saveBookingState(state, id) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        if ((state, id)) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/bookings/${id}`,
              {
                bookings: {
                  state: state,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let foundIndex = this.bookings.findIndex(
                (element) => element.id === id
              );
              this.bookings[foundIndex].state = state;
              let foundIndex1 = this.active_bookings.findIndex(
                (element) => element.id === id
              );
              if (foundIndex1 >= 0) {
                this.active_bookings[foundIndex1].state = state;
              }
              let foundIndex2 = this.inactive_bookings.findIndex(
                (element) => element.id === id
              );
              if (foundIndex2 >= 0) {
                this.inactive_bookings[foundIndex2].state = state;
              }
              this.showSetBookingState = false;
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.update_state"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.update_state_title"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("errors.select_state"),
            text: this.error,
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkClientCategory(booking) {
      // let client = this.clients.find(x => x.id == client_id)
      if (booking.client_category == "Новый") {
        return "";
      } else if (
        booking.client_category == "Постоянный" &&
        !booking.client_debtor
      ) {
        return `green--text`;
      } else if (booking.client_category == "Серый" && !booking.client_debtor) {
        return `grey--text text-decoration-line-through`;
      } else if (
        booking.client_category == "Чёрный" &&
        !booking.client_debtor
      ) {
        return `black--text text-decoration-line-through`;
      } else if (
        booking.client_category == "Лояльный" &&
        !booking.client_debtor
      ) {
        return `deep-purple--text`;
      } else if (booking.client_debtor) {
        return `orange darken-4 white--text`;
      }
    },
    selectColorBooking(booking) {
      if (booking.technical == true) {
        if (this.$vuetify.theme.dark) {
          return 'blue-grey darken-3';
        } else {
          return 'blue-grey lighten-1';
        }
      }
    },
    selectColorRidedBooking(booking) {
      if (this.checkPartReturnDepositDay(booking)) {
        if (this.$vuetify.theme.dark) {
          return 'yellow darken-2';
        } else {
          return 'warning lighten-2';
        }
      }
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.isLoading = false;
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    // flatPickr
    // VueSlider
  },
};
</script>
<style>
.pulse {
  border-radius: 50%;
  margin: 10px;
  height: 20px;
  width: 20px;
  transform: scale(1);
}

.pulse.pulse_success {
  background: rgba(0, 179, 0, 1);
  box-shadow: 0 0 0 0 rgba(0, 179, 0, 1);
  animation: pulse-green 2s infinite;
}

.pulse.pulse_error {
  background: rgb(239, 24, 24);
  box-shadow: 0 0 0 0 rgb(239, 24, 24);
  animation: pulse-error 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}

@keyframes pulse-error {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(217, 51, 51, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(217, 51, 51, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(217, 51, 51, 0);
  }
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 10px;
}

.description-trunc p {
  margin: 0px;
}

.payment .v-label {
  font-size: 12px !important;
}

.is-mobile .v-data-footer__select {
  margin-right: 0 !important;
}

.is-mobile .v-data-footer__icons-before {
  margin: 0 auto !important;
}

.is-mobile .v-data-footer__icons-after {
  margin: 0 auto !important;
}
</style>
