<template>
  <span v-resize="checkMobile">
    <v-overlay v-if="!isLoading" :value="includedInPlan('services')" class="px-5">
      <v-alert
        color="info"
        border="top"
        class="mx-2"
      >
        <v-row>
          <v-col cols="12" class="text-right mt-0 mb-0 pb-0">
            <v-icon @click="goBack">mdi-close</v-icon>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-icon size="50">mdi-information</v-icon>
          </v-col>
          <v-col cols="12" class="text-center font-bold">
            {{ $t('custom.free_tariff_warning') }}
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn @click="subscribeToPaidTariff()" color="success" class="mx-1 my-1" :loading="isLoadingSubscribeToPaidTariff">
              {{ $t('custom.to_paid_plan') }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn small text @click="toTariffOnSite()" class="mx-1 my-1">
              {{ $t('custom.tariff_more_info') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-overlay>
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-card>
      <v-card-title class="start-tour-repairs-1 px-1">
        <v-flex md12 class="d-flex justify-between">
          <v-flex class="d-flex justify-center">
            <h2 v-if="!isMobile">{{ $t("services.tech") }}</h2>
            <h4 v-else>{{ $t("services.tech") }}</h4>
            <v-btn icon color="success" class="ml-2" @click="showNewMaintenanceDialog = true">
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
            <v-btn icon :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
              }/${$root.$i18n.locale}/categories/7/guides/23`" target="_blank">
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </v-flex>
        </v-flex>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
        <v-flex>
          <v-text-field v-model="search_maintenance" class="mb-2" append-icon="mdi-magnify" :label="$t('custom.search')"
            single-line clearable hide-details hide-no-data></v-text-field>
        </v-flex>
        <v-data-table :headers="headers_with_maintenance" :items="cars_with_maintenance" :loading="isLoading"
          :loading-text="$t('custom.loading_table')" mobile-breakpoint="100" :expanded.sync="expandedColumns"
          :show-expand="false" single-expand :search="search_maintenance" :custom-filter="customSearch" sort-by="sort"
          :items-per-page="50">
          <template slot="item" slot-scope="props">
            <tr>
              <td class="text-center pb-2">
                <router-link style="text-decoration: none;" :to="{ path: `/cars/${props.item.id}` }">
                  <span style="text-decoration: underline !important;" class="subtitle-1">{{ props.item.car_name
                    }}</span>
                  <br>
                  <span class="success--text mr-2">{{ props.item.code }}</span>
                  <span class="info--text">{{ props.item.number }}</span>
                </router-link>
                <br>
                <code>{{ props.item.mileage }}</code>
              </td>
              <td class="text-center">
                <v-alert v-for="item in props.item.maintenances" :key="item.id" border="left"
                  :color="selectCriticalityClass(item.criticality)" dense dark class="my-2 white--text"
                  style="cursor: pointer"
                  @click="maintenance = item, showEndMaintenanceDialog = true, maintenance.last_maintenanced_mileage = props.item.mileage"
                  :icon="checkMaintenanceIcon(item)">
                  {{ item.title }}
                </v-alert>
              </td>
              <td class="text-center">
                <!-- expend icon -->
                <v-icon v-if="expandedColumns.includes(props.item)"
                  @click="expandedColumns = expandedColumns.filter((i) => i.id !== props.item.id)">
                  mdi-chevron-up
                </v-icon>
                <v-icon v-else @click="expandedColumns.push(props.item)">
                  mdi-chevron-down
                </v-icon>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table :headers="maintenance_headers" :loading="isLoading"
                :loading-text="$t('custom.loading_table')" :items="item.maintenances" sort-by="id" sort-desc
                item-key="id" mobile-breakpoint="100" :class="!isMobile ? '' : 'is-mobile'" dense hide-default-footer
                disable-pagination>
                <template slot="header" slot-scope="props">
                  <tr>
                    <th v-for="header in props.headers" :key="header.text" :class="[
                      'column sortable',
                      pagination.descending ? 'desc' : 'asc',
                      header.value === pagination.sortBy ? 'active' : '',
                    ]" @click="changeSort(header.value)">
                      <v-icon small>arrow_upward</v-icon>
                      {{ header.text }}
                    </th>
                  </tr>
                </template>
                <template slot="item" slot-scope="props">
                  <tr :class="selectCriticalityClass(props.item.criticality)">
                    <td class="text-center caption" nowrap :key="props.item.id">
                      {{ props.item.id }}
                    </td>
                    <td class="text-center">
                      <v-icon v-if="props.item.periodic" color="white">
                        mdi-all-inclusive-box-outline
                      </v-icon>
                      <v-icon v-else color="white">
                        mdi-numeric-1-box-outline
                      </v-icon>
                    </td>
                    <td class="text-center" nowrap>
                      {{ props.item.date_start ? formatDate(props.item.date_start) : '-' }}
                    </td>
                    <td class="text-center">
                      {{ props.item.title }}
                    </td>
                    <td class="text-center">
                      {{ props.item.description }}
                    </td>
                    <td class="text-center">
                      {{ props.item.maintenance_type == 0 ? props.item.mileage_step : props.item.days_step }}
                    </td>
                    <td class="text-center">
                      {{ props.item.last_maintenanced_date ? formatDate(props.item.last_maintenanced_date) : '-' }} |
                      {{ props.item.last_maintenanced_mileage ? props.item.last_maintenanced_mileage : '-' }}
                    </td>
                    <td class="text-center">
                      {{ findWorkersName(props.item.responsible) }}
                    </td>
                    <td class="text-center" nowrap>
                      <v-btn v-if="props.item.date_start" icon color="success"
                        @click="maintenance = props.item, showEndMaintenanceDialog = true, maintenance.last_maintenanced_mileage = props.item.mileage">
                        <v-icon>mdi-check-all</v-icon>
                      </v-btn>
                      <v-btn v-if="props.item.id" icon
                        @click="maintenance = props.item, showEditMaintenanceDialog = true">
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                      <v-btn icon :color="props.item.criticality == 2 ? 'white' : 'error'"
                        @click="deleteMaintenance(props.item)">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
                <template slot="no-data">
                  {{ $t("custom.no_data_in_table") }}
                </template>
                <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
                  {{ $t("tables.no_search_result", { msg: search_maintenance }) }}
                </v-alert>
              </v-data-table>
            </td>
          </template>
          <template slot="no-data">
            {{ $t("custom.no_data_in_table") }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showNewMaintenanceDialog" :retain-focus="false" persistent max-width="600px"
      :fullscreen="isMobile ? true : false">
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("maintenance.new_maintenance") }}
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="showNewMaintenanceDialog = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex md12 :class="isMobile ? '' : 'd-flex'">
            <v-flex>
              <v-radio-group v-model="maintenance.periodic">
                <v-radio :label="$t('maintenance.periodic')" :value="true"></v-radio>
                <v-radio :label="$t('maintenance.once')" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex v-if="!maintenance.periodic">
              <v-radio-group v-model="maintenance.started">
                <v-radio :label="$t('maintenance.current_maintenance')" :value="true"></v-radio>
                <v-radio :label="$t('maintenance.not_started_maintenance')" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-flex>
          <v-flex md12>
            <v-select v-model="maintenance.maintenance_type" :items="maintenance_types" item-text="text"
              item-value="value" dense outlined :label="$t('maintenance.maintenance_type')"></v-select>
          </v-flex>
          <v-flex md12>
            <v-select v-model="maintenance.criticality" :items="maintenance_criticalities" item-text="text"
              item-value="value" dense outlined :label="$t('maintenance.criticality')"></v-select>
          </v-flex>
          <v-flex md12>
            <v-text-field v-model.trim="maintenance.title" :label="$t('maintenance.title')" outlined
              dense></v-text-field>
          </v-flex>
          <v-flex md12 class="mb-6" v-if="(!maintenance.started || maintenance.periodic)">
            <v-menu offset-y :close-on-content-click="false" max-width="252px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-bind="attrs" v-on="on" readonly
                  :value="formatLastMaintenancedDate(maintenance.last_maintenanced_date)" :attributes="attrs_calendar"
                  :label="$t('maintenance.last_maintenanced')" outlined hide-details dense clearable></v-text-field>
              </template>
              <vc-date-picker v-model="maintenance.last_maintenanced_date" mode="date" :masks="{ input: 'YYYY-MM-DD' }"
                :locale="$i18n.locale">
              </vc-date-picker>
            </v-menu>
          </v-flex>
          <v-flex md12 v-if="(!maintenance.started || maintenance.periodic)">
            <v-text-field v-model.trim="maintenance.last_maintenanced_mileage"
              :label="$t('maintenance.last_maintenanced_mileage')" type="number" outlined dense></v-text-field>
          </v-flex>
          <v-flex md12 v-if="maintenance.maintenance_type == 0 && (!maintenance.started || maintenance.periodic)">
            <v-text-field v-model.trim="maintenance.mileage_step" :label="$t('maintenance.mileage_step')" type="number"
              outlined dense></v-text-field>
          </v-flex>
          <v-flex md12 v-if="maintenance.maintenance_type == 1 && (!maintenance.started || maintenance.periodic)">
            <v-text-field v-model="maintenance.days_step" :label="$t('maintenance.days_step')" type="number" outlined
              dense></v-text-field>
          </v-flex>
          <v-flex md12 v-if="(!maintenance.started || maintenance.periodic)">
            <v-text-field v-model="maintenance.notify_before" :label="$t('maintenance.notify_before')" type="number"
              dense outlined></v-text-field>
          </v-flex>
          <v-flex md12 class="d-flex align-middle mb-5">
            <v-autocomplete v-model="maintenance.responsible" item-text="name" item-value="id"
              :loading="isLoadingResponsible" text hide-no-data hide-details outlined dense multiple clearable
              :label="$t('maintenance.responsible')" :items="workers">
            </v-autocomplete>
            <v-btn icon color="success" @click="maintenance.responsible = workers.map((worker) => worker.id)"
              class="ml-2" v-tooltip="{
                content: $t('counts.select_all_groups'),
                placement: 'top-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 1000,
                  hide: 500,
                },
              }">
              <v-icon>mdi-check-all</v-icon>
            </v-btn>
          </v-flex>
          <v-flex md12 class="d-flex align-middle mb-5">
            <v-autocomplete v-model="maintenance.car_ids" item-text="code" item-value="id" outlined multiple clearable
              dense hide-no-data hide-details :label="$t('custom.select_car')" :items="cars_with_maintenance">
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.code }} - {{ data.item.number }}
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.code }} - {{ data.item.number }}
              </template>
            </v-autocomplete>
            <v-btn icon color="success" @click="maintenance.car_ids = cars_with_maintenance.map((car) => car.id)"
              class="ml-2" v-tooltip="{
                content: $t('counts.select_all_groups'),
                placement: 'top-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 1000,
                  hide: 500,
                },
              }">
              <v-icon>mdi-check-all</v-icon>
            </v-btn>
          </v-flex>
          <v-flex md12>
            <v-textarea v-model.trim="maintenance.description" :label="$t('maintenance.description')" outlined rows="1"
              auto-grow dense></v-textarea>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showNewMaintenanceDialog = false">{{
            $t("custom.close")
            }}</v-btn>
          <v-btn color="success" @click="saveMaintenance()">{{
            $t("custom.save")
            }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEditMaintenanceDialog" :retain-focus="false" persistent max-width="600px"
      :fullscreen="isMobile ? true : false">
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("maintenance.edit_maintenance") }}
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="closeEditMaintenanceDialog()">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex>
            <v-radio-group v-model="maintenance.started">
              <v-radio :label="$t('maintenance.current_maintenance')" :value="true"
                @change="addStartDateToMaintenance(maintenance)"></v-radio>
              <v-radio :label="$t('maintenance.not_started_maintenance')" :value="false"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex md12>
            <v-select v-model="maintenance.maintenance_type" :items="maintenance_types" item-text="text"
              item-value="value" dense outlined :label="$t('maintenance.maintenance_type')"></v-select>
          </v-flex>
          <v-flex md12>
            <v-select v-model="maintenance.criticality" :items="maintenance_criticalities" item-text="text"
              item-value="value" dense outlined :label="$t('maintenance.criticality')"></v-select>
          </v-flex>
          <v-flex md12>
            <v-text-field v-model.trim="maintenance.title" :label="$t('maintenance.title')" outlined
              dense></v-text-field>
          </v-flex>
          <v-flex md12 class="mb-6" v-if="(!maintenance.started || maintenance.periodic)">
            <v-menu offset-y :close-on-content-click="false" max-width="252px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-bind="attrs" v-on="on" readonly
                  :value="formatLastMaintenancedDate(maintenance.last_maintenanced_date)" :attributes="attrs_calendar"
                  :label="$t('maintenance.last_maintenanced')" outlined hide-details dense></v-text-field>
              </template>
              <vc-date-picker v-model="maintenance.last_maintenanced_date" mode="date" :masks="{ input: 'YYYY-MM-DD' }"
                :locale="$i18n.locale">
              </vc-date-picker>
            </v-menu>
          </v-flex>
          <v-flex md12 v-if="(!maintenance.started || maintenance.periodic)">
            <v-text-field v-model.trim="maintenance.last_maintenanced_mileage"
              :label="$t('maintenance.last_maintenanced_mileage')" type="number" outlined dense></v-text-field>
          </v-flex>
          <v-flex md12 v-if="maintenance.maintenance_type == 0 && (!maintenance.started || maintenance.periodic)">
            <v-text-field v-model="maintenance.mileage_step" :label="$t('maintenance.mileage_step')" type="number"
              outlined dense></v-text-field>
          </v-flex>
          <v-flex md12 v-if="maintenance.maintenance_type == 1 && (!maintenance.started || maintenance.periodic)">
            <v-text-field v-model="maintenance.days_step" :label="$t('maintenance.days_step')" type="number" outlined
              dense></v-text-field>
          </v-flex>
          <v-flex md12 v-if="(!maintenance.started || maintenance.periodic)">
            <v-text-field v-model="maintenance.notify_before" :label="$t('maintenance.notify_before')" type="number"
              dense outlined></v-text-field>
          </v-flex>
          <v-flex md12 class="d-flex align-middle mb-5">
            <v-autocomplete v-model="maintenance.responsible" item-text="name" item-value="id"
              :loading="isLoadingResponsible" text hide-no-data hide-details outlined dense multiple clearable
              :label="$t('maintenance.responsible')" :items="workers">
            </v-autocomplete>
            <v-btn icon color="success" @click="maintenance.responsible = workers.map((worker) => worker.id)"
              class="ml-2" v-tooltip="{
                content: $t('counts.select_all_groups'),
                placement: 'top-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 1000,
                  hide: 500,
                },
              }">
              <v-icon>mdi-check-all</v-icon>
            </v-btn>
          </v-flex>
          <v-flex md12>
            <v-textarea v-model="maintenance.description" :label="$t('maintenance.description')" outlined rows="1"
              auto-grow dense></v-textarea>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeEditMaintenanceDialog()">{{
            $t("custom.close")
            }}</v-btn>
          <v-btn color="success" @click="saveEditMaintenance()">{{
            $t("custom.save")
            }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEndMaintenanceDialog" :retain-focus="false" persistent max-width="600px"
      :fullscreen="isMobile ? true : false">
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("maintenance.end_maintenance") }}
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="showEndMaintenanceDialog = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex v-if="maintenance.maintenance_type == 4" md12 class="mb-6">
            <v-menu offset-y :close-on-content-click="false" max-width="252px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-bind="attrs" v-on="on" readonly :attributes="attrs_calendar"
                  :value="maintenance.new_date ? formatLastMaintenancedDate(maintenance.new_date) : $t('services.insurance_renew_date')"
                  outlined hide-details clearable dense></v-text-field>
              </template>
              <vc-date-picker v-model="maintenance.new_date" mode="date" :masks="{ input: 'YYYY-MM-DD' }"
                :locale="$i18n.locale">
              </vc-date-picker>
            </v-menu>
          </v-flex>
          <v-flex v-if="maintenance.maintenance_type == 1 && (!maintenance.started || maintenance.periodic)" md12
            class="mb-6">
            <v-menu offset-y :close-on-content-click="false" max-width="252px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-bind="attrs" v-on="on"
                  :value="maintenance.last_maintenanced_date ? formatLastMaintenancedDate(maintenance.last_maintenanced_date) : $t('maintenance.last_maintenanced')"
                  :label="$t('maintenance.last_maintenanced')" :attributes="attrs_calendar" outlined hide-details
                  dense></v-text-field>
              </template>
              <vc-date-picker v-model="maintenance.last_maintenanced_date" mode="date" :masks="{ input: 'YYYY-MM-DD' }"
                :locale="$i18n.locale">
              </vc-date-picker>
            </v-menu>
          </v-flex>
          <!-- <v-flex md12>
            <v-text-field
              v-model.trim="maintenance.last_maintenanced_mileage"
              :label="$t('maintenance.last_maintenanced_mileage')"
              type="number"
              outlined
              dense
            ></v-text-field>
          </v-flex> -->
          <v-flex md12 class="mb-5">
            <v-autocomplete v-model="maintenance.contractor_id" item-text="short_name" item-value="id"
              :loading="isLoadingContractors" :search-input.sync="search_contractor" outlined clearable hide-no-data
              hide-details dense :label="$t('contractors.select_contractor')" :items="contractors">
            </v-autocomplete>
          </v-flex>
          <v-flex md12>
            <v-textarea v-model="maintenance.description" :label="$t('maintenance.description')" outlined rows="1"
              auto-grow dense></v-textarea>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showEndMaintenanceDialog = false">{{
            $t("custom.close")
            }}</v-btn>
          <v-btn color="success" @click="saveEndMaintenance()">{{
            $t("maintenance.save_end_maintenance")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      cars: [],
      cars_with_maintenance: [],
      expandedColumns: [],
      editedCar: [],
      responsible: [],
      maintenance: {
        periodic: false,
        started: false,
        maintenance_type: 0,
        title: null,
        description: null,
        mileage_step: 0,
        days_step: 0,
        last_maintenanced_date: null,
        last_maintenanced_date: moment().format("YYYY-MM-DD"),
        criticality: 0,
        responsible: [],
        car_id: null,
        car_ids: [],
        notify_before: 0,
        started: true,
      },
      maintenance_items: [],
      maintenance_headers: [
        { text: '', value: "id", align: "center" },
        { text: '', value: "maintenance_type", align: "center" },
        { text: this.$t('maintenance.date_start'), value: "date_start", align: "center" },
        { text: this.$t('maintenance.title'), value: "title", align: "center" },
        { text: this.$t('maintenance.description'), value: "description", align: "center" },
        { text: this.$t('maintenance.step'), sortable: false, align: "center" },
        { text: this.$t('maintenance.last_maintenance'), sortable: false, align: "center" },
        { text: this.$t('maintenance.responsible'), value: "responsible", align: "center" },
        { text: this.$t('custom.control'), sortable: false, align: "center" },
      ],
      search_maintenance: null,
      workers: [],
      contractor: {},
      contractors: [],
      isLoadingContractors: false,
      maintenance_types: [
        { text: this.$t("maintenance.mileage"), value: 0 },
        { text: this.$t("maintenance.days"), value: 1 },
        // { text: this.$t("maintenance.once"), value: 3 },
        // { text: this.$t("maintenance.insurances_and_taxes"), value: 4 },
      ],
      maintenance_criticalities: [
        { text: this.$t("maintenance.low"), value: 0 },
        { text: this.$t("maintenance.medium"), value: 1 },
        { text: this.$t("maintenance.high"), value: 2 },
      ],
      headers: [
        { text: this.$t("cars.name"), value: "car_name", align: "center" },
        { text: this.$t("cars.code"), value: "car_code", align: "center" },
        { text: this.$t("cars.gov_number"), value: "number", align: "center" },
        {
          text: this.$t("bookings.mileage"),
          value: "mileage",
          align: "center",
        },
        { text: this.$t("custom.current"), sortable: false, align: "center" },
      ],
      headers_with_maintenance: [
        { text: this.$t("cars.name"), value: "code", align: "center" },
        { text: this.$t("custom.current"), sortable: false, align: "center" },
        { text: '', value: 'data-table-expand' },
      ],
      attrs_calendar: [
        {
          key: "today",
          highlight: {
            color: "blue",
            fillMode: "outline",
            contentClass: "italic",
          },
          dates: new Date(),
        },
      ],
      search_contractor: null,
      showEndMaintenanceDialog: false,
      showEditMaintenanceDialog: false,
      showNewMaintenanceDialog: false,
      isLoadingResponsible: false,
      timeout: null,
      isMobile: false,
      isLoading: true,
      isLoadingSubscribeToPaidTariff: false,
      error: "",
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get(`/api/v1/current_maintenances`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.cars_with_maintenance = response.data.cars || [];
          this.cars_with_maintenance.forEach((car) => {
            car.maintenances = response.data.maintenances.filter(
              (maintenance) => maintenance.car_id == car.id
            );
          });
          this.workers = response.data.workers_names;
          this.contractors = [];
          response.data.contractors.data.forEach((contractor) => {
            this.contractors.push(contractor.attributes);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  mounted() {
    if (
      this.$tours["registrationTour"] &&
      this.$store.getters.getTourNextStep
    ) {
      this.$tours["registrationTour"].nextStep();
    }
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  watch: {
    search_contractor() {
      if (this.contractor && this.contractor.id) {
        return;
      }
      if (this.search_contractor == "" || this.search_contractor == " ") {
        this.search_contractor = null;
      } else {
        let self = this;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.loadContractors();
          }, 200); // delay
        }
        debounce();
      }
    },
  },
  computed: {
    userMustChangeTariffPlan() {
      if (this.$store.getters.getMainCompany) {
        if (this.$store.getters.getMainCompany.plan && this.$store.getters.getMainCompany.plan.id) {
          if(this.$store.getters.getMainCompany.plan.name == "Старт" || this.$store.getters.getMainCompany.plan.name == "Start") {
            if(this.$store.getters.getMainCompany.plan.trial_end && moment(this.$store.getters.getMainCompany.plan.trial_end).isAfter(moment())) {
              return false;
            } else {
              return moment(this.$store.getters.getMainCompany.created_at).isBefore(moment().subtract(7, "day")) ? true : false;
            }
          } else {
            return false;
          }
        } else {
          return false
        }
      } else {
        return false;
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
    company() {
      return this.$store.getters.getCompany.company;
    },
  },
  methods: {
    saveMaintenance() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (this.maintenance.car_id || (this.maintenance.car_ids && this.maintenance.car_ids.length > 0) && this.maintenance.title && this.maintenance.title.length > 0) {
          this.isLoading = true;
          if (this.maintenance.periodic) {
            this.maintenance.started = false;
          }
          this.axios
            .post("/api/v1/maintenances",
              {
                maintenance: this.maintenance,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
            .then((response) => {
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("maintenance.maintenance_created"),
                text: this.$t("maintenance.maintenance_created_text"),
              });
              this.showNewMaintenanceDialog = false;
              this.maintenance = {
                maintenance_type: 0,
                title: null,
                description: null,
                mileage_step: 0,
                days_step: 0,
                last_maintenanced_date: null,
                criticality: 0,
                responsible: [],
                assign_to_all_cars: false,
                car_id: null,
                car_ids: [],
              };
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.maintenance_create"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("custom.no_data")
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    addStartDateToMaintenance(maintenance) {
      let car = this.cars_with_maintenance.find((car) => car.id == maintenance.car_id);
      if (car) {
        let index = car.maintenances.findIndex((m) => m.id == maintenance.id)
        console.log("index", index);
        if (index > -1) {
          car.maintenances[index].date_start = moment().format("YYYY-MM-DD");
          this.cars_with_maintenance.splice(this.cars.indexOf(car), 1, car);
        }
      }
    },
    saveEndMaintenance() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let car = this.cars_with_maintenance.find((car) => car.id == this.maintenance.car_id);
        if (car) {
          this.isLoading = true;
          this.axios
            .post(`/api/v1/end_maintenance/${this.maintenance.id}`,
              {
                maintenance: {
                  completed: true,
                  date_completed: moment().format("YYYY-MM-DD"),
                  mileage_completed: car.mileage,
                  last_maintenanced_mileage: car.mileage,
                  whois_ended: this.$store.getters.getCurrentUser.id,
                  description: this.maintenance.description,
                  contractor_id: this.maintenance.contractor_id,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
            .then((response) => {
              // remove maintenance from array
              let car = this.cars_with_maintenance.find((car) => car.id == this.maintenance.car_id);
              let current_maintenances = car.maintenances
              current_maintenances.splice(current_maintenances.indexOf(this.maintenance), 1);
              car.maintenances = current_maintenances;
              this.cars_with_maintenance.splice(this.cars.indexOf(car), 1, car);

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("maintenance.maintenance_ended"),
                text: this.$t("maintenance.maintenance_ended_text"),
              });
              this.showEndMaintenanceDialog = false;
              this.maintenance = {
                maintenance_type: 0,
                title: null,
                description: null,
                mileage_step: 0,
                days_step: 0,
                last_maintenanced_date: null,
                notify_before: 0,
                criticality: 0,
                responsible: [],
                assign_to_all_cars: false,
                car_id: null,
                car_ids: [],
              };
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.error"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("errors.error"),
            text: this.$t("errors.error"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    saveEditMaintenance() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let maintenance_data = this.maintenance
        if (maintenance_data.started == false) {
          maintenance_data.date_start = null;
        }
        this.axios
          .patch(`/api/v1/maintenances/${this.maintenance.id}`,
            {
              maintenance: maintenance_data,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
          .then((response) => {
            // update maintenance from array
            let car = this.cars_with_maintenance.find((car) => car.id == this.maintenance.car_id);
            let current_maintenances = car.maintenances
            current_maintenances.splice(current_maintenances.indexOf(this.maintenance), 1, this.maintenance);
            car.maintenances = current_maintenances;
            this.cars_with_maintenance.splice(this.cars_with_maintenance.indexOf(car), 1, car);
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("maintenance.maintenance_updated"),
              text: this.$t("maintenance.maintenance_updated_text"),
            });
            this.showEditMaintenanceDialog = false;
            this.maintenance = {
              maintenance_type: 0,
              title: null,
              description: null,
              mileage_step: 0,
              days_step: 0,
              last_maintenanced_date: null,
              notify_before: 0,
              criticality: 0,
              responsible: [],
              assign_to_all_cars: false,
              car_id: null,
              car_ids: [],
            };
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.maintenance_create"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    closeEditMaintenanceDialog() {
      this.showEditMaintenanceDialog = false;
      this.maintenance = {
        maintenance_type: 0,
        title: null,
        description: null,
        mileage_step: 0,
        days_step: 0,
        last_maintenanced_date: null,
        notify_before: 0,
        criticality: 0,
        responsible: [],
        assign_to_all_cars: false,
        car_id: null,
        car_ids: [],
      }
    },
    deleteMaintenance(maintenance) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        this.$swal({
          title: this.$t("maintenance.delete_maintenance_title"),
          text: this.$t("maintenance.delete_maintenance_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/maintenances/${maintenance.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                // remove maintenance from array
                let car = this.cars_with_maintenance.find((car) => car.id == maintenance.car_id);
                let current_maintenances = car.maintenances
                current_maintenances.splice(current_maintenances.indexOf(maintenance), 1);
                car.maintenances = current_maintenances;
                // deep update car maintenance
                this.cars_with_maintenance.find((car) => car.id == maintenance.car_id).maintenances = Object.assign([], car.maintenances);

                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "success",
                  title: this.$t("maintenance.maintenance_deleted"),
                });
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.error"))
              )
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkMaintenanceIcon(maintenance) {
      function checkDate(maintenance) {
        if (moment(maintenance.last_maintenanced_date).add(maintenance.days_step, 'days').diff(moment(), 'days') <= 0) {
          return true;
        } else {
          return false;
        }
      }
      if (this.isMobile) {
        return false;
      } else {
        if (maintenance.periodic) {
          if (maintenance.last_maintenanced_date && maintenance.days_step && checkDate(maintenance)) {
            return "mdi-alert-box";
          } else {
            return "mdi-all-inclusive-box-outline";
          }
        } else {
          if (maintenance.last_maintenanced_date && maintenance.days_step && checkDate(maintenance)) {
            return "mdi-alert-box";
          } else {
            return "mdi-numeric-1-box-outline";
          }
        }
      }
    },
    formatLastMaintenancedDate(date) {
      if (date) {
        return moment(date, "YYYY-MM-DD").format("LL");
      } else {
        return null;
      }
    },
    selectCriticalityClass(criticality) {
      if (criticality == 0) {
        return "secondary white--text";
      } else if (criticality == 1) {
        return "warning white--text";
      } else if (criticality == 2) {
        return "error white--text";
      }
    },
    findWorkersName(ids) {
      let names = [];
      ids.forEach((id) => {
        this.workers.forEach((worker) => {
          if (worker.id == id) {
            names.push(worker.name);
          }
        });
      });
      return names ? names.join(", ") : "";
    },
    loadContractors() {
      this.isLoadingContractors = true;
      this.axios
        .get(
          `/api/v1/contractors`,
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          response.data.data.forEach((element) => {
            this.contractors.push(element.attributes);
          });
        })
        .catch((error) => {
          this.isLoadingContractors = false;
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => (this.isLoadingContractors = false));
    },
    formatDate(date) {
      if (date && moment(date, "YYYY-MM-DD").isValid()) {
        return moment(date, "YYYY-MM-DD").format("LL");
      } else {
        return "-";
      }
    },
    formatLastMaintenancedDate(date) {
      if (date && moment(date, "YYYY-MM-DD").isValid()) {
        return moment(date, "YYYY-MM-DD").format("LL");
      } else {
        return null;
      }
    },
    customSearch(value, search, item) {
      return Object.values(item).some(v => v && v.toString().toLowerCase().includes(search.toLowerCase()))
    },
    getCar(car) {
      this.axios
        .get(`/api/v1/cars/${car.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.editedCar = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.set_car"));
          console.log(error);
        });
    },
    saveCar() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let self = this;
        let car = this.editedCar;
        this.axios
          .patch(
            `/api/v1/cars/${car.id}`,
            {
              cars: {
                oil_engine: car.oil_engine,
                oil_engine_date: car.oil_engine_date,
                oil_transmission: car.oil_transmission,
                oil_transmission_date: car.oil_transmission_date,
                grm: car.grm,
                grm_km: car.grm_km,
                grm_date: car.grm_date,
                antifreeze: car.antifreeze,
                antifreeze_km: car.antifreeze_km,
                antifreeze_date: car.antifreeze_date,
                brake_fluid: car.brake_fluid,
                brake_fluid_km: car.brake_fluid_km,
                brake_fluid_date: car.brake_fluid_date,
                candle: car.candle,
                candle_km: car.candle_km,
                candle_date: car.candle_date,
                power_steering: car.power_steering,
                power_steering_km: car.power_steering_km,
                power_steering_date: car.power_steering_date,
                salon_filter_renew: car.salon_filter_renew,
                salon_filter_renew_km: car.salon_filter_renew_km,
                salon_filter_renew_date: car.salon_filter_renew_date,
                akb_renew: car.akb_renew,
                akb_renew_km: car.akb_renew_km,
                akb_renew_date: car.akb_renew_date,
                fuel_filter_renew: car.fuel_filter_renew,
                fuel_filter_renew_km: car.fuel_filter_renew_km,
                fuel_filter_renew_date: car.fuel_filter_renew_date,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.dialog_oil_engine = false;
            this.dialog_oil_transmission = false;
            this.dialog_grm = false;
            this.dialog_antifreeze = false;
            this.dialog_brake = false;
            this.dialog_candle = false;
            this.dialog_power_steering = false;
            this.dialog_salon_filter = false;
            this.dialog_akb = false;
            this.dialog_fuel_filter = false;

            let foundIndex = this.cars.findIndex(
              (element) => element.id === car.id
            );
            this.cars.splice(foundIndex, 1, car);

            this.editedCar = "";

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("services.maked"),
              text: this.$t("services.maked_text"),
            });
          })
          .catch((error) => this.setError(error, this.$t("errors.update_car")))
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("LL");
    },
    setServicesWarning: function () {
      return this.$store.commit("servicesCheckTrue");
    },
    unsetServicesWarning: function () {
      return this.$store.commit("servicesCheckFalse");
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    subscribeToPaidTariff() {
      this.$router.push({ name: "Transactions" });
    },
    toTariffOnSite() {
      window.open(`https://rentprog.${this.$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/${this.$root.$i18n.locale}/tariffs`, '_blank');
    },
    includedInPlan(service) {
      if (this.$store.getters.getMainCompany) {
        if (this.$store.getters.getMainCompany.plan) {
          if (this.$store.getters.getMainCompany.plan.included_services) {
            // check trial period
            if (this.$store.getters.getMainCompany.plan.trial_end && moment(this.$store.getters.getMainCompany.plan.trial_end).isAfter(moment())) {
              return false;
            }
            return !this.$store.getters.getMainCompany.plan.included_services.includes(service);
          }
        }
      }
      return false;
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        var fpath = this.PageData.backCrumb.url;
        this.$router
          .push({
            path: fpath,
          })
          .catch((err) => { });
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
<style></style>
