<template>
  <span>
    <v-overlay v-if="!isLoading" :value="includedInPlan('history')" class="px-5">
      <v-alert
        color="info"
        border="top"
        class="mx-2"
      >
        <v-row>
          <v-col cols="12" class="text-right mt-0 mb-0 pb-0">
            <v-icon @click="goBack">mdi-close</v-icon>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-icon size="50">mdi-information</v-icon>
          </v-col>
          <v-col cols="12" class="text-center font-bold">
            {{ $t('custom.free_tariff_warning') }}
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn @click="subscribeToPaidTariff()" color="success" class="mx-1 my-1" :loading="isLoadingSubscribeToPaidTariff">
              {{ $t('custom.to_paid_plan') }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn small text @click="toTariffOnSite()" class="mx-1 my-1">
              {{ $t('custom.tariff_more_info') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-overlay>
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-card>
      <v-card-title>
        <h4>{{ $t("home.events") }}</h4>
        <v-spacer></v-spacer>
        <div :class="'pulse mt-3 mx-2 ' + (connected ? 'pulse_success' : 'pulse_error')
          " v-tooltip="{
            content: connected
              ? $t('custom.connected')
              : $t('custom.disconnected'),
            placement: 'bottom-center',
            classes: ['info'],
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }"></div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
        <v-flex>
          <v-text-field v-model="search" class="mb-2" append-icon="mdi-magnify" :label="$t('custom.search')" single-line
            clearable hide-details hide-no-data></v-text-field>
        </v-flex>
        <v-data-table :items="operations" :headers="headers" :loading="isLoadingSearch" :items-per-page="20"
          :loading-text="$t('custom.loading_table')" :class="!isMobile ? '' : 'is-mobile'" :search="search"
          :server-items-length.sync="total" :options.sync="tableOptions" :page.sync="page" item-key="id"
          :sort-desc="[true]" mobile-breakpoint="100" :footer-props="{
            pageText: `{0} ${$t('custom.of')} {1}`,
            itemsPerPageText: $t('custom.elements_table'),
            showFirstLastPage: true,
            itemsPerPageOptions: [20, 50, 100],
          }">
          <template slot="item" slot-scope="props">
            <tr>
              <td class="text-no-wrap">
                {{ formatCreatedAt(props.item.created_at) }}
              </td>
              <td>{{ props.item.description }}</td>
            </tr>
          </template>
          <template slot="no-data">
            {{ $t("custom.no_data_in_table") }}
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
            {{ $t("tables.no_search_result", { msg: search }) }}
          </v-alert>
        </v-data-table>
      </v-card-text>
    </v-card>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      operations: [],
      headers: [
        {
          text: this.$i18n.t("custom.date"),
          sortable: false,
          value: "created_at",
          class: "text-xs-center",
        },
        {
          text: this.$i18n.t("custom.description"),
          sortable: false,
          value: "description",
          class: "text-xs-center",
        },
      ],
      search: null,
      page: 1,
      per_page: 50,
      sort_by: "id",
      direction: "desc",
      tableOptions: {},
      total: 0,
      timeout: null,
      isMobile: false,
      isLoading: false,
      isLoadingSubscribeToPaidTariff: false,
      isLoadingSearch: false,
      connected: false,
      error: "",
    };
  },
  channels: {
    OperationsChannel: {
      connected() {
        console.log("connected");
        this.connected = "success";
      },
      disconnected() {
        console.log("disconnected");
        this.connected = "error";
      },
      received(data) {
        if (data.action == "create") {
          console.log("create");
          this.operations.unshift(data.operation);
        }
        if (data.action == "update") {
          // Ищем индекс элемента в массиве
          let index = this.operations.findIndex((operation) => operation.id == data.operation.id);
          // Если элемент найден
          if (index != -1) {
            // Заменяем элемент в массиве
            this.operations.splice(index, 1, data.operation);
          }
        }
        if (data.action == "destroy") {
          console.log("destroy");
          this.operations.splice(
            this.operations.findIndex((operation) => operation.id == data.operation_id),
            1
          );
        }
      },
    },
  },
  mounted() {
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
    this.$cable.subscribe({
      channel: "OperationsChannel",
      room: this.$store.getters.getCompany.company.id,
    });
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      if (this.user_role == "admin" || this.user_role == "superadmin") {
        this.loadOperations();
      } else {
        this.$router.push({ path: `/dashboard` }).catch((err) => { });
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          title: this.$t("errors.only_admins"),
          text: this.error,
        });
      }
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  beforeDestroy() {
    this.$cable.unsubscribe({
      channel: "OperationsChannel",
      room: this.$store.getters.getCompany.company.id,
    });
  },
  watch: {
    tableOptions(pagination) {
      this.page = pagination.page;
      this.per_page = pagination.itemsPerPage;
      this.sort_by = pagination.sortBy[0] ? pagination.sortBy[0] : "id";
      this.direction = pagination.sortDesc[0] ? "desc" : "asc";
      this.loadOperations();
    },
    search() {
      if (this.search == "" || this.search == " ") {
        this.search = null;
      } else {
        let self = this;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.loadOperations();
          }, 200); // delay
        }
        debounce();
      }
    },
  },
  computed: {
    userMustChangeTariffPlan() {
      if (this.$store.getters.getMainCompany) {
        if (this.$store.getters.getMainCompany.plan && this.$store.getters.getMainCompany.plan.id) {
          if(this.$store.getters.getMainCompany.plan.name == "Старт" || this.$store.getters.getMainCompany.plan.name == "Start") {
            if(this.$store.getters.getMainCompany.plan.trial_end && moment(this.$store.getters.getMainCompany.plan.trial_end).isAfter(moment())) {
              return false;
            } else if (!this.$store.getters.getMainCompany.plan.trial_end && this.$store.getters.getMainCompany.created_at) {
              return moment(this.$store.getters.getMainCompany.created_at).isBefore(moment().subtract(7, "day")) ? true : false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return false
        }
      } else {
        return false;
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    company() {
      return this.$store.getters.getCompany.company;
    },
  },
  methods: {
    loadOperations() {
      this.isLoadingSearch = true;
      this.axios
        .post(
          `/api/v1/search_operations`,
          {
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            direction: this.direction,
            search: this.search,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.operations = [];
          response.data.operations.data.forEach((operation) => {
            this.operations.push(operation.attributes);
          });
          this.total = response.data.total_operations;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => (this.isLoadingSearch = false));
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("DD MMM YY H:mm");
    },
    subscribeToPaidTariff() {
      this.$router.push({ name: "Transactions" });
    },
    toTariffOnSite() {
      window.open(`https://rentprog.${this.$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/${this.$root.$i18n.locale}/tariffs`, '_blank');
    },
    includedInPlan(service) {
      if (this.$store.getters.getMainCompany) {
        if (this.$store.getters.getMainCompany.plan) {
          if (this.$store.getters.getMainCompany.plan.included_services) {
            // check trial period
            if (this.$store.getters.getMainCompany.plan.trial_end && moment(this.$store.getters.getMainCompany.plan.trial_end).isAfter(moment())) {
              return false;
            }
            return !this.$store.getters.getMainCompany.plan.included_services.includes(service);
          }
        }
      }
      return false;
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        var fpath = this.PageData.backCrumb.url;
        this.$router
          .push({
            path: fpath,
          })
          .catch((err) => { });
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
<style></style>
