<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-card-title  v-if="investor && investor.name">
        <h2 class="title">
          {{ $t("employers.partner") }} - {{ investor.name }} |
          {{ investor.percent }}% |
          {{ translateMode(investor.mode) }}
        </h2>
      </v-card-title>
      <v-card-subtitle>
        {{ investor ? investor.description : null }}
      </v-card-subtitle>

      <v-card-text>
        <v-data-iterator
          v-if="investors_data && investors_data.length > 0"
          :items="investors_data"
          :items-per-page.sync="itemsPerPage"
          :page.sync="page"
          :search="search"
          sort-by="id"
          hide-default-footer
          mobile-breakpoint="100"
          dense
        >
          <template v-slot:header>
            <v-toolbar
              text
              :color="$vuetify.theme.dark ? '' : 'white'"
              class="mb-2"
              :height="isMobile ? '230px' : ''"
            >
              <v-flex :class="isMobile ? '' : 'd-flex'">
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('custom.search')"
                  single-line
                  clearable
                  hide-details
                  class="my-auto"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-menu
                  v-model="start_date_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_date_format"
                      :label="$t('custom.start_period')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      class="mt-5"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_date"
                    @input="start_date_menu = false"
                    first-day-of-week="1"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="end_date_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_date_format"
                      :label="$t('custom.end_period')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :class="isMobile ? 'mt-5' : 'mt-5 ml-3'"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_date"
                    @input="end_date_menu = false"
                    first-day-of-week="1"
                  ></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <!-- <download-excel
                  :data="counts"
                  :fields="excel_fields"
                  class="my-auto"
                  v-if="counts.length > 0 && !isMobile"
                >
                  <v-icon class="ml-2" style="cursor: pointer" color="success">
                    mdi-file-excel-outline
                  </v-icon>
                </download-excel> -->
                <v-icon
                  v-if="(user_role == 'superadmin' || user_role == 'admin')"
                  @click="toXlsx()"
                  class="mx-2"
                  style="cursor: pointer"
                  color="success"
                >
                  mdi-file-excel-outline
                </v-icon>
              </v-flex>
            </v-toolbar>
          </template>

          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="item in props.items"
                :key="item.car_id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <v-card>
                  <v-card-title class="subheading font-weight-bold">
                    <h5>{{ item.code }}</h5>
                    <v-icon
                      v-if="checkAdmin()"
                      class="ml-2"
                      small
                      color="red darken-2"
                      @click="deleteCarFromInvestor(item.car_id)"
                      v-tooltip="{
                        content: $t('partners.remove_car'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 1000,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-delete-forever
                    </v-icon>
                  </v-card-title>

                  <v-divider></v-divider>

                  <v-list dense>
                    <v-list-item
                      v-for="(key, index) in counts_groups"
                      :key="index"
                    >
                      <v-list-item-content
                        :class="selectColorGroup(key.value)"
                      >
                        {{ key.text }}:
                      </v-list-item-content>
                      <v-list-item-content>
                        {{ countsByKey(item.counts, key.value) }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </template>

          <template v-slot:footer>
            <v-row class="mt-2" align="center" justify="center">
              <span class="grey--text">{{ $t("other.car_on_page") }}</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    text
                    color="primary"
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                  >
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-spacer></v-spacer>

              <span class="mr-4 grey--text">
                {{
                  $t("other.page_number", {
                    page: page,
                    numberOfPages: numberOfPages,
                  })
                }}
              </span>
              <v-btn
                fab
                dark
                color="blue darken-3"
                class="mr-1"
                @click="formerPage"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                fab
                dark
                color="blue darken-3"
                class="ml-1"
                @click="nextPage"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template>
        </v-data-iterator>
        <v-flex v-else class="text-center">
          <h2 class="mb-3">{{ $t("other.no_added_cars") }}</h2>
        </v-flex>
        <v-flex md12 class="mt-4">
          <v-card>
            <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'" >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('custom.search')"
                single-line
                clearable
                hide-details
              ></v-text-field>
            </v-toolbar>
            <v-data-table
              :headers="headers_counts"
              :items="investors_counts"
              :search="search_count"
              :loading="isLoading"
              :loading-text="$t('custom.loading_table')"
              :items-per-page="20"
              :sort-by="['id']"
              mobile-breakpoint="100"
              :class="!isMobile ? '' : 'is-mobile'"
              dense
              :sort-desc="[true]"
              :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [20, 50, 100, -1],
              }"
            >
              <template slot="item" slot-scope="props">
                <tr
                  :class="
                    props.item.operation && props.item.sum < 0
                      ? 'red--text'
                      : ''
                  "
                >
                  <td class="text-center">{{ props.item.id }}</td>
                  <td class="text-center" nowrap>
                    {{ returnDate(props.item.created_at) }}
                  </td>
                  <!-- <td class="justify-center layout px-0">
                      <v-icon class="mr-2" @click="editCount(props.item)">
                        mdi-pencil-outline
                      </v-icon>
                    </td> -->
                  <td class="text-center">
                    {{ translateCategory(props.item.group) }}
                  </td>
                  <td class="text-center">{{ props.item.description }}</td>
                  <td class="text-center">
                    {{ props.item.sum }}{{ currency }}
                  </td>
                  <td class="text-center" v-if="props.item.operation">
                    <v-icon class="text-center" style="color: green">
                      mdi-plus-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="!props.item.operation">
                    <v-icon class="text-center" style="color: tomato">
                      mdi-minus-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cash">
                    <v-icon
                      class="text-center"
                      color="success"
                      v-tooltip="{
                        content: $t('tooltips.cash_count'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 1000,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-cash
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cashless">
                    <v-icon
                      class="text-center"
                      color="warning"
                      v-tooltip="{
                        content: $t('tooltips.terminal_count'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 1000,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-contactless-payment-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.entity">
                    <v-icon
                      class="text-center"
                      color="secondery"
                      v-tooltip="{
                        content: $t('tooltips.entity_count'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 1000,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-bank
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cash_card">
                    <v-icon
                      class="text-center"
                      color="info"
                      v-tooltip="{
                        content: $t('money.card_to_card'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 1000,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-credit-card-check-outline
                    </v-icon>
                  </td>
                  <td
                    class="text-left"
                    nowrap
                    v-if="
                      props.item.booking_id &&
                      props.item.car_id &&
                      props.item.car_code
                    "
                  >
                    <p>
                      {{ $t("bookings.booking") }}:
                      <router-link
                        :to="{ path: `/bookings/${props.item.booking_id}` }"
                      >
                        {{ props.item.booking_id }}
                      </router-link>
                    </p>
                    <p>
                      {{ $t("custom.car") }}:
                      <router-link
                        :to="{ path: `/cars/${props.item.car_id}` }"
                      >
                        {{ props.item.car_code }}
                      </router-link>
                    </p>
                  </td>
                  <td
                    class="text-center"
                    v-else-if="props.item.car_id && props.item.car_code"
                  >
                    <router-link
                      :to="{ path: `/cars/${props.item.car_id}` }"
                      >{{ props.item.car_code }}</router-link
                    >
                  </td>
                  <td class="text-center" v-else-if="props.item.source">
                    {{ props.item.source }}
                  </td>
                  <td class="text-center" v-else-if="props.item.investor_id">
                    <router-link
                      :to="{ path: `/investors/${props.item.investor_id}` }"
                      >{{ $t("employers.partner") }} {{ $t("custom.number")
                      }}{{ props.item.investor_id }}</router-link
                    >
                  </td>
                  <td class="text-center" v-else-if="props.item.debt_id">
                    {{ $t("debts.debt") }} {{ $t("custom.number")
                    }}{{ props.item.debt_id }}
                  </td>
                  <td class="text-center" v-else-if="props.item.agent_id">
                    {{ $t("agents.agent") }} {{ $t("custom.number") }}
                    <router-link
                      :to="{ path: `/agent_card/${props.item.agent_id}` }"
                    >
                      {{ props.item.agent_id }}
                    </router-link>
                  </td>
                  <td class="text-center" v-else>-</td>
                </tr>
              </template>
              <template slot="no-data">
                {{ $t("custom.no_data_in_table") }}
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="mdi-alert"
              >
                {{ $t("tables.no_search_result", { msg: search }) }}
              </v-alert>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import PullToRefresh from "pulltorefreshjs";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { saveAs } from "file-saver";
import xlsx from "json-as-xlsx";
// moment.locale("ru");
export default {
  data() {
    var self = this;
    return {
      investor: null,
      investors_data: [],
      paidCar: null,
      count: {},
      counts: [],
      cars: [],
      investors_counts: [],
      itemsPerPageArray: [4, 8, 12],
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: "name",
      counts_groups: [
        { text: this.$t("groups_counts.rent"), value: "Оплата аренды" },
        {
          text: this.$t("groups_counts.partners_payments"),
          value: "Выплаты партнёрам",
        },
        { text: this.$t("groups_counts.salary"), value: "Зарплата" },
        { text: this.$t("groups_counts.repair"), value: "Ремонт" },
        { text: this.$t("groups_counts.service"), value: "Обслуживание" },
        { text: this.$t("groups_counts.parts"), value: "Запчасти" },
        { text: this.$t("groups_counts.insurance_main"), value: "Страховка" },
        {
          text: this.$t("groups_counts.common_expences"),
          value: "Накладные расходы",
        },
        {
          text: this.$t("groups_counts.transport_expences"),
          value: "Транспортные расходы",
        },
        { text: this.$t("groups_counts.tax"), value: "Налоги" },
        { text: this.$t("groups_counts.fines_main"), value: "Штрафы" },
        {
          text: this.$t("groups_counts.rent_property"),
          value: "Аренда имущества",
        },
        {
          text: this.$t("groups_counts.clean_main"),
          value: "Мойка автомобилей",
        },
        { text: this.$t("groups_counts.fuel_main"), value: "Топливо" },
        { text: this.$t("groups_counts.ads"), value: "Реклама" },
        {
          text: this.$t("groups_counts.sell_property"),
          value: "Продажа имущества",
        },
        {
          text: this.$t("groups_counts.buy_property"),
          value: "Покупка имущества",
        },
        {
          text: this.$t("groups_counts.other_expences"),
          value: "Прочие расходы",
        },
        {
          text: this.$t("groups_counts.other_incomes"),
          value: "Прочие доходы",
        },
        {
          text: this.$t("groups_counts.delivery"),
          value: "Доплата за доставку",
        },
        { text: this.$t("groups_counts.checkout"), value: "Доплата за приём" },
        { text: this.$t("groups_counts.fuel"), value: "Доплата за топливо" },
        { text: this.$t("groups_counts.clean"), value: "Доплата за мойку" },
        {
          text: this.$t("groups_counts.equipment"),
          value: "Доплата за оборудование",
        },
        {
          text: this.$t("groups_counts.add_time"),
          value: "Доплата за дополнительное время",
        },
        {
          text: this.$t("groups_counts.damages"),
          value: "Доплата за повреждения",
        },
        { text: this.$t("groups_counts.fines"), value: "Доплата за штрафы" },
        { text: this.$t("groups_counts.other"), value: "Доплата за другое" },
        {
          text: this.$t("groups_counts.mileage"),
          value: "Доплата за превышение пробега",
        },
        {
          text: this.$t("groups_counts.insurance"),
          value: "Доплата за страховку",
        },
        {
          text: this.$t("groups_counts.add_drivers"),
          value: "Доплата за доп водителей",
        },
        {
          text: this.$t("groups_counts.internal"),
          value: "Внутренние переводы",
        },
        { text: this.$t("groups_counts.deposit"), value: "Залог" },
      ],
      cars: [],
      selected_cars: [],
      search: null,
      search_car: null,
      search_count: null,
      start_date: moment().subtract(1, "month").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      start_date_format: moment().subtract(1, "month").format("DD-MM-YYYY"),
      end_date_format: moment().format("DD-MM-YYYY"),
      start_date_menu: false,
      end_date_menu: false,
      editInvestorDialog: false,
      investor_types: [
        { text: this.$t("investor_types.income"), value: "Доходы" },
        {
          text: this.$t("investor_types.income_expences"),
          value: "Доходы - Расходы",
        },
      ],
      headers_counts: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.group"), value: "group", align: "center" },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        {
          text: this.$t("custom.operation"),
          value: "operation",
          align: "center",
        },
        { text: this.$t("custom.type"), value: "cashless", align: "center" },
        { text: this.$t("custom.source"), sortable: false, align: "center" },
      ],
      selectCarDialog: false,
      paymentDialog: false,
      isMobile: false,
      isLoadingCars: false,
      isLoading: false,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      if (
        this.$store.getters.getCurrentUser.role == "partner"
      ) {
        this.getCounts();
      } else {
        this.$router.push("/dashboard");
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          title: this.$t("errors.not_permitted_title"),
        });
      }
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    start_date() {
      if (this.start_date) {
        this.start_date_format = moment(this.start_date, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );

        if (this.start_date && this.end_date) {
          this.getCounts();
        }
      }
    },
    end_date() {
      if (this.end_date) {
        this.end_date_format = moment(this.end_date, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );

        if (this.start_date && this.end_date) {
          this.getCounts();
        }
      }
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.investors_data.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.counts_groups.filter((key) => key !== "Name");
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    checkWriteFile() {
      return Capacitor.isNativePlatform() && Filesystem.checkPermissions();
    },
    requestPermissionsWriteFile() {
      return Filesystem.requestPermissions();
    },
  },
  methods: {
    getCounts() {
      if (this.start_date && this.end_date) {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(
            `/api/v1/investor_analytics?start_date=${this.start_date}&end_date=${this.end_date}`,
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.investor = response.data.investor;
            this.investors_data = response.data.data;
            this.counts = [];
            response.data.data.forEach((item) => {
              this.counts = this.counts.concat(item.counts);
            });
            this.investors_counts = response.data.investors_counts;
          })
          .catch((error) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.info_load"),
              text: this.error,
            });
            this.setError(error, this.$t("errors.info_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.not_enough_data"),
        });
      }
    },
    countsByKey(counts, key) {
      let filtered_counts_plus = counts.filter(
        (count) => count.group == key && count.operation == true
      );
      let filtered_counts_minus = counts.filter(
        (count) => count.group == key && count.operation == false
      );

      const sumPropertyValue = (items, prop) =>
        items.reduce((a, b) => a + b[prop], 0);
      const plus_total = sumPropertyValue(filtered_counts_plus, "sum");
      const minus_total = sumPropertyValue(filtered_counts_minus, "sum");

      if (plus_total && minus_total) {
        return `+ ${plus_total} | - ${minus_total}`;
      } else if (plus_total && !minus_total) {
        return `+ ${plus_total}`;
      } else if (!plus_total && minus_total) {
        return `- ${minus_total}`;
      } else {
        return "-";
      }
    },
    saveSelectedCars() {
       if (this.user_role != "guest" && this.user_role != "partner") {
        if (this.selected_cars && this.selected_cars.length > 0) {
          this.axios
            .post(
              `/api/v1/add_cars_to_investor`,
              {
                investor_id: this.investor.id,
                cars_ids: this.selected_cars,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.selectCarDialog = false;

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("partners.added_cars"),
                text: this.$t("partners.added_cars_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.car_partner"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.car_partner"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("errors.car_partner"),
            text: this.$t("errors.car_partner_not_select"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    saveInvestorCount() {
       if (this.user_role != "guest" && this.user_role != "partner") {
        let count = this.count;
        let self = this;
        if (
          count.cash > 0 ||
          count.cashless > 0 ||
          count.cash_card > 0 ||
          count.entity > 0 ||
          count.cash < 0 ||
          count.cashless < 0 ||
          count.cash_card < 0 ||
          (count.entity < 0 && this.investor)
        ) {
          this.isLoading = true;
          this.axios
            .post(
              "/api/v1/counts",
              {
                counts: {
                  group: "Выплаты партнёрам",
                  operation: false,
                  description: count.description,
                  sum: count.sum,
                  cash: count.cash,
                  cashless: count.cashless,
                  cash_card: count.cash_card,
                  entity: count.entity,
                  investor_id: this.investor.id,
                  car_id: count.car_id,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.count = {};
              this.investors_counts.push(response.data);
              this.paymentDialog = false;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("counts.add_payment"),
                text: this.$t("partners.add_payment"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.partner_payment"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.partner_payment"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    toXlsx() {
      if (this.user_role != "guest") {
        let self = this;
        let data = [
          {
            sheets: "Payments",
            columns: [
              { label: "ID", value: "id" },
              { label: this.$t("custom.created_at"), value: (row) => {
                  return moment(row.created_at).format("DD.MM.YYYY H:mm");
                }
              },
              { label: this.$t("custom.car"), value: "car_code" },
              { label: this.$t("custom.amount"), value: "sum" },
              { label: this.$t("custom.operation"), value: (row) => {
                  if (row.operation) {
                    return "+";
                  } else {
                    return "-";
                  }
                }
              },
              { label: this.$t("counts.about"), value: "description" },
              { label: this.$t("money.terminal"), value: "cashless" },
              { label: this.$t("money.card_to_card"), value: "cash_card" },
              { label: this.$t("money.cash"), value: "cash" },
              { label: this.$t("money.from_entity"), value: "entity" },
              { label: this.$t("custom.group"), value: (row) => {
                return this.translateCategory(row.group);
              }},
              { label: this.$t("bookings.booking"), value: "booking_id" },
              { label: this.$t("counts.completed"), value: "completed" },
            ],
            content: this.counts,
          },
        ];
        let settings = {
          fileName: "cars_payments", // Name of the resulting spreadsheet
          extraLength: 3, // A bigger number means that columns will be wider
          writeMode: "write", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
          writeOptions: {
            bookType: "xlsx",
            type: "array",
          }, // Style options from https://docs.sheetjs.com/docs/api/write-options
          RTL: false, // Display the columns from right-to-left (the default value is false)
        };
        let wbout = xlsx(data, settings);
        var blob_data = new Blob([new Uint8Array(wbout)], {type:"application/octet-stream"});
        if (!this.isNative) {
          saveAs(blob_data, 'cars_payments.xlsx');
        } else {
          if (Filesystem.checkPermissions()) {
            const getBase64FromBlob = async (blob) => {
              return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                  const base64data = reader.result;
                  resolve(base64data);
                }
              });
            }
            getBase64FromBlob(blob_data).then(
              (base64) => {
                // save file
                async function writeFile() {
                  return await Filesystem.writeFile({
                    path: 'cars_payments.xlsx',
                    data: base64,
                    directory: Directory.Documents,
                  });
                };
                let file_uri = null
                let writedFile = writeFile().then((getUriResult) => {
                  // share file opened file
                  let shareFile = async () => {
                    await Share.share({
                      title: 'Download document',
                      files: [getUriResult.uri],
                    });
                  };
                  let sharedFile = shareFile();
                });
              }
            );
          } else {
            Filesystem.requestPermissions();
          }
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    selectColorGroup(key) {
      if (key == "Оплата аренды") {
        return "success--text";
      } else if (key == "Выплаты партнёрам") {
        return "red--text";
      }
    },
    saveInvestor() {
       if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        if (this.investor.name) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/investors/${this.$route.params.id}`,
              {
                investors: {
                  name: self.investor.name,
                  percent: self.investor.percent,
                  mode: self.investor.mode,
                  description: self.investor.description,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then(() => {
              this.editInvestorDialog = false;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("partners.edited"),
                text: this.$t("partners.edited_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.partner_edit"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.partner_edit"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteCarFromInvestor(car_id) {
       if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .patch(
                `/api/v1/cars/${car_id}`,
                {
                  cars: {
                    investor_id: null,
                  },
                },
                {
                  headers: {
                    Authorization: self.$store.getters.getAuthToken,
                  }
                }
              )
              .then((response) => {
                this.investors_data.forEach((count, index) => {
                  if (count.car_id == car_id) {
                    this.investors_data.splice(index, 1);
                  }
                });
                this.$swal(
                  this.$t("custom.deleted"),
                  "success"
                );
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.error"))
              )
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    loadCars() {
      this.isLoading = true;
      this.axios
        .get("/api/v1/index_for_new_count", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.cars.push(element.attributes);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.cars_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    translateMode(mode) {
      let group_text = "";
      switch (mode) {
        case "Доходы":
          group_text = this.$t("investor_types.income");
          break;
        case "Доходы - Расходы":
          group_text = this.$t("investor_types.income_expences");
          break;
      }
      return group_text;
    },
    translateCategory(category) {
      let group_text = "";
      switch (category) {
        case "Оплата аренды":
          group_text = this.$t("groups_counts.rent");
          break;
        case "Доплата за доставку":
          group_text = this.$t("groups_counts.delivery");
          break;
        case "Доплата за приём":
          group_text = this.$t("groups_counts.checkout");
          break;
        case "Доплата за топливо":
          group_text = this.$t("groups_counts.fuel");
          break;
        case "Доплата за мойку":
          group_text = this.$t("groups_counts.clean");
          break;
        case "Доплата за оборудование":
          group_text = this.$t("groups_counts.equipment");
          break;
        case "Доплата за дополнительное время":
          group_text = this.$t("groups_counts.add_time");
          break;
        case "Доплата за повреждения":
          group_text = this.$t("groups_counts.damages");
          break;
        case "Доплата за штрафы":
          group_text = this.$t("groups_counts.fines");
          break;
        case "Доплата за другое":
          group_text = this.$t("groups_counts.other");
          break;
        case "Доплата за превышение пробега":
          group_text = this.$t("groups_counts.mileage");
          break;
        case "Доплата за страховку":
          group_text = this.$t("groups_counts.insurance");
          break;
        case "Доплата за доп водителей":
          group_text = this.$t("groups_counts.add_drivers");
          break;
        case "Ремонт":
          group_text = this.$t("groups_counts.repair");
          break;
        case "Обслуживание":
          group_text = this.$t("groups_counts.service");
          break;
        case "Запчасти":
          group_text = this.$t("groups_counts.parts");
          break;
        case "Страховка":
          group_text = this.$t("groups_counts.insurance_main");
          break;
        case "Накладные расходы":
          group_text = this.$t("groups_counts.common_expences");
          break;
        case "Транспортные расходы":
          group_text = this.$t("groups_counts.transport_expences");
          break;
        case "Налоги":
          group_text = this.$t("groups_counts.tax");
          break;
        case "Штрафы":
          group_text = this.$t("groups_counts.fines_main");
          break;
        case "Аренда имущества":
          group_text = this.$t("groups_counts.rent_property");
          break;
        case "Мойка автомобилей":
          group_text = this.$t("groups_counts.clean_main");
          break;
        case "Топливо":
          group_text = this.$t("groups_counts.fuel_main");
          break;
        case "Реклама":
          group_text = this.$t("groups_counts.ads");
          break;
        case "Зарплата":
          group_text = this.$t("groups_counts.salary");
          break;
        case "Залог":
          group_text = this.$t("groups_counts.deposit");
          break;
        case "Внутренние переводы":
          group_text = this.$t("groups_counts.internal");
          break;
        case "Выплаты партнёрам":
          group_text = this.$t("groups_counts.partners_payments");
          break;
        case "Продажа имущества":
          group_text = this.$t("groups_counts.sell_property");
          break;
        case "Покупка имущества":
          group_text = this.$t("groups_counts.buy_property");
          break;
        case "Прочие расходы":
          group_text = this.$t("groups_counts.other_expences");
          break;
        case "Прочие доходы":
          group_text = this.$t("groups_counts.other_incomes");
          break;
        case "Сервисные сборы":
          group_text = this.$t("groups_counts.service_fees");
          break;
        case "Пополнение баланса клиента":
          group_text = this.$t("groups_counts.top_up_client_balance");
          break;
        case "Выплаты агентам":
          group_text = this.$t("groups_counts.agent_payments");
          break;
      }
      return group_text;
    },
    returnDate(item) {
      return moment(item, "YYYY-MM-DD").format("LL");
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {},
};
</script>
