<template>
  <form>
    <div class="image-container mb-3" v-if="previewPath">
      <img :src="previewPath" alt="Uploaded Image Preview" />
    </div>
    <div class="form-group">
      <div ref="dashboardContainer"></div>
    </div>
    <!-- <v-btn :disabled="disabled" @click.prevent="confirmUpload" class="btn btn-primary btn-block mb-2">Confirm upload</v-btn> -->
  </form>
</template>

<script>
import Uppy from "@uppy/core";
// import XHRUpload from "@uppy/xhr-upload";
import AwsS3Multipart from "@uppy/aws-s3-multipart";

import Dashboard from "@uppy/dashboard";
// import Form from "@uppy/form";
import Russian from "@uppy/locales/lib/ru_RU";
import English from "@uppy/locales/lib/en_US";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

export default {
  props: {
    maxFileSizeInBytes: {
      type: Number,
      required: true,
    },
    width: {
      required: true,
    },
    height: {
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      payload: null,
      previewPath: null,
      disabled: true,
      isLoading: false,
    };
  },
  mounted() {
    this.instantiateUppy();
  },
  beforeDestroy() {
    this.uppy.close();
  },
  methods: {
    instantiateUppy() {
      let self = this;
      this.uppy = Uppy({
        debug: true,
        locale: this.$i18n.locale === "ru" ? Russian : English,
        autoProceed: true,
        allowMultipleUploads: false,
        restrictions: {
          maxFileSize: this.maxFileSizeInBytes,
          minNumberOfFiles: 1,
          maxNumberOfFiles: 1,
          allowedFileTypes: [
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ],
          // allowedFileTypes: ['image/*', 'application/doc', 'application/docx', 'application/pdf']
        },
      })
        .use(Dashboard, {
          hideUploadButton: true,
          theme: this.theme,
          inline: true,
          width: this.width,
          height: this.height,
          target: this.$refs.dashboardContainer,
          replaceTargetContent: true,
          showProgressDetails: true,
          browserBackButtonClose: true,
          locale: {
            strings: {
              poweredBy2: "",
              // // browseFiles: "выберите"
            },
          },
        })
        .use(AwsS3Multipart, {
          limit: 1,
          // companionUrl: "http://localhost:3000/"
          companionUrl: "https://rentprog.net/",
        });

      this.uppy.on("complete", (event) => {
        if (event.successful[0] !== undefined) {
          // this.payload = event.successful[0].response.body.path;
          // const imageName = uuid.v1();
          // let fileExtention = file.name.split(".")[1];
          console.log(event.successful[0].s3Multipart.key);
          this.disabled = false;
          this.axios
            .post(
              `/api/v1/cars_doc_templates/${this.$route.params.id}`,
              {
                cars_doc_templates: {
                  name: event.successful[0].s3Multipart.key,
                  url: `https://rentprog.storage.yandexcloud.net/${event.successful[0].s3Multipart.key}`,
                },
              },
              {
                headers: {
                  Authorization: self.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              console.log(response);
              // this.$store.commit(
              //   "setProlongTemplateUrl",
              //   `https://rentprog.storage.yandexcloud.net/${event.successful[0].s3Multipart.key}`
              // );
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.error"));
            });
        }
      });

      const uploadedFileData = (file, response) => {
        return JSON.stringify(response.body);
      };
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
    },
    updatePreviewPath({ path }) {
      this.previewPath = path;

      return this;
    },
    resetUploader() {
      this.uppy.reset();
      this.disabled = true;

      return this;
    },
    confirmUpload() {
      if (this.payload) {
        this.disabled = true;
        axios
          .post("/store", { file: this.payload })
          .then(({ data }) => {
            this.updatePreviewPath(data).resetUploader();
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: "Файлы загружены!",
              text: "Вы успешно загрузили файлы!",
            });
          })
          .catch((err) => {
            console.error(err);

            this.resetUploader();
          });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "error",
          title: "Ошибка при загрузке!",
          text: this.error,
        });
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}

.image-container>img {
  width: inherit;
  height: inherit;
}
</style>
