<template>
  <span v-resize="checkMobile">
    <v-overlay v-if="!isLoading" :value="includedInPlan('day_plan')" class="px-5">
      <v-alert
        color="info"
        border="top"
        class="mx-2"
      >
        <v-row>
          <v-col cols="12" class="text-right mt-0 mb-0 pb-0">
            <v-icon @click="goBack">mdi-close</v-icon>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-icon size="50">mdi-information</v-icon>
          </v-col>
          <v-col cols="12" class="text-center font-bold">
            {{ $t('custom.free_tariff_warning') }}
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn @click="subscribeToPaidTariff()" color="success" class="mx-1 my-1" :loading="isLoadingSubscribeToPaidTariff">
              {{ $t('custom.to_paid_plan') }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn small text @click="toTariffOnSite()" class="mx-1 my-1">
              {{ $t('custom.tariff_more_info') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-overlay>
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-toolbar v-if="!isMobile" text :color="$vuetify.theme.dark ? '' : 'white'" class="start-tour-timeline-1">
      <h2>{{ $t("custom.day_plan") }}</h2>
      <!-- Date select -->
      <v-row align="center" class="ml-2 d-flex align-start">
        <v-menu offset-y :close-on-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-bind="attrs" v-on="on" readonly :dense="isMobile" v-model="sort_date_for_input"
              prepend-icon="mdi-calendar-clock" style="max-width: 240px" :label="$t('custom.bookings_for_day')"
              single-line hide-details></v-text-field>
          </template>
          <vc-date-picker v-model="sort_date" mode="date" :min-date="new Date()" :attributes="attrs_calendar"
            :masks="{ input: 'DD-MM-YYYY' }" :locale="$i18n.locale">
          </vc-date-picker>
        </v-menu>
        <v-icon class="mr-2 mt-2 text-left" v-if="sort_date" @click="clearDate()">
          mdi-close
        </v-icon>
      </v-row>
      <v-btn text v-tooltip="{
        content: $t('timeline.deposits_tooltip'),
        placement: 'bottom-center',
        delay: {
          show: 500,
          hide: 500,
        },
      }">
        {{ $t('timeline.deposits') }}: {{ deposits }}
      </v-btn>
      <v-btn text v-tooltip="{
        content: $t('timeline.incomes_tooltip'),
        placement: 'bottom-center',
        targetClasses: ['it-has-a-tooltip'],
        delay: {
          show: 500,
          hide: 500,
        },
      }">
        {{ $t('timeline.incomes') }}: {{ incomes }}
      </v-btn>
      <v-btn icon @click="show_checkins = !show_checkins;" v-tooltip="{
        content: $t('timeline.only_checkins'),
        placement: 'bottom-center',
        targetClasses: ['it-has-a-tooltip'],
        delay: {
          show: 500,
          hide: 500,
        },
      }">
        <v-icon :color="show_checkins ? 'warning' : ''">mdi-airplane-takeoff</v-icon>
      </v-btn>
      <v-btn icon @click="show_checkouts = !show_checkouts;" v-tooltip="{
        content: $t('timeline.only_checkouts'),
        placement: 'bottom-center',
        targetClasses: ['it-has-a-tooltip'],
        delay: {
          show: 500,
          hide: 500,
        },
      }">
        <v-icon :color="show_checkouts ? 'warning' : ''">mdi-home</v-icon>
      </v-btn>
      <v-btn icon @click="show_all_bookings = !show_all_bookings;" v-tooltip="{
        content: $t('timeline.all_bookings'),
        placement: 'bottom-center',
        targetClasses: ['it-has-a-tooltip'],
        delay: {
          show: 500,
          hide: 500,
        },
      }">
        <v-icon :color="show_all_bookings ? 'warning' : ''">mdi-all-inclusive-box-outline</v-icon>
      </v-btn>
      <div :class="'pulse mt-3 mx-2 ' + (connected ? 'pulse_success' : 'pulse_error')
        " v-tooltip="{
          content: connected
            ? $t('custom.connected')
            : $t('custom.disconnected'),
          placement: 'bottom-center',
          classes: ['info'],
          targetClasses: ['it-has-a-tooltip'],
          delay: {
            show: 500,
            hide: 500,
          },
        }"></div>
      <v-btn v-if="!isIos" icon :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
        }/${$root.$i18n.locale}/categories/5/guides/18`" target="_blank">
        <v-icon>mdi-help-circle</v-icon>
      </v-btn>
    </v-toolbar>
    <v-toolbar height="150px" v-if="isMobile" text :color="$vuetify.theme.dark ? '' : 'white'"
      class="mb-2 start-tour-timeline-1">
      <v-flex md12 class="text-center" justify-center>
        <v-flex md12 class="mt-2 d-flex">
          <v-menu offset-y :close-on-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-bind="attrs" v-on="on" readonly :dense="isMobile" v-model="sort_date_for_input"
                :attributes="attrs_calendar" prepend-icon="mdi-calendar-clock" :label="$t('custom.bookings_on_day')"
                single-line hide-details></v-text-field>
            </template>
            <vc-date-picker v-model="sort_date" mode="date" :masks="{ input: 'DD-MM-YYYY' }" :locale="$i18n.locale">
            </vc-date-picker>
          </v-menu>
          <v-spacer></v-spacer>
          <v-icon class="mr-2 mt-2 text-left" v-if="sort_date" @click="clearDate()">
            mdi-close
          </v-icon>
          <div :class="'pulse mt-3 mx-2 ' + (connected ? 'pulse_success' : 'pulse_error')
            " v-tooltip="{
              content: connected
                ? $t('custom.connected')
                : $t('custom.disconnected'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }"></div>
        </v-flex>
        <v-flex md12 class="d-flex text-center justify-center align-center">
          <v-btn icon @click="show_checkins = !show_checkins;" v-tooltip="{
            content: $t('timeline.only_checkins'),
            placement: 'bottom-center',
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }">
            <v-icon :color="show_checkins ? 'warning' : ''">mdi-airplane-takeoff</v-icon>
          </v-btn>
          <v-btn icon @click="show_checkouts = !show_checkouts;" v-tooltip="{
            content: $t('timeline.only_checkouts'),
            placement: 'bottom-center',
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }">
            <v-icon :color="show_checkouts ? 'warning' : ''">mdi-home</v-icon>
          </v-btn>
          <v-btn icon @click="show_all_bookings = !show_all_bookings;" v-tooltip="{
            content: $t('timeline.all_bookings'),
            placement: 'bottom-center',
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }">
            <v-icon :color="show_all_bookings ? 'warning' : ''">mdi-all-inclusive-box-outline</v-icon>
          </v-btn>
        </v-flex>
        <v-flex md12 class="d-flex text-center justify-center align-center">
          <v-btn v-if="show_deposits" @click="show_deposits = !show_deposits;" text v-tooltip="{
            content: $t('timeline.deposits_tooltip'),
            placement: 'bottom-center',
            delay: {
              show: 500,
              hide: 500,
            },
          }">
            {{ $t('timeline.deposits') }}: {{ deposits }}
          </v-btn>
          <v-btn v-else @click="show_deposits = !show_deposits;" text v-tooltip="{
            content: $t('timeline.incomes_tooltip'),
            placement: 'bottom-center',
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }">
            {{ $t('timeline.incomes') }}: {{ incomes }}
          </v-btn>
        </v-flex>
      </v-flex>
    </v-toolbar>
    <v-timeline :dense="$vuetify.breakpoint.smAndDown" v-if="bookings && bookings.length > 0">
      <v-timeline-item v-for="booking in (show_all_bookings ? all_bookings : bookings)" :key="booking.id"
        :right="selectBookingState(booking)" :left="!selectBookingState(booking)"
        :color="!selectBookingState(booking) ? 'success' : 'primary'" :icon="!selectBookingState(booking) ? 'mdi-home' : 'mdi-airplane-takeoff'
          ">
        <span slot="opposite">{{
          !selectBookingState(booking)
            ? $t("custom.checkout")
            : $t("custom.checkin")
        }}</span>
        <v-card class="elevation-2" @dblclick="replaceToBooking(booking)">
          <v-expansion-panels>
            <v-expansion-panel :class="!booking.technical ? '' : 'blue-grey lighten-4'">
              <v-expansion-panel-header :class="!selectBookingState(booking) ? 'text-left' : 'text-right'
                " :style="isMobile ? 'padding: 8px 6px;' : ''">
                <span>
                  {{ returnTime(booking) }}&nbsp;
                  {{
                    !selectBookingState(booking)
                      ? booking.location_end
                      : booking.location_start
                  }}
                  {{ booking.car_code }}
                  <!-- <v-flex class="d-flex text-center justify-center"> -->
                  <v-menu offset-y v-if="booking.state == 'Новая'">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="mx-1" color="primary" v-tooltip="{
                          content: $t('bookings.new'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                          mdi-new-box
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item v-for="(state, index) in bookings_states" :key="index"
                        @click="saveBookingState(state.value, booking.id)">
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="booking.state == 'Подтверждена'">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="mx-1" color="success" v-tooltip="{
                          content: $t('bookings.approved'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                          mdi-check-all
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item v-for="(state, index) in bookings_states" :key="index"
                        @click="saveBookingState(state.value, booking.id)">
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="booking.state == 'Не подтверждена'">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="mx-1" color="warning" v-tooltip="{
                          content: $t('bookings.not_approved'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                          mdi-cellphone-basic
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item v-for="(state, index) in bookings_states" :key="index"
                        @click="saveBookingState(state.value, booking.id)">
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="booking.state == 'В обработке'">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="mx-1" color="primary" v-tooltip="{
                          content: $t('bookings.in_work'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                          mdi-autorenew
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item v-for="(state, index) in bookings_states" :key="index"
                        @click="saveBookingState(state.value, booking.id)">
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="booking.state == 'Отказ клиента'">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="mx-1" color="danger" v-tooltip="{
                          content: $t('bookings.declined'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                          mdi-account-remove-outline
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item v-for="(state, index) in bookings_states" :key="index"
                        @click="saveBookingState(state.value, booking.id)">
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="booking.state == 'Нет машин'">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="mx-1" color="secondary" v-tooltip="{
                          content: $t('bookings.declined_free_cars'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                          mdi-car-off
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item v-for="(state, index) in bookings_states" :key="index"
                        @click="saveBookingState(state.value, booking.id)">
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="booking.state == 'Недозвон'">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="warning--text">
                        <v-icon class="mx-1" color="warning" v-tooltip="{
                          content: $t('bookings.not_called'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                          mdi-phone-off-outline
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item v-for="(state, index) in bookings_states" :key="index"
                        @click="saveBookingState(state.value, booking.id)">
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="booking.state == 'Ожидает ответа клиента'">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="mx-1" color="pink" v-tooltip="{
                          content: $t('bookings.waiting_for_answer'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                          mdi-account-question-outline
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item v-for="(state, index) in bookings_states" :key="index"
                        @click="saveBookingState(state.value, booking.id)">
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="booking.state == 'Ожидает оплаты'">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="mx-1" color="lime" v-tooltip="{
                          content: $t('bookings.waiting_for_pay'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                          mdi-currency-usd-off
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item v-for="(state, index) in bookings_states" :key="index"
                        @click="saveBookingState(state.value, booking.id)">
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="booking.state == 'Активная'">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="mx-1" color="light-blue" v-tooltip="{
                          content: $t('bookings.active_state'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                          mdi-alpha-a-circle-outline
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item v-for="(state, index) in bookings_states" :key="index"
                        @click="saveBookingState(state.value, booking.id)">
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="booking.state == 'Отмена'">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="mx-1" color="brown" v-tooltip="{
                          content: $t('bookings.canceled_state'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                          mdi-cancel
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item v-for="(state, index) in bookings_states" :key="index"
                        @click="saveBookingState(state.value, booking.id)">
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="booking.state == 'Отъездила'">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="mx-1" color="light-green" v-tooltip="{
                          content: $t('bookings.ride_true'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                          mdi-garage-variant
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item v-for="(state, index) in bookings_states" :key="index"
                        @click="saveBookingState(state.value, booking.id)">
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="booking.state == 'Вернуть залог'">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="mx-1" color="blue" v-tooltip="{
                          content: $t('bookings.waiting_for_deposit'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                          mdi-keyboard-return
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item v-for="(state, index) in bookings_states" :key="index"
                        @click="saveBookingState(state.value, booking.id)">
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="booking.state == 'Другое' || booking.state == null">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="mx-1" color="secondary" v-tooltip="{
                          content: $t('bookings.other_state'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                          mdi-head-question-outline
                        </v-icon>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item v-for="(state, index) in bookings_states" :key="index"
                        @click="saveBookingState(state.value, booking.id)">
                        <v-list-item-title>
                          {{ state.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <!-- </v-flex> -->
                  <v-icon v-if="
                    booking.chair ||
                    booking.chair_less_1_year ||
                    booking.booster ||
                    booking.navigator ||
                    booking.mp3 ||
                    booking.charger ||
                    booking.wifi_router ||
                    booking.description ||
                    !booking.technical_check_start ||
                    !booking.technical_check_end
                  " color="warning" class="mx-1" v-tooltip="{
                    content: $t('bookings.need_view'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }">
                    mdi-eye-circle-outline
                  </v-icon>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon v-if="!booking.responsible" color="secondary" class="mx-1" v-tooltip="{
                          content: $t('bookings.responsible'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">
                          mdi-account-question
                        </v-icon>
                        <span v-else class="mx-1">
                          <v-icon color="success" class="mr-1" v-tooltip="{
                            content: $t('bookings.responsible'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }">
                            mdi-account-check
                          </v-icon>
                          {{ booking.responsible }}
                        </span>
                      </span>
                    </template>
                    <v-list>
                      <v-list-item v-for="(responsible, index) in responsibles" :key="index"
                        @click="updateResponsible(booking.id, responsible.name, responsible.id)">
                        <v-list-item-title>
                          {{ responsible.name ? responsible.name : $t("bookings.no_responsible") }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <p>
                    {{ $t("custom.number") }}
                    <router-link :to="{ path: `/bookings/${booking.id}` }">
                      {{ booking.id }}
                    </router-link>
                    <v-flex>
                      <v-btn x-small :color="selectColorPaid(booking)" v-if="
                        (booking.counts && booking.counts.length > 0) ||
                        (booking.in_rent && !booking.technical)
                      " v-tooltip="{
                        content: `${calculatePayments(booking) != $t('bookings.paid')
                          ? $t('bookings.part_paid')
                          : $t('bookings.full_paid')
                          }`,
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }">{{ calculatePayments(booking) }}</v-btn>
                      <v-btn x-small outlined :to="{
                        name: 'BookingCard',
                        params: { id: booking.id },
                      }" color="primary" v-if="
                        !booking.in_rent &&
                        booking.counts &&
                        booking.counts.length === 0 &&
                        !booking.technical
                      " style="cursor: default" :disabled="!booking.active || booking.in_rent" v-tooltip="{
                          content: $t('bookings.not_paid'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }">{{ $t("bookings.not_paid_2") }}</v-btn>
                    </v-flex>
                  </p>
                  <p v-if="booking.responsible">
                    <v-icon color="success" class="mr-1" v-tooltip="{
                      content: $t('bookings.responsible'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-account-check </v-icon>{{ booking.responsible }}
                  </p>
                  <p>{{ translateBookingState(booking.state) }}</p>
                  <p v-if="!booking.entity">
                    <router-link :to="{ path: `/clients/${booking.client_id}` }">{{ booking.last_name }}
                      {{ booking.first_name }}
                      {{ booking.middle_name }}</router-link>
                  </p>
                  <p v-else>
                    {{ $t("custom.company") }}:
                    <router-link :to="{ path: `/clients/${booking.client_id}` }">{{ booking.entity_name }}</router-link>
                  </p>
                  <p>
                    <a v-if="booking.client && booking.client.phone" :href="'tel: ' + booking.client.phone">{{
                      booking.client.phone }}</a>
                  </p>
                  <p>
                    <router-link :to="{ path: `/cars/${booking.car_id}` }">{{ booking.car_name }} -
                      {{ booking.car_code }}</router-link>
                  </p>
                  <p>
                    <span :class="!selectBookingState(booking) ? '' : 'font-weight-bold'
                      ">{{ booking.start_date }} - </span><span :class="!selectBookingState(booking) ? 'font-weight-bold' : ''
                        ">
                      {{ booking.end_date }}</span>
                  </p>
                  <div v-if="
                    booking.chair ||
                    booking.chair_less_1_year ||
                    booking.booster ||
                    booking.navigator ||
                    booking.mp3 ||
                    booking.charger ||
                    booking.wifi_router
                  ">
                    {{ $t("bookings.add_equipment") }}
                    <div v-if="booking.chair">
                      {{ $t("bookings.baby_chair") }} -
                      {{ booking.chairs_quantity }}
                    </div>
                    <div v-if="booking.chair_less_1_year">
                      {{ $t("bookings.small_baby_chair") }} -
                      {{ booking.chairs_quantity }}
                    </div>
                    <div v-if="booking.booster">
                      {{ $t("bookings.booster") }} -
                      {{ booking.boosters_quantity }}
                    </div>
                    <div v-if="booking.navigator">
                      {{ $t("bookings.navigator") }}
                    </div>
                    <div v-if="booking.mp3">{{ $t("bookings.mp3") }}</div>
                    <div v-if="booking.charger">
                      {{ $t("bookings.charger") }}
                    </div>
                    <div v-if="booking.wifi_router">
                      {{ $t("bookings.wifi") }}
                    </div>
                    <div v-if="booking.other_equipment">
                      {{ booking.other_equipment }}
                    </div>
                  </div>
                  <p v-if="booking.description">
                    {{ booking.description }}
                  </p>
                  <p v-if="booking.technical_check_start_comment">
                    <v-icon small color="red"> mdi-airplane-takeoff </v-icon>
                    {{ booking.technical_check_start_comment }}
                  </p>
                  <p v-if="booking.technical_check_end_comment">
                    <v-icon small color="warning"> mdi-home </v-icon>
                    {{ booking.technical_check_end_comment }}
                  </p>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-timeline-item>
    </v-timeline>
    <v-card v-else class="mt-3">
      <v-card-title>
        <h3>
          {{ $t("custom.on") }} {{ sort_date_for_input }}
          {{ $t("custom.no_business") }}
        </h3>
      </v-card-title>
    </v-card>
  </span>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      bookings: [],
      all_bookings: [],
      non_filtered_bookings: [],
      non_filtered_all_bookings: [],
      responsibles: [],
      bookings_states: [
        { text: this.$t("bookings_states.new"), value: "Новая" },
        { text: this.$t("bookings_states.in_work"), value: "В обработке" },
        { text: this.$t("bookings_states.abandoned"), value: "Недозвон" },
        { text: this.$t("bookings_states.decline"), value: "Отказ клиента" },
        { text: this.$t("bookings_states.no_cars"), value: "Нет машин" },
        {
          text: this.$t("bookings_states.wait_answer"),
          value: "Ожидает ответа клиента",
        },
        { text: this.$t("bookings_states.wait_pay"), value: "Ожидает оплаты" },
        { text: this.$t("bookings_states.active"), value: "Активная" },
        { text: this.$t("bookings_states.cancel"), value: "Отмена" },
        { text: this.$t("bookings_states.rided"), value: "Отъездила" },
        {
          text: this.$t("bookings_states.return_deposit"),
          value: "Вернуть залог",
        },
        { text: this.$t("bookings_states.accepted"), value: "Подтверждена" },
        {
          text: this.$t("bookings_states.not_accepted"),
          value: "Не подтверждена",
        },
        { text: this.$t("bookings_states.other"), value: "Другое" },
      ],
      sort_date: new Date(),
      sort_date_for_input: null,
      attrs_calendar: [
        {
          key: "today",
          highlight: {
            color: "blue",
            fillMode: "outline",
            contentClass: "italic",
          },
          dates: new Date(),
        },
      ],
      show_all_bookings: false,
      show_checkins: false,
      show_checkouts: false,
      show_deposits: true,
      connected: false,
      isMobile: false,
      isLoading: false,
      isLoadingSubscribeToPaidTariff: false,
    };
  },
  channels: {
    TimelineChannel: {
      connected() {
        console.log("connected");
        this.connected = "success";
      },
      disconnected() {
        console.log("disconnected");
        this.connected = "error";
      },
      received(data) {
        // вставляем свежие платежи
        data.booking.counts = data.counts;
        if (data.action == "new") {
          if (
            data.booking &&
            (moment(data.booking.start_date, "DD-MM-YYYY H:mm").isSame(
              moment(this.sort_date_for_input, "DD-MM-YYYY"),
              "day"
            ) ||
              moment(data.booking.end_date, "DD-MM-YYYY H:mm").isSame(
                moment(this.sort_date_for_input, "DD-MM-YYYY"),
                "day"
              ))
          ) {
            console.log(
              "received and push",
              data,
              moment(data.booking.start_date, "DD-MM-YYYY H:mm").isSame(
                moment(this.sort_date_for_input, "DD-MM-YYYY"),
                "day"
              ),
              moment(data.booking.end_date, "DD-MM-YYYY H:mm").isSame(
                moment(this.sort_date_for_input, "DD-MM-YYYY"),
                "day"
              )
            );

            this.bookings.push(data.booking);
            // Добавляю время для сортировки
            this.bookings.forEach((booking) => {
              if (!this.selectBookingState(booking)) {
                booking.time = moment(
                  booking.end_date,
                  "DD-MM-YYYY H:mm"
                ).format("H:mm");
              } else {
                booking.time = moment(
                  booking.start_date,
                  "DD-MM-YYYY H:mm"
                ).format("H:mm");
              }
            });
            // Сортирую по времени
            this.bookings = this.bookings.sort(function (x1, x2) {
              if (moment(x1.time, "H:mm").isBefore(moment(x2.time, "H:mm")))
                return -1;
              if (moment(x1.time, "H:mm").isAfter(moment(x2.time, "H:mm")))
                return 1;
              return 0;
            });
            // Убираю те которые уже выдали
            this.bookings = this.bookings.filter((booking) => {
              return !(
                booking.in_rent &&
                moment(booking.end_date, "DD-MM-YYYY H:mm").isAfter(
                  moment(),
                  "days"
                )
              );
            });
          }
        }
        if (data.action == "update") {
          if (
            data.booking.archive == true ||
            data.booking.ride == true ||
            data.booking.active == false
          ) {
            this.bookings = this.bookings.filter((booking) => {
              return booking.id != data.booking.id;
            });
          } else {
            let index = this.bookings.findIndex(
              (booking) => booking.id == data.booking.id
            );
            // вставляем свежие платежи
            data.booking.counts = data.counts;
            if (index != -1) {
              this.bookings.splice(index, 1, data.booking);
            } else {
              this.bookings.push(data.booking);
            }
          }
          // Убираю не на этот день
          this.bookings = this.bookings.filter((booking) => {
            return (
              moment(booking.start_date, "DD-MM-YYYY H:mm").isSame(
                moment(this.sort_date_for_input, "DD-MM-YYYY"),
                "day"
              ) ||
              moment(booking.end_date, "DD-MM-YYYY H:mm").isSame(
                moment(this.sort_date_for_input, "DD-MM-YYYY"),
                "day"
              )
            );
          });

          // Добавляю время для сортировки
          this.bookings.forEach((booking) => {
            if (!this.selectBookingState(booking)) {
              booking.time = moment(booking.end_date, "DD-MM-YYYY H:mm").format(
                "H:mm"
              );
            } else {
              booking.time = moment(
                booking.start_date,
                "DD-MM-YYYY H:mm"
              ).format("H:mm");
            }
          });
          // Сортирую по времени
          this.bookings = this.bookings.sort(function (x1, x2) {
            if (moment(x1.time, "H:mm").isBefore(moment(x2.time, "H:mm")))
              return -1;
            if (moment(x1.time, "H:mm").isAfter(moment(x2.time, "H:mm")))
              return 1;
            return 0;
          });
          // // Убираю те которые уже выдали
          // this.bookings = this.bookings.filter((booking) => {
          //   return !(
          //     booking.in_rent &&
          //     moment(booking.end_date, "DD-MM-YYYY H:mm").isAfter(
          //       moment(),
          //       "days"
          //     )
          //   );
          // });
        }
        if (data.action == "destroy") {
          console.log("destroy");
          this.bookings.splice(
            this.bookings.findIndex((booking) => booking.id == data.booking),
            1
          );
        }
      },
    },
  },
  mounted() {
    this.$cable.subscribe({
      channel: "TimelineChannel",
      room: this.$store.getters.getCompany.company.id,
    });
    if (
      this.$tours["registrationTour"] &&
      this.$store.getters.getTourNextStep
    ) {
      this.$tours["registrationTour"].nextStep();
    }
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.sort_date_for_input = moment(this.sort_date).format("DD-MM-YYYY");
      this.axios
        .get(`/api/v1/bookings_for_date?date=${self.sort_date_for_input}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          self.bookings = response.data;
          self.all_bookings = response.data;
          self.non_filtered_bookings = response.data;
          self.non_filtered_all_bookings = response.data;
          // Добавляю время для сортировки
          self.bookings.forEach((booking) => {
            if (!self.selectBookingState(booking)) {
              booking.time = moment(booking.end_date, "DD-MM-YYYY H:mm").format(
                "H:mm"
              );
            } else {
              booking.time = moment(
                booking.start_date,
                "DD-MM-YYYY H:mm"
              ).format("H:mm");
            }
          });
          // Добавляю время для сортировки
          self.all_bookings.forEach((booking) => {
            if (!self.selectBookingState(booking)) {
              booking.time = moment(booking.end_date, "DD-MM-YYYY H:mm").format(
                "H:mm"
              );
            } else {
              booking.time = moment(
                booking.start_date,
                "DD-MM-YYYY H:mm"
              ).format("H:mm");
            }
          });
          // Добавляю время для сортировки
          self.non_filtered_bookings.forEach((booking) => {
            if (!self.selectBookingState(booking)) {
              booking.time = moment(booking.end_date, "DD-MM-YYYY H:mm").format(
                "H:mm"
              );
            } else {
              booking.time = moment(
                booking.start_date,
                "DD-MM-YYYY H:mm"
              ).format("H:mm");
            }
          });
          // Добавляю время для сортировки
          self.non_filtered_all_bookings.forEach((booking) => {
            if (!self.selectBookingState(booking)) {
              booking.time = moment(booking.end_date, "DD-MM-YYYY H:mm").format(
                "H:mm"
              );
            } else {
              booking.time = moment(
                booking.start_date,
                "DD-MM-YYYY H:mm"
              ).format("H:mm");
            }
          });
          // Сортирую по времени
          self.bookings = self.bookings.sort(function (x1, x2) {
            if (moment(x1.time, "H:mm").isBefore(moment(x2.time, "H:mm")))
              return -1;
            if (moment(x1.time, "H:mm").isAfter(moment(x2.time, "H:mm")))
              return 1;
            return 0;
          });
          // Сортирую по времени
          self.all_bookings = self.all_bookings.sort(function (x1, x2) {
            if (moment(x1.time, "H:mm").isBefore(moment(x2.time, "H:mm")))
              return -1;
            if (moment(x1.time, "H:mm").isAfter(moment(x2.time, "H:mm")))
              return 1;
            return 0;
          });
          // Сортирую по времени
          self.non_filtered_bookings = self.non_filtered_bookings.sort(function (
            x1,
            x2
          ) {
            if (moment(x1.time, "H:mm").isBefore(moment(x2.time, "H:mm")))
              return -1;
            if (moment(x1.time, "H:mm").isAfter(moment(x2.time, "H:mm")))
              return 1;
            return 0;
          });
          // Сортирую по времени
          self.non_filtered_all_bookings = self.non_filtered_all_bookings.sort(function (
            x1,
            x2
          ) {
            if (moment(x1.time, "H:mm").isBefore(moment(x2.time, "H:mm")))
              return -1;
            if (moment(x1.time, "H:mm").isAfter(moment(x2.time, "H:mm")))
              return 1;
            return 0;
          });
          // Убираю те которые уже выдали
          self.bookings = self.bookings.filter((booking) => {
            return !(
              booking.in_rent &&
              moment(booking.end_date, "DD-MM-YYYY H:mm").isAfter(
                moment(),
                "days"
              )
            );
          });
          // Убираю те которые уже выдали
          self.non_filtered_bookings = self.non_filtered_bookings.filter((booking) => {
            return !(
              booking.in_rent &&
              moment(booking.end_date, "DD-MM-YYYY H:mm").isAfter(
                moment(),
                "days"
              )
            );
          });
        })
        .then(() => {
          this.axios
            .get("/api/v1/get_workers_names_with_ids", {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              this.responsibles = response.data;
              // добавляем в массив отсутствие ответственного
              this.responsibles.push({
                id: null,
                name: null,
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.workers_load"));
              console.log(error);
            });
        })
        .catch((error) => {
          self.setError(error, self.$t("errors.bookings_load"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });

      // check every 30 seconds if action cable wss server is online
      function check_server() {
        self.check_server = setInterval(function () {
          if (self.$root.$cable._cable.connection.disconnected) {
            self.connected = false;
            console.log("please reconnect");
            // self.$root.$cable.connection.connect();
          } else {
            self.connected = true;
            // console.log("connected");
          }
        }.bind(self), 30000);
      }
      check_server();
    } else {
      this.$store.commit("unsetCurrentUser");
      // this.$router.push({ path: `/signin` }).catch(err => {});
    }
  },
  beforeDestroy() {
    this.$cable.unsubscribe({
      channel: "TimelineChannel",
      room: this.$store.getters.getCompany.company.id,
    });
  },
  destroyed() {
    clearInterval(this.check_server);
  },
  watch: {
    sort_date() {
      let self = this;
      if (this.sort_date) {
        this.getBookingsForDate();
        this.sort_date_for_input = moment(this.sort_date).format("DD-MM-YYYY");
      } else {
        this.sort_date = new Date().toISOString();
        this.sort_date_for_input = moment().format("DD-MM-YYYY");
      }
    },
    show_checkins() {
      if (this.show_checkins) {
        // this.show_all_bookings = false;
        this.bookings = this.bookings.filter((booking) => {
          return this.selectBookingState(booking);
        });
        this.all_bookings = this.all_bookings.filter((booking) => {
          return this.selectBookingState(booking);
        });
      } else {
        this.bookings = this.non_filtered_bookings;
        this.all_bookings = this.non_filtered_all_bookings;
      }
      this.show_checkouts = false;
    },
    show_checkouts() {
      if (this.show_checkouts) {
        this.bookings = this.bookings.filter((booking) => {
          return !this.selectBookingState(booking);
        });
        this.all_bookings = this.all_bookings.filter((booking) => {
          return !this.selectBookingState(booking);
        });
      } else {
        this.bookings = this.non_filtered_bookings;
        this.all_bookings = this.non_filtered_all_bookings;
      }
      this.show_checkins = false;
    },
  },
  computed: {
    userMustChangeTariffPlan() {
      if (this.$store.getters.getMainCompany) {
        if (this.$store.getters.getMainCompany.plan && this.$store.getters.getMainCompany.plan.id) {
          if(this.$store.getters.getMainCompany.plan.name == "Старт" || this.$store.getters.getMainCompany.plan.name == "Start") {
            if(this.$store.getters.getMainCompany.plan.trial_end && moment(this.$store.getters.getMainCompany.plan.trial_end).isAfter(moment())) {
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return false
        }
      } else {
        return false;
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
    deposits() {
      // считаю залоги на сегодня
      let deposits = 0;
      this.bookings.forEach((booking) => {
        if (!booking.hourly) {
          if (booking.deposit && booking.deposit > 0 && (moment(booking.end_date, "DD-MM-YYYY H:mm").isSame(moment(this.sort_date), "days")) && !booking.technical) {
            deposits += booking.deposit;
          }
        } else if (booking.hourly) {
          if (booking.hourly_deposit && booking.hourly_deposit > 0 && (moment(booking.end_date, "DD-MM-YYYY H:mm").isSame(moment(this.sort_date), "days")) && !booking.technical) {
            deposits += booking.hourly_deposit;
          }
        }
      });
      return deposits;
    },
    incomes() {
      // считаю приход на сегодня
      let incomes = 0;
      this.bookings.forEach((booking) => {
        if ((moment(booking.start_date, "DD-MM-YYYY H:mm").isSame(moment(this.sort_date), "days")) && !booking.technical) {
          let booking_sum = 0;
          if (!booking.hourly) {
            booking_sum =
              booking.rental_cost +
              booking.delivery +
              booking.delivery_end +
              booking.equipment +
              booking.insurance +
              booking.clean_payment +
              booking.add_drivers_cost +
              booking.hours_cost +
              booking.mileage_cost +
              booking.damage +
              booking.gas +
              booking.fine +
              booking.other +
              booking.other_end +
              booking.hours_cost_end +
              booking.deposit;
          } else if (booking.hourly) {
            booking_sum =
              booking.rental_cost +
              booking.delivery +
              booking.delivery_end +
              booking.equipment +
              booking.insurance +
              booking.clean_payment +
              booking.add_drivers_cost +
              booking.mileage_cost +
              booking.damage +
              booking.gas +
              booking.fine +
              booking.other +
              booking.other_end +
              booking.hourly_deposit;
          }
          if (booking_sum > 0) {
            incomes += booking_sum;
          }
        }
      });
      return incomes;
    }
  },
  methods: {
    getBookingsForDate() {
      let self = this;
      if (this.sort_date) {
        this.isLoading = true;
        this.show_checkins = false;
        this.show_checkouts = false;
        this.show_all_bookings = false;
        let formattedDate = moment(self.sort_date).format("DD-MM-YYYY");
        this.axios
          .get(`/api/v1/bookings_for_date?date=${formattedDate}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            self.bookings = response.data;
            self.all_bookings = response.data;
            self.non_filtered_bookings = response.data;
            self.non_filtered_all_bookings = response.data;
            // Добавляю время для сортировки
            self.bookings.forEach((booking) => {
              if (!self.selectBookingState(booking)) {
                booking.time = moment(
                  booking.end_date,
                  "DD-MM-YYYY H:mm"
                ).format("H:mm");
              } else {
                booking.time = moment(
                  booking.start_date,
                  "DD-MM-YYYY H:mm"
                ).format("H:mm");
              }
            });
            self.all_bookings.forEach((booking) => {
              if (!self.selectBookingState(booking)) {
                booking.time = moment(
                  booking.end_date,
                  "DD-MM-YYYY H:mm"
                ).format("H:mm");
              } else {
                booking.time = moment(
                  booking.start_date,
                  "DD-MM-YYYY H:mm"
                ).format("H:mm");
              }
            });
            self.non_filtered_bookings.forEach((booking) => {
              if (!self.selectBookingState(booking)) {
                booking.time = moment(
                  booking.end_date,
                  "DD-MM-YYYY H:mm"
                ).format("H:mm");
              } else {
                booking.time = moment(
                  booking.start_date,
                  "DD-MM-YYYY H:mm"
                ).format("H:mm");
              }
            });
            self.non_filtered_all_bookings.forEach((booking) => {
              if (!self.selectBookingState(booking)) {
                booking.time = moment(
                  booking.end_date,
                  "DD-MM-YYYY H:mm"
                ).format("H:mm");
              } else {
                booking.time = moment(
                  booking.start_date,
                  "DD-MM-YYYY H:mm"
                ).format("H:mm");
              }
            });
            // Сортирую по времени
            self.bookings = self.bookings.sort(function (x1, x2) {
              if (moment(x1.time, "H:mm").isBefore(moment(x2.time, "H:mm")))
                return -1;
              if (moment(x1.time, "H:mm").isAfter(moment(x2.time, "H:mm")))
                return 1;
              return 0;
            });
            // Сортирую по времени
            self.all_bookings = self.all_bookings.sort(function (x1, x2) {
              if (moment(x1.time, "H:mm").isBefore(moment(x2.time, "H:mm")))
                return -1;
              if (moment(x1.time, "H:mm").isAfter(moment(x2.time, "H:mm")))
                return 1;
              return 0;
            });
            // Сортирую по времени
            self.non_filtered_bookings = self.non_filtered_bookings.sort(function (x1, x2) {
              if (moment(x1.time, "H:mm").isBefore(moment(x2.time, "H:mm")))
                return -1;
              if (moment(x1.time, "H:mm").isAfter(moment(x2.time, "H:mm")))
                return 1;
              return 0;
            });
            // Сортирую по времени
            self.non_filtered_all_bookings = self.bookings.sort(function (x1, x2) {
              if (moment(x1.time, "H:mm").isBefore(moment(x2.time, "H:mm")))
                return -1;
              if (moment(x1.time, "H:mm").isAfter(moment(x2.time, "H:mm")))
                return 1;
              return 0;
            });
            if (moment().isSame(this.sort_date, "days")) {
              // Убираю те которые уже выдали
              self.bookings = self.bookings.filter((booking) => {
                return !(
                  booking.in_rent &&
                  moment(booking.end_date, "DD-MM-YYYY H:mm").isAfter(
                    moment(),
                    "days"
                  )
                );
              });
            }
            if (moment().isSame(this.sort_date, "days")) {
              // Убираю те которые уже выдали
              self.non_filtered_bookings = self.non_filtered_bookings.filter((booking) => {
                return !(
                  booking.in_rent &&
                  moment(booking.end_date, "DD-MM-YYYY H:mm").isAfter(
                    moment(),
                    "days"
                  )
                );
              });
            }
          })
          .catch((error) => {
            self.setError(error, self.$t("errors.bookings_load"));
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.error"),
          text: this.$t("errors.bad_date"),
        });
      }
    },
    selectBookingState(booking) {
      if (
        moment(booking.start_date, "DD-MM-YYYY H:mm").isSame(
          moment(booking.end_date, "DD-MM-YYYY H:mm"),
          "days"
        ) &&
        booking.in_rent == true
      ) {
        // Бронь однодневка, чтобы не показывать когда выдана в выдаче, а показывать в приеме
        return false;
      } else if (
        // выдача
        moment(booking.start_date, "DD-MM-YYYY H:mm").isSame(
          moment(this.sort_date),
          "days"
        ) &&
        booking.ride == false
      ) {
        return true;
      } else {
        // прием
        return false;
      }
    },
    returnTime(booking) {
      if (!this.selectBookingState(booking)) {
        return moment(booking.end_date, "DD-MM-YYYY H:mm").format("H:mm");
      } else if (this.selectBookingState(booking)) {
        return moment(booking.start_date, "DD-MM-YYYY H:mm").format("H:mm");
      } else {
        return `${booking.start_date} - ${booking.end_date}`;
      }
    },
    calculatePayments(booking) {
      if (booking != undefined && booking.counts && booking.counts.length > 0) {
        let paid = 0;
        let booking_sum =
          booking.rental_cost +
          booking.delivery +
          booking.delivery_end +
          booking.equipment +
          booking.insurance +
          booking.clean_payment +
          booking.add_drivers_cost +
          booking.hours_cost +
          booking.mileage_cost +
          booking.damage +
          booking.gas +
          booking.fine +
          booking.other +
          booking.other_end +
          booking.hours_cost_end;
        booking.counts.forEach((count) => {
          // если оплата по терминалу и в платеже есть tinkoff_payment_id то учитываем только если count.tinkoff_payment.paid = true
          if (count.completed) {
            if (count.operation) {
              paid += count.sum;
            } else {
              paid -= count.sum;
            }
          }
        });
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += booking.deposit;
        }
        if (paid == booking_sum) {
          return this.$t("bookings.paid");
        } else {
          return paid - booking_sum >= 0
            ? `+ ${paid - booking_sum}`
            : ` ${paid - booking_sum}`;
        }
      } else {
        return this.$t("bookings.not_paid_2");
      }
    },
    selectColorPaid(booking) {
      if (booking != undefined && booking.counts && booking.counts.length > 0) {
        let paid = 0;
        let booking_sum =
          booking.rental_cost +
          booking.delivery +
          booking.delivery_end +
          booking.equipment +
          booking.insurance +
          booking.clean_payment +
          booking.add_drivers_cost +
          booking.hours_cost +
          booking.mileage_cost +
          booking.damage +
          booking.gas +
          booking.fine +
          booking.other +
          booking.other_end +
          booking.hours_cost_end;
        booking.counts.forEach((count) => {
          // если оплата по терминалу и в платеже есть tinkoff_payment_id то учитываем только если count.tinkoff_payment.paid = true
          if (count.completed) {
            if (count.operation) {
              paid += count.sum;
            } else {
              paid -= count.sum;
            }
          }
        });
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += booking.deposit;
        }
        if (paid == booking_sum) {
          return "success";
        } else if (paid - booking_sum > 0) {
          return "warning";
        } else if (paid - booking_sum < 0) {
          return "error";
        }
      } else {
        return "error";
      }
    },
    updateResponsible(id, responsible_name, responsible_id) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let self = this;
        this.axios
          .patch(
            `/api/v1/update_responsible`,
            {
              bookings: {
                id: id,
                responsible: responsible_name,
                responsible_id: responsible_id
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.bookings.findIndex(
              (element) => element.id === id
            );
            this.bookings.splice(foundIndex, 1, response.data);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("bookings.responsible_changed_title"),
              text: this.$t("bookings.responsible_changed_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.change_responsible"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.change_responsible"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    saveBookingState(state, id) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        if ((state, id)) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/bookings/${id}`,
              {
                bookings: {
                  state: state,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let foundIndex = this.bookings.findIndex(
                (element) => element.id === id
              );
              console.log(this.bookings[foundIndex])
              this.bookings[foundIndex].state = state;
              console.log(this.bookings[foundIndex])
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.update_state"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.update_state_title"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "warning",
            title: this.$t("errors.select_state"),
            text: this.error,
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    translateBookingState(state) {
      switch (state) {
        case "Новая":
          return this.$t("bookings_states.new");
        case "В обработке":
          return this.$t("bookings_states.in_work");
        case "Недозвон":
          return this.$t("bookings_states.abandoned");
        case "Отказ клиента":
          return this.$t("bookings_states.decline");
        case "Нет машин":
          return this.$t("bookings_states.no_cars");
        case "Ожидает ответа клиента":
          return this.$t("bookings_states.wait_answer");
        case "Активная":
          return this.$t("bookings_states.active");
        case "Отмена":
          return this.$t("bookings_states.cancel");
        case "Отъездила":
          return this.$t("bookings_states.rided");
        case "Вернуть залог":
          return this.$t("bookings_states.return_deposit");
        case "Подтверждена":
          return this.$t("bookings_states.accepted");
        case "Не подтверждена":
          return this.$t("bookings_states.not_accepted");
        case "Другое":
          return this.$t("bookings_states.other");
        default:
          return this.$t("bookings_states.in_work");
      }
    },
    clearDate() {
      this.sort_date = new Date();
      this.sort_date_for_input = moment().format("DD-MM-YYYY");
    },
    replaceToBooking(booking) {
      this.$router.push({ path: `/bookings/${booking.id}` });
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    subscribeToPaidTariff() {
      this.$router.push({ name: "Transactions" });
    },
    toTariffOnSite() {
      window.open(`https://rentprog.${this.$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/${this.$root.$i18n.locale}/tariffs`, '_blank');
    },
    includedInPlan(service) {
      if (this.$store.getters.getMainCompany) {
        if (this.$store.getters.getMainCompany.plan) {
          if (this.$store.getters.getMainCompany.plan.included_services) {
            // check trial period
            if (this.$store.getters.getMainCompany.plan.trial_end && moment(this.$store.getters.getMainCompany.plan.trial_end).isAfter(moment())) {
              return false;
            }
            return !this.$store.getters.getMainCompany.plan.included_services.includes(service);
          }
        }
      }
      return false;
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        var fpath = this.PageData.backCrumb.url;
        this.$router
          .push({
            path: fpath,
          })
          .catch((err) => { });
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
  },
};
</script>

<style></style>
