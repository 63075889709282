<template>
  <v-navigation-drawer v-if="!isMobile && !isNative" width="auto" v-model="show_drawer" :mini-variant.sync="mini"
    :expand-on-hover="!isMobile" app v-resize="checkMobile">
    <v-list-item class="pr-1 pl-3">
      <v-list-item-content class="">
        <v-toolbar-title v-if="mini">
          <router-link to="/dashboard" class="mx-0 d-flex align-middle">
            <v-avatar size="30" class="mt-1">
              <img src="/img/logo-100x100.png" alt="RentProg">
            </v-avatar>
          </router-link>
        </v-toolbar-title>
        <v-toolbar-title v-if="!mini">
          <router-link to="/dashboard"
            :class="$vuetify.theme.dark ? 'white--text text-decoration-none d-flex align-middle' : 'black--text text-decoration-none d-flex align-middle'">
            <v-avatar size="30" class="mr-2 mt-1">
              <img src="/img/logo-100x100.png" alt="RentProg">
            </v-avatar>
            <span class="pt-1 font-weight-light text-decoration-none">RentProg</span>
          </router-link>
        </v-toolbar-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="mt-1"></v-divider>
    <v-list v-if="user_role == 'superadmin' ||
    user_role == 'admin' ||
    user_role == 'guest'
    " dense>
      <v-list-item to="/dashboard" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.main") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/bookings" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-book-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.bookings") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/timeline" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-cursor</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.day_plan") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-month-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/booking/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-plus-box</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.add_booking")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/search_free" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-car-search-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.search_free")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group v-for="item in items" :key="item.title" v-model="item.active" :prepend-icon="item.icon" no-action>
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item v-for="subItem in item.items" :key="subItem.title" @click="closeOnContentClick = true"
          style="padding-left: 25px !important" :to="subItem.to">
          <v-list-item-action>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ subItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item to="/store" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-store-cog</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("store.store") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="company && company.whatsapp && user_role != 'guest' && user_role != 'partner'"
        to="/whatsapp_chat">
        <v-list-item-action>
          <v-icon>mdi-whatsapp</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>WhatsApp</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/transactions" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon color="success">mdi-currency-usd</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <span :class="$store.getters.getMainCompany &&
      $store.getters.getMainCompany.balance < 0
      ? 'red--text'
      : ''
    ">
              {{ $t("header.balance") }}:
              {{
    $store.getters.getMainCompany
      ? Math.round($store.getters.getMainCompany.balance)
      : 0
  }}
              <span>{{
      $store.getters.getMainCompany &&
        $store.getters.getMainCompany.currency == "RUB"
        ? "₽"
        : "€"
    }}</span>
            </span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
    }/${$root.$i18n.locale}/helpcenter`" target="_blank" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-help-circle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="signOut()">
        <v-list-item-action>
          <v-icon color="error">mdi-logout-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-else-if="user_role == 'manager'" dense>
      <v-list-item to="/dashboard" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.main") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/bookings" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-book-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.bookings") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/timeline" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-cursor</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.day_plan") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-month-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/booking/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-plus-box</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.add_booking")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/search_free" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-car-search-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.search_free")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group v-for="item in manager_items" :key="item.title" v-model="item.active" :prepend-icon="item.icon"
        no-action>
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item v-for="subItem in item.items" :key="subItem.title" @click="closeOnContentClick = true"
          style="padding-left: 25px !important" :to="subItem.to">
          <v-list-item-action>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ subItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item to="/store" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-store-cog</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("store.store") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/todos_user" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-multiple-check</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.todo") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/schedule_calendar" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-multiselect</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.schedule") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/salary" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-account-cash-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.salary") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/admin/users" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-account-hard-hat</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.employers") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="company && company.whatsapp && user_role != 'guest' && user_role != 'partner'"
        to="/whatsapp_chat">
        <v-list-item-action>
          <v-icon>mdi-whatsapp</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>WhatsApp</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
    }/${$root.$i18n.locale}/helpcenter`" target="_blank" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-help-circle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="signOut()">
        <v-list-item-action>
          <v-icon color="error">mdi-logout-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-else-if="user_role == 'partner'" dense>
      <v-list-item to="/dashboard" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.main") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/bookings" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-book-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.bookings") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/investor_analytics" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-poll</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.analitics") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/timeline" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-cursor</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.day_plan") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-month-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group v-for="item in partners_items" :key="item.title" v-model="item.active" :prepend-icon="item.icon"
        no-action>
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item v-for="subItem in item.items" :key="subItem.title" @click="closeOnContentClick = true"
          style="padding-left: 25px !important" :to="subItem.to">
          <v-list-item-action>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ subItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
    }/${$root.$i18n.locale}/helpcenter`" target="_blank" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-help-circle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="signOut()">
        <v-list-item-action>
          <v-icon color="error">mdi-logout-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-else-if="user_role == 'agent'" dense>
      <v-list-item to="/booking/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-plus-box</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.add_booking")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/search_free" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-car-search-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.search_free")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-month-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/bookings" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-book-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.bookings") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group v-for="item in agents_items" :key="item.title" v-model="item.active" :prepend-icon="item.icon"
        no-action>
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item v-for="subItem in item.items" :key="subItem.title" @click="closeOnContentClick = true"
          style="padding-left: 25px !important" :to="subItem.to">
          <v-list-item-action>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ subItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
    }/${$root.$i18n.locale}/helpcenter`" target="_blank" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-help-circle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="signOut()">
        <v-list-item-action>
          <v-icon color="error">mdi-logout-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-else dense>
      <v-list-item to="/dashboard" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.main") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/bookings" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-book-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.bookings") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/timeline" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-cursor</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.day_plan") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-month-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/booking/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-plus-box</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.add_booking")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/search_free" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-car-search-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.search_free")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group v-for="item in worker_items" :key="item.title" v-model="item.active" :prepend-icon="item.icon"
        no-action>
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item v-for="subItem in item.items" :key="subItem.title" @click="closeOnContentClick = true"
          style="padding-left: 25px !important" :to="subItem.to">
          <v-list-item-action>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ subItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item to="/store" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-store-cog</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("store.store") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/todos_user" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-multiple-check</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>ToDo</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="company && company.whatsapp && user_role != 'guest' && user_role != 'partner'"
        to="/whatsapp_chat">
        <v-list-item-action>
          <v-icon>mdi-whatsapp</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>WhatsApp</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/schedule_calendar" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-multiselect</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.schedule") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/salary" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-account-cash-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.salary") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
    }/${$root.$i18n.locale}/helpcenter`" target="_blank" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-help-circle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="signOut()">
        <v-list-item-action>
          <v-icon color="error">mdi-logout-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <v-navigation-drawer v-else v-model="show_drawer" width="auto" app v-resize="checkMobile">
    <v-list-item :class="isIos ? 'pr-1 pl-3 pt-10' : 'pr-1 pl-3'">
      <v-list-item-content class="">
        <v-toolbar-title>
          <router-link to="/dashboard"
            :class="$vuetify.theme.dark ? 'white--text text-decoration-none d-flex align-middle' : 'black--text text-decoration-none d-flex align-middle'">
            <v-avatar size="30" class="mr-2 mt-1">
              <img src="/img/logo-100x100.png" alt="RentProg">
            </v-avatar>
            <span class="pt-1 font-weight-light text-decoration-none">RentProg</span>
          </router-link>
        </v-toolbar-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list v-if="user_role == 'superadmin' ||
    user_role == 'admin' ||
    user_role == 'guest'
    " dense class="pt-0 overflow-y-auto">
      <v-list-item to="/dashboard" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.main") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/bookings" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-book-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.bookings") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/timeline" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-cursor</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.day_plan") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-month-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/booking/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-plus-box</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.add_booking")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/search_free" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-car-search-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.search_free")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group v-for="item in items" :key="item.title" v-model="item.active" :prepend-icon="item.icon" no-action>
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item v-for="subItem in item.items" :key="subItem.title" @click="closeOnContentClick = true"
          style="padding-left: 25px !important" :to="subItem.to">
          <v-list-item-action>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ subItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item to="/store" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-store-cog</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("store.store") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="company && company.whatsapp && user_role != 'guest' && user_role != 'partner'"
        to="/whatsapp_chat">
        <v-list-item-action>
          <v-icon>mdi-whatsapp</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>WhatsApp</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/transactions" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon color="success">mdi-currency-usd</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <span :class="$store.getters.getMainCompany &&
      $store.getters.getMainCompany.balance < 0
      ? 'red--text'
      : ''
    ">
              {{ $t("header.balance") }}:
              {{
    $store.getters.getMainCompany
      ? Math.round($store.getters.getMainCompany.balance)
      : 0
  }}
              <span>{{
      $store.getters.getMainCompany &&
        $store.getters.getMainCompany.currency == "RUB"
        ? "₽"
        : "€"
    }}</span>
            </span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
    }/${$root.$i18n.locale}/helpcenter`" target="_blank" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-help-circle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="signOut()">
        <v-list-item-action>
          <v-icon color="error">mdi-logout-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-else-if="user_role == 'manager'" dense class="pt-0 overflow-y-auto"
      :style="isMobile ? 'max-height: 500px' : 'max-height: 1000px'">
      <v-list-item to="/dashboard" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.main") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/bookings" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-book-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.bookings") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/timeline" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-cursor</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.day_plan") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-month-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/booking/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-plus-box</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.add_booking")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/search_free" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-car-search-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.search_free")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group v-for="item in manager_items" :key="item.title" v-model="item.active" :prepend-icon="item.icon"
        no-action>
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item v-for="subItem in item.items" :key="subItem.title" @click="closeOnContentClick = true"
          style="padding-left: 25px !important" :to="subItem.to">
          <v-list-item-action>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ subItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item to="/store" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-store-cog</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("store.store") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/todos_user" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-multiple-check</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.todo") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/schedule_calendar" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-multiselect</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.schedule") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/salary" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-account-cash-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.salary") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/admin/users" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-account-hard-hat</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.employers") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="company && company.whatsapp && user_role != 'guest' && user_role != 'partner'"
        to="/whatsapp_chat">
        <v-list-item-action>
          <v-icon>mdi-whatsapp</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>WhatsApp</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
    }/${$root.$i18n.locale}/helpcenter`" target="_blank" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-help-circle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="signOut()">
        <v-list-item-action>
          <v-icon color="error">mdi-logout-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-else-if="user_role == 'partner'" dense class="pt-0 overflow-y-auto"
      :style="isMobile ? 'max-height: 500px' : 'max-height: 1000px'">
      <v-list-item to="/dashboard" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.main") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/bookings" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-book-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.bookings") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/timeline" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-cursor</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.day_plan") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-month-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group v-for="item in partners_items" :key="item.title" v-model="item.active" :prepend-icon="item.icon"
        no-action>
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item v-for="subItem in item.items" :key="subItem.title" @click="closeOnContentClick = true"
          style="padding-left: 25px !important" :to="subItem.to">
          <v-list-item-action>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ subItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
    }/${$root.$i18n.locale}/helpcenter`" target="_blank" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-help-circle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="signOut()">
        <v-list-item-action>
          <v-icon color="error">mdi-logout-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-else-if="user_role == 'agent'" dense class="pt-0 overflow-y-auto"
      :style="isMobile ? 'max-height: 500px' : 'max-height: 1000px'">
      <v-list-item to="/booking/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-plus-box</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.add_booking")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/search_free" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-car-search-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.search_free")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-month-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/bookings" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-book-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.bookings") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group v-for="item in agents_items" :key="item.title" v-model="item.active" :prepend-icon="item.icon"
        no-action>
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item v-for="subItem in item.items" :key="subItem.title" @click="closeOnContentClick = true"
          style="padding-left: 25px !important" :to="subItem.to">
          <v-list-item-action>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ subItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
    }/${$root.$i18n.locale}/helpcenter`" target="_blank" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-help-circle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="signOut()">
        <v-list-item-action>
          <v-icon color="error">mdi-logout-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-else dense class="pt-0 overflow-y-auto" :style="isMobile ? 'max-height: 500px' : 'max-height: 1000px'">
      <v-list-item to="/dashboard" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.main") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/bookings" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-book-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.bookings") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/timeline" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-cursor</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.day_plan") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-month-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/booking/new" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-plus-box</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.add_booking")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/search_free" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-car-search-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
    $t("custom.search_free")
  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group v-for="item in worker_items" :key="item.title" v-model="item.active" :prepend-icon="item.icon"
        no-action>
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item v-for="subItem in item.items" :key="subItem.title" @click="closeOnContentClick = true"
          style="padding-left: 25px !important" :to="subItem.to">
          <v-list-item-action>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ subItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item to="/store" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-store-cog</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("store.store") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/todos_user" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-multiple-check</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>ToDo</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="company && company.whatsapp && user_role != 'guest' && user_role != 'partner'"
        to="/whatsapp_chat">
        <v-list-item-action>
          <v-icon>mdi-whatsapp</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>WhatsApp</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/schedule_calendar" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-calendar-multiselect</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.schedule") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/salary" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-account-cash-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.salary") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
    }/${$root.$i18n.locale}/helpcenter`" target="_blank" @click="closeOnContentClick = true">
        <v-list-item-action>
          <v-icon>mdi-help-circle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="signOut()">
        <v-list-item-action>
          <v-icon color="error">mdi-logout-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import Gravatar from "vue-gravatar";
import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      mini: true,
      items: [
        {
          icon: "mdi-car-multiple",
          title: this.$t("custom.cars"),
          active: false,
          items: [
            {
              title: this.$t("custom.cars"),
              icon: "mdi-car-multiple",
              to: "/cars",
            },
            {
              title: this.$t("custom.add_car"),
              icon: "mdi-plus-box",
              to: "/cars/new",
            },
          ],
        },
        {
          icon: "mdi-calendar-account-outline",
          title: this.$t("custom.clients"),
          active: false,
          items: [
            {
              title: this.$t("custom.clients"),
              icon: "mdi-calendar-account-outline",
              to: "/clients",
            },
            {
              title: this.$t("custom.fines"),
              icon: "mdi-car-emergency",
              to: "/fines",
            },
            {
              title: this.$t("custom.debts"),
              icon: "mdi-credit-card-remove-outline",
              to: "/debts",
            },
            {
              title: this.$t("custom.invoices"),
              icon: "mdi-receipt",
              to: "/invoices",
            },
            {
              title: this.$t("custom.add_client"),
              icon: "mdi-plus-box",
              to: "/client/new",
            },
          ],
        },
        {
          icon: "mdi-cash-register",
          title: this.$t("custom.counts"),
          active: false,
          items: [
            {
              title: this.$t("custom.counts"),
              icon: "mdi-cash-register",
              to: "/counts",
            },
            {
              title: this.$t("header.company_counts"),
              icon: "mdi-cash-lock",
              to: "/company_counts",
            },
            {
              title: this.$t("header.add_payment"),
              icon: "mdi-book-plus-multiple",
              to: "/counts/new",
            },
          ],
        },
        {
          icon: "mdi-book-plus-multiple",
          title: this.$t("custom.service"),
          active: false,
          items: [
            {
              title: this.$t("custom.tech_service"),
              icon: "mdi-car-cog",
              to: "/service_tech",
            },
            {
              title: this.$t("maintenance.insurances_and_taxes"),
              icon: "mdi-shield-car",
              to: "/service_insurance",
            },
            {
              title: this.$t("maintenance.payments"),
              icon: "mdi-cash-sync",
              to: "/service_payments",
            },
          ],
        },
        {
          icon: "mdi-calendar-multiple-check",
          title: this.$t("custom.todo"),
          active: false,
          items: [
            {
              title: this.$t("header.all_todo"),
              icon: "mdi-calendar-multiple-check",
              to: "/todos_all",
            },
            {
              title: this.$t("header.private_todo"),
              icon: "mdi-calendar-account-outline",
              to: "/todos_user",
            },
          ],
        },
        {
          icon: "mdi-domain",
          title: this.$t("header.company"),
          active: false,
          items: [
            {
              title: this.$t("custom.analitics"),
              icon: "mdi-chart-areaspline",
              to: "/analitics",
            },
            {
              title: this.$t("custom.employers"),
              icon: "mdi-account-hard-hat",
              to: "/admin/users",
            },
            {
              title: this.$t("custom.schedule"),
              icon: "mdi-calendar-multiselect",
              to: "/schedule_calendar",
            },
            {
              title: this.$t("custom.salary"),
              icon: "mdi-account-cash-outline",
              to: "/salary",
            },
            {
              title: this.$t("header.partners"),
              icon: "mdi-handshake-outline",
              to: "/investors",
            },
            {
              title: this.$t("header.branches"),
              icon: "mdi-source-branch",
              to: "/branches",
            },
            {
              title: this.$t("custom.contractors"),
              icon: "mdi-account-convert",
              to: "/contractors",
            },
            {
              title: this.$t("header.company_settings"),
              icon: "mdi-cog-box",
              to: "/company_profile",
            },
            {
              title: this.$t("agents.agents"),
              icon: "mdi-face-agent",
              to: "/agents",
            },
          ],
        },
      ],
      selected_items: [],
      worker_items: [
        {
          icon: "mdi-car-multiple",
          title: this.$t("custom.cars"),
          active: false,
          items: [
            {
              title: this.$t("custom.cars"),
              icon: "mdi-car-multiple",
              to: "/cars",
            },
            {
              title: this.$t("custom.add_car"),
              icon: "mdi-plus-box",
              to: "/cars/new",
            },
          ],
        },
        {
          icon: "mdi-calendar-account-outline",
          title: this.$t("custom.clients"),
          active: false,
          items: [
            {
              title: this.$t("custom.clients"),
              icon: "mdi-calendar-account-outline",
              to: "/clients",
            },
            {
              title: this.$t("custom.fines"),
              icon: "mdi-car-emergency",
              to: "/fines",
            },
            {
              title: this.$t("custom.debts"),
              icon: "mdi-credit-card-remove-outline",
              to: "/debts",
            },
            {
              title: this.$t("custom.invoices"),
              icon: "mdi-receipt",
              to: "/invoices",
            },
            {
              title: this.$t("custom.add_client"),
              icon: "mdi-plus-box",
              to: "/client/new",
            },
          ],
        },
        {
          icon: "mdi-cash-register",
          title: this.$t("custom.counts"),
          active: false,
          items: [
            {
              title: this.$t("custom.counts"),
              icon: "mdi-cash-register",
              to: "/counts",
            },
            {
              title: this.$t("header.add_payment"),
              icon: "mdi-book-plus-multiple",
              to: "/counts/new",
            },
            {
              title: this.$t("custom.contractors"),
              icon: "mdi-account-convert",
              to: "/contractors",
            },
          ],
        },
        {
          icon: "mdi-book-plus-multiple",
          title: this.$t("custom.service"),
          active: false,
          items: [
            {
              title: this.$t("custom.tech_service"),
              icon: "mdi-car-cog",
              to: "/service_tech",
            },
            {
              title: this.$t("maintenance.insurances_and_taxes"),
              icon: "mdi-shield-car",
              to: "/service_insurance",
            },
            {
              title: this.$t("maintenance.payments"),
              icon: "mdi-cash-sync",
              to: "/service_payments",
            },
          ],
        },
      ],
      manager_items: [
        {
          icon: "mdi-car-multiple",
          title: this.$t("custom.cars"),
          active: false,
          items: [
            {
              title: this.$t("custom.cars"),
              icon: "mdi-car-multiple",
              to: "/cars",
            },
            {
              title: this.$t("custom.add_car"),
              icon: "mdi-plus-box",
              to: "/cars/new",
            },
          ],
        },
        {
          icon: "mdi-calendar-account-outline",
          title: this.$t("custom.clients"),
          active: false,
          items: [
            {
              title: this.$t("custom.clients"),
              icon: "mdi-calendar-account-outline",
              to: "/clients",
            },
            {
              title: this.$t("custom.fines"),
              icon: "mdi-car-emergency",
              to: "/fines",
            },
            {
              title: this.$t("custom.debts"),
              icon: "mdi-credit-card-remove-outline",
              to: "/debts",
            },
            {
              title: this.$t("custom.invoices"),
              icon: "mdi-receipt",
              to: "/invoices",
            },
            {
              title: this.$t("custom.add_client"),
              icon: "mdi-plus-box",
              to: "/client/new",
            },
          ],
        },
        {
          icon: "mdi-cash-register",
          title: this.$t("custom.counts"),
          active: false,
          items: [
            {
              title: this.$t("custom.counts"),
              icon: "mdi-cash-register",
              to: "/counts",
            },
            {
              title: this.$t("header.add_payment"),
              icon: "mdi-book-plus-multiple",
              to: "/counts/new",
            },
            {
              title: this.$t("custom.contractors"),
              icon: "mdi-account-convert",
              to: "/contractors",
            },
          ],
        },
        {
          icon: "mdi-calendar-multiple-check",
          title: this.$t("custom.todo"),
          active: false,
          items: [
            {
              title: this.$t("header.all_todo"),
              icon: "mdi-calendar-multiple-check",
              to: "/todos_all",
            },
            {
              title: this.$t("header.private_todo"),
              icon: "mdi-calendar-account-outline",
              to: "/todos_user",
            },
          ],
        },
        {
          icon: "mdi-book-plus-multiple",
          title: this.$t("custom.service"),
          active: false,
          items: [
            {
              title: this.$t("custom.tech_service"),
              icon: "mdi-car-cog",
              to: "/service_tech",
            },
            {
              title: this.$t("maintenance.insurances_and_taxes"),
              icon: "mdi-shield-car",
              to: "/service_insurance",
            },
            {
              title: this.$t("maintenance.payments"),
              icon: "mdi-cash-sync",
              to: "/service_payments",
            },
          ],
        },
      ],
      partners_items: [
        {
          icon: "mdi-car-multiple",
          title: this.$t("custom.cars"),
          active: false,
          items: [
            {
              title: this.$t("custom.cars"),
              icon: "mdi-car-multiple",
              to: "/cars",
            },
          ],
        },
        {
          icon: "mdi-book-plus-multiple",
          title: this.$t("custom.service"),
          active: false,
          items: [
            {
              title: this.$t("custom.tech_service"),
              icon: "mdi-car-cog",
              to: "/service_tech",
            },
            {
              title: this.$t("maintenance.insurances_and_taxes"),
              icon: "mdi-shield-car",
              to: "/service_insurance",
            },
            {
              title: this.$t("maintenance.payments"),
              icon: "mdi-cash-sync",
              to: "/service_payments",
            },
          ],
        },
      ],
      agents_items: [
        {
          icon: "mdi-cash-register",
          title: this.$t("custom.counts"),
          active: false,
          items: [
            {
              title: this.$t("custom.counts"),
              icon: "mdi-cash-register",
              to: "/counts",
            },
            {
              title: this.$t("header.add_payment"),
              icon: "mdi-book-plus-multiple",
              to: "/counts/new",
            },
          ],
        },
      ],
      isMobile: false,
    };
  },
  created() {
    // if(this.isMobile) {
    //   this.$store.commit("setDrawer", false);
    // }
  },
  computed: {
    appVersion() {
      return this.$store.getters.appVersion;
    },
    getMessages() {
      return this.$store.getters.getMessages;
    },
    messages_length() {
      // Отображаем количество только sender != Host
      if (
        this.$store.getters.getMessages &&
        Array.isArray(this.$store.getters.getMessages) &&
        this.$store.getters.getMessages.length > 0
      ) {
        let filtered = this.$store.getters.getMessages.filter(
          (message) => message.sender != "Host"
        );
        return filtered.length;
      } else {
        return 0;
      }
    },
    main_company() {
      return this.$store.getters.getMainCompany;
    },
    new_booking() {
      return this.$store.getters.getNewBookingState;
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    isPushNotificationsAvailable() {
      return Capacitor.isPluginAvailable("PushNotifications");
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
    isAndroid() {
      return Capacitor.getPlatform().toLowerCase() == "android" ? true : false;
    },
    company_id() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company
      ) {
        return this.$store.getters.getCompany.company.id;
      } else {
        return null;
      }
    },
    company() {
      if (this.$store.getters.getCompany && this.$store.getters.getCompany.company) {
        return this.$store.getters.getCompany.company;
      } else {
        return null;
      }
    },
    show_drawer: {
      get: function () {
        return this.$store.getters.getDrawer
      },
      set: function (newValue) {
        this.$store.commit("setDrawer", newValue)
      }
    },
  },
  methods: {
    replaceToProfile() {
      this.$router.push({ name: "Profile" }).catch(err => { });
    },
    replaceToSalary() {
      this.$router.push({ name: "Salary" }).catch(err => { });
    },
    replaceToCheckLists() {
      this.$router.push({ name: "CheckList" }).catch(err => { });
    },
    replaceToSchedule() {
      this.$router.push({ name: "ScheduleCalendar" }).catch(err => { });
    },
    replaceToCompanyProfile() {
      this.$router.push({ name: "CompanyProfile" }).catch(err => { });
    },
    replaceToCompanyCounts() {
      this.$router.push({ name: "CompanyCounts" }).catch(err => { });
    },
    reverseDrawer() {
      return this.$store.commit("setDrawer", !this.$store.getters.getDrawer);
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true
      } else {
        this.isMobile = false;
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.id : null,
            username: this.$store.getters.getCurrentUser.name ? this.$store.getters.getCurrentUser.name : null,
            email: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.email : null,
          },
          custom: {
            company: this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.name ? this.$store.getters.getCompany.company.name : null,
          }
        }
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t('errors.error'),
        text: `${error}. ${text}`
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
    checkAdmin() {
      return this.$store.getters.isAdmin;
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    checkAdmin() {
      return this.$store.getters.isAdmin;
    },
    checkManager() {
      return this.$store.getters.isManager;
    },
    signOut() {
      let self = this;
      this.axios({
        url: "users/sign_out",
        baseURL:
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000"
            // ? "http://10.0.2.2:3000"
            : "https://rentprog.net",
        method: "delete",
        headers: { Authorization: self.$store.getters.getAuthToken },
      })
        .then(() => {
          this.$store.commit("unsetCurrentUser");
        })
        .catch((error) => {
          self.isLoading = false;
          self.$swal({
            title: self.$t("errors.error"),
            text: error,
            icon: "error",
          });
          console.log(error);
        });
    },
    closeDrawer: function () {
      return this.$store.commit("setDrawer", false);
    }
  },
  components: {
    "v-gravatar": Gravatar
  }
};
</script>
<style>
.drawer-logo {
  padding: 0 !important;
}
