<template>
  <span v-resize="checkMobile">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-card>
      <v-toolbar class="text-center" :height="toolbar_height">
        <v-row align="center" class="text-center">
          <v-col cols="12" lg="4" md="4" sm="12" class="d-flex justify-center align-center">
            <h2 class="text-center">{{ $t("other.balance") }}:
              <span class="font-weight-bold">{{ main_company.balance }} {{ main_company && main_company.plan ? main_company.plan.currency : main_company.currency }}</span>
            </h2>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12" class="d-flex justify-center align-center">
            <v-btn v-if="!isNative" color="success" @click="transactionStart()">
              {{ $t("other.add_balance") }}
              <v-icon class="ml-1"> mdi-plus </v-icon>
            </v-btn>
            <v-btn v-if="isIos" color="success" @click="startInAppPurchaseIos()" :disabled="main_company.plan.currency == 'RUB' || main_company.currency == 'RUB'">
              {{ $t("other.add_balance") }}
              <v-icon class="ml-1"> mdi-plus </v-icon>
            </v-btn>
            <v-btn v-if="isAndroid" color="success" @click="startInAppPurchaseAndroid()" :disabled="main_company.plan.currency == 'RUB' || main_company.currency == 'RUB'">
              {{ $t("other.add_balance") }}
              <v-icon class="ml-1"> mdi-plus </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12" class="d-flex justify-center align-center">
            <v-btn color="info" @click="showRebillDialog = true" v-if="
              $store.getters.getMainCompany.rebill_id &&
              $store.getters.getMainCompany.card_id
            ">
              {{ $t("transfers.edit_rebill") }}
              <v-icon class="ml-1"> mdi-reload </v-icon>
            </v-btn>
            <v-btn v-else-if="
              $store.getters.getMainCompany &&
              $store.getters.getMainCompany.currency == 'RUB'
            " color="info" @click="showRebillDialog = true">
              {{ $t("transfers.start_recurrent") }}
              <v-icon class="ml-1"> mdi-credit-card-plus-outline </v-icon>
            </v-btn>
            <v-btn color="error" icon @click="removeRecurrent()" class="mx-2" v-if="
              $store.getters.getMainCompany.rebill_id &&
              $store.getters.getMainCompany.card_id
            " >
              <v-icon class="ml-1"> mdi-credit-card-off-outline </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-row v-if="main_company && main_company.plan && main_company.plan.name" class="mt-5">
        <v-col cols="12" class="d-flex justify-center align-center text-center">
          <span class="text-h5 font-weight-light">{{ $t("plans.current_plan") }}: <em class="font-weight-regular">{{ main_company.plan.name }}</em></span>
        </v-col>
        <v-col cols="12" v-if="main_company && main_company.plan && main_company.plan.trial_end" class="d-flex justify-center align-center text-center">
          <span class="text-h5 font-weight-light">{{ $t("plans.trial_period_for") }}: <em class="font-weight-regular">{{ formatDate(main_company.plan.trial_end) }}</em></span>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
            }/${$root.$i18n.locale}/tariffs`" target="_blank">
            {{ $t("tariffs.tariffs") }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
          <v-list-item single-line>
              <v-list-item-content>
                <v-list-item-title>{{ $t("plans.vehicles_qty") }}: <span :class="main_company.active_cars_count >= main_company.plan.cars_limit ? 'error--text' : ''">{{ main_company.active_cars_count }}</span>/{{ main_company.plan.cars_limit }}<v-icon v-if="main_company.active_cars_count >= main_company.plan.cars_limit" class="mx-2 mb-1" color="warning">mdi-alert</v-icon></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item single-line>
              <v-list-item-content>
                <v-list-item-title>{{ $t("plans.users_qty") }}: <span :class="main_company.active_users_count >= main_company.plan.users_limit ? 'error--text' : ''">{{ main_company.active_users_count }}</span>/{{ main_company.plan.users_limit }}<v-icon v-if="main_company.active_users_count >= main_company.plan.users_limit" class="mx-2" color="warning">mdi-alert</v-icon></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item single-line>
              <v-list-item-content>
                <v-list-item-title>{{ $t("plans.branches_qty") }}: <span :class="main_company.companies_count >= main_company.plan.branches_limit ? 'error--text' : ''">{{ main_company.companies_count }}</span>/{{ main_company.plan.branches_limit }}<v-icon v-if="main_company.companies_count >= main_company.plan.branches_limit" class="mx-2" color="warning">mdi-alert</v-icon></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item two-line v-if="main_company.plan.payment_type != 'free_plan'">
              <v-list-item-content>
                <v-list-item-title>{{ $t("plans.price") }}: <span class="font-weight-bold">{{ main_company.plan.price }} {{ main_company.plan.currency }}</span></v-list-item-title>
                <v-list-item-subtitle v-if="main_company.plan.payment_period == 'day_period' && main_company.plan.payment_type == 'pay_as_you_go'">{{ $t("plans.price_per_day") }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="main_company.plan.payment_period == 'month_period' && main_company.plan.payment_type == 'pay_as_you_go'">{{ $t("plans.price_per_month") }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="main_company.plan.payment_period == 'month_period' && main_company.plan.payment_type == 'fix_plan'">{{ $t("plans.price_per_month_fix") }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="main_company.plan.payment_period == 'day_period' && main_company.plan.payment_type == 'fix_plan'">{{ $t("plans.price_per_day_fix") }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="main_company.plan.payment_period == 'year_period' && main_company.plan.payment_type == 'fix_plan'">{{ $t("plans.price_per_year_fix") }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-content>
                <v-list-item-title>{{ $t("plans.price") }}: <span class="font-weight-bold">0 {{ main_company.plan.currency }}</span></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line v-if="main_company.plan.payment_period == 'day_period' && main_company.plan.payment_type == 'pay_as_you_go' && main_company.plan.min_payment_sum > 0">
              <v-list-item-content>
                <v-list-item-title>{{ $t("plans.min_payment_sum") }}: <span class="font-weight-bold">{{ main_company.plan.min_payment_sum }} {{ main_company.plan.currency }}</span></v-list-item-title>
                <v-list-item-subtitle>{{ $t("plans.price_all_per_day") }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line v-if="company.whatsapp">
              <v-list-item-content>
                <v-list-item-title>{{ $t("plans.whatsapp_cost") }}: {{ main_company.plan.whatsapp_cost }} {{ main_company.plan.currency }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t("plans.price_per_day_fix") }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item single-line v-if="main_company.plan.fines_cost > 0">
              <v-list-item-content>
                <v-list-item-title>{{ $t("plans.fines_cost") }}: {{ main_company.plan.fines_cost }} {{ main_company.plan.currency }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-btn @click="showChangePlanDialog = true" color="primary" block>{{ $t("plans.change_plan") }}</v-btn>
              </v-list-item-content>
            </v-list-item>
        </v-col>
      </v-row>
      <v-flex v-if="
        $store.getters.getMainCompany &&
        $store.getters.getMainCompany.currency == 'RUB'
      ">
        <v-flex :class="isMobile ? '' : 'd-flex justify-start'">
          <h2 v-if="
            card_list &&
            card_list.length > 0 &&
            $store.getters.getMainCompany.rebill_id &&
            $store.getters.getMainCompany.card_id
          " class="ml-3 mt-3">
            {{ $t("transfers.your_cards") }}
          </h2>
        </v-flex>
        <v-simple-table class="mx-5" v-if="
          card_list &&
          card_list.length > 0 &&
          $store.getters.getMainCompany.rebill_id &&
          $store.getters.getMainCompany.card_id
        ">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t("custom.number_word") }}
                </th>
                <th class="text-center">
                  {{ $t("transfers.current_tooltip") }}
                </th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr :class="item.Status != 'A' ? 'red--text' : ''" v-for="item in card_list" :key="item.CardId">
                <td>{{ item.Pan }}</td>
                <td class="text-center">
                  <v-icon v-if="
                    item.CardId == $store.getters.getMainCompany.card_id
                  " v-tooltip="{
                    content: $t('transfers.current_tooltip'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }">mdi-check</v-icon>
                </td>
                <td class="text-right">
                  <v-icon v-if="item.Status != 'D'" size="30" color="red darken-2" @click="deleteCard(item.CardId)"
                    v-tooltip="{
                      content: $t('transfers.delete_card_tooltip'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">mdi-delete-forever</v-icon>
                  <span v-else>{{ $t("transfers.deleted") }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <hr class="mb-2 mt-2" />
      </v-flex>
      <v-flex>
        <!-- <h2 class="ml-3 mt-3">{{ $t("tariffs.transfers") }}</h2> -->
        <v-data-table :headers="headers" :items="transactions" :search="search" dense
          :loading-text="$t('custom.loading_table')" :items-per-page="20" mobile-breakpoint="100"
          :sort-by="['created_at']" :sort-desc="[true]" :class="!isMobile ? '' : 'is-mobile'" :footer-props="{
            pageText: `{0} ${$t('custom.of')} {1}`,
            itemsPerPageText: $t('custom.elements_table'),
            showFirstLastPage: true,
            itemsPerPageOptions: [20, 50, 100, -1],
          }">
          <template slot="item" slot-scope="props">
            <tr>
              <td class="text-center">{{ props.item.id }}</td>
              <td class="text-center">
                {{ formatCreatedAt(props.item.created_at) }}
              </td>
              <td class="text-center">
                {{ props.item.sum }}
                {{
                  $store.getters.getMainCompany
                    ? $store.getters.getMainCompany.currency
                    : ""
                }}
                {{ props.item.promo_code ? " + " + props.item.promo_code : "" }}
              </td>
              <td class="text-center">
                {{ returnState(props.item.state) }}
              </td>
              <td v-if="props.item.sum >= 0" class="text-center">
                <v-icon class="text-center" color="success">
                  mdi-plus-circle-outline
                </v-icon>
              </td>
              <td v-else class="text-center">
                <v-icon class="text-center" color="error">
                  mdi-minus-circle-outline
                </v-icon>
              </td>
              <td class="text-center">
                {{ props.item.description }}
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ $t("custom.no_data_in_table") }}
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
            {{ $t("tables.no_search_result", { msg: search }) }}
          </v-alert>
        </v-data-table>
      </v-flex>
    </v-card>
    <v-dialog v-model="showTransactionDialog" :retain-focus="false" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-center">
          {{ $t("other.add_balance") }}
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab" light :fixed-tabs="!isMobile" :centered="!isMobile" class="mx-auto">
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-if="
              $store.getters.getMainCompany &&
              $store.getters.getMainCompany.currency == 'RUB'
            ">
              {{ $t("tariffs.rubles") }}
            </v-tab>
            <v-tab v-else> {{ $t("tariffs.euro") }} </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="mx-auto" touchless>
            <v-tab-item v-if="
              $store.getters.getMainCompany &&
              $store.getters.getMainCompany.currency == 'RUB'
            " class="mx-auto">
              <v-flex xs12 md12 class="d-flex align-center justify-center text-center">
                <v-text-field v-model.trim="sum" class="mt-5 mx-auto" @click.once="sum = null"
                  :label="$t('other.sum')"></v-text-field>
              </v-flex>
              <v-flex xs12 md12 class="d-flex align-center justify-center text-center">
                <v-text-field v-model.trim="promo_code" class="mt-5 mx-auto" @click.once="promo_code = null"
                  :label="$t('other.promo_code')"></v-text-field>
              </v-flex>
              <!-- <v-flex xs12 md12 class="d-flex">
                <v-checkbox
                  v-model="save_card"
                  hide-details
                  dense
                  label="Запомнить карту для дальнейших платежей"
                ></v-checkbox>
              </v-flex> -->
              <!-- <v-flex xs12 md12 class="d-flex">
                <v-checkbox
                  v-model="recurrent"
                  hide-details
                  dense
                  label="Подключить услугу автоплатежа (постоплата, списание раз в месяц)"
                ></v-checkbox>
              </v-flex> -->
              <v-flex xs12 md12 class="text-center">
                <v-btn color="success" class="mt-2" @click="transactionCreate()">{{ $t("other.pay") }}</v-btn>
              </v-flex>
              <v-flex xs12 md12 v-if="main_company && main_company.plan && main_company.plan.min_topup_sum">
                <p>{{ $t("other.sum_warn_2", { min_topup_sum: main_company.plan.min_topup_sum, currency:  main_company.plan.currency }) }}</p>
              </v-flex>
            </v-tab-item>
            <v-tab-item v-else>
              <v-flex v-if="
                $store.getters.getCompany.company.inn == null ||
                $store.getters.getCompany.company.inn == ''
              " xs12 md12 mt-2 class="text-center">
                <p>{{ $t("tariffs.inn_warn") }}</p>
                <v-btn color="info" class="mt-2" to="/company_profile">
                  {{ $t("tariffs.inn_link") }} ->
                </v-btn>
              </v-flex>
              <v-flex v-else xs12 md12 mt-2 class="text-center">
                <v-text-field v-model.trim="stripe_sum" :readonly="showStripeForm" @click.once="stripe_sum = null"
                  :label="$t('other.sum')"></v-text-field>
                <v-spacer></v-spacer>
              </v-flex>
              <v-flex xs12 md12 class="d-flex align-center justify-center text-center">
                <v-text-field v-model.trim="promo_code" class="mt-5 mx-auto" @click.once="promo_code = null"
                  :label="$t('other.promo_code')"></v-text-field>
              </v-flex>
              <v-flex xs12 md12 v-if="!isAndroid">
                <stripe-element-payment v-if="showStripeForm" ref="paymentRef" :locale="$root.$i18n.locale" :pk="pk"
                  :testMode="true" :elements-options="elementsOptions" :confirm-params="confirmParams" />
              </v-flex>
              <v-flex v-if="
                $store.getters.getCompany.company.inn != null &&
                $store.getters.getCompany.company.inn != ''
              " xs12 md12 class="text-center">
                <v-btn v-if="!showStripeForm" color="success" class="mt-2" @click="generatePaymentIntent">
                  {{ $t("other.to_pay") }}
                </v-btn>
                <v-btn v-else color="success" class="mt-2" @click="pay">
                  {{ $t("other.pay") }}
                </v-btn>
              </v-flex>
              <v-flex v-if="
                $store.getters.getCompany.company.inn != null &&
                $store.getters.getCompany.company.inn != ''
              " xs12 md12>
                <p>{{ $t("other.sum_warn_euro") }}</p>
                <p>{{ $t("other.sum_warn_2", { min_topup_sum: main_company && main_company.plan && main_company.plan.min_topup_sum ? main_company.plan.min_topup_sum : 0, currency:  main_company && main_company.plan && main_company.plan.currency ? main_company.plan.currency : '' }) }}</p>
              </v-flex>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="(showTransactionDialog = false), (sum = 0)">{{ $t("custom.close")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRebillDialog" :retain-focus="false" persistent max-width="1000px">
      <v-card>
        <v-card-title class="text-center">
          {{ $t("transfers.start_rebill_title") }}
        </v-card-title>

        <v-card-text>
          <v-flex class="">
            <v-flex md12 class="pr-5">
              <v-select v-model="rebill_type" :items="rebill_types" item-text="text" item-value="value"
                :label="$t('transfers.rebill_type')"></v-select>
              <v-flex class="d-flex">
                <v-text-field v-if="rebill_type == 'Sum'" v-model.trim="rebill_limit" class="mt-5 mr-3"
                  @click.once="rebill_limit = null" :label="$t('transfers.rebill_limit_title')"></v-text-field>
                <v-text-field v-if="rebill_type == 'Sum'" v-model.trim="rebill_sum" class="mt-5"
                  @click.once="rebill_sum = null" :label="$t('transfers.rebill_sum_title')"></v-text-field>
              </v-flex>
            </v-flex>
            <v-flex md12>
              <p class="mt-1">*{{ $t("transfers.add_card_info") }}</p>
              <!-- <p class="mt-1">
                {{ $t("transfers.rebill_monthly") }} -
                {{ $t("transfers.rebill_type_monthly") }}
              </p> -->
              <p class="mt-1">
                {{ $t("transfers.rebill_sum") }} -
                {{ $t("transfers.rebill_type_sum") }}
              </p>
              <p class="subtitle-1">
                <a :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/${$root.$i18n.locale}/tariffs`"
                  target="_blank">Тарифы на использование</a>,

                <a :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/${$root.$i18n.locale}/agreement`"
                  target="_blank">Пользовательское соглашение</a>,

                <a :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/${$root.$i18n.locale}/privacy`"
                  target="_blank">Политика конфиденциальности</a>
              </p>
            </v-flex>
          </v-flex>
          <p>
            Оплата производится за доступ к сервису RentProg. При отказе от
            оплаты, доступ к сервису будет закрыт, а неиспользованные средства
            будут возвращены на карту.
          </p>
          <p v-if="rebill_type == 'Monthly'">
            Сумма платежа расчитывается исходя из тарифа на использование
            согласно среднему количеству активных автомобилей за текущий период.
            Количество автомобилей фиксируется ежедневно в 00:00 UTC, при
            расчете берется среднее количество за текущий платежный месяц.
            Отменить автоплатеж можно на
            <a :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/${$root.$i18n.locale}/privacy`"
              target="_blank">текущей странице</a>
            нажав на кнопку "Отключить автоплатеж"
          </p>
          <p v-else>
            При достижении на балансе сервиса указанной суммы происходит
            списание с выбранной карты. Порог баланса и сумма платежа
            указывается пользователем вручную в поля выше, и фиксируется на всю
            длительность услуги. <br />
            Отменить автоплатеж можно на
            <a :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/${$root.$i18n.locale}/privacy`"
              target="_blank">текущей странице</a>
            нажав на кнопку "Отключить автоплатеж".
          </p>
          <p>
            При возникновении вопросов обращайтесь в чат поддержки или по почте
            <a href="mailto:
            admin@rentprog.com">admin@rentprog.com</a>.
          </p>
          <v-checkbox v-model="accept_rebill_1" class="subtitle-1" hide-details dense
            label="Согласен с условиями использования сервиса и услуги Автоплатеж, а так же на обработку персональных данных"></v-checkbox>
          <!-- <v-checkbox
            v-model="accept_rebill_2"
            class="subtitle-1"
            hide-details
            dense
            label="Согласен с условиями услуги Автоплатеж"
          ></v-checkbox> -->
          <!-- <v-checkbox
            v-model="accept_rebill_3"
            class="subtitle-1"
            hide-details
            dense
            label="Согласен автоматические оплату услуг сервиса RentProg согласно тарифам начиная со следующих суток"
          ></v-checkbox> -->
        </v-card-text>

        <v-card-actions class="text-center">
          <v-spacer></v-spacer>
          <v-flex :class="isMobile ? '' : 'd-flex'">
            <v-flex :class="isMobile ? 'mb-3' : ''">
              <v-btn color="success darken-1" :disabled="!accept_rebill" @click="addCardCreate()">{{
                $t("transfers.start_recurrent") }}</v-btn>
            </v-flex>
            <v-flex>
              <v-btn color="blue darken-1" text @click="
                (showRebillDialog = false),
                ((sum = 0),
                  (rebill_type = 'Sum'),
                  (rebill_sum = 1000),
                  (rebill_limit = 1000))
                ">{{ $t("custom.close") }}</v-btn>
            </v-flex>
          </v-flex>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showChangePlanDialog" :retain-focus="false">
      <v-card>
        <v-tabs
          v-model="plans_tab"
          grow
        >
          <v-tabs-slider color="warning"></v-tabs-slider>

          <v-tab
            v-for="item in main_company.standart_plans"
            :key="item.id"
          >
            <v-icon color="success" class="mx-2" v-if="main_company && main_company.plan && main_company.plan.name == item.name">mdi-check</v-icon>
            {{ item.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="plans_tab">
          <v-tab-item
            v-for="item in main_company.standart_plans"
            :key="item.id"
          >
            <v-card flat>
              <v-card-text>
                <v-list-item single-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("plans.vehicles_qty") }}: {{ item.cars_limit }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item single-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("plans.users_qty") }}: {{ item.users_limit }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item single-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("plans.branches_qty") }}: {{ item.branches_limit }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("plans.price") }}: <span class="font-weight-bold">{{ item.price }} {{ item.currency }}</span></v-list-item-title>
                    <v-list-item-subtitle v-if="item.payment_period == 'day_period' && item.payment_type == 'pay_as_you_go'">{{ $t("plans.price_per_day") }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.payment_period == 'month_period' && item.payment_type == 'pay_as_you_go'">{{ $t("plans.price_per_month") }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.payment_period == 'month_period' && item.payment_type == 'fix_plan'">{{ $t("plans.price_per_month_fix") }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.payment_period == 'day_period' && item.payment_type == 'fix_plan'">{{ $t("plans.price_per_day_fix") }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.payment_period == 'year_period' && item.payment_type == 'fix_plan'">{{ $t("plans.price_per_year_fix") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line v-if="item.payment_period == 'day_period' && item.payment_type == 'pay_as_you_go' && item.min_payment_sum > 0">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("plans.min_payment_sum") }}: <span class="font-weight-bold">{{ item.min_payment_sum }} {{ item.currency }}</span></v-list-item-title>
                    <v-list-item-subtitle>{{ $t("plans.price_all_per_day") }}</v-list-item-subtitle>
                  </v-list-item-content>
                 </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("plans.whatsapp_cost") }}: {{ item.whatsapp_cost }} {{ item.currency }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t("plans.price_per_day_fix") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item single-line v-if="item.fines_cost > 0">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("plans.fines_cost") }}: {{ item.fines_cost }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-btn :disabled="checkLimitsPlan(item)" color="success" @click="changePlan(item.id)" :loading="isLoadingChangePlan" block>{{ $t("plans.select_plan") + " " + item.name }}</v-btn>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <h3 class="my-5">{{ $t("plans.included_services") }}</h3>
                <v-list-item single-line v-for="item in item.included_services" :key="item.id">
                  <v-list-item-content>
                    <v-list-item-title><v-icon class="mx-2">mdi-check</v-icon>{{ translateService(item) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions class="text-center">
          <v-spacer></v-spacer>
          <v-btn text @click="showChangePlanDialog = false">{{ $t("custom.close") }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
// import { Stripe, PaymentSheetEventsEnum } from '@capacitor-community/stripe';
import "cordova-plugin-purchase"
import { Capacitor } from "@capacitor/core";
// moment.locale("ru");
// import sha256 from "js-sha256";
// import sha256 from "crypto-js/sha256"
import { isMobile } from "mobile-device-detect";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      transactions: [],
      card_list: [],
      save_card: true,
      recurrent: false,
      accept_rebill: false,
      accept_rebill_1: false,
      accept_rebill_2: false,
      accept_rebill_3: false,
      rebill_types: [
        // { text: this.$t("transfers.rebill_monthly"), value: "Monthly" },
        { text: this.$t("transfers.rebill_sum"), value: "Sum" },
      ],
      rebill_type: "Sum",
      rebill_limit: 1000,
      rebill_sum: 1000,
      showTransactionDialog: false,
      showRebillDialog: false,
      sum: 0,
      stripe_sum: 0,
      promo_code: null,
      in_app_purchase_product: null,
      in_app_purchase_eur: 0,
      in_app_purchase_currency: null,
      in_app_purchase_price: null,
      in_app_purchase_start: false,
      pk: "pk_live_51KtQkKFNgvJ2eyx925rNt6rX9DwYTt6TrcsGIjfIeE7gZWPNSgg4pEC3NKI4tJKiPqgMytdYPPHQ8WhYXtb9Ka9o00htarW7FH",
      elementsOptions: {
        clientSecret: "",
        appearance: {}, // appearance options
      },
      confirmParams: {
        return_url: "https://web.rentprog.com/result_success", // success url
      },
      tab: 0,
      showStripeForm: false,
      headers: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        { text: this.$t("custom.status"), value: "state", align: "center" },
        {
          text: this.$t("custom.operation"),
          value: (item) => {
            if (item.sum < 0) {
              return false;
            } else {
              return true;
            }
          },
          align: "center",
        },
        { text: this.$t("custom.description"), value: "description", align: "center" },
      ],
      pagination: { sortBy: "created_at", descending: true },
      search: null,
      plans_tab: 0,
      showChangePlanDialog: false,
      isMobile: false,
      isLoadingSubscribeToPaidTariff: false,
      isLoadingChangePlan: false,
      isLoading: true,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/company`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.$store.commit("setCompany", { company: response.data });
          self.axios
            .get(`api/v1/main_companies/${response.data.main_company_id}`, {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then((response_main_company) => {
              this.$store.commit("setMainCompany", response_main_company.data);
            })
            .catch((error) => {
              self.isLoading = false;
              self.setError(error, self.$t("errors.main_company_data"));
              console.log(error);
            });
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.company_data"));
          console.log(error);
        });
      this.axios
        .get("/api/v1/transfers", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.transactions = response.data.transfers;
          this.card_list = response.data.card_list;
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.counts_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    accept_rebill_1() {
      if (this.accept_rebill_1) {
        this.accept_rebill = true;
      } else {
        this.accept_rebill = false;
      }
    },
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    currency() {
      if (this.$store.getters.getMainCompany) {
        return this.$store.getters.getMainCompany.currency;
      } else {
        return "EUR";
      }
    },
    userMustChangeTariffPlan() {
      if (this.$store.getters.getMainCompany) {
        if (this.$store.getters.getMainCompany.plan && this.$store.getters.getMainCompany.plan.id) {
          if(this.$store.getters.getMainCompany.plan.name == "Старт" || this.$store.getters.getMainCompany.plan.name == "Start") {
            if(this.$store.getters.getMainCompany.plan.trial_end && moment(this.$store.getters.getMainCompany.plan.trial_end).isAfter(moment())) {
              return false;
            } else {
              return moment(this.$store.getters.getMainCompany.created_at).isBefore(moment().subtract(7, "day")) ? true : false;
            }
          } else {
            return false;
          }
        } else {
          return false
        }
      } else {
        return false;
      }
    },
    toolbar_height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 200
        case 'sm': return 200
        case 'md': return 70
        case 'lg': return 70
        case 'xl': return 70
      }
    },
    daily_payments() {
      if (
        this.$store.getters.getMainCompany &&
        this.$store.getters.getMainCompany.tariff != "Старт" &&
        this.$store.getters.getMainCompany.tariff != "Безлимит"
      ) {
        let daily_payment = 0;
        if (this.$store.getters.getMainCompany.currency == "RUB") {
          if (this.$store.getters.getMainCompany.tariff == "Стандарт") {
            daily_payment = 150.0;
          } else if (this.$store.getters.getMainCompany.tariff == "Стабильный") {
            daily_payment = 135.0;
          } else if (this.$store.getters.getMainCompany.tariff == "Средний") {
            daily_payment = 120.0;
          } else if (
            this.$store.getters.getMainCompany.tariff == "Профессионал"
          ) {
            daily_payment = 105.0;
          } else if (this.$store.getters.getMainCompany.tariff == "Эксперт") {
            daily_payment = 90.0;
          } else if (
            this.$store.getters.getMainCompany.tariff == "Индивидуальный"
          ) {
            daily_payment = 78.0;
          } else if (
            this.$store.getters.getMainCompany.tariff == "Индивидуальный+"
          ) {
            daily_payment =
              parseFloat(this.$store.getters.getMainCompany.manual_price) *
              30.0;
          }
        } else {
          if (this.$store.getters.getMainCompany.tariff == "Стандарт") {
            daily_payment = parseFloat(1.5).toFixed(2);
          } else if (this.$store.getters.getMainCompany.tariff == "Стабильный") {
            daily_payment = parseFloat(1.4).toFixed(2);
          } else if (this.$store.getters.getMainCompany.tariff == "Средний") {
            daily_payment = parseFloat(1.3).toFixed(2);
          } else if (
            this.$store.getters.getMainCompany.tariff == "Профессионал"
          ) {
            daily_payment = parseFloat(1.2).toFixed(3);
          } else if (this.$store.getters.getMainCompany.tariff == "Эксперт") {
            daily_payment = parseFloat(1.1).toFixed(3);
          } else if (
            this.$store.getters.getMainCompany.tariff == "Индивидуальный"
          ) {
            daily_payment = parseFloat(1.0).toFixed(3);
          } else if (
            this.$store.getters.getMainCompany.tariff == "Индивидуальный+"
          ) {
            daily_payment = parseFloat(
              this.$store.getters.getMainCompany.manual_price * 30.0
            ).toFixed(3);
          }
        }
        return daily_payment;
      } else {
        return 0;
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
    isAndroid() {
      return Capacitor.getPlatform().toLowerCase() == "android" ? true : false;
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    main_company() {
      return this.$store.getters.getMainCompany;
    },
    company() {
      return this.$store.getters.getCompany.company;
    },
  },
  methods: {
    transactionStart() {
      if (
        this.$store.getters.getCurrentUser.email ||
        this.$store.getters.getCompany.company.email_for_bookings
      ) {
        this.showTransactionDialog = !this.showTransactionDialog;
      } else {
        this.$swal({
          icon: "warning",
          title: this.$t("errors.no_email"),
          text: this.$t("errors.no_email_text"),
        });
      }
    },
    generatePaymentIntent() {
      if (this.stripe_sum && this.stripe_sum >= 15) {
        this.isLoading = true;
        let self = this;
        this.axios
          .post(
            "/api/v1/stripe_payment_intent",
            {
              transfers: {
                sum: parseFloat(this.stripe_sum.replace(/,/, ".")).toFixed(2),
                promo_code: this.promo_code,
                // sum: 1000
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.elementsOptions.clientSecret = response.data.clientSecret;
            this.isLoading = false;
            this.showStripeForm = true;
            // this.createPaymentSheet(
            //   response.data.client_secret,
            //   response.data.customer_id,
            //   response.data.ephemeral_key_secret
            // ).then(() => {
            //   this.isLoading = false;
            // });

          })
          .catch((error) => {
            this.isLoading = false;
            if (
              error.response &&
              error.response.status &&
              error.response.status == 406
            ) {
              self.$swal({
                title: self.$t("errors.promocode_not_found_title"),
                text: self.$t("errors.promocode_not_found_text"),
                icon: "error",
              });
              this.promo_code = null;
            } else {
              this.setError(error, this.$t("errors.company_data"));
              console.log(error);
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$swal({
          icon: "warning",
          title: this.$t("errors.bad_sum"),
          text: this.$t("errors.bad_sum_text"),
        });
      }
    },
    async createPaymentSheet(client_secret, customer_id, ephemeral_key_secret) {
      let self = this;
      await Stripe.createPaymentSheet({
        paymentIntentClientSecret: client_secret,
        customerId: customer_id,
        customerEphemeralKeySecret: ephemeral_key_secret,
        enableApplePay: false,
        applePayMerchantId: 'merchant.ru.rentprog',
        enableGooglePay: true,
        merchantCountryCode: 'PT',
        merchantDisplayName: 'RentProg',
      }).then(() => {
        self.presentPaymentSheet();
      });
    },
    async presentPaymentSheet() {
      const { error } = await Stripe.presentPaymentSheet({
        confirmPayment: true,
      });

      if (error) {
        console.log(error);
      } else {
        // this.$swal({
        //   icon: "success",
        //   title: this.$t("success"),
        //   text: this.$t("success_text"),
        // });
        // this.$router.push({ name: "home" });
      }
    },
    startInAppPurchaseIos() {
      let self = this;
      var myProduct;
      const { store, ProductType, Platform, LogLevel, TransactionState } = CdvPurchase;
      // set timeout to wait for platform ready
      this.isLoading = true;
      store.off();
      store.verbosity = LogLevel.INFO;
      store.register([
        {
          type: CdvPurchase.CONSUMABLE,
          id: 'ru.rentprog',
          platform: Platform.APPLE_APPSTORE,
        }
      ]);
      store.error(function (error) {
        console.error('CdvPurchase ERROR: ' + error.message);
      });
      store.initialize([Platform.APPLE_APPSTORE]);
      setTimeout(() => {
        self.isLoading = false;
        store.ready(() => {
          console.log('Platform is ready');
          // const monitor = store.monitor(myTransaction, state => {
          //   console.log('new state: ' + state);
          //   if (state === TransactionState.FINISHED)
          //     monitor.stop();
          // });
          store.update();

          const myProduct = store.get('ru.rentprog', Platform.APPLE_APPSTORE)
          if (myProduct) {
            console.log('myProduct found', myProduct);
            self.in_app_purchase_product = myProduct;
            self.in_app_purchase_currency = self.in_app_purchase_product['offers'][0]['pricingPhases'][0]['currency']
            self.in_app_purchase_price = self.in_app_purchase_product['offers'][0]['pricingPhases'][0]['priceMicros'] / 1000000
            // get currecy price in euro
            self.isLoading = true;
            let url = `https://api.currencyapi.com/v3/latest?apikey=fca_live_sBpm46aqmsrZYxpWWytCPi2Uoy6OfCfxqrSshcpr&base_currency=${self.in_app_purchase_currency}&currencies=${self.currency}`

            self.axios({
              url: url,
              method: "get",
            })
              .then((response) => {
                let value = response.data.data[self.currency].value;
                self.in_app_purchase_eur = (parseFloat(value * self.in_app_purchase_price)).toFixed(2);
              })
              .then((response) => {
                self.isLoading = false;
                let without_commison = (self.in_app_purchase_eur - (parseFloat(self.in_app_purchase_eur * 0.3))).toFixed(2);
                console.log('without_commison', without_commison);
                self.$swal({
                  title: self.$t("custom.you_sure_title"),
                  text: self.$t("transfers.in_app_purchase_question", { payment_sum: self.in_app_purchase_price, payment_currency: self.in_app_purchase_currency, top_up_sum: without_commison, top_up_currency: self.currency }),
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: self.$t("custom.yes"),
                  cancelButtonText: self.$t("custom.no"),
                  showCloseButton: true,
                }).then((result) => {
                  if (result.value) {
                    console.log('want to buy')
                    const myTransaction = store.findInLocalReceipts(myProduct);

                    myProduct.getOffer().order().then((transaction) => {
                      console.log(transaction);
                    });
                    store.when('product').approved((product) => {
                      this.topUpBalanceFromInAppPurchase();
                      product.finish();
                      store.update();
                      // store.off();
                    });
                  }
                });
              })
              .catch((error) => {
                self.isLoading = false;
                self.setError(error, self.$t("errors.error"));
                console.log(error);
              })
              .finally(() => {
                self.isLoading = false;
              });
          } else {
            self.$swal({
              icon: "warning",
              title: self.$t("errors.error"),
              text: self.$t("errors.payment_creating"),
            });
            console.log('myProduct not found', myProduct);
          }
        });
      }, 2000);


    },
    async startInAppPurchaseAndroid() {
      let self = this;
      // We register a dummy product.
      CdvPurchase.store.register({
        id: "ru.rentprog.20",
        type: CdvPurchase.ProductType.CONSUMABLE,
        platform: CdvPurchase.Platform.GOOGLE_PLAY,
        group: 'default',
      });
      CdvPurchase.store.verbosity = CdvPurchase.LogLevel.DEBUG;
      CdvPurchase.store.error(function (error) {
        console.error('CdvPurchase ERROR: ' + error.message);
      });
      CdvPurchase.store
        .when()
        .approved(p => {
          console.log('approved', p);
        })
        .verified(p => {
          console.log('verified', p);
        })
        .unverified(p => {
          console.log('unverified', p);
        })
        .finished(p => {
          console.log('finished', p);
        });


      await CdvPurchase.store.initialize([CdvPurchase.Platform.GOOGLE_PLAY]);
      await CdvPurchase.store.update();


      CdvPurchase.store.ready(() => {
        console.log('store ready');
        const myProduct = CdvPurchase.store.get('ru.rentprog.20', CdvPurchase.Platform.GOOGLE_PLAY)
        console.log('myProduct', myProduct);
        if (myProduct) {
          console.log('myProduct found', myProduct);
          self.in_app_purchase_product = myProduct;
          self.in_app_purchase_currency = self.in_app_purchase_product['offers'][0]['pricingPhases'][0]['currency']
          self.in_app_purchase_price = self.in_app_purchase_product['offers'][0]['pricingPhases'][0]['priceMicros'] / 1000000
          // get currecy price in euro
          self.isLoading = true;
          let url = `https://api.currencyapi.com/v3/latest?apikey=fca_live_sBpm46aqmsrZYxpWWytCPi2Uoy6OfCfxqrSshcpr&currencies=${self.currency}&base_currency=${self.in_app_purchase_currency}`

          self.axios({
            url: url,
            method: "get",
          })
            .then((response) => {
              let value = response.data.data[self.currency].value;
              self.in_app_purchase_eur = (parseFloat(value * self.in_app_purchase_price)).toFixed(2);
            })
            .then((response) => {
              self.isLoading = false;
              let without_commison = (self.in_app_purchase_eur - (parseFloat(self.in_app_purchase_eur * 0.3))).toFixed(2);
              self.$swal({
                title: self.$t("custom.you_sure_title"),
                text: self.$t("transfers.in_app_purchase_question", { payment_sum: self.in_app_purchase_price, payment_currency: self.in_app_purchase_currency, top_up_sum: without_commison, top_up_currency: self.currency }),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: self.$t("custom.yes"),
                cancelButtonText: self.$t("custom.no"),
                showCloseButton: true,
              }).then((result) => {
                if (result.value) {
                  console.log('want to buy')
                  const myTransaction = CdvPurchase.store.findInLocalReceipts(myProduct);

                  myProduct.getOffer().order().then((transaction) => {
                    console.log(transaction);
                  });
                  CdvPurchase.store.when('product').approved((product) => {
                    this.topUpBalanceFromInAppPurchase();
                    product.finish();
                    CdvPurchase.store.update();
                    // store.off();
                  });
                }
              });
            })
            .catch((error) => {
              self.isLoading = false;
              self.setError(error, self.$t("errors.error"));
              console.log(error);
            })
            .finally(() => {
              self.isLoading = false;
            });
        } else {
          self.$swal({
            icon: "warning",
            title: self.$t("errors.error"),
            text: self.$t("errors.payment_creating"),
          });
          console.log('myProduct not found', myProduct);
        }
      });
    },
    topUpBalanceFromInAppPurchase() {
      console.log('topUpBalanceFromInAppPurchase')
      if (!this.in_app_purchase_start) {
        this.in_app_purchase_start = true;
        this.isLoading = true;
        this.axios
          .post(
            "/api/v1/create_from_in_app_purchase",
            {
              transfers: {
                sum: parseFloat(this.in_app_purchase_eur - (this.in_app_purchase_eur * 0.3)).toFixed(2),
                recurrent: false,
                turn_off: false,
                state: 1,
                status: 'Paid In App Purchase',
                paid: true
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.transactions.unshift(response.data.transfer);
            this.$store.commit("setMainCompany", response.data.main_company);
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    subscribeToPaidTariff() {
      let self = this;
      this.$swal({
        title: this.$t("custom.you_sure_title"),
        text: this.$t("custom.to_subscribe_to_paid_tariff_warning"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.yes"),
        cancelButtonText: this.$t("custom.no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value && this.$store.getters.getMainCompany && this.$store.getters.getMainCompany.id) {
          this.isLoadingSubscribeToPaidTariff = true;
          this.axios
            .post(`/api/v1/subscribe_to_paid_v2`,
              {
                main_company_id: this.$store.getters.getMainCompany.id,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
            .then(() => {
              this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("custom.success"),
              });
              let main_company = this.$store.getters.getMainCompany;
              main_company.tariff = "Стандарт";
              this.$store.commit("setMainCompany", main_company);
            })
            .catch((error) =>
              this.setError(error, this.$t("errors.error"))
            )
            .finally(() => (this.isLoadingSubscribeToPaidTariff = false));
        }
      });
    },
    pay() {
      this.$refs.paymentRef.submit();
    },
    transactionCreate() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (
          this.sum &&
          parseFloat(this.sum.replace(/,/, ".")) &&
          parseFloat(this.sum.replace(/,/, ".")) > 0
        ) {
          if (parseInt(this.sum) >= (this.main_company.plan.min_topup_sum || 0)) {
            this.isLoading = true;
            let self = this;
            // Создаём транзакцию у нас
            this.axios
              .post(
                "/api/v1/create_transfer",
                {
                  transfers: {
                    sum: parseFloat(this.sum.replace(/,/, ".")).toFixed(2),
                    // save_card: this.save_card,
                    recurrent: this.recurrent,
                    turn_off: false,
                    promo_code: this.promo_code,
                  },
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                if (response.data.Success == true && response.data.PaymentURL) {
                  window.location.replace(response.data.PaymentURL);
                  this.showTransactionDialog = false;
                  this.sum = 0;
                } else {
                  this.$swal({
                    icon: "error",
                    title: response.data.Message,
                    text: response.data.Details,
                  });
                }
              })
              .catch((error) => {
                this.isLoading = false;
                if (
                  error.response &&
                  error.response.status &&
                  error.response.status == 406
                ) {
                  self.$swal({
                    title: self.$t("errors.promocode_not_found_title"),
                    text: self.$t("errors.promocode_not_found_text"),
                    icon: "error",
                  });
                  this.promo_code = null;
                } else {
                  this.setError(error, this.$t("errors.payment_creating"));
                  this.$swal({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "error",
                    title: this.$t("errors.payment_creating"),
                    text: error,
                  });
                }
              })
              .finally(() => (this.isLoading = false));
          } else {
            this.$swal({
              icon: "warning",
              title: this.$t("other.payment_min", { min_topup_sum: this.main_company && this.main_company.plan && this.main_company.plan.min_topup_sum ? this.main_company.plan.min_topup_sum : 0, currency:  this.main_company && this.main_company.plan && this.main_company.plan.currency ? this.main_company.plan.currency : '' }),
            });
          }
        } else {
          this.$swal({
            icon: "warning",
            title: this.$t("errors.payment_sum"),
            text: this.$t("errors.payment_sum_text"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    addCardCreate() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let self = this;
        // Создаём транзакцию у нас
        this.axios
          .post(
            "/api/v1/create_transfer",
            {
              transfers: {
                sum: 10,
                recurrent: true,
                rebill_type: this.rebill_type,
                rebill_sum: this.rebill_sum,
                rebill_limit: this.rebill_limit,
                turn_off: false,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            if (response.data.Success == true && response.data.PaymentURL) {
              window.location.replace(response.data.PaymentURL);
              this.showTransactionDialog = false;
            } else {
              this.$swal({
                icon: "error",
                title: response.data.Message,
                text: response.data.Details,
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.payment_creating"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.payment_creating"),
              text: error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    removeRecurrent() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        this.axios
          .post(
            "/api/v1/turn_off_rebill",
            {
              turn_off: true,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("transfers.removed_recurrent"),
            });
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.payment_creating"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.payment_creating"),
              text: error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    tryRebill() {
      this.isLoading = true;
      this.axios
        .post(
          "/api/v1/try_rebill",
          {
            transfers: {
              sum: 10,
            },
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: this.$t("transfers.removed_recurrent"),
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.payment_creating"));
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: this.$t("errors.payment_creating"),
            text: error,
          });
        })
        .finally(() => (this.isLoading = false));
    },
    deleteCard(CardId) {
      this.isLoading = true;
      this.axios
        .post(
          "/api/v1/delete_card",
          {
            CardId: CardId,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.isLoading = false;
          let card = this.card_list.find((card) => card.CardId == CardId);
          card.Status = "D";
          this.card_list.splice(this.card_list.indexOf(card), 1, card);
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.card_delete"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    translateService(item) {
      // booking_management
      // calendar
      // fines_monitoring
      // docs_generation
      // analitics
      // day_plan
      // services
      // simple_cashbox
      // store
      // partners
      // agents
      // export_to_excel
      // pdf_docs_generation
      // email_notifications
      // sms_notifications
      // whatsapp_notifications
      // telegram_notifications
      // push_notifications
      // booking_website_form
      // localrent
      // amocrm
      // avito
      // payments_integration
      // beacons
      // api
      // webhooks
      if (item == "booking_management") {
        return this.$t("included_services.booking_management");
      } else if (item == "calendar") {
        return this.$t("included_services.calendar");
      } else if (item == "fines_monitoring") {
        return this.$t("included_services.fines_monitoring");
      } else if (item == "docs_generation") {
        return this.$t("included_services.docs_generation");
      } else if (item == "analitics") {
        return this.$t("included_services.analitics");
      } else if (item == "day_plan") {
        return this.$t("included_services.day_plan");
      } else if (item == "services") {
        return this.$t("included_services.services");
      } else if (item == "simple_cashbox") {
        return this.$t("included_services.simple_cashbox");
      } else if (item == "scheduler") {
        return this.$t("included_services.scheduler");
      } else if (item == "store") {
        return this.$t("included_services.store");
      } else if (item == "partners") {
        return this.$t("included_services.partners");
      } else if (item == "agents") {
        return this.$t("included_services.agents");
      } else if (item == "export_to_excel") {
        return this.$t("included_services.export_to_excel");
      } else if (item == "pdf_docs_generation") {
        return this.$t("included_services.pdf_docs_generation");
      } else if (item == "email_notifications") {
        return this.$t("included_services.email_notifications");
      } else if (item == "sms_notifications") {
        return this.$t("included_services.sms_notifications");
      } else if (item == "whatsapp_notifications") {
        return this.$t("included_services.whatsapp_notifications");
      } else if (item == "telegram_notifications") {
        return this.$t("included_services.telegram_notifications");
      } else if (item == "push_notifications") {
        return this.$t("included_services.push_notifications");
      } else if (item == "booking_website_form") {
        return this.$t("included_services.booking_website_form");
      } else if (item == "localrent") {
        return this.$t("included_services.localrent");
      } else if (item == "amocrm") {
        return this.$t("included_services.amocrm");
      } else if (item == "avito") {
        return this.$t("included_services.avito");
      } else if (item == "payments_integration") {
        return this.$t("included_services.payments_integration");
      } else if (item == "beacons") {
        return this.$t("included_services.beacons");
      } else if (item == "api") {
        return this.$t("included_services.api");
      } else if (item == "webhooks") {
        return this.$t("included_services.webhooks");
      } else if (item == "workers_schedule") {
        return this.$t("included_services.workers_schedule");
      } else if (item == "history") {
        return this.$t("included_services.history");
      } else {
        return item;
      }
    },
    checkLimitsPlan(plan) {
      if (this.main_company && this.main_company.plan && (this.main_company.plan.standart_plan_id == plan.id || this.main_company.active_cars_count > plan.cars_count || this.main_company.active_users_count > plan.users_limit || this.main_company.companies_count > plan.branches_limit)) {
        return true;
      } else {
        return false;
      }
    },
    changePlan(plan_id) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.$swal({
          title: this.$t("plans.change_plan_warning_title"),
          text: this.$t("plans.change_plan_warning_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("plans.confirm_change"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoadingChangePlan = true;
            this.axios
            .post(`/api/v1/change_plan`,
              {
                plan_id: plan_id,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                let main_company = this.$store.getters.getMainCompany;
                main_company.plan = response.data.plan;
                this.$swal({
                  toast: true,
                  position: "center",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "success",
                  title: this.$t("custom.success"),
                });
                this.$store.commit("setMainCompany", main_company);
                this.showChangePlanDialog = false;
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.error"));
              })
              .finally(() => (this.isLoadingChangePlan = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    returnState(state) {
      if (state == 1) {
        return this.$t("other.success");
      } else if (state == 4) {
        return this.$t("other.returned");
      } else if (state == 3) {
        return this.$t("errors.error");
      } else {
        return "?";
      }
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("DD MMM H:mm:ss");
    },
    formatDate(date) {
      return moment(date).format("LL");
    },
    returnDate(item) {
      return moment.parseZone(item).format("LL");
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    StripeElementPayment,
  },
};
</script>
