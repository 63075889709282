<template>
  <span v-resize="checkMobile" :style="isMobile ? 'padding: 5px !important;' : ''">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-card>
      <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'">
        <v-flex class="d-flex align-center justify-space-between">
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('custom.search')" single-line clearable
            hide-details></v-text-field>
          <v-spacer></v-spacer>
          <v-btn small color="success" @click="showNewContractorDialog = true">
            {{ $t("contractors.add_contractor") }}
          </v-btn>
          <!-- <v-icon v-if="contractors &&
            contractors.length > 0 &&
            (user_role == 'superadmin' || user_role == 'admin')
            " @click="toXlsx" class="ml-2" style="cursor: pointer" color="success">
            mdi-file-excel-outline
          </v-icon> -->
          <v-btn icon :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
            }/${$root.$i18n.locale}/categories/4/guides/14`" target="_blank">
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
        </v-flex>
      </v-toolbar>
      <v-data-table :headers="headers" :items="contractors" :search="search" :loading="isLoading"
        :loading-text="$t('custom.loading_table')" :items-per-page="50" mobile-breakpoint="100" sort-by="id" sort-desc
        :class="!isMobile ? '' : 'is-mobile'" dense :footer-props="{
          pageText: `{0} ${$t('custom.of')} ${total}`,
          itemsPerPageText: $t('custom.elements_table'),
          showFirstLastPage: true,
          itemsPerPageOptions: [50, 100, 200],
        }">
        <template slot="item" slot-scope="props">
          <tr :class="" @dblclick="replaceToContractor(props.item)" style="cursor: pointer">
            <td class="text-center" :key="props.item.id">
              {{ props.item.id }}
            </td>
            <td class="text-center">
              {{ props.item.full_name }}
            </td>
            <td class="text-center">
              {{ props.item.short_name }}
            </td>
            <td class="text-center">
              {{ props.item.entity_type }}
            </td>
            <td class="text-center">
              {{ props.item.tax_number }}
            </td>
            <td class="text-center">
              {{ props.item.tin_number }}
            </td>
            <td class="text-center">
              {{ props.item.phone }}
            </td>
            <td class="text-center">
              <v-icon small class="mr-2" @click="replaceToContractor(props.item)"
                :title="$t('contractors.go_to_contractor')">
                mdi-eye-outline
              </v-icon>
              <v-icon small @click="deleteItem(props.item)" v-if="user_role == 'superadmin' || user_role == 'admin'">
                mdi-delete-forever
              </v-icon>
            </td>
          </tr>
        </template>
        <template slot="no-data">
          {{ $t("custom.no_data_in_table") }}
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
          {{ $t("tables.no_search_result", { msg: search }) }}
        </v-alert>
      </v-data-table>
    </v-card>
    <v-dialog v-model="showNewContractorDialog" :retain-focus="false" persistent max-width="1200px"
      :fullscreen="isMobile">
      <v-card>
        <v-card-title>
          <v-flex>
            <h2 v-if="!isMobile">{{ $t("contractors.add_contractor") }}</h2>
            <h3 v-else>
              {{ $t("contractors.add_contractor") }}
            </h3>
          </v-flex>
          <v-flex class="ml-5" v-if="isMobile">
            <v-icon @click="showNewContractorDialog = false">mdi-close</v-icon>
          </v-flex>
          <v-icon v-if="!isMobile" class="ml-3" @click="showNewContractorDialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-container v-if="contractor">
              <v-layout row wrap>
                <v-flex xs12 sm12 md4>
                  <v-text-field v-model.trim="contractor.full_name" :label="$t('custom.company_name')"
                    :placeholder="$t('custom.company_name_placeholder')" outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field v-model.trim="contractor.short_name" :label="$t('custom.company_name_short')"
                    :placeholder="$t('custom.company_name_short_plc')" outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field v-model.trim="contractor.entity_type" :label="$t('custom.reg_form')"
                    :placeholder="$t('custom.reg_form_plc')" outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md3>
                  <v-text-field v-model.trim="contractor.tax_number" :label="$t('custom.tax_number')"
                    outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md3>
                  <v-text-field v-model.trim="contractor.tin_number" :label="$t('custom.entity_number')"
                    outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md3>
                  <v-text-field v-model.trim="contractor.phone" :label="$t('custom.phone')" type="tel"
                    outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md3>
                  <v-text-field v-model.trim="contractor.email" :label="$t('custom.email')" outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field v-model.trim="contractor.account" :label="$t('custom.entity_bank_number')"
                    outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field v-model.trim="contractor.bank_name" :label="$t('custom.bank_name')"
                    :placeholder="$t('custom.bank_name_plc')" outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field v-model.trim="contractor.bank_number" :label="$t('custom.bank_number')"
                    outlined></v-text-field>
                </v-flex>
                <v-flex md12>
                  <v-textarea v-model.trim="contractor.address" :label="$t('custom.entity_address')" outlined rows="1"
                    auto-grow></v-textarea>
                </v-flex>
                <v-flex md12>
                  <v-textarea v-model.trim="contractor.description" :label="$t('custom.description')" outlined rows="1"
                    auto-grow></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveNewContractor()">{{ $t("custom.save") }}</v-btn>
          <v-btn @click="showNewContractorDialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEditContractorDialog" :retain-focus="false" persistent max-width="1200px"
      :fullscreen="isMobile">
      <v-card>
        <v-card-title>
          <v-flex>
            <h2 v-if="!isMobile">{{ $t("contractors.add_contractor") }}</h2>
            <h3 v-else>
              {{ $t("contractors.add_contractor") }}
            </h3>
          </v-flex>
          <v-flex class="ml-5" v-if="isMobile">
            <v-icon @click="showEditContractorDialog = false">mdi-close</v-icon>
          </v-flex>
          <v-icon v-if="!isMobile" class="ml-3" @click="showEditContractorDialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-container v-if="contractor">
              <v-layout row wrap>
                <v-flex xs12 sm12 md4>
                  <v-text-field v-model.trim="contractor.full_name" :label="$t('custom.company_name')"
                    :placeholder="$t('custom.company_name_placeholder')" outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field v-model.trim="contractor.short_name" :label="$t('custom.company_name_short')"
                    :placeholder="$t('custom.company_name_short_plc')" outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field v-model.trim="contractor.entity_type" :label="$t('custom.reg_form')"
                    :placeholder="$t('custom.reg_form_plc')" outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md3>
                  <v-text-field v-model.trim="contractor.tax_number" :label="$t('custom.tax_number')"
                    outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md3>
                  <v-text-field v-model.trim="contractor.tin_number" :label="$t('custom.entity_number')"
                    outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md3>
                  <v-text-field v-model.trim="contractor.phone" :label="$t('custom.phone')" type="tel"
                    outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md3>
                  <v-text-field v-model.trim="contractor.email" :label="$t('custom.email')" outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field v-model.trim="contractor.account" :label="$t('custom.entity_bank_number')"
                    outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field v-model.trim="contractor.bank_name" :label="$t('custom.bank_name')"
                    :placeholder="$t('custom.bank_name_plc')" outlined></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field v-model.trim="contractor.bank_number" :label="$t('custom.bank_number')"
                    outlined></v-text-field>
                </v-flex>
                <v-flex md12>
                  <v-textarea v-model.trim="contractor.address" :label="$t('custom.entity_address')" outlined rows="1"
                    auto-grow></v-textarea>
                </v-flex>
                <v-flex md12>
                  <v-textarea v-model.trim="contractor.description" :label="$t('custom.description')" outlined rows="1"
                    auto-grow></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveEditContractor()">{{ $t("custom.save") }}</v-btn>
          <v-btn @click="showEditContractorDialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { saveAs } from "file-saver";
import Uppy from "../../../plugins/uppy";
import { isMobile } from "mobile-device-detect";
import xlsx from "json-as-xlsx";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      contractors: [],
      contractor: {},
      search: null,
      headers: [
        {
          text: this.$t("custom.number"),
          align: "center",
          value: "id",
        },
        {
          text: this.$t("custom.company_name"),
          align: "center",
          value: "full_name",
        },
        {
          text: this.$t("custom.company_name_short"),
          align: "center",
          value: "short_name",
        },
        {
          text: this.$t("custom.reg_form"),
          align: "center",
          value: "entity_type",
        },
        {
          text: this.$t("custom.tax_number"),
          align: "center",
          value: "tax_number",
        },
        {
          text: this.$t("custom.entity_number"),
          align: "center",
          value: "tin_number",
        },
        {
          text: this.$t("custom.phone"),
          align: "center",
          value: "phone",
        },
        { text: this.$t("custom.editor"), sortable: false, align: "center" },
      ],
      showNewContractorDialog: false,
      showEditContractorDialog: false,
      timeout: null,
      total: 0,
      isMobile: false,
      isLoading: true,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get("/api/v1/contractors", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.contractors.push(element.attributes);
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.branch_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    checkWriteFile() {
      return Capacitor.isNativePlatform() && Filesystem.checkPermissions();
    },
    requestPermissionsWriteFile() {
      return Filesystem.requestPermissions();
    },
  },
  methods: {
    saveNewContractor() {
      if (this.user_role != "guest" || this.user_role != "partner") {
        this.isLoading = true;
        this.axios
          .post(
            "/api/v1/contractors",
            {
              contractors: this.contractor,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.contractors.push(response.data);
            this.$swal(
              this.$t("custom.saved"),
              this.$t("contractors.contractor_added"),
              "success"
            );
            this.showNewContractorDialog = false;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteItem(client) {
      if (this.user_role != "guest" || this.user_role != "partner") {
        let self = this;
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/clients/${client.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then(() => {
                this.clients.splice(this.clients.indexOf(client), 1);
                this.$swal(
                  this.$t("custom.deleted"),
                  this.$t("clients.remove"),
                  "success"
                );
              })
              .catch((error) => {
                // check if error is 406
                if (error.response.status == 406) {
                  this.$swal({
                    showConfirmButton: true,
                    icon: "error",
                    title: this.$t("errors.client_delete"),
                    text: this.$t("errors.delete_client_has_bookings"),
                  });
                } else {
                  this.setError(error, this.$t("errors.client_delete"))
                }
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    toXlsx() {
      if (this.user_role != "guest" || this.user_role != "partner") {
        this.isLoading = true;
        this.axios
          .get(`/api/v1/clients`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            let clients = [];
            let entity_clients = [];
            response.data.data.forEach((element) => {
              if (!element.attributes.entity) {
                clients.push(element.attributes);
              } else {
                entity_clients.push(element.attributes);
              }
            });
            let data = [
              {
                sheets: "Clients",
                columns: [
                  { label: "ID", value: "id" },
                  { label: this.$t("clients.created_at"), value: "created_at" },
                  { label: this.$t("bookings.name"), value: "name" },
                  {
                    label: this.$t("bookings.middlename"),
                    value: "middlename",
                  },
                  { label: this.$t("bookings.lastname"), value: "lastname" },
                  { label: this.$t("custom.phone"), value: "phone" },
                  { label: "Email", value: "email" },
                  {
                    label: this.$t("bookings.birthdate"),
                    value: (row) => {
                      return row.birthday && row.birthday != "Invalid date"
                        ? this.birthdayFormat(row.birthday)
                        : "";
                    },
                  }, // Custom format
                  {
                    label: this.$t("clients.category"),
                    value: (row) => {
                      return this.localeClientCategory(row.category);
                    },
                  },
                  { label: this.$t("clients.tax_number"), value: "inn" },
                  { label: this.$t("clients.debtor"), value: "debtor" },
                  { label: this.$t("clients.source"), value: "source" },
                  { label: this.$t("clients.country"), value: "country" },
                  { label: this.$t("clients.city"), value: "city" },
                  { label: this.$t("clients.address"), value: "address" },
                  {
                    label: this.$t("clients.branch"),
                    value: (row) => {
                      return this.branchCity(row.company_id);
                    },
                  },
                  {
                    label: this.$t("clients.driver_series"),
                    value: "driver_series",
                  },
                  {
                    label: this.$t("clients.driver_number"),
                    value: "driver_number",
                  },
                  {
                    label: this.$t("clients.driver_issued"),
                    value: "driver_issued",
                  },
                  {
                    label: this.$t("clients.passport_series"),
                    value: "passport_series",
                  },
                  {
                    label: this.$t("clients.passport_number"),
                    value: "passport_number",
                  },
                  {
                    label: this.$t("clients.passport_issued"),
                    value: "passport_issued",
                  },
                  { label: this.$t("clients.sale"), value: "sale" },
                  { label: this.$t("clients.sale_cash"), value: "sale_cash" },
                ],
                content: clients,
              },
              {
                sheets: "Entity Clients",
                columns: [
                  { label: "ID", value: "id" },
                  { label: this.$t("clients.created_at"), value: "created_at" },
                  { label: this.$t("clients.reg_form"), value: "reg_form" },
                  { label: this.$t("companies.name"), value: "entity_name" },
                  { label: this.$t("clients.ceo"), value: "ceo" },
                  { label: this.$t("custom.phone"), value: "entity_phone" },
                  { label: "Email", value: "email" },
                  {
                    label: this.$t("clients.category"),
                    value: (row) => {
                      return this.localeClientCategory(row.category);
                    },
                  },
                  { label: this.$t("clients.tax_number"), value: "inn" },
                  { label: this.$t("clients.debtor"), value: "debtor" },
                  {
                    label: this.$t("clients.branch"),
                    value: (row) => {
                      return this.branchCity(row.company_id);
                    },
                  },
                  { label: this.$t("clients.country"), value: "country" },
                  { label: this.$t("clients.city"), value: "city" },
                  { label: this.$t("clients.address"), value: "entity_adress" },
                  { label: this.$t("clients.tin"), value: "ogrn" },
                  { label: this.$t("clients.bank"), value: "bank" },
                  {
                    label: this.$t("clients.checking_account"),
                    value: "acc_number",
                  },
                  {
                    label: this.$t("clients.correspondent_account"),
                    value: "korr",
                  },
                ],
                content: entity_clients,
              },
            ];
            let settings = {
              fileName: "clients", // Name of the resulting spreadsheet
              extraLength: 3, // A bigger number means that columns will be wider
              writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
              writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
              RTL: false, // Display the columns from right-to-left (the default value is false)
            };
            xlsx(data, settings); // Will download the excel file
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.clients_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    localeClientCategory(category) {
      if (category == "Новый") {
        return this.$t("customer_category.new");
      } else if (category == "Постоянный") {
        return this.$t("customer_category.statement");
      } else if (category == "Серый") {
        return this.$t("customer_category.problem");
      } else if (category == "Чёрный") {
        return this.$t("customer_category.bad");
      } else if (category == "Лояльный") {
        return this.$t("customer_category.loyal");
      } else {
        return "";
      }
    },
    replaceToContractor(contractor) {
      this.$router.push({ path: `/contractors/${contractor.id}` });
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    Uppy,
  },
};
</script>
