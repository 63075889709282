<template>
  <v-row v-if="documents.length > 0">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-row>
      <v-col
        cols="12"
      >
        <v-list subheader>
          <v-subheader>{{ $t('custom.files') }}</v-subheader>

          <v-list-item
            v-for="item in documents"
            :key="item.id"
          >
            <v-list-item-avatar tile>
              <v-img
                v-if="item.document_type && item.document_type.includes('image')"
                :alt="item.original_name ? item.original_name : item.id"
                :src="item.url"
                @click="screenFullImage(item)"
                style="cursor: pointer;"
                aspect-ratio="1"
                class="grey lighten-2"
              >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              </v-img>
              <v-icon
                v-else
                color="grey"
                @click="screenFullImage(item)"
              >
                mdi-file
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-if="edit_filename_id != item.id" @click="edit_filename_id = item.id" v-text="item.original_name ? item.original_name : item.id" style="cursor: pointer;"></v-list-item-title>
              <v-text-field
                v-else
                v-model="item.original_name"
                @keyup.enter="updateFileInfo(item)"
                @blur="updateFileInfo(item)"
                :loading="isLoadingUpdateFileInfo"
                autofocus
              ></v-text-field>
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon @click="downloadItem(item)" color="success" class="mr-2">
                mdi-content-save-outline
              </v-icon>
              <v-icon @click="deleteItem(item)" color="error">
                mdi-delete-forever
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" :retain-focus="false" persistent max-width="800">
      <v-card class="mt-2">
        <v-card-text>
          <v-img :src="link_full_image" contain class="grey lighten-2"> </v-img>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(dialog = false), (link_full_image = '')"
          >
            {{ $t('custom.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { saveAs } from "file-saver";
export default {
  props: {
    documents: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      // documents: [],
      gallery_items: [],
      link_full_image: "",
      edit_filename_id: null,
      dialog: false,
      isLoadingUpdateFileInfo: false,
      isLoading: false,
    };
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role
      } else {
        return ""
      }
    },
  },
  methods: {
    screenFullImage(item) {
      this.link_full_image = item.url;
      this.dialog = true;
    },
    downloadItem(item) {
      saveAs(`${item.url}`);
    },
    updateFileInfo(item) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (!item.original_name || item.original_name.trim() === "") {
          return;
        }
        this.isLoadingUpdateFileInfo = true;
        this.axios
          .patch(`/api/v1/clients_files/${item.id}`, {
            clients_files: {
              original_name: item.original_name.trim()
            }
          }, {
            headers: {
              Authorization: this.$store.getters.getAuthToken
            }
          })
          .then(() => {
            this.edit_filename_id = null;
          })
          .catch(error => {
            this.setError(error, this.$t('errors.error'));
          })
          .finally(() => (this.isLoadingUpdateFileInfo = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t('errors.no_access')
        });
      }
    },
    async deleteItem(item) {
       if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this
        this.$swal({
          title: this.$t('custom.you_sure_title'),
          text: this.$t('custom.you_sure_text'),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t('custom.you_sure_yes'),
          cancelButtonText: this.$t('custom.you_sure_no'),
          showCloseButton: true
        }).then(result => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/clients_files/${item.id}`, {
                headers: {
                  Authorization: self.$store.getters.getAuthToken
                }
              })
              .then(() => {
                this.documents.splice(this.documents.indexOf(item), 1);
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000,
                  icon: "success",
                  title: this.$t('custom.deleted'),
                  text: this.$t('other.delete_file')
                });
              })
              .catch(error => {
                this.setError(error, this.$t('errors.file_client_load'));
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t('errors.no_access')
        });
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.id : null,
            username: this.$store.getters.getCurrentUser.name ? this.$store.getters.getCurrentUser.name : null,
            email: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.email : null,
          },
          custom: {
            company: this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.name ? this.$store.getters.getCompany.company.name : null,
          }
        }
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t('errors.error'),
        text: `${error}. ${text}`
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    }
  }
};
</script>
