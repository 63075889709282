<template>
  <span v-resize="checkMobile">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <!-- <h2 v-if="!isMobile">{{ $t("custom.archive") }}</h2>
    <h3 class="text-center" v-else>{{ $t("custom.archive") }}</h3> -->
    <v-toolbar :color="$vuetify.theme.dark ? '' : 'white'" :height="toolbar_height">
      <v-row align="center" justify="center">
        <v-col cols="12" md="5" sm="12" class="text-center">
          <v-btn small color="secondary" :to="{ name: 'Bookings' }">{{
            $t("custom.back_to_booking")
            }}</v-btn>
          <v-btn small class="ml-2" @click="
            toggleShowBookings(), (show_ride_booking = !show_ride_booking)
            " v-if="!show_ride_booking">{{ $t("archive.ride_bookings") }}</v-btn>
          <v-btn small class="ml-2" @click="
            toggleShowBookings(), (show_ride_booking = !show_ride_booking)
            " v-if="show_ride_booking">{{ $t("archive.all_bookings") }}</v-btn>
        </v-col>
        <v-col cols="12" md="5" sm="12">
          <v-text-field v-model="search" append-icon="mdi-magnify" class="mx-2" :label="$t('custom.search')" single-line clearable
            hide-details></v-text-field>
        </v-col>
        <v-col cols="12" md="2" sm="12" class="text-center">
          <v-icon
            v-if="(user_role == 'superadmin' || user_role == 'admin' || 'partner') && bookings && bookings.length > 0"
            @click="toXlsx" class="mx-2" style="cursor: pointer" color="success" :loading="isLoadingToXlsx">
            mdi-file-excel-outline
          </v-icon>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-data-table v-model="selected" :headers="headers" :items="bookings" :search="search" :loading="isLoading"
      :loading-text="$t('custom.loading_table')" :server-items-length.sync="total" :options.sync="tableOptions" :page.sync="page" item-key="id"
      :class="!isMobile ? 'elevation-1 mt-2' : 'elevation-1 mt-2 is-mobile'" :items-per-page="50"
      mobile-breakpoint="100" dense :footer-props="{
        pageText: `{0} ${$t('custom.of')} ${total}`,
        itemsPerPageText: $t('custom.elements_table'),
        showFirstLastPage: true,
        itemsPerPageOptions: [50, 150, 300, 500, 1000],
      }">
      <template slot="header" slot-scope="props">
        <tr>
          <th v-for="header in props.headers" :key="header.text" nowrap>
            {{ header.text }}
          </th>
        </tr>
      </template>
      <template slot="item" slot-scope="props">
        <tr @dblclick="replaceToBooking(props.item)" style="cursor: pointer"
          :class="!props.item.technical ? '' : 'blue-grey lighten-4'">
          <td class="text-center" :key="props.item.id">{{ props.item.id }}</td>
          <td class="text-center" nowrap>
            {{ formatCreatedAt(props.item.created_at) }}
          </td>
          <td class="text-center" nowrap>
            {{ formatCreatedAt(props.item.archived_date) }}
          </td>
          <td class="text-center" nowrap>
            <v-icon v-if="props.item.ride" color="success" v-tooltip="{
              content: $t('bookings.ride_true'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }">
              mdi-garage-variant
            </v-icon>
            <v-icon v-else color="secondery"> mdi-minus </v-icon>
          </td>
          <td class="text-center" nowrap>
            <v-icon v-if="props.item.auto_archived" color="error">
              mdi-robot-outline
            </v-icon>
            <v-icon v-else color="success"> mdi-face-man-outline </v-icon>
          </td>
          <td class="text-center">
            <v-icon small class="mr-2" @click="replaceToBooking(props.item)" :title="$t('archive.booking_info')">
              mdi-eye-outline
            </v-icon>
            <v-icon v-if="checkAdminManager()" small @click="deleteItem(props.item)">
              mdi-delete-forever
            </v-icon>
          </td>
          <td class="text-center">
            <v-icon class="mr-2" color="red" v-if="!props.item.active && !props.item.archive"
              @click="archiveBooking(props.item)">
              mdi-archive-arrow-down
            </v-icon>
            <v-icon class="mr-2" color="green" v-if="!props.item.active && props.item.archive"
              @click="unarchiveBooking(props.item)">
              mdi-archive-arrow-up
            </v-icon>
          </td>
          <td class="text-center">
            <router-link :to="{ path: `/cars/${props.item.car_id}` }">{{ props.item.car_name }}({{ props.item.car_code
              }})</router-link>
          </td>
          <td class="text-center" nowrap>
            <router-link :class="checkClientCategory(props.item.client_id)"
              :to="{ name: 'ClientCard', params: { id: props.item.client_id } }">{{ props.item.first_name ?
                props.item.first_name[0] : "" }}.
              {{ props.item.middle_name ? props.item.middle_name[0] : "" }}.
              {{
                props.item.last_name ? props.item.last_name : ""
              }}</router-link>
          </td>
          <td class="text-center" nowrap>
            {{ formatBookingDates(props.item.start_date) }}
          </td>
          <td class="text-center" nowrap>
            {{ formatBookingDates(props.item.end_date) }}
          </td>
          <td class="text-center">{{ props.item.days }}</td>
          <td class="text-center">{{ props.item.location_start }}</td>
          <td class="text-center">{{ props.item.location_end }}</td>
        </tr>
      </template>
      <template slot="no-data">
        {{ $t("custom.no_data_in_table") }}
      </template>
      <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
        {{ $t("tables.no_search_result", { msg: search }) }}
      </v-alert>
    </v-data-table>
  </span>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
import { isMobile } from "mobile-device-detect";
import PullToRefresh from "pulltorefreshjs";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import xlsx from "json-as-xlsx";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      bookings: [],
      all_bookings: [],
      ride_bookings: [],
      workers_names: [],
      payment: {
        cash: null,
        booking_id: null,
      },
      showArrivalPayments: false,
      show_ride_booking: false,
      arrival_payments: {
        gas: 0,
        damages: 0,
        clean: 0,
        delivery: 0,
        additional_hours: 0,
        other: 0,
      },
      prolongData: {
        days: null,
        price: null,
        additional_hours: null,
        paid: null,
      },
      selected: [],
      search: "",
      rowsPerPageItems: [10, 25, 50, 100],
      headers: [
        { text: this.$t("custom.number"), value: "id", align: "center" },
        {
          text: this.$t("custom.created"),
          value: "created_at",
          align: "center",
        },
        {
          text: this.$t("custom.archived_date"),
          value: "archived_date",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("bookings.ride_true"),
          value: "ride",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("custom.who_archived"),
          value: "auto_archived",
          sortable: false,
          align: "center",
        },
        { text: this.$t("custom.editor"), sortable: false, align: "center" },
        { text: this.$t("custom.actions"), sortable: false, align: "center" },
        { text: this.$t("custom.car"), value: "car_code", align: "center", sortable: false },
        { text: this.$t("custom.client"), value: "last_name", align: "center", sortable: false },
        {
          text: this.$t("custom.start"),
          value: "start_date_formatted",
          align: "center",
        },
        { text: this.$t("custom.end"), value: "end_date_formatted", align: "center" },
        { text: this.$t("custom.days"), value: "days", align: "center", sortable: false },
        {
          text: this.$t("custom.checkin"),
          value: "location_start",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("custom.checkout"),
          value: "location_end",
          align: "center",
          sortable: false
        },
      ],
      sortingBookings: { sortBy: "created_at", descending: true },
      options: {
        sortBy: "car_code",
      },
      calendarConfig: {
        type: "string",
        mask: "DD-MM-YYYY H:mm", // Uses 'iso' if missing,
        // timeAdjust: "12:00:00",
      },
      calendar_attributes: [
        {
          key: "today",
          highlight: {
            color: "red",
            fillMode: "outline",
          },
          dates: new Date(),
        },
      ],
      sort_date_menu: false,
      start_date_format: null,
      sort_date_between_menu: false,
      sort_date: null,
      sort_date_between: null,
      configSort: {
        altFormat: "d-m-Y",
        altInput: true,
        dateFormat: "d-m-Y",
      },
      page: 1,
      per_page: 50,
      sort_by: "id",
      direction: "desc",
      tableOptions: {},
      total: 0,
      timeout: null,
      isMobile: false,
      isLoading: false,
      isLoadingToXlsx: false,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      // this.isLoading = true;
      this.show_ride_booking = this.$store.getters.getShowRideBookings;
    } else {
      this.$store.commit("unsetCurrentUser");
      this.$router.push({ path: `/signin` }).catch((err) => { });
    }
  },
  watch: {
    sort_date() {
      this.getDataFromApi();
    },
    sort_date_between() {
      let self = this;
      if (this.sort_date_between) {
        this.ride_bookings = [];
        this.bookings = [];
        this.all_bookings.map(function (booking) {
          if (booking.ride == true) {
            self.ride_bookings.push(booking);
          }
        });
        this.all_bookings.map(function (booking) {
          self.bookings.push(booking);
        });

        this.bookings = this.bookings.filter((x) =>
          moment(self.sort_date_between, "YYYY-MM-DD").isBetween(
            moment(x.start_date, "DD-MM-YYYY"),
            moment(x.end_date, "DD-MM-YYYY"),
            "day"
          )
        );
        this.ride_bookings = this.ride_bookings.filter((x) =>
          moment(self.sort_date_between, "YYYY-MM-DD").isBetween(
            moment(x.start_date, "DD-MM-YYYY"),
            moment(x.end_date, "DD-MM-YYYY"),
            "day"
          )
        );
      } else {
        this.ride_bookings = [];
        this.bookings = [];
        this.all_bookings.map(function (booking) {
          if (booking.ride == true) {
            self.ride_bookings.push(booking);
          }
        });
        this.all_bookings.map(function (booking) {
          self.bookings.push(booking);
        });
      }
    },
    tableOptions(pagination) {
      this.page = pagination.page;
      this.per_page = pagination.itemsPerPage;
      this.sort_by = pagination.sortBy[0] ? pagination.sortBy[0] : "id";
      this.direction = pagination.sortDesc[0] ? "asc" : "desc";
      this.getDataFromApi();
    },
    search() {
      if (this.search == "") {
        this.search = null;
      } else {
        let self = this;
        this.page = 1;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.getDataFromApi();
          }, 300); // delay
        }
        debounce();
      }
    },
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    sort_date_formatted() {
      if (this.sort_date) {
        return moment(this.sort_date, "YYYY-MM-DD").format("DD-MM-YYYY");
      } else {
        return "";
      }
    },
    sort_date_between_formatted() {
      if (this.sort_date_between) {
        return moment(this.sort_date_between, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );
      } else {
        return "";
      }
    },
    toolbar_height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 180
        case 'sm': return 180
        case 'md': return 65
        case 'lg': return 65
        case 'xl': return 65
      }
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    checkWriteFile() {
      return Capacitor.isNativePlatform() && Filesystem.checkPermissions();
    },
    requestPermissionsWriteFile() {
      return Filesystem.requestPermissions();
    },
  },
  methods: {
    getDataFromApi() {
      let self = this;
      this.isLoading = true;
      this.axios
        .post(
          `/api/v1/archive_bookings_v2`,
          {
            search: this.search,
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            direction: this.direction,
            rided: !this.show_ride_booking,
            // sort_date in seconds
            // start_or_end: moment(this.sort_date).unix() / 1000,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.bookings = [];
          response.data.bookings.data.forEach((booking) => {
            this.bookings.push(booking.attributes);
          });
          this.total = response.data.total;
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.error"));
        })
        .finally(() => (this.isLoading = false));
    },
    deleteItem(booking) {
      if (booking.vseprokaty_id) {
        this.$swal({
          position: "center",
          showConfirmButton: true,
          confirmButtonText: this.$t("custom.ok"),
          icon: "warning",
          title: this.$t("agregator.no_delete_aggregator"),
          text: this.$t("agregator.no_delete_aggregator_text"),
        });
        return;
      }
      this.$swal({
        title: this.$t("custom.you_sure_title"),
        text: this.$t("custom.you_sure_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.you_sure_yes"),
        cancelButtonText: this.$t("custom.you_sure_no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value) {
          this.axios
            .delete(`/api/v1/bookings/${booking.id}`, {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              this.bookings.splice(this.bookings.indexOf(booking), 1);
              this.ride_bookings.splice(this.ride_bookings.indexOf(booking), 1);
            })
            .catch((error) =>
              this.setError(error, this.$t("errors.archive_bookings_delete"))
            );
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            icon: "success",
            title: this.$t("custom.deleted"),
            text: this.$t("custom.deleted_ok"),
          });
        }
      });
    },
    findWorker(id) {
      let name;
      if (this.workers_names.find((worker) => worker.id == id)) {
        name = this.workers_names.find((worker) => worker.id == id).name
          ? this.workers_names.find((worker) => worker.id == id).name
          : this.workers_names.find((worker) => worker.id == id).email;
      }
      if (name) {
        return name;
      } else {
        return id;
      }
    },
    toXlsx() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let bookings = this.all_bookings;
        this.isLoadingToXlsx = true;
        this.axios
          .get("/api/v1/get_workers_names_with_ids", {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.workers_names = response.data;
            let bookings_data = this.bookings;

            let data = [
              {
                sheets: "BookingArchive",
                columns: [
                  { label: "ID", value: "id" },
                  { label: this.$t("custom.created_at"), value: "created_at" },
                  { label: this.$t("bookings.name"), value: "first_name" },
                  {
                    label: this.$t("bookings.middlename"),
                    value: "middle_name",
                  },
                  { label: this.$t("bookings.lastname"), value: "last_name" },
                  { label: this.$t("bookings.car"), value: "car_name" },
                  { label: this.$t("doc_variables.car_code"), value: "car_code" },

                  { label: this.$t("bookings.start_date"), value: "start_date" },
                  { label: this.$t("bookings.end_date"), value: "end_date" },
                  { label: this.$t("bookings.days"), value: "days" },
                  { label: this.$t("bookings.add_hours_short"), value: "additional_hours" },
                  { label: this.$t("bookings.price"), value: "price" },
                  {
                    label: this.$t("bookings.rental_cost"),
                    value: "rental_cost",
                  },
                  { label: this.$t("bookings.delivery"), value: "delivery" },
                  {
                    label: this.$t("bookings.checkout"),
                    value: "delivery_end",
                  },
                  { label: this.$t("bookings.equipment"), value: "equipment" },
                  { label: this.$t("bookings.insurance"), value: "insurance" },
                  {
                    label: this.$t("bookings.clean"),
                    value: "clean_payment",
                  },
                  {
                    label: this.$t("bookings.add_drivers_2"),
                    value: "add_drivers_cost",
                  },
                  { label: this.$t("bookings.hours_cost"), value: "hours_cost" },
                  {
                    label: this.$t("bookings.mileage_cost"),
                    value: "mileage_cost",
                  },
                  { label: this.$t("bookings.damages"), value: "damage" },
                  { label: this.$t("bookings.gas"), value: "gas" },
                  { label: this.$t("bookings.for_fines"), value: "fine" },
                  { label: this.$t("bookings.other"), value: "other" },
                  {
                    label: this.$t("bookings.for_other_end"),
                    value: "other_end",
                  },
                  {
                    label: this.$t("bookings.hours_cost_end"),
                    value: "hours_cost_end",
                  },
                  {
                    label: this.$t("clients.client_source"),
                    value: "client_source",
                  },
                  {
                    label: this.$t("clients.source"),
                    value: "source",
                  },
                  {
                    label: this.$t("bookings.checkiner"),
                    value: (row) => {
                      return this.findWorker(row.start_worker_id);
                    },
                  },
                  {
                    label: this.$t("bookings.checkouter"),
                    value: (row) => {
                      return this.findWorker(row.end_worker_id);
                    },
                  },
                ],
                content: bookings_data,
              },
            ];
            let settings = {
              fileName: "booking_archive", // Name of the resulting spreadsheet
              extraLength: 3, // A bigger number means that columns will be wider
              writeMode: "write", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
              writeOptions: {
                bookType: "xlsx",
                type: "array",
              }, // Style options from https://docs.sheetjs.com/docs/api/write-options
              RTL: false, // Display the columns from right-to-left (the default value is false)
            };
            let wbout = xlsx(data, settings);
            var blob_data = new Blob([new Uint8Array(wbout)], {type:"application/octet-stream"});
            if (!this.isNative) {
              saveAs(blob_data, 'booking_archive.xlsx');
            } else {
              if (Filesystem.checkPermissions()) {
                const getBase64FromBlob = async (blob) => {
                  return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                      const base64data = reader.result;
                      resolve(base64data);
                    }
                  });
                }
                getBase64FromBlob(blob_data).then(
                  (base64) => {
                    // save file
                    async function writeFile() {
                      return await Filesystem.writeFile({
                        path: 'booking_archive.xlsx',
                        data: base64,
                        directory: Directory.Documents,
                      });
                    };
                    let file_uri = null
                    let writedFile = writeFile().then((getUriResult) => {
                      // share file opened file
                      let shareFile = async () => {
                        await Share.share({
                          title: 'Download document',
                          files: [getUriResult.uri],
                        });
                      };
                      let sharedFile = shareFile();
                    });
                  }
                );
              } else {
                Filesystem.requestPermissions();
              }
            }
          })
            .catch((error) => {
              this.setError(error, "Something went wrong in get_workers_names");
              console.log(error);
            })
            .finally(() => (this.isLoadingToXlsx = false));

      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    translateCategory(category) {
      let group_text = "";
      switch (category) {
        case "Оплата аренды":
          group_text = this.$t("groups_counts.rent");
          break;
        case "Доплата за доставку":
          group_text = this.$t("groups_counts.delivery");
          break;
        case "Доплата за приём":
          group_text = this.$t("groups_counts.checkout");
          break;
        case "Доплата за топливо":
          group_text = this.$t("groups_counts.fuel");
          break;
        case "Доплата за мойку":
          group_text = this.$t("groups_counts.clean");
          break;
        case "Доплата за оборудование":
          group_text = this.$t("groups_counts.equipment");
          break;
        case "Доплата за дополнительное время":
          group_text = this.$t("groups_counts.add_time");
          break;
        case "Доплата за повреждения":
          group_text = this.$t("groups_counts.damages");
          break;
        case "Доплата за штрафы":
          group_text = this.$t("groups_counts.fines");
          break;
        case "Доплата за другое":
          group_text = this.$t("groups_counts.other");
          break;
        case "Доплата за превышение пробега":
          group_text = this.$t("groups_counts.mileage");
          break;
        case "Доплата за страховку":
          group_text = this.$t("groups_counts.insurance");
          break;
        case "Доплата за доп водителей":
          group_text = this.$t("groups_counts.add_drivers");
          break;
        case "Ремонт":
          group_text = this.$t("groups_counts.repair");
          break;
        case "Обслуживание":
          group_text = this.$t("groups_counts.service");
          break;
        case "Запчасти":
          group_text = this.$t("groups_counts.parts");
          break;
        case "Страховка":
          group_text = this.$t("groups_counts.insurance_main");
          break;
        case "Накладные расходы":
          group_text = this.$t("groups_counts.common_expences");
          break;
        case "Транспортные расходы":
          group_text = this.$t("groups_counts.transport_expences");
          break;
        case "Налоги":
          group_text = this.$t("groups_counts.tax");
          break;
        case "Штрафы":
          group_text = this.$t("groups_counts.fines_main");
          break;
        case "Аренда имущества":
          group_text = this.$t("groups_counts.rent_property");
          break;
        case "Мойка автомобилей":
          group_text = this.$t("groups_counts.clean_main");
          break;
        case "Топливо":
          group_text = this.$t("groups_counts.fuel_main");
          break;
        case "Реклама":
          group_text = this.$t("groups_counts.ads");
          break;
        case "Зарплата":
          group_text = this.$t("groups_counts.salary");
          break;
        case "Залог":
          group_text = this.$t("groups_counts.deposit");
          break;
        case "Внутренние переводы":
          group_text = this.$t("groups_counts.internal");
          break;
        case "Продажа имущества":
          group_text = this.$t("groups_counts.sell_property");
          break;
        case "Покупка имущества":
          group_text = this.$t("groups_counts.buy_property");
          break;
        case "Прочие расходы":
          group_text = this.$t("groups_counts.other_expences");
          break;
        case "Прочие доходы":
          group_text = this.$t("groups_counts.other_incomes");
          break;
        case "Выплаты партнёрам":
          group_text = this.$t("groups_counts.partners_payments");
          break;
        case "Сервисные сборы":
          group_text = this.$t("groups_counts.service_fees");
          break;
        case "Пополнение баланса клиента":
          group_text = this.$t("groups_counts.top_up_client_balance");
          break;
        case "Выплаты агентам":
          group_text = this.$t("groups_counts.agent_payments");
          break;
      }
      return group_text;
    },
    checkAdminManager() {
      return (
        this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isManager
      );
    },
    toggleShowBookings() {
      this.$store.commit("show_ride_bookings");
      this.getDataFromApi();
    },
    formatCreatedAt(date) {
      if (moment(date).isValid()) {
        return moment.parseZone(date).format("DD MMM H:mm");
      } else {
        return date;
      }
    },
    checkClientCategory(client_id) {
      let client = this.set_client;
      if (client && client.category == this.$t("clients_groups.new")) {
        return "" + client.debt > 0 ? "orange darken-4" : "";
      } else if (
        client &&
        client.category == this.$t("clients_groups.permanent")
      ) {
        return `green--text ${client.debt > 0 ? " orange darken-4" : ""}`;
      } else if (client && client.category == this.$t("clients_groups.gray")) {
        return `grey--text text-decoration-line-through ${client.debt > 0 ? " orange darken-4" : ""
          }`;
      } else if (client && client.category == this.$t("clients_groups.black")) {
        return `black--text text-decoration-line-through ${client.debt > 0 ? " orange darken-4" : ""
          }`;
      } else if (client && client.category == this.$t("clients_groups.loyal")) {
        return `deep-purple--text ${client.debt > 0 ? " orange darken-4" : ""}`;
      }
    },
    formatBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("DD MMM H:mm");
    },
    replaceToBooking(booking) {
      this.$router.push({ path: `/bookings/${booking.id}` }).catch((err) => { });
    },
    archiveBooking(booking) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        if (booking) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/bookings/${booking.id}`,
              {
                bookings: {
                  archive: true,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let foundIndex = this.bookings.findIndex(
                (element) => element.id === booking.id
              );
              this.bookings[foundIndex].archive = true;
            })
            .catch((error) => {
              this.setError(
                error,
                this.$t("errors.archive_bookings_archiving")
              );
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.archive_bookings_archiving"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    unarchiveBooking(booking) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        if (booking) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/bookings/${booking.id}`,
              {
                bookings: {
                  archive: false,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let foundIndex = this.bookings.findIndex(
                (element) => element.id === booking.id
              );
              this.bookings[foundIndex].archive = false;
            })
            .catch((error) => {
              this.setError(
                error,
                this.$t("errors.archive_bookings_unarchiving")
              );
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.archive_bookings_unarchiving"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
